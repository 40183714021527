export default function DividerLight() {
    return (
        <div className="my-secondDegree flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="1188" height="2" viewBox="0 0 1188 2" fill="none">
                <path d="M1 1H1187" stroke="url(#paint0_linear_1807_2392)" strokeLinecap="round" />
                <defs>
                    <linearGradient id="paint0_linear_1807_2392" x1="1187" y1="1" x2="19.8484" y2="1" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#205CC1" stopOpacity="0" />
                        <stop offset="0.479167" stopColor="#205CC1" />
                        <stop offset="1" stopColor="#205CC1" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}