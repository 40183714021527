import React from 'react';
import SkeletonOverview from './SkeletonOverview';
import SkeletonOverflow from './SkeletonOverflow';

// Map of relationships between names and Skeleton components
const skeletonComponents = {
  'overview': SkeletonOverview,
  'overflow': SkeletonOverflow,
  // Add other relationships as needed
};
export default function SkeletonHandler({ name }) {
  // Convert the name to lowercase for comparison
  // eslint-disable-next-line react/prop-types
  const lowercaseName = name.toLowerCase();
  // Get the corresponding Skeleton component or null if there is no match
  const SkeletonComponent = skeletonComponents[lowercaseName] || null;

  return (
    <div>
      {SkeletonComponent && <SkeletonComponent />}
    </div>
  );
}
