/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@/components/molecules/inputs/Input";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { validateFormData } from "./validateForm";
import useId from "@mui/material/utils/useId";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextArea from "@/components/molecules/inputs/TextArea";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
export default function AddCertification({
    certification,
    showForm,
    setShowForm,
    setCertifications,
    editingCertification = false,
    setEditingCertification,
}) {
    const form = useForm();
    const id = useId();
    const [formData, setFormData] = useState({
        title: certification ? certification.title : "",
        organism: certification ? certification.organism : "",
        date: certification ? certification.date : "",
        description: certification ? certification.description : "",
        id: certification ? certification.id : id,
    });
    const [erros, setErros] = useState({
        title: "",
        organism: "",
        date: "",
        description: "",

    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        const { value } = e.target;

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingCertification) {
                // Si nous sommes en mode édition, mettez à jour la description existante

                setCertifications((prevData) =>
                    prevData.map((item) =>
                        item.id === certification.id ? formData : item
                    )
                );
            } else {
                // Sinon, ajoutez simplement la nouvelle description à la liste
                // console.log("id ", id);
                setCertifications((prevData) => [...prevData, formData]);
            }
            setShowForm(false);
        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("value invalid", erros);
            console.log("form data", formData);
        }
    };
    const handleCancel = () => {
        setEditingCertification(false);
        setShowForm(false);
    };
    const handleDelete = () => {
        setShowForm(false);
        if (certification) {
            // Supprimer la certification de la liste
            setCertifications((prevData) =>
                prevData.filter((item) => item.id !== certification.id)
            );
        }
    };
    return (
        <div>
            {showForm && (
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}
                        className={` flex flex-col gap-secondDegree shadow-md bg-smoke  border-sm border-medium  p-secondDegree `}
                    >
                        <TextFieldInput
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.certificationTitle"
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange(e, "title")}
                            color={listOfInvalidInput.includes("title") ? "error" : ""}
                        // border={
                        //   listOfInvalidInput.includes("certificationTitle")
                        //     ? "border-error"
                        //     : ""
                        // }
                        />
                        <TextFieldInput
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.organism"
                            type="text"
                            value={formData.organism}
                            onChange={(e) => handleChange(e, "organism")}
                            color={listOfInvalidInput.includes("organism") ? "error" : ""}
                        // border={
                        //   listOfInvalidInput.includes("organism") ? "border-error" : ""
                        // }
                        // className={
                        //   listOfInvalidInput.includes("organism") ? "border-error" : ""
                        // }
                        />
                        <TextFieldInput
                            // placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.date"
                            type={"date"}
                            value={formData.date}
                            onChange={(e) => handleChange(e, "date")}
                            color={listOfInvalidInput.includes("date") ? "error" : ""}
                        //border={listOfInvalidInput.includes("date") ? "border-error" : ""}
                        />
                        <TextArea
                            value={formData.description}
                            onChange={(e) => handleChange(e, "description")}
                            placeHolder="consultants.statement.prospectConsultantPanel.description"
                            rows={4} // Nombre de lignes par défaut
                            cols={40} // Nombre de colonnes par défaut
                            className="rounded-sm border-disabled border-[1px] p-firstDegree w-full " // Ajoutez d'autres attributs ou styles au besoin
                        />

                        <div className="flex flex-row gap-[12px] items-center w-full">
                            <Button
                                onClick={handleDelete}
                                showLeftIcon={true}
                                leftIcon={
                                    <DeleteIcon className="text-primary cursor-pointer" />
                                }
                                style="ghost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<ClearIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.cancel"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                className="font-bold border-primary w-full"
                                onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<SaveIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.save"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                className="font-bold w-full"
                                type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    </form>
                </Form>
            )}
        </div>
    );
}
