import React, { useState, useEffect } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

export default function Copy({ value, ...props }) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setCopied(false)
        }, 3000)
    }, [copied == true])

    return (
        <button {...props} className="invisible group-hover:visible">
            {copied ? <LibraryAddCheckIcon fontSize='small' color='success' /> : <ContentCopyIcon
                color='secondary'
                fontSize='small'
                className='cursor-pointer'
                onClick={async () => {
                    await navigator.clipboard.writeText(value);
                    setCopied(true)
                }}
            />
            }

        </button>
    )
}