import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';

const useLogin = () => {
  const navigate = useNavigate();
  const { simulateLogin } = useAuth();

  const handleLogin = (role) => {
    simulateLogin(role);
    // Rediriger en fonction du rôle
    if (role === 'admin') {
      navigate('/consultant');
    } else if (role === 'consultant') {
      navigate('/administration');
    }
  };

  return handleLogin;
};

export default useLogin;
