import React, { useRef } from 'react';
import { Button } from "@/components/molecules/buttons/button";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Expense from "./expense";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import FormNewExpense from './formNewExpense';
import useExpenseData from './useExpenseDataUser';


export default function ExpenseTabUser() {
    const submitRef = useRef(null);
    const { expenses, error } = useExpenseData();

    const handleSubmit = async (formData) => {
        console.log("Form data:", formData);
        try {
            // const response = await fetch('API_URL', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(formData)
            // });
            // const data = await response.json();
            // console.log("Response:", data);
        } catch (error) {
            console.error("Erreur lors de la soumission:", error);
        }
    };
    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-scroll pb-secondDegree px-1">


            <Sheet>
                <SheetTrigger >
                    <Button leftIcon={<AddRoundedIcon />} showLeftIcon>New expense account</Button>

                </SheetTrigger>
                <SheetContent >
                    <SheetHeader
                        title={"consultants.expense.newExpense"}
                    />
                    <SheetSlot className=" flex ">
                        <FormNewExpense onSubmit={handleSubmit} submitRef={submitRef} />
                        {/* <NewCooptation changeData={changeData} showErrorMessage={showErrorMessage} global={global}/> */}
                    </SheetSlot>
                    <SheetFooter
                        buttonOutlineText={"global.actions.clear"}
                        buttonDressedText={"global.actions.ConfirmNext"}
                        warning={"consultants.expense.fileAdded"}
                        onClickOutline={(e) => submitRef.current && submitRef.reset.resetForm()}
                        onClickDressed={() => submitRef.current && submitRef.current.click()}
                    />
                </SheetContent>
            </Sheet>

            {(!error && expenses) && expenses.map((item, index) => (
                <Expense key={index} data={item} dataOwner />
            ))}
        </div>
    )
}