import axios from "axios";
import { useEffect } from "react";
const data = {
  user: {
    id: "55be2cd0-2799-4f83-9db7-ea17e9c4ef68",
    firstName: "John",
    name: "DOE",
    personalEmail: "johndoe@gmail.com",
    dayOfBirth: "01/02/1997",
    cooptedBy: "cocojones@gmail.com",
    urlPicture: "https://thispersondoesnotexist.com",
    resumeState: "draft", //or complete
  },
  education: [
    {
      id: "cf10488b-4dd2-48bc-b587-3ae9b6641576",
      title: "Education title",
      school: "School",
      city: "Paris",
      country: "France",
      startDate: "10/2020",
      endDate: "02/2023",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
    {
      id: "55be2cd0-2799-4f83-9db7-ea17e9c4ef68",
      title: "Education title",
      school: "School",
      city: "Paris",
      country: "France",
      startDate: "10/2020",
      endDate: "02/2023",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
    {
      id: "8ed7823a-9942-45f0-aac5-57ed3856d538",
      title: "Education title",
      school: "School",
      city: "Paris",
      country: "France",
      startDate: "10/2020",
      endDate: "02/2023",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
  ],
  experience: [
    {
      id: "e34553ac-5c85-4479-b599-de39ed233207",
      title: "Data Engineer",
      sector: "Aviation",
      company: "Data Engineer",
      city: "Paris",
      country: "France",
      startDate: "01/10/2020",
      endDate: "02/02/2023",
      technologies: "JAVA, Spark, Hadoop",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
    {
      id: "ebbf814e-4406-4625-9551-c9108e66dcd3",
      title: "Software Developer",
      sector: "IT",
      company: "Sonar Ltd.",
      city: "Paris",
      country: "France",
      startDate: "01/10/2020",
      endDate: "02/02/2023",
      technologies: "JAVA, Spark, Hadoop",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
    {
      id: "2a57c615-4d42-44c0-93ee-eb10a5ec523b",
      title: "Data Scientist",
      sector: "Tech",
      company: "Tech Solutions",
      city: "Paris",
      country: "France",
      startDate: "01/10/2020",
      endDate: "02/02/2023",
      technologies: "JAVA, Spark, Hadoop",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
  ],
  project: [
    {
      id: "31ea39b4-a065-48e4-ab9a-39adfda3bc3c",
      title: "E-commerce Website",
      sector: "Commerce",
      company: "Company",
      city: "New york",
      country: "USA",
      year: "2020",
      startDate: "01/10/2020",
      endDate: "02/02/2023",
      technologies: "JAVA, Spark, Hadoop",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
    {
      id: "f5ae42c6-dd62-48d4-9af1-f553ffa8f884",
      title: "Data Analysis Tool",
      sector: "Sector",
      city: "Paris",
      country: "France",
      company: "Company",
      startDate: "01/01/2023",
      endDate: "01/01/2024",
      technologies: "JAVA, Spark, Hadoop",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
  ],
  certification: [
    {
      id: "bae53145-a0b9-49f4-9574-9244076c7245",
      title: "Certification title ",
      organism: "Company",
      date: "12/02/2023",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
    {
      id: "75341f64-043a-40ea-ac67-515c3759a1be",
      title: "Certification title ",
      organism: "Company",
      date: "12/02/2023",
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
    },
  ],

  jobTitle: [
    { description: "Job title 1", id: "a665869d-6a51-4564-b62a-76004d07ec46" },
    { description: "Job title 2", id: "4a6eb9f3-9884-48e9-b246-39a05932740c" },
    { description: "Job title 3", id: "d7d34f1a-e9cb-4724-92cf-2ee25af1edd1" },
    { description: "Job title 4", id: "4f420cd1-6f34-4d70-9c41-878b00fa5140" },
  ],
  description: [
    {
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
      id: "d64f2cf4-0c31-4cd4-ba92-f8a33461d071",
      date: "01/02/2023",
    },
    {
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
      id: "dd21b8db-a10b-456e-a3bc-d18eadeef94f",
      date: "01/02/2023",
    },
    {
      description:
        "Lorem ispum dolor sit amet consectetur. Integer purus sollicitudin arcu cras sed. Libero viverra id arcu sed id mauris quam etiam sed.  ",
      id: "af3ad8b9-c522-49d2-98de-1a54692cfe97",
      date: "01/02/2023",
    },
  ],
  template: [
    {
      title: "Template title 1",
      countryISE2: "FR",
      id: "93d7d7ad-f3b3-427c-897f-055130108248",
    },
    {
      title: "Template title 2",
      countryISE2: "GB",
      id: "838d3022-7eca-4040-87ed-d60fa526e4d6",
    },
  ],
  skills: [
    {
      title: "C++",
      type: "hard",
      id: "3edad995-3bf0-4ddd-9ebd-d9c8eea271dc",
      level: 1,
    },
    {
      title: "Java",
      type: "hard",
      id: "23b98262-788e-4b42-9de5-c0798f589a69",
      level: 2,
    },
    {
      title: "JS",
      type: "hard",
      id: "6f57a435-7e2c-46f8-a949-35cae82d5e87",
      level: 4,
    },

    {
      title: "Commmunication",
      type: "soft",
      id: "be4f7e07-ea36-44b8-905c-7a817a7933f2",
      level: 5,
    },
    {
      title: "Teamwork",
      type: "soft",
      id: "16c476ba-184b-420b-b22c-80a3f67bef3e",
      level: 3,
    },
    {
      title: "Problem solving",
      type: "soft",
      id: "7fad1540-7658-4802-835d-4c22ec8c7f48",
      level: 2,
    },

    {
      title: "Data analysis",
      type: "power",
      id: "af57ea0e-4b0f-4759-b02d-481b61037bd5",
      level: 2,
    },
    {
      title: "Digital Literacy",
      type: "power",
      id: "ebf6fb30-9603-445a-b4a9-c1c6d111579d",
      level: 2,
    },
    {
      title: "Project Management",
      type: "power",
      id: "4b48d42e-2644-414b-bc0a-73ba6953c785",
      level: 2,
    },
  ],
  languages: [{ language: "French", level: "A1" ,id:"1"},{ language:"English", level:"A1", id:"2"}],
};
//rajouter la partie langue
//const getCVData = ({id}) 
const getCVData = () => {
  //  try {
  //   // Récupérer la liste d'utilisateurs depuis l'API
  //   const response = await axios.get('https://jsonplaceholder.typicode.com/users');

  //   return response;
  // } catch (error) {
  //   console.error('Error fetching users:', error.message);
  //   throw error;
  // }

  // const data = {education, experience, project, certificaton, profile, jobName, description, template}

  return data;
};
const getPdfConfirmation = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, 10000);
  });
};
const getDeletionConfirmation = ({ idBlock }) => {
  const updatedCVData = Object.keys(data).reduce((acc, key) => {
    const filteredBlocks = data[key].filter((block) => {
      if (block.id === idBlock) {
        return false;
      }
      return true;
    });
    acc[key] = filteredBlocks;
    return acc;
  }, {});
  return true;
};
const getTranslationConfirmation = ({ idBlock }) => {
  //  try {
  //   // Récupérer la liste d'utilisateurs depuis l'API
  //   const response = await axios.get('https://jsonplaceholder.typicode.com/users');

  //   return response;
  // } catch (error) {
  //   console.error('Error fetching users:', error.message);
  //   throw error;
  // }

  // const data = {education, experience, project, certificaton, profile, jobName, description, template}

  return true;
};
const getDuplicationConfirmation = ({ idBlock }) => {
  //  try {
  //   // Récupérer la liste d'utilisateurs depuis l'API
  //   const response = await axios.get('https://jsonplaceholder.typicode.com/users');

  //   return response;
  // } catch (error) {
  //   console.error('Error fetching users:', error.message);
  //   throw error;
  // }

  // const data = {education, experience, project, certificaton, profile, jobName, description, template}

  return true;
};



export function getSelectableSkills() {
  return [
    // Hard Skills
    {
      title: "Python",
      type: "hard",
      id: "f7a3f960-4a2f-4ec3-a758-7b9723b71e85",
      level: 4,
    },
    {
      title: "SQL",
      type: "hard",
      id: "8a7e93d3-3f29-4c17-9c2b-49f7256a6aa5",
      level: 3,
    },
    {
      title: "Ruby",
      type: "hard",
      id: "73f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "C#",
      type: "hard",
      id: "b7f58ae1-23f5-4c3b-b6b1-f9a43c9a0c3a",
      level: 3,
    },
    {
      title: "HTML/CSS",
      type: "hard",
      id: "c5e87a60-4a2e-4ec3-a758-7b9723b71e85",
      level: 4,
    },
    {
      title: "PHP",
      type: "hard",
      id: "f7a3f960-3a1b-4ec3-a758-7b9723b71e85",
      level: 3,
    },
    {
      title: "JavaScript",
      type: "hard",
      id: "d6a3f960-4a2f-4ec3-a758-7b9723b71e85",
      level: 5,
    },
    {
      title: "Kotlin",
      type: "hard",
      id: "h5a3f960-4a2f-4ec3-a758-7b9723b71e85",
      level: 2,
    },
    {
      title: "Swift",
      type: "hard",
      id: "g7a3f960-4a2f-4ec3-a758-7b9723b71e85",
      level: 3,
    },
    {
      title: "R Programming",
      type: "hard",
      id: "e4a3f960-4a2f-4ec3-a758-7b9723b71e85",
      level: 3,
    },
    {
      title: "Shell Scripting",
      type: "hard",
      id: "e8a3f960-4a2f-4ec3-a758-7b9723b71e85",
      level: 2,
    },
    {
      title: "MATLAB",
      type: "hard",
      id: "d9a3f960-4a2f-4ec3-a758-7b9723b71e85",
      level: 3,
    },
    {
      title: "TensorFlow",
      type: "hard",
      id: "b6f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "PyTorch",
      type: "hard",
      id: "b6a3f960-4a2f-4ec3-a758-7b9723b71e85",
      level: 3,
    },
    {
      title: "Machine Learning",
      type: "hard",
      id: "c3f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 4,
    },
    {
      title: "Deep Learning",
      type: "hard",
      id: "f4e8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Data Visualization",
      type: "hard",
      id: "f5f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Big Data",
      type: "hard",
      id: "g4f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Data Science",
      type: "hard",
      id: "c2f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Computer Vision",
      type: "hard",
      id: "c3f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Natural Language Processing",
      type: "hard",
      id: "f3f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Network Administration",
      type: "hard",
      id: "d3f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 2,
    },
    {
      title: "Cybersecurity",
      type: "hard",
      id: "e3f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "DevOps",
      type: "hard",
      id: "a3f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Software Testing",
      type: "hard",
      id: "b3f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Agile",
      type: "hard",
      id: "a4f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 2,
    },
    {
      title: "Cloud Computing",
      type: "hard",
      id: "a5f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "AWS",
      type: "hard",
      id: "a6f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Azure",
      type: "hard",
      id: "a7f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 2,
    },
    {
      title: "Google Cloud",
      type: "hard",
      id: "a8f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 2,
    },
    {
      title: "Blockchain",
      type: "hard",
      id: "b3f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 2,
    },
    {
      title: "Quantum Computing",
      type: "hard",
      id: "c2f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 1,
    },
    {
      title: "Game Development",
      type: "hard",
      id: "b4f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 2,
    },
    {
      title: "UI/UX Design",
      type: "hard",
      id: "b5f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Mobile App Development",
      type: "hard",
      id: "c6f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Web Development",
      type: "hard",
      id: "c7f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 4,
    },
    {
      title: "Software Development",
      type: "hard",
      id: "c8f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 4,
    },
    {
      title: "Operating Systems",
      type: "hard",
      id: "b2f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },

    // Soft Skills
    {
      title: "Critical Thinking",
      type: "soft",
      id: "16c4f7e07-184b-420b-b22c-80a3f67bef3e",
      level: 3,
    },
    {
      title: "Leadership",
      type: "soft",
      id: "15e4f7e07-ea36-44b8-905c-7a817a7933f2",
      level: 4,
    },
    {
      title: "Adaptability",
      type: "soft",
      id: "14d4f7e07-184b-420b-b22c-80a3f67bef3e",
      level: 3,
    },
    {
      title: "Creativity",
      type: "soft",
      id: "13c4f7e07-184b-420b-b22c-80a3f67bef3e",
      level: 3,
    },
    {
      title: "Emotional Intelligence",
      type: "soft",
      id: "12c4f7e07-184b-420b-b22c-80a3f67bef3e",
      level: 3,
    },
    {
      title: "Public Speaking",
      type: "soft",
      id: "11b4f7e07-ea36-44b8-905c-7a817a7933f2",
      level: 2,
    },
    {
      title: "Interpersonal Skills",
      type: "soft",
      id: "10a4f7e07-ea36-44b8-905c-7a817a7933f2",
      level: 3,
    },

    // Power Skills
    {
      title: "Business Acumen",
      type: "power",
      id: "1f8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 2,
    },
    {
      title: "Customer Relationship Management",
      type: "power",
      id: "2e8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Time Management",
      type: "power",
      id: "3e8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Negotiation",
      type: "power",
      id: "4e8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 2,
    },
    {
      title: "Resource Allocation",
      type: "power",
      id: "5e8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Budget Management",
      type: "power",
      id: "6e8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Product Development",
      type: "power",
      id: "7e8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
    {
      title: "Business Strategy",
      type: "power",
      id: "8e8ae18-4c1d-4b9f-bbcc-7313f4c99f71",
      level: 3,
    },
  ];

}

export {
  getCVData,
  getPdfConfirmation,
  getDeletionConfirmation,
  getTranslationConfirmation,
  getDuplicationConfirmation,
};
