/* eslint-disable react/prop-types */
import React from "react";
import { Label } from "@/components/atoms/texts/label";
import { cn } from "@/lib/utils";
import { isNumeric } from "@/utils/formatDateString";
export default function FilterItem({
  itemContent,
  isSelected = false,
  onClick,
  className,
}) {
  const handleClick = () => {
    if (onClick) {
      onClick(itemContent);
    }
  };

  return (
    <div
      className={cn(
        className,
        "p-firstDegree text-body font-600  text-center h-full truncate",
        isSelected
          ? "rounded-filterItemBorder font-bold bg-primary  filterItem outline outline-2 outline-secondary "
          : "",
        isNumeric(itemContent) ? "w-[70px]" : ""
      )}
      onClick={handleClick}
    >
      <Label
        size="body"
        fontWeight=""
        className={`${isSelected ? "text-white" : "text-primary"}`}
       // message={!isNumeric(itemContent) && itemContent}
      >
        {/* {isNumeric(itemContent) && itemContent} */}

        {itemContent}
      </Label>
    </div>
  );
}
