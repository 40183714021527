/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Input from "@/components/molecules/inputs/Input";
import Stars from "./Stars";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { motion, useAnimation } from "framer-motion";
import { item, container } from "@/utils/animationConst";

import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import UniqueSelect from "@/components/molecules/inputs/UniqueSelect";
import { getSelectableSkills } from "@/services/global-services/cvData";
import generateRandomId from "@/utils/randomId";

export default function AddSkill({
    showForm = true,
    setShowForm,
    setSkills,
    editingSkill = false,
    skill,
}) {
    const selectableSkills = getSelectableSkills();
    const form = useForm();
    const id = generateRandomId();
    const [formData, setFormData] = useState({
        title: skill ? skill.title : "",
        level: skill ? skill.level : 0,
        type: skill ? skill.type : "",
        id: id,
    });
    // skill && console.log(skill.title);
    const [erros, setErros] = useState({
        skill: "",
        indexOfStar: "",
    });
    const [inputIsValid, setInputIsValid] = useState(false);
    const [isStarsValid, setIsStarsValid] = useState(true);
    const controls = useAnimation();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        //const isValid = validateFormData(formData, setErros);

        if (formData.title == "") {
            setInputIsValid(false);
        }
        if (formData.level == 0) {
            controls.start("start");
            console.log("level is invalid");
            setIsStarsValid(false);
        }
        if (formData.title != "" && formData.level != 0) {
            setIsStarsValid(true);
            setSkills((prevData) => [...prevData, formData]);
            setShowForm(false);
        }
    };
    const handleChange = (value, name) => {
        // const { value } = e.target;
        //const value=e
        setInputIsValid(true);

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        console.log(formData);
    };
    const setStars = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            level: index,
        }));
        setIsStarsValid(true);
    };
    const handleDelete = () => {
        setShowForm(false);
        if (skill) {
            // Supprimer la certification de la liste
            setSkills((prevData) =>
                prevData.filter((item) => item.title !== skill.title)
            );
        }
    };

    const setSkillNameAndType = (title) => {
        const selectedSkills = selectableSkills.filter(
            (skill) => skill.title === title
        );
        if (selectedSkills) {
            handleChange(title, "title");
            handleChange(selectedSkills.type, "type");
        }
    };
    return (
        <motion.div
            initial={{
                scale: 0.9,
            }}
            animate={{
                scale: 1,
            }}
        >
            {showForm && (
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}
                        className={`bg-lighter  border-sm border-medium   p-secondDegree shadow-md flex flex-col gap-secondDegree w-full`}
                    >
                        <div className=" flex flex-row gap-secondDegree items-center w-full">
                            {/* <div className="basis-[90%]">
                <TextFieldInput
                  value={formData.title}
                  placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.skill"
                  onChange={(e) => handleChange(e, "title")}
                  error={!formData.title && !inputIsValid}
                />
              </div> */}
                            <UniqueSelect
                                className="basis-[90%]"
                                id="1"
                                placeholderSelect={skill ? skill.title :
                                    "consultants.statement.prospectConsultantPanel.formPlaceHolder.skill"
                                }
                                showLabel={false}
                                dataToSelect={selectableSkills.map(({ title }) => title)}
                                isSearchable={true}
                                filterDataFunction={setSkillNameAndType}
                            />
                            <div
                                className={`${!isStarsValid ? "animate-pulse delay-0" : ""}`}
                            >
                                <Stars
                                    totalStars={5}
                                    informSelectedIndex={setStars}
                                    indexSelected={formData.level}
                                    allowChange={true}
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-[12px] items-center w-full">
                            {editingSkill && (
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={
                                        <DeleteIcon className="text-primary cursor-pointer" />
                                    }
                                    showText={false}
                                    style="ghost"
                                    onClick={handleDelete}
                                />
                            )}

                            <Button
                                showLeftIcon={true}
                                leftIcon={<ClearIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.cancel"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                className="font-bold border-primary w-full"
                                onClick={() => setShowForm(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<SaveIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.save"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                className="font-bold w-full"
                                type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    </form>
                </Form>
            )}
        </motion.div>
    );
}
