import React from 'react';
import { Label } from '@/components/atoms/texts/label';

import ShowMoreButton from './ShowMoreButton';

export default function CertificationItem({ data,className, ...props }) {

    return (
        <div className={`bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree min-w-[320px] relative grow-0 ${className}`} {...props} >
            <Label fontWeight="bold" size="h4">{data.title}</Label>
            <Label>{data.organism} • {data.date} </Label>
            <Label>{data.description}</Label>
            <ShowMoreButton idBlock={data.id}/>
        </div>
    )
}