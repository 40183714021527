import axios from 'axios';
const data = [
    {
    templateId:"93d7d7ad-f3b3-427c-897f-055130108248", 
    blockSelected : [
        {id: "af3ad8b9-c522-49d2-98de-1a54692cfe97"}, 
        {id:"14f88dd0-de00-4289-bc6f-27e5a7028f89"}
        ]
    },
    {
    templateId:"838d3022-7eca-4040-87ed-d60fa526e4d6", 
    blockSelected : [
        {id:"16c476ba-184b-420b-b22c-80a3f67bef3e"}, 
        {id:"3edad995-3bf0-4ddd-9ebd-d9c8eea271dc"}, 
        {id:"75341f64-043a-40ea-ac67-515c3759a1be"},
        {id:"31ea39b4-a065-48e4-ab9a-39adfda3bc3c"},
        {id:"f5ae42c6-dd62-48d4-9af1-f553ffa8f884"},
        {id:"ebbf814e-4406-4625-9551-c9108e66dcd3"},

        ]
    },
    ]


const getTemplateData = () => {
    //  try {
    //   // Récupérer la liste d'utilisateurs depuis l'API
    //   const response = await axios.get('https://jsonplaceholder.typicode.com/users');


    //   return response;
    // } catch (error) {
    //   console.error('Error fetching users:', error.message);
    //   throw error;
    // }

    // const data = {education, experience, project, certificaton, profile, jobName, description, template}


    return data;

}

const newTemplateData = () => {
    return "this is a new template id from POST request";
}

export { getTemplateData , newTemplateData}