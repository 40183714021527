import { useState } from 'react';
import ConsultantItem from '@/components/molecules/navigation/ConsultantItem';
import useConsultantData from './useConsultantData';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import formatUsers from './fomatName';
import useConsultant from './useConsultant';
import { Label } from '@/components/atoms/texts/label';
import { useParams } from 'react-router-dom';

export default function ListingConsultant() {

  const { users, error } = useConsultantData();
  const { id } = useParams();

  const { usersFormated,
    usersMatched,
    searchText,
    isSelected,
    currentClicked,
    searchConsultant,
    clearSearch,
    handleClick } = useConsultant(users);

  if (error) {
    // return <div>Erreur : {error}</div>;
    return <Label size='bodySmall' fontWeight='regular' fontFamily='' className=" " message='listingConsultant.error' />
  }

  if (!users) {
    //return <div>Chargement en cours...</div>;
    return <Label size='bodySmall' fontWeight='regular' fontFamily='' className=" " message='listingConsultant.loading' />
  }
  return (
    <div className='w-full grow flex flex-col max-h-[80%] '>
      <div className='relative mt-marginTopAndBottom mb-marginTopAndBottom '>
        <input
          className='border-solid  outline-none  border-secondary border-2  w-full mt-firstDegree  text-left  text-black  h-12 p-4 pr-8 rounded-md inputListConsultant'
          onChange={(e) => searchConsultant(e.target.value)}
          value={searchText}

          placeholder="Consultant"
        />
        {searchText ? (
          <button
            className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer'
            onClick={clearSearch}
          >
            <ClearIcon className='text-secondary mt-2' />
          </button>
        ) : (
          <button
            className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer'
            onClick={clearSearch}
          >
            <SearchIcon className='text-secondary mt-2' />
          </button>
        )
        }
      </div>

      <div className='justify-items-start overflow-scroll text-left grow ' >
        {/* we display the filtered users if exist and if not we display the list of users*/}
        {usersMatched.length > 0 ? (usersMatched.map((user) => (
          <ConsultantItem
            key={user.id}
            consultantName={user.name}
            onClick={() => { handleClick(user) }}
            isSelected={user.id == id}
          />
        ))) : (usersFormated.map((user) => (

          <ConsultantItem
            key={user.id}
            consultantName={user.name}
            onClick={() => handleClick(user)}
            isSelected={user.id == id}
          />

        )))}
      </div>
    </div>
  );
}
