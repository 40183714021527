// useConsultantData.js
import { useState, useEffect } from "react";
import { getUserById } from "@/services/consultant-services/StatementData";
import { getAddedSkills } from "@/services/admin-services/setSkillPanelData";
import { getSkillsType } from "@/services/admin-services/setSkillPanelData";

const useSetSkillPanelData = () => {
    const [skillsAdded, setSkillsAdded] = useState(null);
  const [error, setError] = useState(null);
//   console.log("useActiveUser: main");

  useEffect(() => {
    // console.log("useActiveUser: useEffect");

    const fetchData = async () => {
      try {
        const skills = await getAddedSkills();
        setSkillsAdded(skills);
        //console.log("useActiveUser userById: ", userById);
      } catch (error) {
        setError(error);
        //errorSignal.value=error
        console.error("Error when fetching SkillsAdded", error);
      }
    };

    fetchData();
  }, []);

  return { skillsAdded, error };
};

export default useSetSkillPanelData;
