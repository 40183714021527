// serviceConsultant.js
import axios from 'axios';

function getRandomAvatar() {
  return "https://thispersondoesnotexist.com/"
}
function getRandomDate() {
  // Générer des valeurs aléatoires pour l'année, le mois, le jour, l'heure, la minute, la seconde et la milliseconde
  const year = Math.floor(Math.random() * (2023 - 2000 + 1)) + 2000; // Année entre 1000 et 9999
  const month = Math.floor(Math.random() * 12) + 1; // Mois entre 1 et 12
  const day = Math.floor(Math.random() * 31) + 1; // Jour entre 1 et 31 (peut être ajusté selon le mois)
  // Créer et retourner la date aléatoire
  return `${day}/${month}/${year}`;
}

function getRandomRIB() {
  const ribLength = 20;
  const ribChars = '0123456789';
  let rib = '';
  for (let i = 0; i < ribLength; i++) {
    rib += ribChars.charAt(Math.floor(Math.random() * ribChars.length));
  }
  return rib;
}

function getRandomBIC() {
  const bicLength = 11;
  const bicChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let bic = '';
  for (let i = 0; i < bicLength; i++) {
    bic += bicChars.charAt(Math.floor(Math.random() * bicChars.length));
  }
  return bic;
}

function getRandomNameSurname() {
  const names = ['John', 'Jane', 'Alice', 'Bob', 'Emma', 'Michael', 'Olivia', 'William'];
  const surnames = ['Doe', 'Smith', 'Johnson', 'Brown', 'Jones', 'Garcia', 'Martinez'];
  const randomName = names[Math.floor(Math.random() * names.length)];
  const randomSurname = surnames[Math.floor(Math.random() * surnames.length)];
  return `${randomName} ${randomSurname}`;
}

function getRandomProfil() {
    const profiles = ['S24', 'S42', '24S', 'Classic'];
    const randomProfile = profiles[Math.floor(Math.random() * profiles.length)];
    return `${randomProfile}`;
  }


const cooptingData = [
  {
    name: "John Doe",
    retribution: "5",
    ownership: "50",
    duration: "",
    totalDuration: "∞",
  },
  {
    name: "Jane Doe",
    retribution: "5",
    ownership: "100",
    duration: "16",
    totalDuration: "24",
  },
  {
    name: "Jack Bauer",
    retribution: "5",
    ownership: "50",
    duration: "60",
    totalDuration: "80",
  }
];


const adress =
{
  street: "Kulas Light",
  suite: "Apt. 556",
  city: "Gwenborough",
  zipcode: "92998-3874",
  geo: {
    "lat": "-37.3159",
    "lng": "81.1496"
  }
}


const getInformationData = async (id) => {
  try {
    // Récupérer la liste d'utilisateurs depuis l'API
    const response = await axios.get('https://jsonplaceholder.typicode.com/users');
    
    // Enrichir la liste d'utilisateurs avec les nouvelles propriétés

    // console.log(response);
    const data = {
      name: getRandomNameSurname(),
      address: adress,
      email: "Sincere@april.biz",
      avatarUrl: getRandomAvatar(),
      seniority: getRandomDate(),
      rib: getRandomRIB(),
      bic: getRandomBIC(),
      cooptedBy: getRandomNameSurname(),
      coopting: cooptingData,
      retribution: ["5/10/15"],
      allRetribution: ["5/10/15", "7/14/21"],
      allProfiles:['S24', 'S42', '24S', 'Classic'],
      profil: getRandomProfil()

    }
    console.log('Information Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching information data:', error.message);
    throw error;
  }
};

export { getInformationData, getRandomAvatar };
