import React from 'react'
import { Skeleton } from '@mui/material';
export default function SkeletonOverflow() {
     const sekeletonNumber = 70;

  // Créez une liste d'éléments div basée sur le nombre de cartes
  const skeletonElements = Array.from({ length: sekeletonNumber }, (_, index) => (
    
      <Skeleton key={index+13} className=" rounded-sm" animation="wave" height={70} sx={{ my: 0, mx: 1 }} />
     
     
  ));
  
  return (
    <div className='h-full'>
      <div className="space-y-2">
        <Skeleton className=" w-[50%] rounded-sm" height={50}/>
      </div>
    <div className='grid grid-cols-5   h-[98%] mt-4'> 
      {skeletonElements}
      </div>

       </div>
  )
}
