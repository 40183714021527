// serviceConsultant.js
import axios from 'axios';

const data = [
    {"id":"8cce41fc-6826-4f0a-b5e2-5d43d2e1d46c","statut": "0", "category": "travelExpenses", "number": "15073", "date": "2024-03-14", "amountExclTax": "179.22", "amountTax": {"5%": 5, "10%": 6,"12%": 9.8}, "sizeFile": "652ko","url":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/ReceiptSwiss.jpg/1920px-ReceiptSwiss.jpg"},
    {"id":"054c8458-ee60-4263-bc8a-ab9c0531188b","statut": "0", "category": "mealExpenses", "number": "31816", "date": "2024-03-06", "amountExclTax": "408.81", "amountTax": {"5%": 5.62, "10%": 1.89, "12%": 6.45}, "sizeFile": "613ko","url":"https://cdn-s-www.leprogres.fr/images/0CBC3769-7B8C-4FE6-BD34-3F98BA116E06/NW_raw/les-consommateurs-auront-le-choix-entre-recevoir-un-ticket-papier-un-ticket-dematerialise-ou-ne-pas-avoir-de-ticket-du-tout-photo-sipa-romain-doucelin-1678544961.jpg"},
    {"id":"14be3e36-b307-4e3a-9d3a-9c66fec0bac8","statut": "0", "category": "accommodation", "number": "77330", "date": "2024-03-01", "amountExclTax": "129.79", "amountTax": {"5%": 8.62, "10%": 1.00, "12%": 4.42}, "sizeFile": "774ko","url":"https://media-cdn.tripadvisor.com/media/photo-s/17/c8/9a/e6/ticket-de-caisse.jpg"},
    {"id":"21d7c7e5-1bd4-4df2-b903-a5f97069171b","statut": "1", "category": "officeSupplies", "number": "11097", "date": "2024-01-30", "amountExclTax": "241.46", "amountTax": {"5%": 0.50, "10%": 2.80, "12%": 2.22}, "sizeFile": "767ko","url":"https://cdn-s-www.leprogres.fr/images/0CBC3769-7B8C-4FE6-BD34-3F98BA116E06/NW_raw/les-consommateurs-auront-le-choix-entre-recevoir-un-ticket-papier-un-ticket-dematerialise-ou-ne-pas-avoir-de-ticket-du-tout-photo-sipa-romain-doucelin-1678544961.jpg"},
    {"id":"5dd0fad0-743c-4ca3-b983-cf6c084a92ea","statut": "-1", "category": "softwareSubscriptions", "number": "45511", "date": "2024-01-06", "amountExclTax": "148.82", "amountTax": {"5%": 7.14, "10%": 8.54, "12%": 8.69, "14": "3.45"}, "sizeFile": "209ko","url":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/ReceiptSwiss.jpg/1920px-ReceiptSwiss.jpg"},
    {"id":"e1435b6d-94cd-4dd0-a529-2e6d7d0a4dd3","statut": "1", "category": "professionalServices", "number": "41019", "date": "2024-01-03", "amountExclTax": "285.81", "amountTax": {"5%": 6.67, "10%": 6.58, "12%": 5.61}, "sizeFile": "766ko","url":"https://www.facture-excel.com/wp-content/uploads/facture-simple.jpg"},
    {"id":"766abd41-e429-41be-bffb-baff2a2672a8","statut": "1", "category": "trainingFees", "number": "18390", "date": "2023-12-13", "amountExclTax": "429.60", "amountTax": {"5%": 8.07, "10%": 0.56, "12%": 9.67}, "sizeFile": "174ko","url":"https://templates.invoicehome.com/modele-facture-fr-bande-bleu-750px.png"},
    {"id":"00e90e47-c954-438f-9991-5a753f2cc485","statut": "1", "category": "vehicleExpenses", "number": "14969", "date": "2023-10-27", "amountExclTax": "313.14", "amountTax": {"5%": 7.36, "10%": 4.53}, "s%sizeFile":"433ko","url":"https://media-cdn.tripadvisor.com/media/photo-s/17/c8/9a/e6/ticket-de-caisse.jpg"},
    {"id":"4ec925eb-ebdc-47bf-b48e-015018c951e6","statut": "1", "category": "communicationFees", "number": "73613", "date": "2023-08-05", "amountExclTax": "185.33", "amountTax": {"5%": 3.62, "10%": 4.19, "12%": 7.49}, "sizeFile": "374ko","url":"https://cdn-s-www.leprogres.fr/images/0CBC3769-7B8C-4FE6-BD34-3F98BA116E06/NW_raw/les-consommateurs-auront-le-choix-entre-recevoir-un-ticket-papier-un-ticket-dematerialise-ou-ne-pas-avoir-de-ticket-du-tout-photo-sipa-romain-doucelin-1678544961.jpg"},
    {"id":"ea3f1a26-27dd-45fa-9d8a-0954e5b43e55","statut": "1", "category": "entertainment", "number": "24276", "date": "2023-05-01", "amountExclTax": "19.79", "amountTax": {"5%": 4.25, "10%": 7.67, "12%": 8.53}, "sizeFile": "515ko","url":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/ReceiptSwiss.jpg/1920px-ReceiptSwiss.jpg"}
]



const getExpenseData = async (id) => {
  try {
    // Récupérer la liste d'utilisateurs depuis l'API
    // const response = await axios.get('https://jsonplaceholder.typicode.com/users');
    
    // Enrichir la liste d'utilisateurs avec les nouvelles propriétés
    // console.log(response);
    
    console.log('Expense Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching information data:', error.message);
    throw error;
  }
};

export { getExpenseData };
