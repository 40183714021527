/* eslint-disable react/prop-types */

import { cn } from "@/lib/utils"; // Assuming this utility exists for concatenating class names
import { Label } from "@/components/atoms/texts/label"; // Assuming this is your Label component
import { cva } from "class-variance-authority";

import Polygon1 from "../../../assets/Polygon1.svg";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "@/components/ui/hover-card";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DisplayHoverContent from "./DisplayHoverContent";

import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "@/context/AuthContext";
/**
 * DataCell Component
 * @component
 *
 * @param {string} data - The data to be displayed in the cell.
 * @param {string} border - Border radius variant. Options: 'none', 'all', 'top', 'bottom', 'left', 'right'.
 * @param {string} padding - Padding size variant. Options: 'default', 'betweenText'.
 * @param {string} background - Background color variant. Options: 'primaryLinear', 'grayBackground', 'medium', 'primaryLight', 'transparent'.
 * @param {string} className - Additional custom class names for styling.
 * @param {object} props - Other props for the base element.
 *
 * @example
 * // Usage of this component:
 * <DataCell
 *   data="1234"
 *   border="left"
 *   className="w-[100px]"
 *   background="primaryLinear"
 *   padding=""
 * contentType='text' //or not,
 * />
 */

const baseClass =
  "flex inline-flex items-center text-center  gap-4 font-500 text-h7 relative  truncate ...";

const dataCellVariants = cva(baseClass, {
  variants: {
    border: {
      none: "rounded-none",
      all: "rounded-sm",
      top: "rounded-t-sm",
      bottom: "rounded-b-sm",
      left: "rounded-l-sm",
      right: "rounded-r-sm",
    },
    background: {
      primaryLinear: "bg-horizontalPrimaryLinear text-white",
      grey: "bg-grey text-black ",
      medium: "bg-medium text-black ",
      primaryLight: "bg-bgLight text-primary",
      transparent: "bg-transparent text-black",
    },
    padding: {
      default: "px-paddingTopAndBottom py-paddingSmallLeftAndRight",
      betweenText: "p-betweenText",
      textAtEnd:
        "flex-row-reverse items-center gap-4 px-paddingSmallLeftAndRight py-paddingTopAndBottom",
    },
    sizeText: {
      h1: "text-h1",
      h2: "text-h2",
      h3: "text-h3",
      h4: "text-h4",
      h5: "text-h5",
      h6: "text-h6 ",
      h7: "text-h7",
      h8: "text-h8",
      body: "text-body",
      small: "text-bodySmall",
      caption: "text-caption",
    },
    textColor: {
      white: "text-white",
      black: "text-black",
    },
  },
  defaultVariants: {
    border: "none",
    background: "primaryLinear",
    padding: "default",
    sizeText: "h6",
    textColor: "black",
  },
});
//this function helps to make the title of the table be bold
function isMonthName(str) {
  const monthNames = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  // Extraction du nom du mois de la chaîne
  const monthName = str.split(".").pop().toLowerCase();

  return monthNames.includes(monthName);
}

const DataCell = ({
  data,
  border,
  padding,
  background,
  enableHover = false,
  contentType = "text",
  className,
  onHoverChange,
  sizeText = "h6",
  textColor,
  Id,
  printToolTip = false,

  icon, // Additional custom class names
  ...props // Other props for the base element
}) => {
  const { user } = useAuth();
  return (
    <HoverCard>
      <HoverCardTrigger
        className={cn(
          dataCellVariants({
            border,
            background,
            padding,
            sizeText,
            textColor,
          }),
          className,
          "relative ",
          padding != "textAtEnd" ? "justify-center" : ""
        )}
        {...props}
        onMouseEnter={onHoverChange}
      >
        <div className="truncate ...">
          {enableHover && (
            <div className="absolute left-0 top-0 ">
              <img src={Polygon1} />
            </div>
          )}
          {/* if it is number display  */}
          {/* <span className={`text-${sizeText}`}></span> */}
          {printToolTip ? (
            <Tooltip title={data} arrow>
              <Label size={sizeText}>
                {!(contentType === "text") && data}{" "}
              </Label>
            </Tooltip>
          ) : (
            <Label size={sizeText}>{!(contentType === "text") && data} </Label>
          )}

          {icon}
          {contentType === "text" && (
            <Label
              size={sizeText}
              fontWeight={isMonthName(data) ? "" : "bold"}
              fontFamily=""
              message={data}
              color=""
            />
          )}
        </div>
      </HoverCardTrigger>
      {enableHover && (
        <HoverCardContent
          align="left"
          // className=' w-full mt-[-70px] ml-[-100%] relative grow  px-secondDegree py-paddingTopAndBottom'
          className={`${
            user.role == "admin"
              ? "ml-[-90%] 2xl:ml-[-109%]"
              : "ml-[-98%] 2xl:ml-[-117%]"
          } w-full mt-[-56px]  relative grow  px-secondDegree py-paddingTopAndBottom `}
        >
          <DisplayHoverContent Id={Id} />
          <PlayArrowIcon className="absolute z-50 top-7 right-0 text-[#FFF]" />
        </HoverCardContent>
      )}
    </HoverCard>
  );
};

export default DataCell;
