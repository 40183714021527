/* eslint-disable react/prop-types */

import AddExperience from "./AddExperience";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import PrintExperience from "./PrintExperience";
import { useState, useEffect } from "react";

import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import useCVTab from "@/hooks/global-hooks/useCVTab";
import FetchingData from "@/components/molecules/PrintFetchingData";
import ErrorMessage from "@/components/molecules/PrintErrorMessage";
export default function Experience({ hideAllOverview, showComponent ,experiences, setExperiences}) {
  const { CVData, error } = useCVTab();
  const [showForm, setShowForm] = useState(false);
  // const [experiences, setExperiences] = useState([]);
  const [editingExperience, setEditingExperience] = useState(false);
  // useEffect(() => {
  //   if (CVData && CVData.experience) {
  //     setExperiences(CVData.experience);
  //   }
  // }, [CVData]);
  // if (error) {
  //   return <ErrorMessage/>
   
  // }
  // if (!CVData) {
  //   return <FetchingData />;
  // }

  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
  return (
    <motion.div variants={item}>
      <div className="flex items-center gap-fourthDegree w-full">
        <ArrowBackIcon className="cursor-pointer" onClick={hider} />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.experience"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
      <div className=" flex flex-col gap-secondDegree">
        {showForm && (
          <AddExperience
            showForm={showForm}
            setShowForm={setShowForm}
            setExperiences={setExperiences}
            editingExperience={false}
            setEditingExperience={setEditingExperience}
          />
        )}
        <div className=" gap-firstDegree">
          {experiences.map((experience, index) => {
            return (
              <PrintExperience
                key={index}
                experience={experience}
                setEditingExperience={setEditingExperience}
                editingExperience={editingExperience}
                setExperiences={setExperiences}
              />
            );
          })}
        </div>
      </div>
    </motion.div>
  );
}
