import { useState, useEffect } from 'react';
import formatUsers from './fomatName';
import { useConsultantContext } from '@/context/ConsultantContext';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";


const useConsultant = (users) => {
    const usersFormated = formatUsers(users);
    const [usersMatched, setUsesMatched] = useState(usersFormated);
    const [searchText, setSearchText] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();


    const { showDetailsAboutConsultant, activeUser, setActiveUser, toggleShowDetails, currentClicked, setCurentClicked } = useConsultantContext();

    const searchConsultant = (text) => {
        setSearchText(text);
        const filteredUsers = usersFormated.filter((user) =>
            user.name.toLowerCase().includes(text.toLowerCase())
        );
        setUsesMatched(filteredUsers);
    };

    const clearSearch = () => {
        setSearchText('');
        setUsesMatched([]);
    };
    // useEffect(() => {
    //   const newPath = location.pathname.replace(id, user.id);
    //   navigate(newPath, { replace: true });

    // })
    const initActivesUsersFromUrl = () => {
        if (!activeUser[0] && users) {
            const tempActiveUser = users.filter((u) => u.id == id)
            setActiveUser(tempActiveUser[0]);
            // console.log("initActivesUsersFromUrl", users, users.filter((u) => u.id == id));

        }
    }
    useEffect(() => {
        activeUser && initActivesUsersFromUrl();
        // console.log("useConsultant :use effect user activeUser" , activeUser);
    }, [users])
    // if not activeeUser, setActiveUser(users(id))
    // useEffect(()=>{
    //   console.log("useConsultant :use effect actiiveUser, activeUser" , activeUser);
    // },[activeUser])

    const handleClick = (user) => {
        setIsSelected(true);
        setCurentClicked(user.id);
        toggleShowDetails(true, user);
        if (id) {
            const newPath = location.pathname.replace(id, user.id);
            navigate(newPath, { replace: true });
        } else {
            navigate(user.id + '/statement');
        }
    };

    return { usersFormated, usersMatched, searchText, isSelected, currentClicked, showDetailsAboutConsultant, searchConsultant, clearSearch, handleClick, toggleShowDetails };
};

export default useConsultant;
