import { Label } from "./components/atoms/texts/label";
// import { useNavigate } from 'react-router-dom';
import { Button } from "./components/molecules/buttons/button";

export default function ErrorMobile() {

    // const navigate = useNavigate();

    return (
        <div id="error-page" className="flex flex-col items-center p-thirdDegree justify-center gap-thirdDegree h-[90vh] " >
            <Label size="h1" fontWeight="bold" message="error.title" />
            <iframe className="my-0" src="https://lottie.host/embed/05041d26-f714-4fd3-9ab7-6fc2f5939b5d/OPXNBrgeri.json" width={'50%'} ></iframe>            
            <Label size="body" fontWeight="bold" message="error.notAvailableOnMobile" className="flex text-center"/>


        </div>
    );
}
