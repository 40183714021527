/* eslint-disable react/prop-types */
import React from "react";
import TableRow from "./DataTable/TableRow";
import DataCell from "../molecules/data-cell/DataCell";
import MovingIcon from "@mui/icons-material/Moving";
import TableHead from "./DataTable/TableHead";
import { getYearDataForUser } from "@/utils/transformUserData";
import { getMonthData } from "@/utils/transformUserData";

const tablehead = [
  { employerCost: "consultants.statement.overflow.tableHead.employerCost" },
  {
    customerPaiment: "consultants.statement.overflow.tableHead.customerPaiment",
  },
  { cooptation: "consultants.statement.overflow.tableHead.cooptation" },
  { overflow: "consultants.statement.overflow.tableHead.overflow" },
];
export default function TableOverflow({ user, year }) {
  const yearlyData = getYearDataForUser(user, year);

  const userMonthData = getMonthData(yearlyData);

  let totalEmployerCost = 0;
  let totalCustomerPayment = 0;
  let totalCooptation = 0;

  // Calcul des totaux
  userMonthData.forEach((monthData) => {
    totalEmployerCost += monthData.employerCost || 0;
    totalCustomerPayment += monthData.customerPayment || 0;
    totalCooptation += monthData.cooptation || 0;
  });

  // Création de la ligne annuelle avec les totaux
  const annualRow = [
    "annuel",
    totalEmployerCost,
    totalCustomerPayment,
    totalCooptation,
    "",
  ];

  // Utilisa
  return (
    <div className="w-full py-2 ">
      <div className="w-full">
        <TableHead
          tableHeadData={tablehead}
          indexContainingIcon={3}
          indexWithSpecificColor={3}
          colorForTheSpecificIndex="primaryLinear"
          colorsOfAllcells="grey"
          icon={<MovingIcon className="mr-2" />}
        />
        {/* </div> */}
      </div>

      {userMonthData.map((month, index) => (
        <TableRow
          key={index}
          rowData={Object.values(month)}
          enableHover={true}
          indexOfCellWithSpecificColor={4}
          colorOfSpecificCell="primaryLight"
          indexOfCellWithSpecificBorder={0}
          borderName={"left"}
          tableOfIndexOfCellsToTranslate={[0]}
          makeTansparentFirstCell={true}
          // onRowDataChange={(newData) => handleMonthDataChange(newData, index)}
        />
      ))}
      <div>
        {" "}
        <TableRow
          rowData={annualRow}
          indexOfCellWithSpecificBorder={0}
          borderName={"left"}
          tableOfIndexOfCellsToTranslate={[0]}
          //onRowDataChange={(newData) => handleMonthDataChange(newData, monthlyData.length)}
        />
      </div>
    </div>
  );
}
