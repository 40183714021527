import { object } from "joi";
function getRandomNumberBetween(min, max) {
  // Utilisez Math.random() pour obtenir un nombre décimal entre 0 et 1
  const randomDecimal = Math.random();

  // Utilisez la formule pour étendre la plage entre min et max
  const randomNumber = min + randomDecimal * (max - min);

  // Arrondir le résultat à un nombre entier si nécessaire
  return Math.round(randomNumber);
}
function getStaticsData() {
  const staticsData = {};

  // Noms complets des mois
  const monthNames = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  // Générer des données pour chaque année de 2021 à 2024
  for (let year = 2021; year <= 2024; year++) {
    // Générer des données pour chaque catégoriehttps://www.chartjs.org/docs/latest/charts/doughnut.html
    staticsData[year] = {
      consultants: {},
      daysWorked: {},
      sickDays: {},
      expenseAccount: {},
      vehicleFleet: {},
      grossSalary: {},
      employerCharges: {},
      salaryCharges: {},
      directContributions: {},
      
      "24S": {},
      S24: {},
      S42: {},
      classic: {}
    };

    // Initialiser la propriété total à 0 pour chaque catégorie
    const totals = {
      consultants: 0,
      daysWorked: 0,
      sickDays: 0,
      expenseAccount: 0,
      vehicleFleet: 0,
      grossSalary: 0,
      employerCharges: 0,
      salaryCharges: 0,
      directContributions: 0,
      "24S":0 ,
      S24: 0,
      S42: 0,
      classic: 0,
    };

    // Générer des données pour chaque mois de l'année
    for (let month = 0; month < 12; month++) {
      // Générer des données aléatoires pour chaque catégorie
      const monthName = monthNames[month];

      // Ajouter la valeur aléatoire au mois correspondant
      staticsData[year].consultants[monthName] = getRandomNumberBetween(
        0,
        100
      );
      staticsData[year].daysWorked[monthName] = getRandomNumberBetween(0,31);
      staticsData[year].sickDays[monthName] = getRandomNumberBetween(0, 10);
      staticsData[year].expenseAccount[monthName] = getRandomNumberBetween(
        0,
        3000
      );
      staticsData[year].vehicleFleet[monthName] = getRandomNumberBetween(
        0,
        300
      );
      staticsData[year].grossSalary[monthName] = getRandomNumberBetween(
        2000,
        4000
      );
      staticsData[year].employerCharges[monthName] = getRandomNumberBetween(
        0,
        3000
      );
      staticsData[year].salaryCharges[monthName] = getRandomNumberBetween(
        0,
        3000
      );
      staticsData[year].directContributions[monthName] = getRandomNumberBetween(0, 3000)
      staticsData[year]["24S"][monthName] = getRandomNumberBetween(0, 3000);
      staticsData[year]["S24"][monthName] = getRandomNumberBetween(0, 3000);
      staticsData[year]["S42"][monthName] = getRandomNumberBetween(0, 3000);
      staticsData[year]["classic"][monthName] = getRandomNumberBetween(0, 3000);

      // Ajouter la valeur aléatoire à la propriété total correspondante
    }

    // Ajouter les totaux à la ligne correspondante
    staticsData[year].consultants.total = Object.values(
      staticsData[year].consultants
    ).reduce((acc, currentValue) => acc + currentValue, 0);

    staticsData[year].daysWorked.total = Object.values(
      staticsData[year].daysWorked
    ).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year].sickDays.total = Object.values(
      staticsData[year].sickDays
    ).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year].expenseAccount.total = Object.values(
      staticsData[year].expenseAccount
    ).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year].vehicleFleet.total = Object.values(
      staticsData[year].vehicleFleet
    ).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year].grossSalary.total = Object.values(
      staticsData[year].grossSalary
    ).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year].employerCharges.total = Object.values(
      staticsData[year].employerCharges
    ).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year].salaryCharges.total = Object.values(
      staticsData[year].salaryCharges
    ).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year].directContributions.total = Object.values(
      staticsData[year].directContributions
    ).reduce((acc, currentValue) => acc + currentValue, 0);

     staticsData[year]["24S"].total = Object.values(staticsData[year]["24S"]).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year]["S24"].total = Object.values(staticsData[year]["S24"]).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year]["S42"].total = Object.values(staticsData[year]["S42"]).reduce((acc, currentValue) => acc + currentValue, 0);
    staticsData[year]["classic"].total = Object.values(staticsData[year]["classic"]).reduce((acc, currentValue) => acc + currentValue, 0);
  
  }

  return staticsData;
}
export function getStatisticsKpi() {
  
  return [
    {
      kpiName: "totalConsultants",
      data: getRandomNumberBetween(300, 1000),
      evolution: getRandomNumberBetween(-10, 100),
    },
    {
      kpiName: "monthlyHiring",
      data: getRandomNumberBetween(0, 50),
      evolution: getRandomNumberBetween(-10, 100),
    },
    {
      kpiName: "daysWorkedRatio",
      data: getRandomNumberBetween(0, 20),
      evolution: getRandomNumberBetween(-10, 100),
    },
    {
      kpiName: "sickDaysRatio",
      data: getRandomNumberBetween(0, 10),
      evolution: getRandomNumberBetween(-10, 100),
    },
    {
      kpiName: "medianSalary",
      data: getRandomNumberBetween(3000, 10000),
      evolution: getRandomNumberBetween(-10, 100),
    },
  ];

}
export function getKpiData (){
  const data = {
    2021: getStatisticsKpi(),
    2022: getStatisticsKpi(),
    2023: getStatisticsKpi(),
    2024: getStatisticsKpi()
  };
  return data
}
export function getTotalConsultantLastMonth() {
//should get the total number of consultants during last month before actual month
  return 600
  
}
export function getAverageSalaryLastYear() {
 
  return 24000;
}

export default getStaticsData;
// const staticsData =
//   {
//           2021: {
//             consultants:{january: 12, february:13, march: 11, april: 200, may: 300,june: 100, july: 400, august: 100, september: 600, october:300, november: 250, december:600, total= somme des valeurs des mois },
//
//           },

// },
