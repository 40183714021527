/* eslint-disable react/prop-types */
import { lorem } from "faker/lib/locales/cz";
import React from "react";
import AddDescription from "./AddDescription";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import { useState,useEffect } from "react";
import Printdescription from "./PrintDescription";

import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import useCVTab from "@/hooks/global-hooks/useCVTab";
export default function Description({
  hideAllOverview,
  showComponent,
  descriptions,
  setDescriptions,
}) {
  const { CVData, error } = useCVTab();

  const [showForm, setShowForm] = useState(false);
  // const [descriptions, setDescriptions] = useState([]);
  const [editingDescription, setEditingDescription] = useState(false);
  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
  // const handleAdd = () => {
  //   setShowForm(true);
  //   setEditingDescription(true);
  // };
  // useEffect(() => {
  //   if (CVData && CVData.description && Array.isArray(CVData.description)) {
  //     setDescriptions(CVData.description);
  //   }
  // }, [CVData]);
  // if (error) {
  //   return <Label message="error.errorFetchData" />;
  //   // "errorFetchData": "An error occured when fetching data",
  //   //   "fetchingData":
  // }
  // if (!CVData) {
  //   return <Label message="error.fetchingData" />;
  // }

  return (
    <motion.div variants={item}>
      <div className="flex items-center gap-fourthDegree w-full">
        <ArrowBackIcon className="cusor-pointer" onClick={hider} />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.description"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)}
        />
      </div>
      <div className="flex flex-col gap-secondDegree">
        {showForm && (
          <AddDescription
            showForm={showForm}
            setShowForm={setShowForm}
            setDescriptions={setDescriptions}
            editingDescription={false}
            setEditingDescription={setEditingDescription}
          />
        )}
        {descriptions &&
          descriptions.map((description, index) => {
            return (
              <Printdescription
                key={index}
                description={description}
                editingDescription={editingDescription}
                setEditingDescription={setEditingDescription}
                setDescriptions={setDescriptions}
              />
            );
          })}
      </div>
    </motion.div>
  );
}
