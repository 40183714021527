
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Tentez de récupérer les informations d'utilisateur du stockage local au démarrage de l'application
    const storedUserInfo = localStorage.getItem('user');
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  });

  useEffect(() => {
    // Écoutez les modifications du stockage local pour mettre à jour l'état d'authentification en conséquence
    const handleStorageChange = () => {
      const storedUserInfo = localStorage.getItem('user');
      setUser(storedUserInfo ? JSON.parse(storedUserInfo) : null);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const simulateLogin = (role) => {
    const userInfo = { role: role, name:"Doe", surname:"John", avatarSrc:"https://thispersondoesnotexist.com/", isAuthenticated: true };

    localStorage.setItem('user', JSON.stringify(userInfo));
    setUser(userInfo);
  };

  const logout = () => {
    localStorage.removeItem('user'); // Supprimez les informations de l'utilisateur du stockage local lors de la déconnexion
    setUser(null);
  };

  const isAuth = () => !!user;
  const hasRoles = (roles) => roles.includes(user?.role);

  return (
    <AuthContext.Provider value={{ user, simulateLogin, logout, isAuth, hasRoles }}>
      {children}
    </AuthContext.Provider>
  );
};
