import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./router/PrivateRoute";
import Login from "@/pages/login/login";
import Consultant from "./pages/admin/consultant/consultant";
import Administration from "./pages/consultant/administration/administration";
import OverflowOptimization from "./pages/admin/overflow-optimization/overflowOptimization";
import Statistics from "./pages/admin/statistics/statistics";
import Finance from "./pages/admin/finance/finance";
import ProfitSharing from "./pages/consultant/profit-sharing/profitSharing";
import CompanyValuation from "./pages/consultant/company-valuation/companyValuation";
import Overflow from "./pages/consultant/overflow/overflow";
import ErrorPage from "./error404";
import NavBar from "./components/organisms/navigation/header/Navbar";
import RootRedirect from "./router/rootRedirect";
import Logout from "./pages/logout/logout";
import { ConsultantProvider } from "./context/ConsultantContext";
import ComponentTest from "./components/ui/ComponentTest";
import ConsultantIndex from "./components/macro-organisms/tabs/consultants-vision-admin/ConsultantIndex";
import StatementTab from "./components/macro-organisms/tabs/consultants-vision-admin/statement/StatementTab";
import InformationTab from "./components/macro-organisms/tabs/consultants-vision-admin/information/InformationTab";
import DetailsAboutConsultant from "./components/macro-organisms/tabs/consultants-vision-admin/DetailsAboutConsultant";
import StatementTabUser from "./components/macro-organisms/tabs/consultant-vision-user/statement/StatementTabUser";
import InformationTabUser from "./components/macro-organisms/tabs/consultant-vision-user/information/InformationTabUser";
import CVTab from "./components/macro-organisms/tabs/consultants-vision-admin/cv/cvTab";
import GenerateCV from "./components/macro-organisms/tabs/consultants-vision-admin/cv/GenerateCV";
import CVTabUser from "./components/macro-organisms/tabs/consultant-vision-user/cv/cvTabUser";
import SkillsPage from "./components/macro-organisms/tabs/consultants-vision-admin/skills/SkillTab";
import SkillsPageConsultant from "./components/macro-organisms/tabs/consultant-vision-user/skills/SkillTabUser";
import ExpenseTabUser from './components/macro-organisms/tabs/consultant-vision-user/expense-account/expenseTabUser';
import ExpenseTab from './components/macro-organisms/tabs/consultants-vision-admin/expense-account/expenseTab';
import useMobileDetect from "./utils/useMobileDetect";
import ErrorMobile from "./errorMobile";


const App = () => {
    const isMobile = useMobileDetect();

    // if (isMobile) {
    //     // Redirige tout accès mobile vers ErrorMobile
    //     return <ErrorMobile />;
    // }
    if (isMobile) {
        // Affiche une page d'erreur ou un composant spécifique pour les mobiles
        return (
            <AuthProvider>
                <Router>
                    <NavBar />
                    <Routes>
                        <Route path="*" element={<ErrorMobile />} />
                    </Routes>
                </Router>
            </AuthProvider>

        );
    }
    return (
        <AuthProvider>
            <Router>
                <NavBar />
                <Routes>

                    {/* Phase de développement uniquement */}
                    <Route path="/test" element={<ComponentTest />} />

                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/" element={<RootRedirect />} /> {/* Gère la redirection à la racine */}

                    <Route path="/consultant" element={<PrivateRoute element={<ConsultantProvider><Consultant /></ConsultantProvider>} roles={['admin']} />}>
                        <Route index element={<ConsultantIndex />} />
                        <Route path=":id" element={<DetailsAboutConsultant />}>
                            <Route path='statement' element={<StatementTab />} />
                            <Route path='information' element={<InformationTab />} />
                            <Route path='cv' >
                                <Route index element={<CVTab />} />
                                <Route path='generate' element={<GenerateCV />} />
                                <Route path='generate/:templateID' element={<GenerateCV />} />
                            </Route>

                            <Route path='payslip' element={<><p>payslip</p></>} />
                            <Route path='timesheet' element={<><p>timesheet</p></>} />
                            <Route path="skills" element={<SkillsPage />} />
                            <Route path='expense' element={<ExpenseTab />} />
                            <Route path='leasing' element={<><p>leasing</p></>} />

                        </Route >

                    </Route>
                    <Route path="/overflowOptimization" element={<PrivateRoute element={<OverflowOptimization />} roles={['admin']} />} />
                    <Route path="/statistics" element={<PrivateRoute element={<Statistics />} roles={['admin']} />} />
                    <Route path="/finance" element={<PrivateRoute element={<Finance />} roles={['admin']} />} />

                    <Route path="/administration" element={<PrivateRoute element={<Administration />} roles={['consultant']} />} >
                        {/* <Route index element={<StatementTabUser />} /> */}
                        <Route index path='statement' element={<StatementTabUser />} />
                        <Route path='information' element={<InformationTabUser />} />
                        <Route path='cv' >
                            <Route index element={<CVTabUser />} />
                            <Route path='generate' element={<GenerateCV />} />
                            <Route path='generate/:templateID' element={<GenerateCV />} />
                        </Route>
                        <Route path="skills" element={<SkillsPageConsultant />} />
                        <Route path='expense' element={<ExpenseTabUser />} />


                    </Route>
                    <Route path="/profitSharing" element={<PrivateRoute element={<ProfitSharing />} roles={['consultant']} />} />
                    <Route path="/companyValuation" element={<PrivateRoute element={<CompanyValuation />} roles={['consultant']} />} />
                    <Route path="/overflow" element={<PrivateRoute element={<Overflow />} roles={['consultant']} />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
