/* eslint-disable react/prop-types */

import PrintValuation from "./PrintValuation"; // Assurez-vous d'importer le composant PrintValuation depuis le bon chemin

const HistoricalDataList = ({ historicalData }) => {
  return (
    <div className="flex flex-col gap-secondDegree">
      {historicalData.map((historicalLine, index) => (
        <PrintValuation key={index} historicalLine={historicalLine} />
      ))}
    </div>
  );
};

export default HistoricalDataList;
