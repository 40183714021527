// src/hooks/useLogout.js
import { useAuth } from '@/context/AuthContext';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirige vers la page de connexion après la déconnexion
  };

  return handleLogout;
};

export default useLogout;
