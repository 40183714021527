import { Label } from "@/components/atoms/texts/label";
import React, { useState, useRef, useEffect } from "react";

import useConsultant from "../../../../organisms/navigation/MenuConsultant/useConsultant";
import useConsultantData from "../../../../organisms/navigation/MenuConsultant/useConsultantData";
import { useConsultantContext } from "@/context/ConsultantContext";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/organisms/forms/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckIcon from "@mui/icons-material/Check";

export default function NewCooptation({
  changeData,
  showErrorMessage,
  global = false,
}) {
  const { users } = useConsultantData();
  const { usersFormated } = useConsultant(users);
  const { activeUser } = useConsultantContext();
  const [usersFormatedX, setUsersFormatedX] = useState({ usersFormated });
  const [numberOfLines, setNumberOfLines] = useState(0);
  const [pourcent, setPourcent] = useState(0);

  const [nameSelected, setNameSelected] = useState();
  useEffect(() => {
    activeUser && setNameSelected([{ name: `${activeUser.name}`, rowIndex: -1 },]);
  }, [activeUser])
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current && usersFormated.length > 0 && activeUser) {
      const usersFormatedFiltered = usersFormated.filter(
        (user) => user.name != activeUser.name
      );
      setUsersFormatedX(usersFormatedFiltered);
      initialized.current = true;
    };
    if (!initialized.current && usersFormated.length > 0 && !activeUser) {
      setUsersFormatedX(usersFormated);
      initialized.current = true;
    }
  }, [usersFormated, usersFormatedX, nameSelected]);

  const line = (index) => [
    <>
      <TableCell className="py-0.5 px-0">
        <Select onValueChange={(value) => handleNameChange(value, index)}>
          <SelectTrigger className="h-full border-0 bg-medium">
            <SelectValue
              placeholder={<Label message="global.actions.select" />}
            />
          </SelectTrigger>
          <SelectContent>
            {/* {console.log("usersFormatedX", usersFormatedX, usersFormated, activeUser)} */}
            {usersFormatedX.map((user) => (
              <SelectItem key={user.id} value={user.name}>
                {user.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </TableCell>
      <TableCell className="p-1 ">
        <Input
          onChange={(e) => controlPourcent(e, index)}
          id={numberOfLines}
          type="number"
          placeholder="0"
          className="bg-transparent rounded-sm border-0 text-center h-8 percentage-input"
          min="0"
          step="10"
          max="100"
        />
      </TableCell>
      <TableCell className="p-1">
        <Input
          onChange={(e) => handleDurationChange(e, index)}
          type="number"
          id={numberOfLines}
          placeholder="∞"
          className="bg-transparent rounded-sm border-0 text-center h-8"
          min="0"
          step="6"
          max="120"
        />
      </TableCell>
    </>,
  ];

  const [components, setComponents] = useState([]);

  const addComponent = () => {
    const numberOfLines = components.length;
    const index = numberOfLines;
    setNumberOfLines(index);
    const newComponent = line(index);
    setComponents((prevComponents) => [...prevComponents, newComponent]);
  };

  const controlPourcent = (e, index) => {
    const percentageInputs = document.querySelectorAll(".percentage-input");
    let totalPercentage = 0;
    percentageInputs.forEach((input) => {
      const value = parseInt(input.value, 10) || 0;
      totalPercentage += value;
    });
    setPourcent(totalPercentage);
    changeData(index, { pourcent: e.target.value });
  };
  const handleNameChange = (value, index) => {
    changeData(index, { name: value });
  };

  const handleDurationChange = (e, index) => {
    changeData(index, { duration: e.target.value });
  };
//   console.log("condition $$$$$", activeUser && usersFormated.find(user => user.name.includes(activeUser.name))?.name);
  const nameInUserFormated = (activeUser && usersFormated.find(user => user.name.includes(activeUser.name))?.name);
//   console.log(nameInUserFormated);
  return (
    <div className='flex flex-col gap-secondDegree p-secondDegree'>
      <div className="flex flex-col space-y-4">
        <Label message="consultants.dashboard.newCooptation.newConsultant" fontWeight="bold" />

        <Label message="consultants.dashboard.newCooptation.newConsultantName" className="" />
        <Select  onValueChange={(value) => changeData(value)}  >
          <SelectTrigger>
            <SelectValue placeholder={<Label message="global.actions.select" />} />
          </SelectTrigger>

          <SelectContent>
            <>

              {global
                ? usersFormated.map((user) => (
                  <SelectItem value={user.name}>{user.name}</SelectItem>
                ))
                : usersFormated.map((user) =>


                  user.name.includes(activeUser.name) && (
                    <SelectItem value={user.name}>{user.name}</SelectItem>
                  )
                )}
            </>
          </SelectContent>
        </Select>

        <Label
          message="consultants.dashboard.newCooptation.cooptedBy"
          fontWeight="bold"
        />
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="bg-transparent w-[25%] h-auto py-firstDegree">
                <Label message="consultants.dashboard.newCooptation.table.name" />{" "}
                *
              </TableHead>
              <TableHead className="">
                <Label message="consultants.dashboard.newCooptation.table.ownership" />{" "}
                *
              </TableHead>
              <TableHead className="">
                <Label message="consultants.dashboard.newCooptation.table.duration" />
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {components.map((component, index) => (
              <TableRow key={index}>{component}</TableRow>
            ))}
            <TableRow className="">
              <Button
                style="dressedGhost"
                className="w-full text-primary "
                onClick={() => addComponent()}
                showText={false}
                showRightIcon={true}
                rightIcon={<AddIcon></AddIcon>}
              />
              {/* <TableCell /> */}
              <TableCell
                className={`rounded-b-sm border-2  bg-medium text-center py-betweenText space-x-2 h-full ${pourcent === 100 ? "border-success" : "border-error"
                  }`}
              >
                <span>{pourcent}%</span>
                {pourcent === 100 ? (
                  <CheckIcon color="success" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </div>
      {/* Error messages based on `showErrorMessage` */}
      <Label
        message="error.mulitpleSimilarCoopting"
        className={` text-error ${showErrorMessage == "mulitpleSimilarCoopting" ? "flex" : "hidden"
          }`}
      />
      <Label
        message="error.totalOwnership"
        className={`text-error ${showErrorMessage == "totalOwnership" ? "flex" : "hidden"
          }`}
      />
      <Label
        message="error.missingRequired"
        className={`text-error ${showErrorMessage == "missingRequired" ? "flex" : "hidden"
          }`}
      />
    </div>
  );
}
