import React, { useState } from "react";
import { ResizablePanel } from "@/components/ui/resizable";
import { Label } from "@/components/atoms/texts/label";
import ListOfActions from "@/components/organisms/navigation/ListOfActions";

import SortButton from "@/components/molecules/buttons/Sort";
import useNotifications from "@/hooks/global-hooks/useNotification";
import Notification from "@/components/organisms/notification/Notification";
import { useConsultantContext } from "@/context/ConsultantContext";
import { Fragment } from "react";
import { Toaster } from "@/components/organisms/feedback/toaster";
import LineArt from "@/assets/images/lineArt.png"

export default function ConsultantIndex() {
  const { actions, informations, error, sortNotifications } =
    useNotifications();
  const [sortByDateAscInformations, setSortByDateAscInformations] =
    useState(true);
  const [sortByDateAscActions, setSortByDateAscActions] = useState(true);

  const handleSortNotifications = (type) => {
    if (type == "information") {
      sortNotifications(sortByDateAscInformations ? "asc" : "desc", type);
      setSortByDateAscInformations(!sortByDateAscInformations);
    } else {
      sortNotifications(sortByDateAscActions ? "asc" : "desc", type);
      setSortByDateAscActions(!sortByDateAscActions);
    }
  };

  if (error || (!actions && !informations)) {
    return (
      <Label
        size="bodySmall"
        fontWeight="regular"
        fontFamily=""
        className=" "
        message="error.fetchUnsuccessfull"
      >
        error{" "}
      </Label>
    );
  }
  console.log("actions :", actions, ", informations : ", informations);

  return (
    <Fragment>


      <ResizablePanel
        defaultSize={15}
        className=" min-w-[260px] p-secondDegree  z-20"
        id="actions-panel"
        order={2}
      >
        <div className="flex flex-col text-center gap-secondDegree overflow-none h-full">
          {/* <div> */}
            <Label
              size="h3"
              fontWeight="bold"
              className="sticky top-0 bg-lighter pb-4 "
              message="consultants.dashboard.title.grpActions"
            />
          {/* </div> */}
          <ListOfActions className="h-full flex flex-col gap-4 overflow-auto pb-8" />
        </div>
      </ResizablePanel>

      <ResizablePanel
        defaultSize={85}
        className="h-full z-10  overflow-auto"
        id="notifications-panel"
        order={3}
      >
        <img src={LineArt} className="bgLineArt overflow-hidden" />
        <div className="grid grid-cols-2 divide-x h-full">
          <div className="flex flex-col overflow-auto	">
            <div className="p-secondDegree flex justify-between">
              <div className="flex flex-col	">
                <Label
                  size="h3"
                  fontWeight="bold"
                  className=""
                  message="consultants.dashboard.title.recent"
                />
                <Label
                  size="body"
                  className=""
                  message="consultants.dashboard.title.recentMessage"
                />
              </div>
              <SortButton
                type="date"
                sort={sortByDateAscActions ? "up" : "down"}
                onClick={() => handleSortNotifications("actions")}
              />
            </div>
            <div className="space-y-4 p-4 flex flex-col h-full overflow-auto">
              {actions.map((notification, index) => (
                <Notification key={ index} notification={notification} />
              ))}
            </div>
          </div>
          <div className="flex flex-col overflow-auto	">
            <div className="p-secondDegree flex justify-between">
              <div className="flex flex-col	">
                <Label
                  size="h3"
                  fontWeight="bold"
                  className=""
                  message="consultants.dashboard.title.notifications"
                />
                <Label
                  size="body"
                  className=""
                  message="consultants.dashboard.title.notificationsMessage"
                />
              </div>
              <SortButton
                type="date"
                sort={sortByDateAscInformations ? "up" : "down"}
                onClick={() => handleSortNotifications("information")}
              />
            </div>
            <div className="space-y-4 p-4 flex flex-col h-full overflow-auto">
              {informations.map((notification, index) => (
                <Notification key={ index} notification={notification} />
              ))}
            </div>
          </div>
        </div>
      </ResizablePanel>
      <Toaster />
    </Fragment>
  );
}
