import React, { useState } from 'react'
import { useConsultantContext } from '@/context/ConsultantContext';
import { useToast } from "@/components/organisms/feedback/use-toast"


const UseNewCooptation = () => {
    const [data, setData] = useState([]);
    const { activeUser } = useConsultantContext();
    const [openNewCooptation, setOpenNewCooptation] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState('');
    // const retribution = ["5/10/15", "7/14/21"];
    const { toast } = useToast();

    const changeData = (index, lineData) => {
        setData(currentData => {
            const existingItem = currentData[index] || {};
            const updatedItem = { ...existingItem, ...lineData };
            const newData = [...currentData];
            newData[index] = updatedItem;
            return newData;
        });
    };

    function onCancel() {
        toast({
            variant: "warning",
            title: "error.discardChange",
            duration: 1500,
        })
        setData([])
        setOpenNewCooptation(false)
    }

    function onSend() {
        try {
            dataControl();
        } catch (error) {
            console.error("Error while processing data: ", error);
            return
        }
        console.log("Data sent : ", data,  " , showErrorMessage : ", showErrorMessage);
        showErrorMessage === "" && setOpenNewCooptation(false);
        setOpenNewCooptation(false)
        toast({
            variant: "success",
            title: "success.changesSaved",
            duration: 1500,
        })
    }
    function dataControl() {
        setShowErrorMessage("")
        let totalPercentage = 0;
        let names = new Set();

        data.forEach(item => {
            if (!item.name || !item.pourcent || item.pourcent == 0) {
                // console.log("data : ", data)
                setShowErrorMessage("missingRequired");
                throw new Error("missingRequired");
            }

            if (names.has(item.name)) {
                // console.log("data : ", data)

                setShowErrorMessage("mulitpleSimilarCoopting");
                throw new Error("mulitpleSimilarCoopting");
            } else {
                names.add(item.name);
            }
            if (item.pourcent) {
                totalPercentage += parseInt(item.pourcent);
            }
            // console.log("dataControl = totalPercentage", totalPercentage, " , names.has(item.name) : ", names.has(item.name))
        });
        if (totalPercentage !== 100) {
            setShowErrorMessage("totalOwnership");
            throw new Error("totalOwnership");
        }

    }
    
    return {
        showErrorMessage, 
        openNewCooptation,
        setOpenNewCooptation,  
        // selectedRetribution, 
        // setSelectedRetribution, 
        // isInputValid, 
        // inputValue, 
        changeData, 
        onCancel, 
        onSend, 
        dataControl, 
        // validateInput, 
        // handleInputBlur
    }
}
export default UseNewCooptation;
// export default {openNewCooptation , selectedRetribution, setSelectedRetribution, isInputValid, inputValue, changeData, onCancel, onSend, dataControl, validateInput, handleInputBlur}