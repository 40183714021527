// serviceConsultant.js
import axios from 'axios';

function getRandomNameSurname() {
  const names = ['John', 'Jane', 'Alice', 'Bob', 'Emma', 'Michael', 'Olivia', 'William'];
  const surnames = ['Doe', 'Smith', 'Johnson', 'Brown', 'Jones', 'Garcia', 'Martinez'];
  const randomName = names[Math.floor(Math.random() * names.length)];
  const randomSurname = surnames[Math.floor(Math.random() * surnames.length)];
  return `${randomName} ${randomSurname}`;
}
function getRandomNumberBetween(min, max) {
    // Utilisez Math.random() pour obtenir un nombre décimal entre 0 et 1
    const randomDecimal = Math.random();
  
    // Utilisez la formule pour étendre la plage entre min et max
    const randomNumber = min + randomDecimal * (max - min);
  
    // Arrondir le résultat à un nombre entier si nécessaire
    return Math.round(randomNumber);
  }

function generateRandomCompanyName() {
    const companyNames = {
      france: [
        'Eiffel Solutions',
        'ParisTech Innovations',
        'Provence Logistique',
        'Côte d\'Azur Développement',
        'Bordeaux BioTech',
        'Montmartre Média',
        'Alpes Maritimes Consult',
        'Loire Valley Productions',
        'Normandie Conseil',
        'Riviera Ressources',
      ],
      luxembourg: [
        'Grand Duchy Holdings',
        'LuxTech Solutions',
        'Bank of Luxembourg Group',
        'Steel Valley Industries',
        'Schengen Consultancy',
        'Ardennes Financial Services',
        'Moselle IT Innovations',
        'Gutland Trade Partners',
        'Echternach Energy Solutions',
        'Mullerthal Manufacturing',
      ],
    };
  
    const countries = Object.keys(companyNames);
    const randomCountry = countries[Math.floor(Math.random() * countries.length)];
    const availableNames = companyNames[randomCountry];
  
    if (availableNames.length === 0) {
      return null; // Aucun nom d'entreprise disponible pour ce pays
    }
  
    const randomIndex = Math.floor(Math.random() * availableNames.length);
    const selectedName = availableNames[randomIndex];
  
    return selectedName;
  }
  
  function generateInvoiceNumber() {
    const prefix = 'S42_';
    const randomNumber = Math.floor(Math.random() * 1000) + 1; // Numéro aléatoire entre 1 et 1000
    const invoiceNumber = `${prefix}${randomNumber}`;
    return invoiceNumber;
  }
  
  const generateMonthlyBills = () => {
    const months = [
      'january', 'february', 'march', 'april', 'may', 'june', 'july',
      'august', 'september', 'october', 'november', 'december'
    ];
  
  
    const yearlyData = {};
  
    months.forEach((month) => {
      const monthlyData = {};
      //for all the day of the month
      //i choose just one line for each month
      //if you want all the day let i = 1; i <= 31; i++
      for (let i = 1; i <= 1; i++) {
        const day = `${i} ${month}`;
        const customer = generateRandomCompanyName();
        const bills = generateInvoiceNumber();
        const nbDays = getRandomNumberBetween(30, 365);
        const amount = getRandomNumberBetween(50000, 500000)
        const tjm = getRandomNumberBetween(300, 1000)
      const margin= getRandomNumberBetween(150, 700)
  
        monthlyData[day] = {
          rowNameOrMonth: month,
          customer,
          bills,
          nbDays,
          amount,
          tjm,
          margin
        };
      }
  
      yearlyData[month] = monthlyData;
    });
  
    return yearlyData;
  }
  
  const generateMonthlyData = () => {
              const months = [
                'january', 'february', 'march', 'april', 'may', 'june', 'july',
                'august', 'september', 'october', 'november', 'december'
              ];
  
  
              let yearlyData = {};
  
              months.forEach((month) => {
                const monthlyData = {};
                //for all the day of the month
                //i choose just one line for each month
                //if you want all the day let i = 1; i <= 31; i++
                for (let i = 1; i <= 1; i++) {
                  const day = `${i} ${month}`;
                  const employerCost = getRandomNumberBetween(20000, 100000);
                  const customerPayment = getRandomNumberBetween(50000, 300000);
                  const cooptation = getRandomNumberBetween(500, 4000);
                  const overflow = customerPayment - employerCost;
  
                  monthlyData[day] = {
                    rowNameOrMonth: month,
                    employerCost,
                    customerPayment,
                    cooptation,
                    overflow,
                  };
                }
                
                yearlyData[month] = monthlyData;
                
              });
            const statics = {
                daysWorked: getRandomNumberBetween(1, 20),
                sickDays: getRandomNumberBetween(1, 60),
                leasing: getRandomNumberBetween(500, 10000),
                expenseAccount: getRandomNumberBetween(500, 10000),
                s42Margin: getRandomNumberBetween(10000, 40000),
                globalOverflow: getRandomNumberBetween(500, 10000),
                grossSalary: getRandomNumberBetween(30000, 90000),
                employerContributions: getRandomNumberBetween(500, 10000),
                totalCostEmployee: getRandomNumberBetween(30000, 90000),
                taxs: getRandomNumberBetween(30000, 90000),
                dailyRate: getRandomNumberBetween(450, 8000),
                dailyRateConsultant: getRandomNumberBetween(350, 1000),
              };
  
              // Adding statics to yearlyData
              yearlyData.statics = statics;
  
    return yearlyData;
  };

const enrichUsers = (users) => {
    const enrichedUsers = users.map((user) => {
      return {
        ...user,
        YearlyData: {
          2022: generateMonthlyData(),
          2023: generateMonthlyData(),
          2024: generateMonthlyData(),
          2021: generateMonthlyData(),
        },
        bills: {
          2022: generateMonthlyBills(),
          2023: generateMonthlyBills(),
          2024: generateMonthlyBills(),
          2021: generateMonthlyBills()
        }
    
      };
    });
  
    return enrichedUsers;
  };
const getUserById = async (userId) => {
    try {
      // Récupérer un utilisateur spécifique depuis l'API
      const response = await axios.get(`https://jsonplaceholder.typicode.com/users/${userId}`);
  
      // Enrichir l'utilisateur avec les nouvelles propriétés
      const enrichedUser = enrichUsers([response.data])[0]; // Using [response.data] to convert single user to array
  
      console.log('Enriched User: $$$$```', enrichedUser);
      return enrichedUser;
    } catch (error) {
      console.error(`Error fetching user with id ${userId}:`, error.message);
      throw error;
    }
  };
  
export { getUserById };
