import { Label } from "@/components/atoms/texts/label";

import { cn } from "@/lib/utils";
import Chip from "@/components/molecules/buttons/Chip";
import {
    ArrowDownward,
    ArrowUpward,

    HelpOutline,

} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import AnimatedNumbers from "react-animated-numbers";

import { useTranslation } from "react-i18next";

export default function DataCard({
    data,
    evolution,
    helpText,
    label,
    roundedBorders = true,
    chipText,
    className,
}) {
    const [t] = useTranslation("global");
    const text = t(helpText);
    const borders = roundedBorders
        ? "shadow-lg rounded-sm border border-medium" : "shadow-0 rounded-0 border border-0  "


    //   console.log('rounded borders', roundedBorders)


    return (
        <div
            className={cn(
                "py-firstDegree px-secondDegree flex flex-col gap-fifthDegree bg-white items-center w-full h-full min-h-[155px] object-contain relative  ",
                borders,
                className
            )}
        >
            <div className="w-full flex justify-between items-stretch absolute">
                {chipText && (
                    <Chip
                        item={chipText}
                        rounded=""
                        bg="medium"
                        size="small"
                        fontWeight=""
                        className={"truncate w-auto"}
                    />
                )}
                <div></div>
                {helpText && (
                    <Tooltip title={text} placement="top" arrow>
                        {/* <HelpOutline className=" top-[-10px] float-right text-grey" /> */}
                        <HelpOutline className="top-2 text-grey  mx-2  " />
                    </Tooltip>
                )}
            </div>

            <span className="text-h3 font-bold  flex items-center grow">
                {/* <AnimatedNumbers
          includeComma
          // className={styles.container}
          transitions={(index) => ({
            type: "spring",
            duration: index ,
          })}
          animateToNumber={data}
          fontStyle={{
            fontSize: 40,
            color: "black",
          }}
        /> */}
                {data}
            </span>
            <div className=" flex flex-row gap-betweenText items-center font-bold ">
                <span className={`${evolution > 0 ? "text-success" : "text-error"}`}>
                    {evolution && evolution > 0 && "+"}
                    {evolution + "%"}
                </span>
                {evolution > 0 ? (
                    <ArrowUpward className=" text-success" fontSize="small" />
                ) : (
                    <ArrowDownward className=" text-error" fontSize="small" />
                )}
            </div>

            {label && <Label message={label} size="body" fontWeight="bold" />}
        </div>
    );
}
