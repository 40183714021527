import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';

const RootRedirect = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Rediriger l'utilisateur en fonction de son état d'authentification et de son rôle
    if (user) {
      if (user.role === 'admin') {
        navigate('/consultant');
      } else if (user.role === 'consultant') {
        navigate('/administration');
      }
    } else {
      navigate('/login');
    }
  }, [user, navigate]);

  return null; // Ce composant ne rend rien, il s'occupe seulement de la redirection
};

export default RootRedirect;
