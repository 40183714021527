/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Label } from "@/components/atoms/texts/label";
import React from "react";
import AddEducation from "./AddEducation";


export default function PrintEducation({
  education,
  setEditingEducation,
  editingEducation,
  setEducations,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setEditingEducation(true);
    setShowForm(true);
  };

  return (
    <div className="flex flex-col gap-firstDegree">
      {!showForm && (
        <div className="flex gap-[12px] ">
          {!showDetails && (
            <ExpandMoreIcon onClick={() => setShowDetails(true)} />
          )}
          {showDetails && (
            <ExpandLessIcon onClick={() => setShowDetails(false)} />
          )}
          <span className="font-bold flex items-center">
            {education.school}
          </span>
          <EditIcon
            className="text-primary cursor-pointer"
            onClick={handleEdit}
          />
        </div>
      )}
      {!showForm && showDetails && (
        <div className="flex flex-col gap-firstDegree">
          <div className="flex flex-row gap-firstDegree py-firstDegree text-bodySmall items-center">
            <span className="font-bold">{education.city}</span>
            <span>{education.country}</span>
            <span>{education.startDate}</span>

            <Label
              message="global.information.fromAtoB"
              size="small"
              className=""
            />
            <span>{education.endDate}</span>
          </div>
          <div className="text-bodySmall">
            <span>{education.description}</span>
          </div>
        </div>
      )}
      
        <AddEducation
          education={education}
          showForm={showForm}
          setShowForm={setShowForm}
          editingEducation={editingEducation}
          setEditingEducation={setEditingEducation}
          setEducations={setEducations}
        />
      
    </div>
  );
}
