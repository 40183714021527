// useConsultantData.js
import { useState, useEffect } from 'react';

import { effect } from '@preact/signals-react';
import { getExpenseData } from '@/services/global-services/expenseData';

const useExpenseData = () => {
    const [expenses, setExpenses] = useState(null);
    const [error, setError] = useState(null);
    effect(() => {
        // console.log('la valeur du signal change');
    })
    useEffect(() => {
        const fetchData = async () => {
            try {
                const tempData = await getExpenseData();
                setExpenses(tempData);
            } catch (error) {
                setError(error);
                //errorSignal.value=error
                console.error("Erreur récupération des données", error);
            }
        };

        fetchData();
    }, []);

    return { expenses, error };
};

export default useExpenseData;

