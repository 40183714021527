import Filter from "@/components/organisms/Filter";
import PolarAreaChart from "@/components/organisms/charts/PolarAreaChart";
import React from "react";
import { Button } from "@/components/molecules/buttons/button";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import useCVTab from "@/hooks/global-hooks/useCVTab";
import { useState, useEffect } from "react";
import ErrorMessage from "@/components/molecules/PrintErrorMessage";
import FetchingData from "@/components/molecules/PrintFetchingData";
import { useTranslation } from "react-i18next";
import PrintSkill from "@/components/macro-organisms/panels/panels-admin/prospectConsultant/skills/PrintSkill";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { useConsultantContext } from "@/context/ConsultantContext";
import generateRandomId from "@/utils/randomId";
import { Label } from "@/components/atoms/texts/label";
import { getRandomNumberBetween } from "@/utils/randomNumber";
import { translationsPaths } from "@/utils/translationPath";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose,
} from "@/components/ui/dialog";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Input from "@/components/molecules/inputs/Input";
import { Toaster } from "@/components/organisms/feedback/toaster";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { ToastAction } from "@/components/organisms/feedback/toast";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';



const mapSkills = [
    { typeIndata: "hard", type: "Hard skills" },
    { typeIndata: "soft", type: "Soft skills" },
    { typeIndata: "power", type: "Power skills" },
];
const actualConsultant = { daysWorked: getRandomNumberBetween(0, 220),balance: getRandomNumberBetween(500,3500)};
export default function SkillsPage() {
    const { CVData, error } = useCVTab();
    const [skills, setSkills] = useState([]);
    const { activeUser } = useConsultantContext()
    const [selectedItem, setSelectedItem] = useState("")
    const [allSkills, setAllSkills] = useState([]);
    const [open, setOpen] = useState();
    const { toast } = useToast();
    const [inputValue, setInputValue] = useState();

    const handleClose = (open_, dataSaved = false, inputValue) => {
        setOpen(open_);
         console.log("open = ", open_, !dataSaved,inputValue);
        if (open_ == false) {
            if (!dataSaved) {
                inputValue && toast({
                    variant: "warning",
                    title: "error.editWithoutSave",
                    description: inputValue,
                    duration: 2500,
                    action: (
                        <ToastAction
                            altText={inputValue}
                            onClick={async () => {
                                await navigator.clipboard.writeText(inputValue);
                            }}
                            className="border-0 py-thirdDegree"
                        >
                            <ContentCopyRoundedIcon />
                        </ToastAction>
                    ),
                });
            } else {
                inputValue && toast({
                    variant: "success",
                    title: "success.changesSaved",
                    description: inputValue,
                    duration: 2500,
                    action: (
                        <ToastAction
                            altText={inputValue}
                            onClick={async () => {
                                await navigator.clipboard.writeText(inputValue);
                            }}
                            className="border-0 py-thirdDegree"
                        >
                            <ContentCopyRoundedIcon />

                        </ToastAction>
                    ),
                });
            }
        }

    }

    useEffect(() => {
        if (CVData) {
            if (CVData.skills) {
                setSkills(CVData.skills);
                setAllSkills(CVData.skills);
            }

        }
        // console.log(getTitles(CVData.project))
    }, [CVData]);
    useEffect(() => {
        const getSkillTypeName = (type) => {
            // Recherche le type basé sur 'typeIndata'
            const matchingSkillType = mapSkills.find((skill) => skill.type === type);

            // Retourne le type correspondant ou un message par défaut
            return matchingSkillType ? matchingSkillType.typeIndata : "unknown";
        };
        const filterSkills = (skillType) => {
            const correspondingType = getSkillTypeName(skillType);
            if (correspondingType != "unknown") {
                const skillsFiltered = allSkills.filter(
                    (skill) => skill.type === correspondingType && skill
                );
                setSkills(skillsFiltered);
                //selectedItem(correspondingType);
            } else {
                setSkills(allSkills);
            }
            //   selectedItem(correspondingType);
        };
        filterSkills(selectedItem);
    }, [selectedItem]);


    if (error) {
        return <ErrorMessage />;
    }
    if (!CVData) {
        return <FetchingData />;
    }

    return (
        <div className=" w-full grid grid-cols-3  gap-fourthDegree p-secondDegree ">

            <div className=" w-full col-span-2 grid gap-fourthDegree justify-items-center px-[80px]">
                <Filter
                    items={["Global", "Soft skills", "Hard skills", "Power skills"]}
                    className="w-[335px]"
                    informSelectedItem={setSelectedItem}
                />
                <PolarAreaChart
                    label={skills.map((skill) => skill.title)}
                    datas={skills.map((skill) => skill.level)}
                />
            </div>
            <div className="w-full flex flex-col   justify-items-end gap-secondDegree ">
                <div className="flex flex-col gap-firstDegree w-full bg-medium shadow-sm p-secondDegree items-end rounded-sm">
                    <div><Button
                        showLeftIcon={true}
                        leftIcon={<RocketLaunchOutlinedIcon fontSize="small" />}
                        showRightIcon={true}
                        showText={true}
                        text="consultants.skillsPage.unlockTraining"
                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                        disabled={(actualConsultant.daysWorked < 220)}
                    // className={`${actualConsultant.daysWorked >= 220
                    //         ? "cursor-pointer"
                    //         : "cursor-not-allowed "
                    //     } fill `}

                    // Options: "fill", "outline", "ghost", "dressedGhost"
                    /></div>
                    <div className=" flex flex-col items-end gap-betweenText w-full">
                        <div className="flex gap-betweenText">
                            <Label size="" fontWeight="bold">{actualConsultant.daysWorked}</Label>
                            <Label size="">{"/ 220"}</Label>
                            <Label size="" message={translationsPaths.daysWorked} />
                        </div>

                        {actualConsultant.daysWorked < 220 ? (
                            <span >
                                <Label
                                    size=""
                                    message="consultants.skillsPage.unlockNotAvaible"
                                />
                                <Label size="">{" 220 jours"}</Label>
                            </span>
                        ) : (
                            <Label
                                size=""
                                message="consultants.skillsPage.unlockAvailable"
                            />
                        )}
                    </div>
                    <Separator />
                    <div className="flex gap-betweenText items-center">
                        <Label size="" fontWeight="bold" message="consultants.skillsPage.balance"></Label>
                        <Label size="">{actualConsultant.balance} €</Label>
                        <Dialog open={open} onOpenChange={(e) => { handleClose(e, false, inputValue); setInputValue(""); }}>
                            <DialogTrigger className=" ">
                                <Button style="ghost" text="global.actions.use" sizePadding="small"/>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle><Label message={"consultants.skillsPage.trainingCredit"} size="h4" /></DialogTitle>
                                    <DialogDescription className="flex flex-col gap-4 py-4">
                                        <span>
                                            <Label message={"consultants.skillsPage.balance"} />
                                            <Label fontWeight="bold">{actualConsultant.balance} €</Label>
                                        </span>
                                        <Label message={"consultants.skillsPage.amount"} />
                                        <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                                    </DialogDescription>
                                </DialogHeader>
                                <DialogFooter className="sm:justify-start">
                                    <Button type="button" style="ghost" text='global.actions.cancel' onClick={() => { setInputValue(""); handleClose(false, false, inputValue) }} />
                                    <Button style="fill" text='global.actions.save' className="w-full" onClick={() => { console.log("send : ", inputValue); setInputValue(""); handleClose(false, true, inputValue) }} />
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>
                    </div>

                </div>

                <div className=" flex flex-col gap-secondDegree truncate py-paddingTopAndBottom px-paddingLeftAndRight overflow-scroll">
                    <span className="text-h3 font-bold">
                        <span className="font-normal w-full truncate">{selectedItem}</span>
                    </span>

                    <Separator />

                    {skills.map((skill, index) => {
                        return (
                            <PrintSkill
                                key={index}
                                skill={skill}
                                setSkills={setSkills}
                                canEdit={false}
                            />
                        );
                    })}
                </div>
                <Toaster />

            </div>
        </div>
    );
}
