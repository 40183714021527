import { object } from "joi";

function getFinanceData() {
    const financeData = {};
  
    // Noms complets des mois
    const monthNames = [
      "january", "february", "march", "april", "may", "june",
      "july", "august", "september", "october", "november", "december",
    ];
  
    const categories = {
      income: ["lorem", "ipsum"], // Définissez ici les catégories pour income
      expense: ["interne", "externe", "lorem", "ipsum"], // Définissez ici les catégories pour expense
    };
  
    // Générer des données pour chaque année de 2021 à 2024
    for (let year = 2021; year <= 2024; year++) {
      financeData[year] = { income: {}, expense: {} };
  
      // Initialiser les catégories pour income et expense
      Object.keys(categories).forEach(type => {
        categories[type].forEach(category => {
          financeData[year][type][category] = {};
          monthNames.forEach(monthName => {
            financeData[year][type][category][monthName] = Math.floor(Math.random() * 1000);
          });
          // Calculer le total pour la catégorie
          financeData[year][type][category].total = Object.values(financeData[year][type][category])
            .filter(value => !isNaN(value)) // Filtre pour ne prendre en compte que les valeurs numériques
            .reduce((acc, currentValue) => acc + currentValue, 0);
        });
      });
    }
  
    return financeData;
  }
  
  export default getFinanceData;
  


