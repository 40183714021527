import axios from 'axios';



const vatData = [
    { vat: "7%", checked: true },
    { vat: "30%", checked: false },
    { vat: "20%", checked: true },
     { vat: "15%", checked: true }
    
]

const getVatData = () => {
    return vatData;
}

export {getVatData}