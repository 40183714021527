const getNumbersFromString = (str) => {
  // Rechercher toutes les séquences de chiffres
  const regex = /\d+/g; // 'g' pour global (tous les résultats)
  const matches = str.match(regex); // Retourne un tableau de correspondances

  if (matches) {
    // Convertir les correspondances en nombres (entiers)
    const numbers = matches.map((num) => parseInt(num, 10)); // Base 10
    return numbers;
  }

  return []; // Retourne un tableau vide si aucun nombre n'est trouvé
};

export default getNumbersFromString