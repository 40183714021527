import React, { useState, useEffect, useRef } from 'react';
// import { DnDFileInput } from '@shadcn/ui';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/organisms/forms/select";
import DragAndDropZone from './DragAndDropZone'
import { Label } from '@/components/atoms/texts/label';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import ExpenseNotification from './ExpenseNotification';
import useFormNewExpense from './useFormNewExpense';

export default function FormNewExpense({ onSubmit, submitRef, data}) {

    const categories = ["travelExpenses", "mealExpenses", "accommodation", "officeSupplies", "softwareSubscriptions", "professionalServices", "trainingFees", "vehicleExpenses", "communicationFees", "entertainment", "publicTransport", "fuelCosts", "conferenceFees", "marketingAndAdvertising", "clientGifts"]
    const vatRates = ["5%", "10%", "12%", "14%", "20%"];
    const [dateSend, setDateSend] = useState();
    const [amountSend, setAmountSend] = useState();
    const [currencySend, setCurrencySend] = useState();

    function toNumber(value) {
        const number = parseFloat(value);
        return isNaN(number) ? 0 : number;  // Retourne 0 si la valeur n'est pas un nombre
    }
    useEffect(() => {
        if (submitRef) {
            submitRef.reset = {
                resetForm
            };
            submitRef.decline = {
                declineExpense
            }
        }
    }, [submitRef]);
    const declineExpense = () => {
        handleSubmitForm(e, "-1");
    }
    const {
        file,
        date,
        currency,
        currencies,
        selectValue,
        amount,
        vatValues,
        totalVat,
        handleVatChange,
        resetForm,
        fileError,
        dateError,
        selectValueError,
        amountError,
        totalVatError,
        setFile,
        setDate,
        setCurrency,
        setSelectValue,
        setAmount,
        validateForm

    } = useFormNewExpense(onSubmit, submitRef, data);

    const handleSubmitForm = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        const valid = validateForm("all");
        const id = data ? data.id : undefined;
        if (valid) {
            const formData = {
                file,
                date,
                category: selectValue,
                currency,
                amount,
                vatValues,
                totalVat,
                id
            };
            setDateSend(date);
            setAmountSend(amount);
            setCurrencySend(currency);
            onSubmit(formData); // Appelle la fonction de soumission passée en props
            resetForm();

        }
    }


    return (
        <div >

            {(dateSend && amountSend && currencySend) && <ExpenseNotification dateSend={dateSend} amountSend={amountSend} currencySend={currencySend} />}
            <form onSubmit={handleSubmitForm} className="flex flex-col gap-thirdDegree p-4" >
                <div className='flex flex-col gap-secondDegree'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.expense.supportingDocuments" />
                    <DragAndDropZone file={file} setFile={setFile} fileError={fileError} />
                </div>
                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.expense.date" />
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => { setDate(e.target.value) }}
                        className={`col-span-3 p-2 border rounded-md text-body ${dateError === true && `border-error`} ${dateError === false && `border-success`} ${dateError == null && `border-greyStrong`} `}
                    // onBlur={dateError ? validateForm("dateErrorTest") : undefined}
                    />
                </div>
                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.expense.category" />
                    <Select className="" value={selectValue} onValueChange={(e) => { setSelectValue(e) }} >
                        <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body ${selectValueError === true && `border-error`} ${selectValueError === false && `border-success`} ${selectValueError == null && `border-greyStrong`} `}>
                            <SelectValue placeholder="Select a category" />
                        </SelectTrigger>
                        <SelectContent>
                            {categories.map((category, index) => (
                                <SelectItem key={index} value={category}>
                                    <Label message={`consultants.expense.categories.${category}`} />
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.expense.currency" />
                    <Select className="" value={currency} onValueChange={(e) => { setCurrency(e) }} >
                        <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body `}>
                            <SelectValue placeholder="Currency" />
                        </SelectTrigger>
                        <SelectContent >
                            {currencies.map((cur, index) => (
                                <SelectItem key={index} value={cur.symbol}>
                                    <Label >{cur.code + " " + cur.symbol}</Label>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                    <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.amountExclTaxs" />
                    <div className='col-span-3 flex items-center gap-firstDegree'>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => { setAmount(e.target.value) }}
                            placeholder={`0,00 ` + currency}
                            className={`p-2 border rounded-md grow text-body text-right ${amountError === true && `border-error`} ${amountError === false && `border-success`} ${amountError == null && `border-greyStrong`} `}
                        // onBlur={amountError ? validateForm("amountErrorTest") : undefined}
                        />
                        {currency}
                    </div>

                </div>
                <div>
                    <Label fontWeight="bold" message="consultants.expense.VATBreakdown" />
                    <h3 className="font-bold mb-2"></h3>
                    <div className="flex flex-row gap-secondDegree justify-center items-center">
                        <div className="flex flex-col gap-2 grow">
                            {vatRates.map((rate, index) => (
                                <div key={index} className='grid grid-cols-6 gap-firstDegree items-center text-right'>
                                    <Label fontWeight="semibold" className="">{rate}</Label>
                                    <input
                                        type="number"
                                        name={`rate${index}`}
                                        value={vatValues[rate] || ''}
                                        onChange={(e) => { handleVatChange(e, rate) }}
                                        placeholder={`0,00 ` + currency}
                                        className="p-2 border border-greyStrong rounded-md text-body col-span-5 text-right"
                                    // onBlur={totalVatError ? validateForm("totalVatErrorTest") : undefined}

                                    />
                                </div>
                            ))}
                        </div>
                        {/* <input
                            type="number"
                            value={totalVat.toFixed(2)}
                            disabled
                            placeholder="Total VAT"
                            className={`p-2 border rounded-md text-body bg-gray-200 h-min grow text-right  ${totalVatError === true && `border-error`} ${totalVatError === false && `border-success`} ${totalVatError == null && `border-greyStrong`}`}

                        /> */}
                        <div className='grow flex flex-col gap-firstDegree items-center text-right'>
                            <span><Label fontWeight="semibold" message="consultants.expense.totalVAT" /> : </span>
                            <Label>{totalVat.toFixed(2)} {currency}</Label>
                            {/* <input
                                type="number"
                                value={totalVat.toFixed(2)}
                                disabled
                                placeholder="Total VAT"
                                className={`p-2 w-24 border rounded-sm text-body bg-gray-200 h-min text-right  ${totalVatError === true && `border-error`} ${totalVatError === false && `border-success`} ${totalVatError == null && `border-greyStrong`}`}

                            /> */}
                        </div>
                    </div>
                </div>


                <Separator />

                <div className='flex gap-secondDegree items-center'>
                    <div className='grow'>
                        <Label size="h3" message="consultants.expense.amountInclTaxs" />
                    </div>
                    <Label fontWeight="bold" size="h3" color="primary">{toNumber(totalVat) + toNumber(amount)} {currency}</Label>



                </div>
                <input type='submit' id="submitFormNewExpense" className='hidden' ref={submitRef} />

            </form>
        </div>
    );
}
