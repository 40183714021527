import React, { useEffect, useState, useMemo } from "react";
// import { FiPlus, FiTrash } from "react-icons/fi";
import { motion } from "framer-motion";
import { Button } from "@/components/molecules/buttons/button";
import DescriptionItem from './DescriptionItem';
import useTemplateData from "./useTemplateData";
import SkillItem from "./SkillItem";
import ProjectItem from "./ProjectItem";
import ExperienceItem from "./ExperienceItem";
import CertificationItem from "./CertificationItem";
import EducationItem from "./EducationItem";
import JobTitleItem from "./JobTitleItem";
import createNewTemplate from "./useManageTemplate";
import { Label } from "@/components/atoms/texts/label";
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
export const CustomKanban = ({ CVData, className, step, setTemplate, template }) => {
    const stepToDataPart = useMemo(() => ({
        0: 'description',
        1: 'jobTitle',
        2: 'skills',
        3: 'project',
        4: 'experience',
        5: 'certification',
        6: 'education',
        7: '*'
    }), []);

    // Déterminez la clé de la partie actuelle de CVData à utiliser basée sur step
    const currentDataKey = stepToDataPart[step];


    // Utilisation directe de useTemplate dans le corps du composant
    // Nous passons directement la partie pertinente de CVData à useTemplate
    // basée sur la clé déterminée par le step actuel
    const CVData_copy = useTemplate(CVData[currentDataKey], step, template);
    console.log("custom kanbam, CVData_copy : ", CVData_copy)

    return (
        <div className={` w-full text-neutral-50 ${className}`}>
            <Board CVData={CVData_copy} setTemplate={setTemplate} template={template} step={step} />
        </div>
    );
};
// Function to update CVData based on the presence of a UUID in the templates
const useTemplate = (CVData, stepToAdd = "", template) => {
    const updatedCVData = CVData.map(item => {
        // Check if the item's id is in the selected block of the matching template
        const columnValue = template && template.blockSelected.some(schemaItem => schemaItem.id === item.id) ? "selected" : "other";
        const stepValue = stepToAdd;

        // Return a new object with the original item data plus the column property
        return { ...item, column: columnValue, step: stepValue };
    });

    return updatedCVData;
};

const Board = ({ CVData, setTemplate, template, step }) => {
    const [cVdata_copy, setCVdata_copy] = useState(CVData);
    const [actualStep, setActualStep] = useState(CVData);
    useEffect(() => {
        if (actualStep != step || !CVData) {
            setActualStep(step);
            setCVdata_copy(CVData);
        }

    }, [CVData, step]);

    const transferAllCardsToSelected = () => {
        // Mettre à jour l'état cVdata_copy pour que toutes les cartes aient la colonne 'selected'
        const updatedCVData = cVdata_copy.map(card => ({
            ...card,
            column: 'selected'  // Mettre à jour la colonne de chaque carte à 'selected'
        }));
        setCVdata_copy(updatedCVData);
    
        // Mettre à jour l'état template pour inclure tous les IDs dans blockSelected sans doublon
        setTemplate(prevTemplate => {
            // Créer une copie de blockSelected en évitant les doublons
            const newBlockSelected = [...prevTemplate.blockSelected];
            updatedCVData.forEach(card => {
                if (!newBlockSelected.some(block => block.id === card.id)) {
                    newBlockSelected.push({ id: card.id });
                }
            });
    
            return {
                ...prevTemplate,
                blockSelected: newBlockSelected
            };
        });
    };
    
    

    const transferAllCardsToNotSelected = () => {
        // Mettre à jour l'état cVdata_copy pour que toutes les cartes aient la colonne 'other'
        const updatedCVData = cVdata_copy.map(card => ({
            ...card,
            column: 'other'  // Mettre à jour la colonne de chaque carte à 'other'
        }));
        setCVdata_copy(updatedCVData);
    
        // Extraire les IDs de cVdata_copy pour les retirer de blockSelected
        const idsToRemove = cVdata_copy.map(card => card.id);
    
        // Mettre à jour l'état template pour retirer les IDs extraits de blockSelected
        setTemplate(prevTemplate => {
            const updatedBlockSelected = prevTemplate.blockSelected.filter(block => 
                !idsToRemove.includes(block.id)  // Garder les blocs dont les IDs ne sont pas à retirer
            );
    
            // Mise à jour de l'état de prevTemplate avec le blockSelected mis à jour
            return {
                ...prevTemplate,
                blockSelected: updatedBlockSelected
            };
        });
    };
    
    
    return (
        <div className="flex h-full w-full gap-3 overflow-scroll ">
            <Column
                title="consultants.cv.other"
                column="other"
                headingColor="text-greyStrong"
                cards={cVdata_copy}
                setCards={setCVdata_copy}
                setTemplate={setTemplate}
                template={template}
            />
            <div className="flex flex-col gap-secondDegree h-full justify-center">
                <Button style="ghost" showLeftIcon leftIcon={<KeyboardDoubleArrowRightRoundedIcon />} showText={false} onClick={() => transferAllCardsToSelected()}>Select all</Button>
                <Button style="ghost" showLeftIcon leftIcon={<KeyboardDoubleArrowLeftRoundedIcon />} showText={false} onClick={() => transferAllCardsToNotSelected()}>Unselect all</Button>
            </div>

            <Column
                title="consultants.cv.selected"
                column="selected"
                headingColor="text-primary"
                cards={cVdata_copy}
                setCards={setCVdata_copy}
                setTemplate={setTemplate}
                template={template}
            // setCardsToSend={setCardsToSend}
            />
        </div>
    );
};

const Column = ({ title, headingColor, cards, column, setCards, setTemplate, template }) => {
    const [active, setActive] = useState(false);

    const handleDragStart = (e, card) => {
        e.dataTransfer.setData("cardId", card.id);
    };

    const handleDragEnd = (e) => {
        const cardId = e.dataTransfer.getData("cardId");

        setActive(false);
        clearHighlights();

        const indicators = getIndicators();
        const { element } = getNearestIndicator(e, indicators);

        const before = element.dataset.before || "-1";
        const isMovingToSelected = element.dataset.column === "selected";

        if (before !== cardId) {
            let copy = [...cards];

            let cardToTransfer = copy.find((c) => c.id === cardId);
            if (!cardToTransfer) return;
            cardToTransfer = { ...cardToTransfer, column };

            copy = copy.filter((c) => c.id !== cardId);

            const moveToBack = before === "-1";

            if (moveToBack) {
                copy.push(cardToTransfer);
            } else {
                const insertAtIndex = copy.findIndex((el) => el.id === before);
                if (insertAtIndex === undefined) return;

                copy.splice(insertAtIndex, 0, cardToTransfer);
            }

            setCards(copy);
            setTemplate(prevTemplate => {
                let blockSelectedUpdated = [...prevTemplate.blockSelected];

                if (isMovingToSelected) {
                    // console.log("custom kandan, isMovingToSelected = ", isMovingToSelected);
                    // Si on déplace vers "selected" et l'ID n'existe pas déjà, on l'ajoute
                    if (!blockSelectedUpdated.some(block => block.id === cardId)) {
                        blockSelectedUpdated.push({ id: cardId });
                    }
                } else {
                    // Si on déplace hors de "selected", et l'ID existe, on le supprime
                    blockSelectedUpdated = blockSelectedUpdated.filter(block => block.id !== cardId);
                }

                return {
                    ...prevTemplate,
                    blockSelected: blockSelectedUpdated
                };
            });

        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        highlightIndicator(e);

        setActive(true);
    };

    const clearHighlights = (els) => {
        const indicators = els || getIndicators();

        indicators.forEach((i) => {
            i.style.opacity = "0";
        });
    };

    const highlightIndicator = (e) => {
        const indicators = getIndicators();

        clearHighlights(indicators);

        const el = getNearestIndicator(e, indicators);

        el.element.style.opacity = "1";
    };

    const getNearestIndicator = (e, indicators) => {
        const DISTANCE_OFFSET = 50;

        const el = indicators.reduce(
            (closest, child) => {
                const box = child.getBoundingClientRect();

                const offset = e.clientY - (box.top + DISTANCE_OFFSET);

                if (offset < 0 && offset > closest.offset) {
                    return { offset: offset, element: child };
                } else {
                    return closest;
                }
            },
            {
                offset: Number.NEGATIVE_INFINITY,
                element: indicators[indicators.length - 1],
            }
        );

        return el;
    };

    const getIndicators = () => {
        return Array.from(document.querySelectorAll(`[data-column="${column}"]`));
    };

    const handleDragLeave = () => {
        clearHighlights();
        setActive(false);
    };

    const filteredCards = cards.filter((c) => c.column === column);
    return (
        <div className="flex flex-col w-[50%]">
            <div className="mb-3 flex items-center justify-between px-2">
                <Label className={headingColor} message={title} />
                <Label className={`${headingColor}`}>{filteredCards.length}</Label>

            </div>
            <div
                onDrop={handleDragEnd}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                className={`flex flex-col h-full w-full transition-colors rounded-sm py-1 px-2 ${active ? "bg-white" : "bg-lighter"
                    }`}
            >
                {filteredCards.map((c) => {
                    return <Card key={c.id} {...c} handleDragStart={handleDragStart} data={c} />;
                    // return <SaveTemplate {...c} handleDragStart={handleDragStart} data={dataTest}/>;

                })}
                <DropIndicator beforeId={null} column={column} />
                {/* <AddCard column={column} setCards={setCards} /> */}
            </div>
        </div>
    );
};

const Card = ({ id, column, handleDragStart, step, data }) => {
    return (
        <>
            <DropIndicator beforeId={id} column={column} />
            <motion.div
                layout
                layoutId={id}
                draggable="true"
                onDragStart={(e) => handleDragStart(e, { id })}
                className="cursor-grab flex active:cursor-grabbing text-night "
            >

                {step == 0 && <DescriptionItem data={data} className="bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree  relative grow-0" />}
                {step == 1 && <JobTitleItem data={data} className="bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree w-full relative grow-0" />}
                {step == 2 && <SkillItem data={data} className="w-full" />}
                {step == 3 && <ProjectItem data={data} className="" />}
                {step == 4 && <ExperienceItem data={data} className="bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree " />}
                {step == 5 && <CertificationItem data={data} className="" />}
                {step == 6 && <EducationItem data={data} className="" />}


                {/* <p className="text-black">{type}</p> */}
            </motion.div>
        </>
    );
};

const DropIndicator = ({ beforeId, column }) => {
    return (
        <div
            data-before={beforeId || "-1"}
            data-column={column}
            className="my-0.5 h-0.5 w-full bg-secondary opacity-0"
        />
    );
};


