/* eslint-disable react/prop-types */
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";

const chipVariants = cva(
  " flex items-center justify-center p-chipPadding font-bold w-[40px] h-[40px]  rounded-[20px] shadow-md", // Ajout de 'relative' ici pour tous les variants
  {
    variants: {
      bg: {
        success: "bg-success",
        default: "bg-white",
        medium: "bg-medium",
      },
      borderColor: {
        success: "border-success",
        error: "border-error",
        information: "border-information",
        white: "border-white",
       
      },
      borderSize: {
        solid: "border-[3px] border-solid ",
        default: ""
      },
    },
    defaultVariants: {
      bg: "default",
      borderColor: "default",
      borderSize:"default"
    },
  }
);

export default function Step({ item, bg, borderColor, borderSize , ...props}) {
  return (
    <div className={cn(chipVariants({ bg, borderColor , borderSize}))} {...props}>
      {item}
    </div>
  );
}
