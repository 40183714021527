/* eslint-disable react/prop-types */
import { Label } from "@/components/atoms/texts/label";

import { Button } from "@/components/molecules/buttons/button";
import Input from "@/components/molecules/inputs/Input";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { validateFormData } from "./validateFormProfile";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextArea from "@/components/molecules/inputs/TextArea";
import { useId } from "react";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
export default function AddProfileTitle({
    profile,
    showForm,
    setShowForm,
    editingProfile = false,
    setProfiles,
    setEditingProfile,
}) {
    const form = useForm();
    const today = new Date();
    const id = useId();
    const [formData, setFormData] = useState({
        profileTitle: profile ? profile.profileTitle : "",
        date: profile ? profile.date : today,
        id: profile ? profile.id : id,
    });
    const [erros, setErros] = useState({
        profileTitle: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        const { value } = e.target;
        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);
        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingProfile) {
                // Si nous sommes en mode édition, mettez à jour la description existante
                setProfiles((prevData) =>
                    prevData.map((item) => (item.id === profile.id ? formData : item))
                );
            } else {

                // console.log("fomr data to dadd", formData)
                setProfiles((prevData) => [...prevData, formData]);
            }

            setShowForm(false);
        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("form data", formData);
        }
    };
    const handleCancel = () => {
        setEditingProfile(false);
        setShowForm(false);
    };
    const handleDelete = () => {
        setShowForm(false);
        if (profile) {
            // Supprimer la certification de la liste
            setProfiles((prevData) =>
                prevData.filter((item) => item.id != profile.id)
            );
        }
    };


    return (
        <div>
            {showForm && (
                <div>
                    <Form {...form}>
                        <form
                            onSubmit={handleSubmit}

                            className={` flex flex-col gap-firstDegree bg-lighter  border-sm border-medium  p-secondDegree w-full`}
                        >
                            <TextFieldInput
                                placeHolder="consultants.statement.prospectConsultantPanel.profileTitle"
                                value={formData.profileTitle}
                                onChange={(e) => handleChange(e, "profileTitle")}
                                error={listOfInvalidInput.includes("profileTitle")}

                            />
                            <div className="flex flex-row gap-[12px] items-center w-full">
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={
                                        <DeleteIcon
                                            className="text-primary cursor-pointer"
                                            fontSize="small"
                                        />
                                    }
                                    style="ghost"
                                    onClick={handleDelete}
                                />

                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<ClearIcon fontSize="small" />}
                                    showText={true}
                                    text="global.actions.cancel"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="outline"
                                    className="font-bold border-primary w-full"
                                    onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                                />
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<SaveIcon fontSize="small" />}
                                    showText={true}
                                    text="global.actions.save"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="fill"
                                    className="font-bold w-full"
                                    type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                                />
                            </div>
                        </form>
                    </Form>
                </div>
            )}
        </div>
    );
}
