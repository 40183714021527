/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import FilterItem from '../molecules/FilterItem';
import { cn } from '@/lib/utils';

const defaultFilterItem = ['202', '2023', '2022', '2021'];

function Filter({ items = defaultFilterItem, className, informSelectedItem, sortItemDESC = true, inclusive = false }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  // const [numberItem, setNumberItem] = useState(items.length);

  //setNumberItem(items.length);
  const sortItems = (a, b) => {
    return sortItemDESC ? b - a : a - b;
  };

  // Tri des éléments
  const sortedItems = [...items].sort(sortItems);

  useEffect(() => {
    // Set the first item in the sorted list as the default selected item

    if (inclusive) {
      // console.log("filter selectedItems = ", (selectedItems.length == 0))
      (!selectedItems || selectedItems.length == 0) && setSelectedItems( [ sortedItems[0]] );

      (!selectedItems || selectedItems.length == 0) && informSelectedItem && informSelectedItem([ sortedItems[0]]);


      
    } else {
      !selectedItem && setSelectedItem(sortedItems[0]);
      !selectedItem && informSelectedItem && informSelectedItem(sortedItems[0]);

    }
  }, [sortedItems]);

  const handleItemClick = (item) => {
    if (inclusive) {
      setSelectedItems(prevItems => {
        if (prevItems.includes(item)) {
          informSelectedItem(prevItems.filter(currentItem => currentItem !== item).sort((a, b) => b - a));
          return prevItems.filter(currentItem => currentItem !== item);
        } else {
          informSelectedItem([...prevItems, item].sort((a, b) => b - a));
          return [...prevItems, item];
        }
      });
    } else {
      setSelectedItem(item);
      informSelectedItem(item);

    }
  };
  const gridColsClass = `flex flex-row grow ${
    inclusive ? `gap-firstDegree` : `border-primary border rounded-[50px]`
  }   `;
  // const gridColsClass = `flex flex-row    grow  outline outline-offset-0`;


  //you should define your own w in your component where you call this filter
  return (
    <div className='w-auto '>
      {inclusive ?
        <div className={cn(gridColsClass, className)}>
          { (selectedItems) && sortedItems.map((item, index) => (
            <FilterItem
              key={index}
              itemContent={item}
              isSelected={inclusive ? selectedItems.includes(item) : item === selectedItem}
              className={`cursor-pointer ${inclusive && `border border-primary rounded-filterItemBorder text-primary`}`}
              onClick={() => handleItemClick(item)}

            />
          ))}
        </div>
        :
        <div className={cn(gridColsClass, className)}>
          {sortedItems.map((item, index) => (
            <FilterItem
              key={index}
              itemContent={item}
              isSelected={item === selectedItem}
              className="cursor-pointer "
              onClick={() => handleItemClick(item)}

            />
          ))}
        </div>
      }

    </div>
  );
}

export default Filter;
