import React from 'react';

import { Label } from '@/components/atoms/texts/label';

import { useEffect } from 'react';
import RenderCvContent from '../../consultants-vision-admin/cv/RenderCvContent';
import useCVTab from '@/hooks/global-hooks/useCVTab';

export default function CVTabUser() {
    const { CVData, error } = useCVTab();

    // console.log('CVTab() CVData : ', CVData)
    useEffect(()=>{console.log("CVTab() CVData =", CVData);},[CVData]);

    if (error || (!CVData)) {
        return (
            <Label
                size="bodySmall"
                fontWeight="regular"
                message="error.fetchUnsuccessfull"
            >
                {error}
            </Label>
        );
    }
    return (
        <div className='flex flex-col gap-thirdDegree py-paddingTopAndBottom px-paddingLeftAndRight'>
            {console.log("CVTab, CVData = ", CVData)}
            <RenderCvContent CVData={CVData} isMain/>

        </div>
    )

}