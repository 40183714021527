const getBillsForYear = (user, year) => {
  if (!user.bills || !user.bills[year]) {
    return {}; // Return an empty object or handle the absence of data as needed
  }

  return user.bills[year];
};

const getMonthDataBills = (yearlyData) => {
  const monthsData = Object.values(yearlyData);

  const monthDataBills = monthsData.map((monthlyData) => {
    const dayEntries = Object.values(monthlyData);

    // Assuming you want to get the first entry for each month
    const firstDayData = dayEntries.length > 0 ? dayEntries[0] : null;

    return firstDayData;
  });

  return monthDataBills;
};
const getUniqueYearsForUser = (user) => {
  const uniqueYears = new Set();
  console.log(user)

  if (user.YearlyData) {
    const years = Object.keys(user.YearlyData);
    years.forEach((year) => uniqueYears.add(Number(year)));
  }

  return Array.from(uniqueYears);
};
const getYearDataForUser = (user, year) => {
  if (user.YearlyData && user.YearlyData[year]) {
    return user.YearlyData[year];
  } else {
    return null; // Ou une valeur par défaut selon votre besoin
  }
};


function formatCurrency(amount, currency) {
  const upperCaseCurrency = currency.toUpperCase();

  
  const validCurrencies = ['USD', 'EUR']; // add another currencry here
  if (!validCurrencies.includes(upperCaseCurrency)) {
    return 'Invalid Currency Code';
  }

  const formattedAmount = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: upperCaseCurrency,
  }).format(amount);

  return `${formattedAmount}`;
}

const getMonthData = (yearlyData) => {
      const userMonthData = [];
  yearlyData && Object.values(yearlyData).forEach((month) => {
    userMonthData.push(Object.values(month)[0])
 
  });
    return userMonthData
}

const getYearsWithBills = (user) => {
  return Object.keys(user.bills);
};
const getAllBillYears = (user) => {
    const uniqueYears = new Set();

  if (user.bills) {
    const years = Object.keys(user.bills);
    years.forEach((year) => uniqueYears.add(Number(year)));
  }

  return Array.from(uniqueYears);
}
const getClientsForUser = (user) => {
  const clients = [];
  for (const year in user.bills) {
    for (const month in user.bills[year]) {
      const monthlyData = user.bills[year][month];
      for (const day in monthlyData) {
        const { customer } = monthlyData[day];
        clients.push(customer);
      }
    }
  }
  return clients;
};


const getStatisticValue = (user, year, statisticName) => {
  if (!user || !user.YearlyData || !user.YearlyData[year]) {
    // L'utilisateur, l'année ou les données annuelles ne sont pas trouvés
    return null;
  }

  const yearlyData = user.YearlyData[year];
  const statisticValue = yearlyData.statics[statisticName];

  return statisticValue !== undefined ? statisticValue : null;
};
const filterUnwantedPropertiesAdmin = (data) => {
  return Object.values(data).map((dayData) => {
    const { rowNameOrMonth, customer, nbDays, amount,bills ,...rest } = dayData;
    return { rowNameOrMonth, customer,bills, nbDays, amount };
  });
};

const getUsersName = (users) => {
  let names=[]
  users.map((user) =>
    names.push(user.name)
  )

  return names
  
}


export {getUniqueYearsForUser,getUsersName , getYearDataForUser, formatCurrency,getMonthData, getBillsForYear, getYearsWithBills, getClientsForUser, getMonthDataBills, getAllBillYears, getStatisticValue, filterUnwantedPropertiesAdmin };
