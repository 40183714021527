// useEditableItemOverview.js
import { useState, useRef, useEffect } from 'react';
import useDoubleClick from 'use-double-click';
import { Toast } from '@/components/organisms/feedback/toast';
import { useToast } from '@/components/organisms/feedback/use-toast';
import { useAuth } from '@/context/AuthContext';
// import { ToastAction } from '@/components/organisms/feedback/toast';

const useEditableItemOverview = (initialValue) => {
    const [editing, setEditing] = useState(false);
    const [newValue, setNewValue] = useState(initialValue);
    const [isHovered, setIsHovered] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [modify, setModify] = useState(false);
    const { user } = useAuth();
    const { toast } = useToast();
    const divRef = useRef();

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);

    };
    function displayToast() {
        toast({
            variant: "warning",
            title: "consultants.statement.overview.cantBeNull",
            duration: 1600,
            // action: <ToastAction altText="Try again" onClick={() => { alert("test") }}>ici</ToastAction>
        });
        // setOpen(false);
    }
    const displayToastSaved = () => {
        toast({
            variant: "success",
            title: "consultants.statement.overview.editOverviewSaved",
            duration: 1700,
            // action: <ToastAction altText="Try again" onClick={() => { alert("test") }}>ici</ToastAction>
        });

    }

    //@todo
    const handleCheck = () => {
        // alert('Confirmez-vous la modification ?');
        setEditing(false);
        setIsChecked(true);
        if (newValue != initialValue && newValue != '') displayToastSaved()
        else setNewValue(initialValue)

        // Change the data in the backend
    };
    const displayWarningToast = () => {
        toast({
            variant: "warning",
            title: "consultants.statement.overview.editWithoutSave",
            duration: 1700,
            // action: <ToastAction altText="Try again" onClick={() => { alert("test") }}>ici</ToastAction>
        });
    }

    const test = () => {
        console.log("checked")
    }
    const handleToast = () => {
        (initialValue != newValue) ? displayWarningToast() : test()
    }
    const handleValueEnter = (e) => {
        const enteredValue = e.target.value;
        let containsChar = false
        if (enteredValue == '') {
            //   console.log("const char", containsChar);
            setEditing(true);
            setNewValue(enteredValue);
            // console.log("actual input is", enteredValue);
            // console.log("length", enteredValue.length);
        }
        if (!/^[0-9.]+$/.test(enteredValue)) {
            // console.log('entered value', enteredValue)
            containsChar = true
            toast({
                variant: "warning",
                title: "consultants.statement.overview.anothercaracterEntered",
                duration: 1700,
                // action: <ToastAction altText="Try again" onClick={() => { alert("test") }}>ici</ToastAction>
            });

        }
        //enterValue.length do 
        else {
            // console.log('const char', containsChar)
            setEditing(true);
            setNewValue(enteredValue);
            // console.log('actual input is', enteredValue);
            // console.log('length', enteredValue.length);
        }
        // } else {

        //   setNewValue(initialValue); 
        //   displayToast()

        // }
    };

    const handleStartEditing = () => {
        setModify(true);
        setEditing(true);
        // console.log('valeur', modify);
    };

    useDoubleClick({
        onSingleClick: () => {
            console.log('single click');
        },
        onDoubleClick: () => {
            console.log('double click');
            (user.role == 'admin') && setEditing(true);
        },
        ref: divRef,
        latency: 250,
    });

    useEffect(() => {
        setNewValue(initialValue);
        //(editing ===isChecked) && displayToast()
    }, [initialValue]);

    return {
        editing,
        newValue,
        isHovered,
        isChecked,
        modify,
        divRef,
        handleMouseEnter,
        handleMouseLeave,
        handleCheck,
        handleValueEnter,
        handleStartEditing,
        setIsChecked,
        displayWarningToast,
        handleToast
    };
};

export default useEditableItemOverview;
