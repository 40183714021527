import React from 'react';
import MenuListingConsultants from "@/components/organisms/navigation/MenuConsultant/MenuListingConsultants";
import { ResizablePanelGroup, ResizableHandle, ResizablePanel } from "@/components/ui/resizable";

import { Outlet } from "react-router-dom"

export default function Consultant() {

       return (
        <div className="bg-lighter flex grow overflow-hidden relative">
            <ResizablePanelGroup direction="horizontal" className={"divide-x "} id="panel1" order={1}>
                <MenuListingConsultants />
                {/* <ResizableHandle withHandle /> */}
                <Outlet />
            </ResizablePanelGroup>
        </div>

    )
}
