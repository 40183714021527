import axios from "axios";
import { subMonths, format, startOfMonth } from "date-fns";
import { getRandomNumberBetween } from "@/utils/finance";

const getLastSixMonthData = () => {
  const currentMonth = new Date();
  const lastSixMonths = [];

  // Obtenir les six derniers mois avec valorisation par défaut
  for (let i = 0; i < 6; i++) {
    const month = subMonths(startOfMonth(currentMonth), i);
    lastSixMonths.push({
      month: format(month, "MMMM").toLowerCase(),
      data: getRandomNumberBetween(10000000, 50000000), // Valorisation par défaut
    });
  }

  return lastSixMonths.reverse(); // Retourner les mois dans l'ordre chronologique
};

const getLast12MonthData = () => {
  const currentMonth = new Date();
  const last12Months = [];

  // Obtenir les 12 derniers mois avec valorisation par défaut
  for (let i = 0; i < 12; i++) {
    const month = subMonths(startOfMonth(currentMonth), i);
    last12Months.push({
      month: format(month, "MMMM").toLowerCase(),
      data: getRandomNumberBetween(10000000, 50000000), // Valorisation par défaut
    });
  }

  return last12Months.reverse(); // Retourner les mois dans l'ordre chronologique
};
const getLastMonthData = (n) => {
  const currentMonth = new Date();
  const last12Months = [];

  // Obtenir les 12 derniers mois avec valorisation par défaut
  for (let i = 0; i < n; i++) {
    const month = subMonths(startOfMonth(currentMonth), i);
    last12Months.push({
      month: format(month, "MMMM").toLowerCase(),
      year: format(month, "yyyy"),
      data: getRandomNumberBetween(70, 100), // Valorisation par défaut
    });
  }

  return last12Months.reverse(); // Retourner les mois dans l'ordre chronologique
};
const getLast3Years = () => {
  const data = [
    { year: "2022", data: 300000000 },
    { year: "2023", data: 400000000 },
    { year: "2024", data: 500000000 },
  ];

  return data;
};

const getLast5Years = () => {
  const data = [
    { year: "2020", data: 300000000 },
    { year: "2021", data: 300000000 },
    { year: "2022", data: 300000000 },
    { year: "2023", data: 400000000 },
    { year: "2024", data: 500000000 },
  ];

  return data;
};

const getValuationData = () => {
  const data = [
    { item: 6, type: "month", data: getLastMonthData(6) },
    { item: 12, type: "month", data: getLastMonthData(12) },
    { item: 3, type: "year", data: getLastMonthData(36) },
    { item: 4, type: "year", data: getLastMonthData(60) },
  ];
  return data;
};

// const getValuationItems = () => {
//   // Extraction des valeurs 'item'
//   const itemValues = getValuationItems().map((d) => d.item); // Retourne uniquement les valeurs 'item'

//   return itemValues;
// }

function generateHistoricalData() {
  const years = [2021, 2022, 2023, 2024];
  const historicalData = [];
  years.forEach((year) => {
    // Pour chaque mois de l'année
    for (let month = 1; month <= 12; month++) {
      // Générer une date au format "YYYY-MM-DD"
      const date = `${year}-${month.toString().padStart(2, "0")}-01`;

      // Générer des valeurs aléatoires pour les données
      const ebit = Math.floor(Math.random() * 10000) + 5000; // Exemple de valeur pour ebit (en euros, par exemple)
      const companyValuation = Math.floor(Math.random() * 5000000) + 10000000; // Exemple de valeur pour la valorisation de l'entreprise
      const actionNumber = Math.floor(Math.random() * 1000) + 1000; // Exemple de nombre d'actions émises
      const sharePrice = Math.floor(Math.random() * 50) + 50; // Exemple de prix par action (en euros, par exemple)

      // Ajouter les données générées au tableau historicalData
      historicalData.push({
        date,
        ebit,
        companyValuation,
        actionNumber,
        sharePrice,
      });
    }
  });

  return historicalData;
}

// const getHistoricalData = async () => {
//   try {
//     // Simuler une requête HTTP en attendant l'intégration de l'API réelle
//     // const response = await axios.get('https://jsonplaceholder.typicode.com/users');

//     // Appel de la fonction pour générer des données historiques pour l'année actuelle (2024)
//     const historicalData = generateHistoricalData();

//     return historicalData;
//   } catch (error) {
//     console.error("Error fetching historical data:", error.message);
//     throw error;
//   }

// };
const getHistoricalData = () => {
  //  try {
  //   // Récupérer la liste d'utilisateurs depuis l'API
  //   const response = await axios.get('https://jsonplaceholder.typicode.com/users');

  //   return response;
  // } catch (error) {
  //   console.error('Error fetching users:', error.message);
  //   throw error;
  // }

  return generateHistoricalData();
};

// const getValuationsData = () => {
//   //récuper les historicaldata getHistoricalData
//   //les ranger de cette façon
//   //annee: {
//   // {mois: le nom du mois, companyValuation: la plus granre valeur de valuation dans le mois}
//   // }
// }
// const getLastSixMonthData = () => {

//    const data = [
//      { monthName: "january", valuation: 200000 },
//      { monthName: "february", valuation: 200000 },
//      { monthName: "march", valuation: 150000 },
//      { monthName: "april", valuation: 300000 },
//      { monthName: "may", valuation: 350000 },
//      { monthName: "june", valuation: 400000 },
//      { monthName: "july", valuation: 450000 },
//    ];

//   return data
// }
// const getLast12MonthData = () => {
//   const data = [
//     { monthName: "january", valuation: 200000 },
//     { monthName: "february", valuation: 200000 },
//     { monthName: "march", valuation: 150000 },
//     { monthName: "april", valuation: 300000 },
//     { monthName: "may", valuation: 350000 },
//     { monthName: "june", valuation: 400000 },
//     { monthName: "july", valuation: 450000 },
//   ];

//   return data;
// };
const getValuationsData = () => {
  // Récupérer les données historiques
  const historicalData = generateHistoricalData();

  // Initialiser un objet pour stocker les données organisées
  const organizedData = {};

  // Parcourir les données historiques
  historicalData.forEach((data) => {
    // Extraire l'année et le mois de la date
    const [year, month] = data.date.split("-");

    // Si l'année n'existe pas encore dans organizedData, l'initialiser
    if (!organizedData[year]) {
      organizedData[year] = {};
    }

    // Si le mois n'existe pas encore dans organizedData[year], l'initialiser
    if (!organizedData[year][month]) {
      organizedData[year][month] = {
        month: getMonthName(parseInt(month)),
        companyValuation: -Infinity,
      };
    }

    // Mettre à jour la valeur maximale de companyValuation pour ce mois si nécessaire
    if (data.companyValuation > organizedData[year][month].companyValuation) {
      organizedData[year][month].companyValuation = data.companyValuation;
    }
  });

  return organizedData;
};

// Fonction pour obtenir le nom du mois à partir de son numéro (1 pour janvier, 2 pour février, etc.)
const getMonthName = (monthNumber) => {
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  return months[monthNumber - 1];
};
function getMostRecentData() {
  // Triez historicalData par date de manière décroissante (de la plus récente à la plus ancienne)
  const historicalData = generateHistoricalData();
  // const sortedData = historicalData.sort(
  //   (a, b) => new Date(b.date) - new Date(a.date)
  // );

  // Renvoyer le premier élément du tableau trié

  const ebit = 5000; // Exemple de valeur pour ebit (en euros, par exemple)
  const companyValuation = 10000000; // Exemple de valeur pour la valorisation de l'entreprise
  const actionNumber = 1000; // Exemple de nombre d'actions émises
  const sharePrice = 50;

  //return historicalData[historicalData.length -1];

  return {
    ebit,
    companyValuation,
    actionNumber,
    sharePrice,
  };
}

function getPrecedentOfMostRecentData() {
  // Triez historicalData par date de manière décroissante (de la plus récente à la plus ancienne)
  const historicalData = generateHistoricalData();
  // const sortedData = historicalData.sort(
  //   (a, b) => new Date(b.date) - new Date(a.date)
  // );

  // return historicalData[historicalData.length - 2];
  const ebit = 4000; // Exemple de valeur pour ebit (en euros, par exemple)
  const companyValuation = 9000000; // Exemple de valeur pour la valorisation de l'entreprise
  const actionNumber = 999; // Exemple de nombre d'actions émises
  const sharePrice = 45;

  //return historicalData[historicalData.length -1];

  return {
    ebit,
    companyValuation,
    actionNumber,
    sharePrice,
  };
}
export {
  getHistoricalData,
  getValuationsData,
  getMostRecentData,
  getPrecedentOfMostRecentData,
  getLastSixMonthData,
  getLast12MonthData,
  getLast3Years,
  getLast5Years,
  getValuationData,
};
