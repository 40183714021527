
import { useState, useEffect } from 'react';
import { getNotifications } from '@/services/global-services/NotificationData';

function useNotifications() {
    const [notifications, setNotifications] = useState();
    const [error, setError] = useState(null);
    const [informations, setInformations] = useState();
    const [actions, setActions] = useState();

    useEffect(() => {
        let isMounted = true;

        const fetchNotifications = async () => {
            try {
                const data = await getNotifications(); // Utilisation de données simulées pour l'exemple
               
                if (isMounted) {
                    data.sort((a, b) => {
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);
                        return dateB - dateA;
                    });
                    setNotifications(data);
                    // Simuler un nouveau fetch toutes les 120 secondes
                    setTimeout(fetchNotifications, 120000);
                }
            } catch (error) {
                setError(error)
                console.error("Error fetching notifications:", error);
                if (isMounted) {
                    setTimeout(fetchNotifications, 120000);
                }
            }
        };

        fetchNotifications();
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (notifications) {
            setInformations(notifications.filter(notification => notification.type === 'information'));
            setActions(notifications.filter(notification => notification.type !== 'information'));
        }
    }, [notifications]);

    const sortNotifications = (order, type) => {
        const sortedNotifications = type === 'information' ? [...informations] : [...actions];
        sortedNotifications.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            if (order === 'asc') {
                return dateA - dateB;
            } else if (order === 'desc') {
                return dateB - dateA;
            }
            return 0;
        });

        if (type === 'information') {
            setInformations(sortedNotifications);
        } else {
            setActions(sortedNotifications);
        }
    };

    return { actions, informations,notifications, error, sortNotifications };
}

export default useNotifications;

