/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import EditIcon from "@mui/icons-material/Edit";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddExperience from "./AddExperience";
import { Label } from "@/components/atoms/texts/label";

export default function PrintExperience({ experience, setEditingExperience, editingExperience, setExperiences }) {
  const [showDetails, setShowDetails] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setEditingExperience(true)
    setShowForm(true)
  }
  return (
    <div className="flex flex-col gap-firstDegree">
      {!showForm && (
        <div className="flex gap-[12px] ">
          {!showDetails && (
            <ExpandMoreIcon onClick={() => setShowDetails(true)} />
          )}
          {showDetails && (
            <ExpandLessIcon onClick={() => setShowDetails(false)} />
          )}
          <span className="font-bold flex items-center">
            {experience.title}
          </span>
          <EditIcon
            className="text-primary cursor-pointer"
            onClick={handleEdit}
          />
        </div>
      )}
      {!showForm && showDetails && (
        <div className="flex flex-col gap-firstDegree">
          <div className="flex flex-row gap-firstDegree py-firstDegree text-bodySmall items-center">
            <span className="font-bold">{experience.company}</span>
            <span>{experience.city}</span>
            <span>{experience.country}</span>
            <span>{experience.startDate}</span>
            <Label
              message="global.information.fromAtoB"
              size="small"
              className=""
            />
            <span>{experience.endDate}</span>
          </div>
          <div className="text-bodySmall">
            <span>{experience.description}</span>
          </div>
        </div>
      )}

      <AddExperience
        experience={experience}
        showForm={showForm}
        setShowForm={setShowForm}
        editingExperience={editingExperience}
        setEditingExperience={setEditingExperience}
        setExperiences={setExperiences}
      />
    </div>
  );
}
