/* eslint-disable react/prop-types */
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import Input from "@/components/molecules/inputs/Input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import { validateFormData } from "./validateForm";
import TextArea from "@/components/molecules/inputs/TextArea";
import useId from "@mui/material/utils/useId";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import { formatDate } from "@/utils/formatDate";
export default function AddExperience({ experience, showForm, setShowForm, editingExperience = false, setExperiences, setEditingExperience }) {

    const form = useForm();
    const id = useId()

    const [formData, setFormData] = useState({
        title: experience ? experience.title : "",
        company: experience ? experience.company : "",
        country: experience ? experience.country : "",
        city: experience ? experience.city : "",
        startDate: experience ? formatDate(experience.startDate) : "",
        endDate: experience ? formatDate(experience.endDate) : "",
        description: experience ? experience.description : "",
        technologies: experience ? experience.technologies : "",
        sector: experience ? experience.sector : "",
        id: experience ? experience.id : id
    });

    const [erros, setErros] = useState({
        title: "",
        company: "",
        city: "",
        country: "",
        startDate: "",
        endDate: "",
        sector: "",
        description: "",

        technologies: "",

    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        const { value } = e.target;
        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingExperience) {
                // Si nous sommes en mode édition, mettez à jour la description existante

                setExperiences((prevData) =>
                    prevData.map((item) => (item.id === experience.id ? formData : item))
                );
            } else {
                // Sinon, ajoutez simplement la nouvelle description à la liste
                // console.log("id ", id);
                setExperiences((prevData) => [...prevData, formData]);
            }

            setShowForm(false);
        } else {
            console.log("invalid form");
            console.log("value invalid", erros);
            console.log("value invalid", isValid);
            console.log("form data", formData);

        }
    };
    const handleCancel = () => {
        setEditingExperience(false)
        setShowForm(false)
    }
    const handleDelete = () => {
        setShowForm(false);
        if (experience) {
            // Supprimer la certification de la liste
            setExperiences((prevData) =>
                prevData.filter((item) => item.id !== experience.id)
            );
        }
    };
    return (
        <div>
            {showForm && (
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}
                        className={` flex flex-col gap-secondDegree shadow-md bg-lighter  border-sm border-medium   p-secondDegree`}
                    >
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.jobTitle"
                            }
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange(e, "title")}
                            error={listOfInvalidInput.includes("title")}
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.enterprise"
                            }
                            type="text"
                            value={formData.company}
                            onChange={(e) => handleChange(e, "company")}
                            error={listOfInvalidInput.includes("company")}
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.sector"
                            }
                            type="text"
                            value={formData.sector}
                            onChange={(e) => handleChange(e, "sector")}
                            error={listOfInvalidInput.includes("sector")}
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.city"
                            }
                            type="text"
                            value={formData.city}
                            onChange={(e) => handleChange(e, "city")}
                            error={listOfInvalidInput.includes("city")}
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.country"
                            }
                            type="text"
                            value={formData.country}
                            onChange={(e) => handleChange(e, "country")}
                            error={listOfInvalidInput.includes("country")}
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.startDate"
                            }
                            type={"date"}

                            value={formData.startDate}
                            onChange={(e) => handleChange(e, "startDate")}
                            error={listOfInvalidInput.includes("startDate")}
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.endDate"
                            }
                            type={"date"}
                            value={formData.endDate}
                            onChange={(e) => handleChange(e, "endDate")}
                            error={listOfInvalidInput.includes("endDate")}
                        />
                        {/* <DatePickerWithRange/> */}
                        <TextArea
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.description"
                            value={formData.description}
                            rows={4} // Nombre de lignes par défaut
                            cols={40} // Nombre de colonnes par défaut
                            onChange={(e) => handleChange(e, "description")}
                            border={
                                listOfInvalidInput.includes("description") ? "border-error" : ""
                            } // Ajoutez d'autres attributs ou styles au besoin
                        />

                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.technologies"
                            }
                            value={formData.technologies}
                            type={"text"}
                            onChange={(e) => handleChange(e, "technologies")}
                            error={listOfInvalidInput.includes("technologies")}
                        />

                        <div className="flex flex-row gap-[12px] items-center w-full">
                            {editingExperience && (
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={
                                        <DeleteIcon
                                            className="text-primary cursor-pointer"
                                            fontSize="small"
                                        />
                                    }
                                    showText={false}
                                    // Utilisez "small" pour un padding plus petit
                                    style="ghost"
                                    onClick={handleDelete} // Options: "fill", "outline", "ghost", "dressedGhost"
                                />
                            )}
                            <Button
                                showLeftIcon={true}
                                leftIcon={<ClearIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.cancel"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                className="font-bold border-primary w-full"
                                onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<SaveIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.save"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                type="submit"
                                className="font-bold w-full"
                            //onClick={listOfInvalidInput.length==0 && setShowForm(false) }// Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    </form>
                </Form>
            )}
        </div>
    );
}
