/* eslint-disable react/prop-types */

import LogoSynapse from '../../../../assets/logos/Logo_Synapse_42_Typo_Blanc.png';
import { useAuth } from '@/context/AuthContext';
import NavItem from '@/components/molecules/navigation/NavItem';
import NotificationIcon from '@/components/atoms/NotificationIcon';

import { Button } from '@/components/molecules/buttons/button';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { useNavbar } from './useNavBar';
import PopUpProfile from '@/components/organisms/PopUpProfile';
import ProfileAvatar from '@/components/macro-organisms/panels/panels-admin/prospectConsultant/information/ProfileAvartar';
import { Avatar } from '@/components/atoms/avatar';



export default function NavBar() {

    const { user } = useAuth();
    const { showMenu, handleClick, handleNavItemClick, variantItems, isSelected, selectedItem, showProfile, showNotification } = useNavbar();
    //   const user = { surname: "John", name: "Doe", role: "admin" };

    return (<>

        <header className={`${showMenu ? 'object-contain min-h-[60px] ' : ' xl:h-[7%] '}    object-contain w-full sticky top-0 left-0 right-0 z-50 bg-horizontalPrimaryLinear `} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <nav className={`${showMenu ? 'flex-row items-start justify-end py-[20px] xl:py-0' : ''}  xl:items-center xl:flex   xl:gap-[30px] w-full h-full px-secondDegree`}>

                <div className={`${showMenu ? '' : ''} object-contain flex items-center xl:h-full  xl:w-auto w-full shrink-0 `}>
                    <img src={LogoSynapse} alt='Logo Synapse' className={`${showMenu ? '' : 'xl:mt-0 mt-[20px]'} h-[20.3px] w-auto object-contain shrink-0 `} id="logo" />
                    {user && (
                        <button type="button"
                            onClick={handleClick}
                            className=" xl:hidden   top-[16px] px-secondDegree absolute right-0 text-sm text-white  justify-end w-1/5 float-right"
                        >
                            {showMenu ? <MenuIcon className="justify-end float-right" /> : <MenuOpenIcon className="justify-end float-right" />}
                        </button>)}
                </div>
                {user && <>
                    <div className=' h-full xl:w-4/5 items-center justify-items-center flex text-white  xl:flex-row  text-bodySmall justify-center ' >
                        <div className={`${showMenu ? " hidden " : "  flex mt-secondDegree pb-secondDegree xl:pb-0 mb- w-full xl:mt-0 relative"}  h-full flex-col xl:flex-row xl:w-auto`}>
                            {variantItems.map((itemName, index) => (
                                <NavItem
                                    key={index}
                                    name={itemName.name}
                                    selected={isSelected(itemName.path)}
                                    path={itemName.path}

                                    className="text-white cursor-pointer "
                                    handleNavItemClick={(path) => handleNavItemClick(path)}

                                />
                            ))

                            }
                            <Button
                                showLeftIcon={false}
                                leftIcon=""
                                showRightIcon={false}
                                rightIcon=""
                                showText={true}

                                text="navbarGlobal.notification"// @todo
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="ghost"
                                onClick={() => showNotification()}// Options: "fill", "outline", "ghost", "dressedGhost"
                                className={`${showMenu ? ' hidden' : 'sm:block text-white'}  xl:hidden text-white  h-[57px] text-body hover:bg-hover cursor-pointer rounded-md  ${selectedItem === 'Notification' ? 'text-secondary bg-hover font-bold font-[700] ' : ''} `}
                            />
                            <div className=''>
                                
                                <PopUpProfile
                                user={user}
                                isForMobile
                                component={<Button
                                    showLeftIcon={false}
                                    leftIcon=""
                                    showRightIcon={false}
                                    rightIcon=""
                                    showText={true}
                                    text={"navbarGlobal.profile"}
    
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="ghost"
                                    onClick={() => showProfile()}// Options: "fill", "outline", "ghost", "dressedGhost"
                                    className={`${showMenu ? ' hidden' : 'sm:block w-full text-white'}  xl:hidden  h-[57px] text-white text-body hover:bg-hover cursor-pointer rounded-md text-bodySmall  ${selectedItem === user.name ? 'text-secondary bg-hover font-bold  font-[700]' : ''} `}
                                />} />
                            </div>
                            


                        </div>
                    </div>
                    {/* {console.log(user)} */}
                    <div className={`${user ? 'xl:block' : 'xl:hidden'} h-full self-stretch gap-x-thirdDegree relative py-firstDegree justify-end items-center  w-auto hidden xl:flex`} >

                        <NotificationIcon className='w-1/5  ' />


                        <PopUpProfile
                            user={user}
                            component={<Avatar src={user.avatarSrc} fallback={`${user.surname} ${user.name}`} size="small" className="shrink-0 grow-0" />} />


                    </div>
                </>}


            </nav>
        </header>
    </>



    )



}