import { getCVData, getDeletionConfirmation } from '@/services/global-services/cvData';
import { useState, useEffect } from 'react';

function useCVTab() {
    const [CVData, setCVData] = useState();
    const [error, setError] = useState(null);

    function deleteBlockById(idBlock) {
        const deleteBlock = async () => {
            try {
                const data = await getDeletionConfirmation(idBlock); // Utilisation de données simulées pour l'exemple
                setCVData(data);
                // console.log('fetchCVData, data:', data)
            } catch (error) {
                setError(error)
                console.error("Error fetching notifications:", error);
            }
        };
        fetchCVData();
    };
    
    function duplicateBlockById(idBlock) {
        const deleteBlock = async () => {
            try {
                const data = await getDuplicationConfirmation(idBlock); // Utilisation de données simulées pour l'exemple
                setCVData(data);
                // console.log('fetchCVData, data:', data)
            } catch (error) {
                setError(error)
                console.error("Error fetching notifications:", error);
            }
        };
        // setCVData(deleteBlock());
        fetchCVData();
    };
    
    function translateBlockById(idBlock) {
        const deleteBlock = async () => {
            try {
                const data = await getTranslationConfirmation(idBlock); // Utilisation de données simulées pour l'exemple
                setCVData(data);
                // console.log('fetchCVData, data:', data)
            } catch (error) {
                setError(error)
                console.error("Error fetching notifications:", error);
            }
        };
        // setCVData(deleteBlock());
        fetchCVData();

    };
    const fetchCVData = async () => {
        try {
            const data = await getCVData(); // Utilisation de données simulées pour l'exemple
            setCVData(data);
            // console.log('fetchCVData, data:', data)
        } catch (error) {
            setError(error)
            console.error("Error fetching cv Data", error);
        }
    };
    useEffect(() => {
        // console.log("useCvTab : useEffect");

        fetchCVData();
    }, []);

    return ({ CVData, error, deleteBlockById , duplicateBlockById, translateBlockById})
}

export default useCVTab;