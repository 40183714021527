import React, { useState, useEffect } from 'react';
import { Label } from '@/components/atoms/texts/label';
import { motion, AnimatePresence } from "framer-motion"

const ExpenseNotification = ({ dateSend, amountSend, currencySend }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);  // Cache le div après 30 secondes
        }, 10000);  // 10000 ms = 10 secondes

        return () => clearTimeout(timer);  // Nettoyez le timer si le composant est démonté
    }, []);
    
    // Effet pour réinitialiser isVisible quand les props changent
    useEffect(() => {
        setIsVisible(true);
        // Redémarrer le timer chaque fois que les props changent
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 10000);
        return () => clearTimeout(timer);
    }, [dateSend, amountSend, currencySend]);  // Dépendances à surveiller

    return (
        
        <AnimatePresence>
        {isVisible && (
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 , height:0}}
            className='m-4 mb-0 flex flex-col p-secondDegree border border-success rounded-sm bg-success20'
          >
             <Label>
                La note de frais du {dateSend} pour un montant de {amountSend} {currencySend} a été transmise. Vous pouvez continuer votre saisie.
            </Label>
          </motion.div>
        )}
      </AnimatePresence>
    
    );
};

export default ExpenseNotification;
