import Action from '@/components/molecules/buttons/Action';
import { useState } from 'react';
import { cn } from '@/lib/utils';
import {
  FileCopy as FileCopyIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  Storefront as StorefrontIcon,
  Euro as EuroIcon,
  EventAvailable as EventAvailableIcon,
  ShoppingCart as ShoppingCartIcon,
  AddTask as AddTaskIcon,
  DirectionsCar as DirectionsCarIcon,
} from '@mui/icons-material';
import ValuationPanel from '@/components/macro-organisms/panels/panels-admin/valuation/ValuationPanel';
import VatSettingsPanel from '@/components/macro-organisms/panels/panels-admin/vatSettings/VatSettingsPanel';
import NewCooptationPanel from '@/components/macro-organisms/panels/panels-admin/newCooptation/NewCooptationPanel';
import ProspectConsultantPanel from "@/components/macro-organisms/panels/panels-admin/prospectConsultant/ProspectConsultantPanel";
import { components } from 'react-select';
import SetSkillsPanel from '@/components/macro-organisms/panels/panels-admin/setSkills/SetSkillsPanel';

export default function ListOfActions({ className }) {
  
  const actionsData = [
    {
      icon: <PersonIcon />,
      text: "consultants.dashboard.actions.newProfile",
      component: <ProspectConsultantPanel />,
    },
    {
      icon: <GroupIcon />,
      text: "consultants.dashboard.actions.cooptation",
      component: <NewCooptationPanel global />,
    },
    {
      icon: <StorefrontIcon />,
      text: "consultants.dashboard.actions.valuation",
      component: <ValuationPanel />,
    },
    {
      icon: <EuroIcon />,
      text: "consultants.dashboard.actions.vat",
      component: <VatSettingsPanel />,
    },
    {
      icon: <AddTaskIcon />,
      text: "consultants.dashboard.actions.skills",
      component: <SetSkillsPanel />,
    },
    {
      icon: <EventAvailableIcon />,
      text: "consultants.dashboard.actions.timesheet",
      initialVariant: "disabled",
    },
    {
      icon: <ShoppingCartIcon />,
      text: "consultants.dashboard.actions.expense",
      initialVariant: "disabled",
    },

    {
      icon: <DirectionsCarIcon />,
      text: "consultants.dashboard.actions.leasing",
      initialVariant: "disabled",
    },
    {
      icon: <FileCopyIcon />,
      text: "consultants.dashboard.actions.payslip",
      initialVariant: "disabled",
    },
  ];
  return (
    <div className={cn (className)} >

      {actionsData.map((action, index) => (
        action.component ? <div key={index} className='w-full'>{action.component}</div> : (<Action key={index} {...action} onClick={() => console.log("handleClick disabled action")}/>)
      ))}
    </div>
  );
}
//
{/* {actionsData.map((action, index) => (
        action.component ?  action.component
                          : (
                          <Action
                            key={index}
                            icon={action.icon}
                            text={action.text}
                            initialVariant={activeAction === action ? 'active' : 'default'} // Utiliser activeIndex pour vérifier l'index actif
                            //onClickAction={() => handleClick(index)}
                            onClick={() => handleClick(action)}
                            isSelected={activeAction === action }
                          />
        )
      ))} */}