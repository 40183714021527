/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import { Avatar } from "@/components/atoms/avatar";
import { getRandomAvatar } from "@/services/consultant-services/InformationData";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
const ProfileAvatar = ({avatarSrc, setAvatarSrc, name, firstName}) => {
  const [isEditing, setIsEditing] = useState(false);
//   const [avatarSrc, setAvatarSrc] = useState(avatarUrl);
  const [newAvatar, setNewAvatar] = useState(null);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Ici, vous pouvez implémenter la logique pour enregistrer la nouvelle photo de profil
    if (newAvatar) {
      setAvatarSrc(newAvatar);
      // Réinitialiser le nouveau avatar après l'avoir enregistré
      setNewAvatar(null);
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    // Réinitialiser le nouvel avatar et sortir du mode édition
    setNewAvatar(null);
    setIsEditing(false);
  };

  const handleAvatarChange = (event) => {
    // Mettre à jour l'état du nouvel avatar lorsqu'un fichier est sélectionné
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewAvatar(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
    
    //  const handleDrop = (event) => {
    //    event.preventDefault();
    //    const file = event.dataTransfer.files[0];
    //    const reader = new FileReader();
    //    reader.onloadend = () => {
    //      setNewAvatar(reader.result);
    //    };
    //    if (file) {
    //      reader.readAsDataURL(file);
    //    }
    //  };

    //  const handleDragOver = (event) => {
    //    event.preventDefault();
    //  };


  return (
    <div className="w-fuull">
      {isEditing ? (
        <div className="flex flex-col gap-secondDegree w-full">
          <input type="file" accept="image/*" onChange={handleAvatarChange} />
          <div className="flex flex-row gap-firstDegree">
            <Button
              showLeftIcon={true}
              leftIcon={<ClearIcon fontSize="small" />}
              showText={true}
              text="global.actions.cancel"
              sizePadding="default"
              style="outline"
              onClick={handleCancelClick}
            />
            <Button
              showLeftIcon={true}
              leftIcon={<SaveIcon fontSize="small" />}
              showText={true}
              text="global.actions.save"
              sizePadding="default"
              style="fill"
              onClick={handleSaveClick}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-firstDegree">
          <Avatar
            fallback={firstName +' ' +name  }
            size="medium"
            src={avatarSrc}
            className="shrink-0 grow-0"
          />
          <Button
            showText={true}
            text="global.actions.edit"
            sizePadding="default"
            style="ghost"
            onClick={handleEditClick}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
