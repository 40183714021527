/* eslint-disable react/prop-types */
import { Label } from "@/components/atoms/texts/label";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Button } from "@/components/molecules/buttons/button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar } from "@/components/atoms/avatar";

import { getEmojiFlag } from "countries-list";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";

import {getLanguageCode} from "@/utils/languagesUtils"



export default function OverviewInformationProfile({
  hideAllOverview,
  showComponent,
  languages,
  user,
  avatarSrc,
}) {
  // const { CVData, error } = useCVTab();

  // if (error) {
  //   return <ErrorMessage />;
  // }
  // if (!CVData) {
  //   return <FetchingData />;
  // }
  const hider = () => {
    hideAllOverview(true);
    showComponent(true);
  };
  //   const printFlags = () => {

  //   return languages.map((country, index) => (
  //     <div key={index} className="text-[25px] ">
  //       {getEmojiFlag(getLanguageCode(country))}
  //     </div>
  //   ));
  // };
  return (
    <motion.div
      variants={item}
      className="py-paddingTopAndBottom px-paddingSmallLeftAndRight flex flex-row gap-firstDegree bg-medium shadow-md rounded-sm border-l-[1px] border-l-solid"
    >
      <div className="flex flex-row place-content-end items-center py-firstDegree basis-1/3 p-betweenText">
        <Label
          message="consultants.statement.prospectConsultantPanel.informations"
          size="body"
          fontWeight="bold"
        />
      </div>

      <div className="flex">
        <Separator orientation="vertical" className="bg-black" />
      </div>
      <div className="flex flex-row basis-1/2 gap-firstDegree text-bodySmall items-center  py-paddingSmallTopAndBottom">
        <Avatar
          fallback={"John Doe"}
          size="medium"
          src={avatarSrc}
          className="shrink-0 grow-0 "
        />

        <div className="flex flex-col gap-firstDegree">
          <Label
            message="consultants.statement.prospectConsultantPanel.profileTitle"
            fontWeight="bold"
          />
          <span>{user.firstName + " " + user.name}</span>
          {/* <div className="flex flex-row gap-1">{printFlags(user.listCodelanguage)}</div>*/}
          <div className="flex flex-row gap-1">
            {" "}
            {languages &&
              languages.map((lang, index) => (
                <span key={index} className="text-[23px]">
                  {getEmojiFlag(
                    lang.language == "English"
                      ? "GB"
                      : getLanguageCode(lang.language)
                  )}
                </span>
              ))}
          </div>
        </div>
      </div>
      <div className="grid items-center">
        <Button
          showLeftIcon={true}
          leftIcon={<ArrowForwardIcon fontSize="small" />}
          showText={false}
          text="global.actions.continue"
          sizePadding="" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold"
          onClick={() => hider()} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
    </motion.div>
  );
}
