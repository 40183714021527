/* eslint-disable react/prop-types */

import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/atoms/texts/label';

export default function HandleVats({ vatData, onCheckboxChange }) {
  return (
    <div className="w-full ">
      <Label
        message="consultants.statement.vatSettings.vatRate"
        size="body"
        fontWeight="bold"
      />
      {vatData.map((item, index) => (
        <div
          key={index}
          className="flex items-center w-full  px-firstDegree py-betweenText "
        >
          <div
            className={`${
              item.checked ? "bg-medium" : ""
            } flex items-center gap-2  w-full  p-firstDegree rounded-sm text-body`}
          >
            <Checkbox
              checked={item.checked}
              onClick={() => onCheckboxChange(index)}
              className="mx-[2px] h-4 w-4 shrink-0  border border-black ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
            />
            <span>{item.vat}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
