/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import OverviewInformationProfile from "./overviews/OverviewInformationProfile";
import SectionsCvOverview from "./overviews/SectionsCvOverview";
import Information from "./information/Information";
import Description from "./description/Description";
import Experience from "./experience/Experience";
import Education from "./education/Education";
import Certification from "./certification/Certification";
import Project from "./project/Project";

import { Button } from "@/components/molecules/buttons/button";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Save from "@mui/icons-material/Save";
import Step from "@/components/molecules/buttons/Step";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import Skills from "./skills/SkillsInPanel";
import { motion } from "framer-motion";
import { container } from "@/utils/animationConst";
import useCVTab from "@/hooks/global-hooks/useCVTab";

import ErrorMessage from "@/components/molecules/PrintErrorMessage";
import FetchingData from "@/components/molecules/PrintFetchingData";

const getTitles = (cvSectionData) => {
    return cvSectionData.map((item) => item.title);
};
const getDescriptionList = (descriptions) => {
    return descriptions.map((description) => description.description);
};
export default function CreateAndUpdateCV({
    openCvSection,
    draftIsClicked,
    shoulGetSectionsData = false,
    setNewIsClicked,
    showButtonsBackAndSave = true,
    showStepper = true,
    showingSectionInProspect = true,
    setShowFourthStep,
    user,
    setUser,
}) {
    const { CVData, error } = useCVTab();

    const [hideMainComponent, setHideMainComponent] = useState(false);
    const [showCertification, setShowCertification] = useState(false);
    const [showInformation, setShowInformation] = useState(false);
    const [showExperience, setShowExperience] = useState(false);
    const [showEducation, setShowEducation] = useState(false);
    const [showProject, setShowProject] = useState(false);
    const [showSkill, setShowSkill] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const [experiences, setExperiences] = useState([]);
    const [educations, setEducations] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [skills, setSkills] = useState([]);
    const [projects, setProjects] = useState([]);
    const [avatarSrc, setAvatarSrc] = useState("");
    const [languages, setLanguages] = useState();
    useEffect(() => {
        if (CVData && shoulGetSectionsData) {
            if (CVData.project) {
                setProjects(CVData.project);
            }
            if (CVData.experience) {
                setExperiences(CVData.experience);
            }
            if (CVData.education) {
                setEducations(CVData.education);
            }
            if (CVData.description) {
                setDescriptions(CVData.description);
            }
            if (CVData.certification) setCertifications(CVData.certification);
            if (CVData.skills) {
                setSkills(CVData.skills);
            }
            if (CVData.languages) {
                setLanguages(CVData.languages);
            }
            if (CVData.user) {
                setAvatarSrc(CVData.user.urlPicture);
                // setUser({ name: CVData.user.name, firsName: CVData.user.firsName, personalEmail: CVData});
            }
        }
        // console.log(getTitles(CVData.project))
    }, [CVData]);

    if (error) {
        return <ErrorMessage />;
    }
    if (!CVData) {
        return <FetchingData />;
    }

    //get data for each sections
    const handleBack = () => {
        openCvSection && openCvSection(false);
        draftIsClicked && draftIsClicked(false);
        setNewIsClicked && setNewIsClicked(true);
    };
    const handleSave = () => {
        openCvSection && openCvSection(false);
        setShowFourthStep && setShowFourthStep(true);
    };

    return (
        <motion.div
            className="p-secondDegree "
            variants={container}
            initial="hidden"
            animate="visible"
        >
            {!hideMainComponent && (
                <motion.div className="flex gap-secondDegree">
                    {showStepper && (
                        <div className=" flex flex-col items-center ">
                            <Step item="3" bg="white" borderSize="default" />
                            <Separator orientation="vertical" />
                        </div>
                    )}


                    <div className={`${showingSectionInProspect ? "w-[89%]" : "w-full"} flex flex-col gap-secondDegree `}>
                        <Label
                            message="consultants.statement.prospectConsultantPanel.createAndUpdateCV"
                            size="body"
                            fontWeight="bold"
                        />
                        <OverviewInformationProfile
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowInformation}
                            avatarSrc={avatarSrc}
                            languages={languages}
                            user={CVData.user}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowSkill}
                            title="consultants.statement.prospectConsultantPanel.skills"
                            items={getTitles(skills)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowDescription}
                            title="consultants.statement.prospectConsultantPanel.description"
                            items={getDescriptionList(descriptions)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowCertification}
                            title="consultants.statement.prospectConsultantPanel.certifications"
                            items={getTitles(certifications)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowExperience}
                            title="consultants.statement.prospectConsultantPanel.experience"
                            items={getTitles(experiences)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowEducation}
                            title="consultants.statement.prospectConsultantPanel.education"
                            items={getTitles(educations)}
                            showingInProspect={showingSectionInProspect}
                        />
                        <SectionsCvOverview
                            hideAllOverview={setHideMainComponent}
                            showComponent={setShowProject}
                            title="consultants.statement.prospectConsultantPanel.projects"
                            items={getTitles(projects)}
                            showingInProspect={showingSectionInProspect}
                        />
                        {showButtonsBackAndSave && (
                            <div className="flex gap-secondDegree py-secondDegree">
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<ArrowBack />}
                                    showText={true}
                                    text="global.actions.back"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="outline"
                                    className="font-bold border-primary"
                                    onClick={handleBack} // Options: "fill", "outline", "ghost", "dressedGhost"
                                ></Button>
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<Save />}
                                    showText={true}
                                    text="global.actions.save"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="fill"
                                    className="w-full"
                                    onClick={handleSave}
                                // onClick={() => setNewClicked(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                ></Button>
                            </div>
                        )}
                    </div>
                </motion.div>
            )}
            {showInformation && (
                <Information
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowInformation}
                    avatarSrc={avatarSrc}
                    languages={languages}
                    setAvatarSrc={setAvatarSrc}
                    setLanguages={setLanguages}
                    user={CVData.user}
                />
            )}

            {showCertification && (
                <Certification
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowCertification}
                    certifications={certifications}
                    setCertifications={setCertifications}
                />
            )}

            {showExperience && (
                <Experience
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowExperience}
                    experiences={experiences}
                    setExperiences={setExperiences}
                />
            )}

            {showEducation && (
                <Education
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowEducation}
                    educations={educations}
                    setEducations={setEducations}
                />
            )}

            {showProject && (
                <Project
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowProject}
                    projects={projects}
                    setProjects={setProjects}
                />
            )}
            {showSkill && (
                <Skills
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowSkill}
                    skills={skills}
                    setSkills={setSkills}
                />
            )}
            {showDescription && (
                <Description
                    hideAllOverview={setHideMainComponent}
                    showComponent={setShowDescription}
                    descriptions={descriptions}
                    setDescriptions={setDescriptions}
                />
            )}
        </motion.div>
    );
}
