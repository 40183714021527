import React from 'react';
import { Button } from './button';
import { ArrowUpNarrowWide, ArrowDownWideNarrow } from 'lucide-react';

const SortButton = ({ type, sort, className, ...props }) => {
    let icon;
    let variable;

    if (type === 'date') {
        variable = 'Date';
        icon = sort === 'up' ? <ArrowUpNarrowWide /> : <ArrowDownWideNarrow />;
    }

    return (
        <Button 
            showLeftIcon={true} 
            leftIcon={icon}
            style="dressedGhost"
            className="rounded-sm font-bold text-primary"
            {...props}
        >
            {variable}
        </Button>
    );
};

export default SortButton;
