/* eslint-disable react/prop-types */
import { useState } from "react";
import DataCell from "../../molecules/data-cell/DataCell";
import { isNumeric } from "@/utils/formatDateString";

// import { translaterRowNameTab } from '@/utils/monthStringTranslater';
import CreateIcon from "@mui/icons-material/Create";
import { cn } from "@/lib/utils";

import { formatCurrency } from "@/utils/transformUserData";
import { useAuth } from "@/context/AuthContext";
import { translationsPaths } from "@/utils/translationPath";
//import { Button } from '@/components/molecules/buttons/button';

//********************ADD  your string on translationPath.js in /utils/ so that it can be translated

// exemple:  {monthData.map((month, index) => (
//         <TableRow
//           key={index}
//           rowData={Object.values(month)}
//           enableHover={true}
//           indexOfCellWithSpecificColor={4}
//           colorOfSpecificCell='primaryLight'
//           indexOfCellWithSpecificBorder={0}
//           borderName={'left'}
//           tableOfIndexOfCellsToTranslate={[0]}
//           makeTansparentFirstCell={true}
//           // onRowDataChange={(newData) => handleMonthDataChange(newData, index)}
//         />
//       ))}
const findTheTranslationString = (str) => {
    let result = "";
    if (str && translationsPaths[str.toLocaleLowerCase()]) {
        result = translationsPaths[str.toLocaleLowerCase()];
    } else {
        result = "consultants.statement.overflow.totranslate"; //by default
    }
    return result;
};
export default function TableRow({
    rowData = [],
    enableHover,
    indexOfCellWithSpecificColor,
    colorOfSpecificCell,
    tableOfIndexOfCellsToTranslate = [],
    indexOfCellWithSpecificBorder,
    borderName,
    makeTansparentFirstCell = false,
    displayHoveredIcon = false,
    className,
    handleClick,
    currency = "EUR",
    printCurency = true,
    printToolTip = false,
    tableOfIndexCellsWithoutCurrency = [],
}) {
    const { user } = useAuth();
    const [idCell, setIdCell] = useState(null);
    //const { setCurrentTableCellHovered } = useConsultantContext();// Initialement, pas de cellule active
    const [hover, setHover] = useState(false);
    const [pencilHovered, setPencilHovered] = useState(false);

    const handleCellHover = (rowName, index) => {
        // On met à jour l'ID de la cellule lorsqu'elle est survolée
        if (enableHover) {
            setIdCell(`${rowName}_${index}`);
            // setCurrentTableCellHovered(`${rowName}_${index}`);
            //   console.log("Row:", idCell);
        }
    };

    const handleMouseEnter = () => {
        displayHoveredIcon && setHover(true);
    };
    const handleMouseLeave = () => {
        // displayHoveredIcon &&
        setHover(false);
    };

    const prinData = (data, index) => {
        return printCurency &&
            data != "" &&
            !tableOfIndexCellsWithoutCurrency.includes(index)
            ? formatCurrency(data, currency)
            : data;
    };
    //grid grid-cols-5 gap-betweenText my-betweenText w-full
    return (
        <div
            className={cn(
                "flex flex-row gap-betweenText my-betweenText w-full relative",
                className
            )}
            onClick={() => setHover(true)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {rowData.map((data, index) => (
                <DataCell
                    key={index}
                    data={
                        isNumeric(data) ||
                            data === "" ||
                            !tableOfIndexOfCellsToTranslate.includes(index)
                            ? prinData(data, index)
                            : findTheTranslationString(data)
                    }
                    border={
                        data == rowData[indexOfCellWithSpecificBorder] ? borderName : ""
                    } // no worry total is translated
                    className={`${rowData[0] === "annuel" && data != "annuel" ? "bg-bgLight " : ""
                        }  h-[26px] w-[20%] }`} // && index!=4 to colored last cell of total
                    contentType={index === 0 ? "text" : "numb"}
                    background={
                        data == "annuel"
                            ? ""
                            : index === 0 && makeTansparentFirstCell
                                ? "transparent"
                                : index === indexOfCellWithSpecificColor && rowData[0] != "annuel"
                                    ? colorOfSpecificCell
                                    : "medium"
                    }
                    padding={index === 0 ? "textAtEnd" : ""}
                    id={idCell}
                    printToolTip={printToolTip}
                    sizeText={"small"}
                    textColor={data == "annuel" ? "white" : "black"}
                    //onMouseLeave={handleMouseLeave}
                    onHoverChange={() => handleCellHover(rowData[0], index)}
                    enableHover={(index === 1 || index === 3) && enableHover}
                    Id={idCell}
                //enable hover just item2 and item4
                />
            ))}

            {/* edit a row  just for admin*/}

            {user.role == "admin" &&
                rowData[0] != "annuel" &&
                hover &&
                displayHoveredIcon && (
                    <div
                        onClick={handleClick}
                        onMouseEnter={handleMouseEnter}
                        className="absolute  ml-[100%] z-50 text-primary  cursor:pointer  bg-grey w-[32px] h-7 rounded-md items-center"
                    >
                        <CreateIcon className=" ml-1" />
                    </div>
                )}
        </div>
    );
}
