/* eslint-disable react/prop-types */
import { formatDateString } from "@/utils/formatDateString";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { translationsPaths } from "@/utils/translationPath";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { cn } from "@/lib/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

// const animation = (data) => {

//   const totalDuration = 10000;
//   const delayBetweenPoints = totalDuration / data.length;
//   const previousY = (ctx) =>
//     ctx.index === 0
//       ? ctx.chart.scales.y.getPixelForValue(100)
//       : ctx.chart
//           .getDatasetMeta(ctx.datasetIndex)
//           .data[ctx.index - 1].getProps(["y"], true).y;
//   const animation = {
//     x: {
//       type: "number",
//       easing: "linear",
//       duration: delayBetweenPoints,
//       from: NaN, // the point is initially skipped
//       delay(ctx) {
//         if (ctx.type !== "data" || ctx.xStarted) {
//           return 0;
//         }
//         ctx.xStarted = true;
//         return ctx.index * delayBetweenPoints;
//       },
//     },
//     y: {
//       type: "number",
//       easing: "linear",
//       duration: delayBetweenPoints,
//       from: previousY,
//       delay(ctx) {
//         if (ctx.type !== "data" || ctx.yStarted) {
//           return 0;
//         }
//         ctx.yStarted = true;
//         return ctx.index * delayBetweenPoints;
//       },
//     },
//   };

//   return animation
  
// }

// const options = {
//   responsive: true,
//  animation,

//   plugins: {},
// };

const options = (data) => {

  const totalDuration = 10000;
  const delayBetweenPoints = totalDuration / data.length;
  const previousY = (ctx) =>
    ctx.index === 0
      ? ctx.chart.scales.y.getPixelForValue(100)
      : ctx.chart
          .getDatasetMeta(ctx.datasetIndex)
          .data[ctx.index - 1].getProps(["y"], true).y;
  const animation = {
    x: {
      type: "number",
      easing: "linear",
      duration: delayBetweenPoints,
      from: NaN, // the point is initially skipped
      delay(ctx) {
        if (ctx.type !== "data" || ctx.xStarted) {
          return 0;
        }
        ctx.xStarted = true;
        return ctx.index * delayBetweenPoints;
      },
    },
    y: {
      type: "number",
      easing: "linear",
      duration: delayBetweenPoints,
      from: previousY,
      delay(ctx) {
        if (ctx.type !== "data" || ctx.yStarted) {
          return 0;
        }
        ctx.yStarted = true;
        return ctx.index * delayBetweenPoints;
      },
    },
  };
 const option= {
  responsive: true,
 animation,
  plugins: {},
 };
  
  
  return option
}

const formatDataForChart = (months, overflows, label, tension=0) => {
  return {
    labels: months,
    datasets: [
      {
        fill: true,
        label: label,
        data: overflows,
        borderColor: "rgba(247, 55, 247, 0.70)",
        borderWidth: 2,
        backgroundColor: "rgba(255, 255, 255, 0)",
        tension: tension,
      },
    ],
  };
};





const LineChart = ({ label = "Empty Label", monthData, tension, className}) => {

  const [t] = useTranslation("global");
  let monthsTranslated =[]

 
  monthsTranslated = monthData.map((data) => {
    
     const month = t(translationsPaths[data.month])
       ? t(translationsPaths[data.month])
       : data.month;
    if (data.year) {
      return month + ' '+ data.year
    }
    else {
   return month;
    }
   
    });
    
  
  // if (labelType=="year") {
  //    monthsTranslated = monthData.map((item) => {
  //      return item.year
  //    });
  // }
  

  const ordinatesData = monthData.map(({ data }) => data);

  const translatedLabel = t(label);

   const data = formatDataForChart(
    monthsTranslated,
    ordinatesData,
     translatedLabel, 
     tension
   );

  return (
    
     
        <Line
          options={options(data)}
          data={data}
          height={100}
          width={250}
          className={cn("bg-white rounded-sm shadow-md w-full p-2", className)}
        />
    
   
  );
};



export default LineChart;
