import React from 'react';

import { Label } from '@/components/atoms/texts/label';

import useCVTab from '../../../../../hooks/global-hooks/useCVTab';
import RenderCvContent from './RenderCvContent';
import { useEffect } from 'react';

export default function CVTab() {
    const { CVData, error } = useCVTab();

    // console.log('CVTab() CVData : ', CVData)
    useEffect(()=>{console.log("CVTab() CVData =", CVData);},[CVData]);

    if (error || (!CVData)) {
        return (
            <Label
                size="bodySmall"
                fontWeight="regular"
                message="error.fetchUnsuccessfull"
            >
                {error}
            </Label>
        );
    }
    return (
        <div className='flex flex-col gap-thirdDegree py-paddingTopAndBottom px-paddingLeftAndRight'>
            {/* {console.log("CVTab, CVData = ", CVData)} */}
            <RenderCvContent CVData={CVData} isMain/>

        </div>
    )

}