import React from 'react';
import { Navigate , useNavigate} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

// `PrivateRoute` ajusté pour fonctionner comme un wrapper
const PrivateRoute = ({ element: Element, roles }) => {
  const { isAuth, hasRoles } = useAuth();

  if (!isAuth()) {
    // Non authentifié, rediriger vers la page de connexion
    return <Navigate to="/login" replace />;
  }

  if (!hasRoles(roles)) {
    // Authentifié mais n'a pas le bon rôle, rediriger vers une page non autorisée
    return <Navigate to="/unauthorized" replace />;
  }
  
  // Authentifié et a le bon rôle, rendre l'élément protégé
  return Element;
};

export default PrivateRoute;
