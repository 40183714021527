
import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { useTranslation } from 'react-i18next'
import useTranslate from '@/services/global-services/useTranslate'



const labelVariants = cva(
  "text-ellipsis overflow-hidden "
  ,
  {
    variants: {
      size: {
        h1: 'text-h1',
        h2: 'text-h2',
        h3: 'text-h3',
        h4: 'text-h4',
        h5: 'text-h5',
        h6: 'text-h6',
        h7: 'text-h7',
        h8: 'text-h8',
        body: 'text-body',
        small: 'text-bodySmall',
        caption: 'text-caption',
      },
      wFull:{
        true:'w-full ',
      },
      color: {
        white: 'text-white',
        black: 'text-night',
        grey: 'text-greyStrong',
        primary: 'text-primary',
        secondary: 'text-secondary',
        warning:"text-warning",
        gray400:"text-gray-400"
      },
      fontWeight: {
        extralight: 'font-extralight',
        regular: 'font-regular',
        semibold: 'font-semibold',
        bold: 'font-bold',
      },
      fontFamily: {
        mediaGothic: 'font-mediaGothic',
      },
    },
    defaultVariants: {
      size: 'body',
      fontWeight: 'regular',
      fontFamily: '',
    },
  });

const Label = React.forwardRef((props, ref) => {
  const {
    className,
    size,
    fontWeight,
    fontFamily,
    color,
    wFull,
    message,
    children,
    ...otherProps
  } = props;
  let content;
  if (message) {
    // If the message is provided, use the useTranslate hook to translate it
    //the first message is argument, the second is the key to find the message in the dictionary
    content = useTranslate(message)(message);
    content == false && (content = children)
  } else {
    // Otherwise, use the children passed to the component
    content = children;
  }
  
  

  return (
    <span className={cn(labelVariants({ color , wFull}))}>
      <LabelPrimitive.Root
        ref={ref}
        className={cn(labelVariants({ size, fontWeight, fontFamily }), className)}
        {...otherProps}
      >
        {content}
      </LabelPrimitive.Root>
    </span>
  );
});
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
