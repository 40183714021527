/* eslint-disable react/prop-types */
import  { useState } from "react";
import TableRow from "../../../organisms/DataTable/TableRow";
import TableHead from "../../../organisms/DataTable/TableHead";
//import Select from 'react-select'
import { useEffect } from "react";
//import useConsultantData from '../navigation/MenuConsultant/useConsultantData';
import {
  getBillsForYear,
  getClientsForUser,
  getMonthDataBills,
  getAllBillYears,
} from "@/utils/transformUserData";
import { filterUnwantedPropertiesAdmin } from "@/utils/transformUserData";
import UniqueSelect from "../../../molecules/inputs/UniqueSelect";
import MultiSelect from "../../../molecules/inputs/MultiSelect";
import { useAuth } from "@/context/AuthContext";

// Assigner la somme totale à la dernière ligne
// Ajoutez la ligne "annuel" après avoir calculé totalDays

const getTableHead = (role) => {
  let tableHead = [
    { customer: "consultants.statement.billsDetails.table.customers" },
    { bills: "consultants.statement.billsDetails.bills" },
    { nbDays: "consultants.statement.billsDetails.table.nbDays" },
    { amount: "consultants.statement.billsDetails.table.amount" },
  ];
  if (role == "admin") return tableHead;
  else {
    let nouvelleValeurBills = "consultants.statement.overview.dailyRate";

    // Recherche de l'index de l'élément avec la clé "bills" dans le tableau
    let indexBills = tableHead.findIndex((element) => "bills" in element);

    // Vérification si l'index a été trouvé
    if (indexBills !== -1) {
      // Mise à jour de la valeur de la clé "bills"
      tableHead[indexBills].bills = nouvelleValeurBills;
    } else {
      // Gestion si la clé "bills" n'a pas été trouvée
      console.error("Clé 'bills' non trouvée dans le tableau.");
    }
  }
  return tableHead;
};

// Affichage du tableau mis à jour

const sortItems = (a, b) => {
  return b - a;
};

const filterUnwantedPropertiesConsultants = (data) => {
  return Object.values(data).map((dayData) => {
    const { rowNameOrMonth, customer, tjm, nbDays, amount, ...rest } = dayData;
    return { rowNameOrMonth, customer, tjm, nbDays, amount };
  });
};

function BillsContent({
  setChangeContent,
  setEditBillsData,
  activeUser,
  itemToDelete,
  changeMonthDataDeleted,
  monthDataAfterDelete,
  informSelectedYear,
}) {
  // let sortedDESC = true
  const { user } = useAuth();
  const wantedProperties =
    user.role == "admin"
      ? filterUnwantedPropertiesAdmin
      : filterUnwantedPropertiesConsultants;
  //const { activeUser } = useConsultantContext();
  const [years, setYears] = useState(getAllBillYears(activeUser));
  const sortedYears = [...years].sort(sortItems);

  //setYears(sortedYears);
  const [yearlydata, setYearlyData] = useState(
    getBillsForYear(activeUser, sortedYears[0])
  );
  const [monthDataBills, setMonthDataBills] = useState(
    getMonthDataBills(yearlydata)
  );
  const dataMonth =
    user.role == "admin"
      ? filterUnwantedPropertiesAdmin(monthDataBills)
      : filterUnwantedPropertiesConsultants(monthDataBills);
  const [filteredData, setFilteredData] = useState(
    itemToDelete ? monthDataAfterDelete : dataMonth
  );
  const [selectedYear, setSelectedYear] = useState(sortedYears[0]);
  const customers = getClientsForUser(activeUser);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  // console.log('my data',monthDataBills)

  const totalDays = (monthData) => {
    return (
      monthData
        // Exclure la dernière ligne
        .reduce((sum, row) => sum + parseFloat(row.nbDays), 0)
    );
  };
  const totalAmount = (monthData) => {
    return (
      monthData
        // Exclure la dernière ligne
        .reduce((sum, row) => sum + parseFloat(row.amount), 0)
    );
  };
  const filterDataYear = (year) => {
    setSelectedYear(year);
    informSelectedYear(year);

    if (selectedCustomers) {
      filterTable(selectedCustomers);
    } else {
      //setSelectedYear(year);
      const newYearlyData = getBillsForYear(activeUser, year);

      const newData =
        user.role == "admin"
          ? filterUnwantedPropertiesAdmin(getMonthDataBills(newYearlyData))
          : filterUnwantedPropertiesConsultants(
              getMonthDataBills(newYearlyData)
            );
    //   console.log("Year", year);
      newData.push({
        rowNameOrMonth: "annuel",
        clients: "",
        bills: "",
        nbDays: `${totalDays(newData)} jours`,
        amount: totalAmount(newData),
      });
      setFilteredData(() => newData);
    } // Utiliser une fonction de rappel pour s'assurer que newData est à jour
  };

  const filterTable = (selectedValuesClients = []) => {
    //setSelectedCustomers(selectedValuesClients);
    const newYearlyData = getBillsForYear(activeUser, selectedYear);
    setYearlyData(newYearlyData);

    let newFilteredData =
      user.role == "admin"
        ? filterUnwantedPropertiesAdmin(getMonthDataBills(newYearlyData))
        : filterUnwantedPropertiesConsultants(getMonthDataBills(newYearlyData));
    // console.log("selectedValuesClients:", selectedValuesClients);
    // console.log("monthDataBills:", monthDataBills);

    // console.log("selected values", selectedValuesClients);
    // // Vérifier si des clients sont sélectionnés
    if (selectedValuesClients.length > 0) {
      // Filtrer la table en fonction des clients sélectionnés
      newFilteredData = monthDataBills.filter((item) =>
        selectedValuesClients.includes(item.customer)
      );
    }
    // console.log("new filtered DATA", newFilteredData);
    newFilteredData = newFilteredData.filter(
      (item) => item.rowNameOrMonth !== "annuel"
    );
    // Ajouter la ligne "annuel" après avoir calculé totalDays et totalAmount
    newFilteredData.push({
      rowNameOrMonth: "annuel",
      clients: "",
      bills: "",
      nbDays: `${totalDays(newFilteredData)} jours`,
      amount: totalAmount(newFilteredData),
    });

    // Mettre à jour l'état avec les données filtrées
    setFilteredData(newFilteredData);
  };

  const handleClick = (rowData) => {
    // console.log("this row has been cliked", rowData);
    setChangeContent(true);
    setEditBillsData(rowData);
    changeMonthDataDeleted(monthDataBills);
  };
  // const changeData = (value) => {
  //     console.log('Bills selected value', value)
  // }

  useEffect(() => {
    //setFilteredData(monthDataBills);
    informSelectedYear(selectedYear);
    const calculateTotal = () => {
      const newFilteredData = [...filteredData];

      // Supprimer la ligne "annuel" s'il existe déjà
      const filteredWithoutAnnuel = newFilteredData.filter(
        (item) => item.rowNameOrMonth !== "annuel"
      );

      // Ajouter la ligne "annuel" après avoir calculé totalDays et totalAmount
      filteredWithoutAnnuel.push({
        rowNameOrMonth: "annuel",
        clients: "",
        bills: "",
        nbDays: `${totalDays(filteredWithoutAnnuel)} jours`,
        amount: totalAmount(filteredWithoutAnnuel),
      });

      // Mettre à jour l'état avec les données filtrées
      setFilteredData(filteredWithoutAnnuel);
    };

    // Appeler la fonction de calcul lorsque filteredData change
    calculateTotal();
  }, []);

  return (
    <div>
      <div className="flex flex-row w-full py-paddingTopAndBottom px-paddingLeftAndRight gap-secondDegree">
        <div className="w-full">
          <UniqueSelect
            dataToSelect={years}
            labelClassName={"font-bold text-h6"}
            filterDataFunction={filterDataYear}
          />
          {/* <Selecter dataToSelect={years} handleValueSelected={changeData}/>  */}
        </div>
        <div className="w-full">
          <MultiSelect
            dataToSelect={customers}
            marginBottom={"mb-[7px]"}
            labelClassName={"font-bold text-h6"}
            filterDataFunction={filterTable}
          />
          {/*<Selecter dataToSelect={clientsList} handleValueSelected={changeData} /> */}
        </div>
      </div>
      <div className="p-secondDegree w-[95%]">
        <TableHead
          tableHeadData={getTableHead(user.role)}
          colorsOfAllcells={"grey"}
          classNameForCell={"py-4"}
        />
        {filteredData &&
          filteredData.map((month, index) => (
            <TableRow
              rowData={Object.values(month)}
              key={index}
              enableHover={false}
              tableOfIndexOfCellsToTranslate={[0]}
              indexOfCellWithSpecificBorder={0}
              borderName={"left"}
              makeTansparentFirstCell={true}
              displayHoveredIcon={true}
              className=""
              printToolTip={true}
              handleClick={() => handleClick(Object.values(month))}
              tableOfIndexCellsWithoutCurrency={[1, 2, 3]}
              indexOfCellWithSpecificColor={
                Object.values(month)[0] == "annuel" && 4
              }
              colorOfSpecificCell="primaryLight"

              // onRowDataChange={(newData) => handleMonthDataChange(newData, index)}
            />
          ))}
      </div>
    </div>
  );
}

export default BillsContent;
