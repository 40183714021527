import { getTemplateData } from '@/services/global-services/templateData';
import { useState, useEffect } from 'react';

function useTemplateData() {
    const [templateData, setTemplateData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTemplateData = async () => {
            try {
                const data = await getTemplateData(); // Utilisation de données simulées pour l'exemple
                setTemplateData(data);
                // console.log('fetchTemplateData, data:', data)
            } catch (error) {
                setError(error)
                console.error("Error fetching notifications:", error);
            }
        };

        fetchTemplateData();

    }, []);

    return ({templateData, error})
}

export default useTemplateData;