import { isNumeric } from "@/utils/formatDateString";
// EditBills.jsx
const validateFormData = (formData, setErrors) => {
    const newErrors = {
        date: "",
        ebit: "",
        companyValuation: "",
        actionNumber: "",
        sharePrice: "",
    };

    // Règles de validation
    const validationRules = {
        date: { required: true, message: "Date is required" },
        ebit: { required: true, message: "ebit is required" },
        companyValuation: {
            required: true,
            message: "Company Valuation is required",
        },
        actionNumber: { required: true, message: "Action number is required" },
        sharePrice: { required: true, message: "shares is required" },
    };

    // Appliquer les règles de validation à chaque champ
    Object.keys(validationRules).forEach((fieldName) => {
        const rule = validationRules[fieldName];
        const value = formData[fieldName];
        // console.log("the cause v ", value);
        if (!isNumeric(value)) {
            if (rule.required && !value.trim()) {
                newErrors[fieldName] = rule.message;
            }
        }
    });

    // Mettez à jour le state des erreurs
    setErrors(newErrors);

    // Retournez la liste des champs non renseignés
    return Object.keys(newErrors).filter((fieldName) => newErrors[fieldName]);
};

export { validateFormData };
