import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { cn } from "@/lib/utils";
import Chip from "@/components/molecules/buttons/Chip";
import { CircularProgressbar } from "react-circular-progressbar";

import {
    ArrowDownward,
    ArrowUpward,
    Help,
    HelpOutline,
    Upcoming,
} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function CircularProgressBar({
    data,
    evolution,
    helpText,
    label,
    roundedBorders = true,
    chipText,
    className,
}) {
    const [t] = useTranslation("global");
    const text = t(helpText);
    const borders = roundedBorders
        ? "shadow-lg rounded-sm border border-medium"
        : "shadow-0 rounded-0 border border-0  ";

    //   console.log("rounded borders", roundedBorders);

    return (
        <div
            className={cn(
                "py-firstDegree px-secondDegree flex flex-col gap-fifthDegree bg-white items-center w-full h-full min-h-[155px] object-contain relative ",
                borders,
                className
            )}
        >
            <div className="w-full flex justify-between  items-stretch absolute">
                {chipText && (
                    <Chip
                        item={chipText}
                        rounded=""
                        bg="medium"
                        size="small"
                        fontWeight=""
                        className={"truncate w-auto"}
                    />
                )}
                <div></div>
                {helpText && (
                    <Tooltip title={text} placement="top" arrow>
                        {/* <HelpOutline className=" top-[-10px] float-right text-grey mx-1" /> */}
                        <HelpOutline className="top-[-10px] text-grey  " />
                    </Tooltip>
                )}
            </div>
            <div className="w-full min-h-[105px] mt-[30px]">
                <CircularProgressbar
                    className="text-h3 font-bold  flex items-center "
                    value={data}
                    text={`${data}%`}
                />
            </div>
            <div className=" flex flex-row gap-betweenText items-center font-bold ">
                <span className={`${evolution > 0 ? "text-success" : "text-error"}`}>
                    {evolution && evolution > 0 && "+"}
                    {evolution + "%"}
                </span>
                {evolution > 0 ? (
                    <ArrowUpward className=" text-success" fontSize="small" />
                ) : (
                    <ArrowDownward className=" text-error" fontSize="small" />
                )}
            </div>

            {label && <Label message={label} size="body" fontWeight="bold" />}
        </div>
    );
}
