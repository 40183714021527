import { Label } from "@/components/atoms/texts/label";
import ReadingStatus from "./readingStatus";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/molecules/popover";
import { Button } from "@/components/molecules/buttons/button";
import { useState } from "react";
import Polygon1 from "@/assets/Polygon1.svg"
import ControlsDownload from "@/components/molecules/controlsDownload";

// "amountTax": { "5": "7.36", "10": "4.53", "12": "7.68" },
export default function Expense({ data, dataOwner }) {

    const [open, setOpen] = useState();
    const totalTax = data.amountTax ? Object.values(data.amountTax).reduce((acc, value) => acc + parseFloat(value), 0) : 0;

    return (
        <div className="bg-white w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom rounded-sm justify-start items-center inline-flex gap-secondDegree">
            <ReadingStatus type={data.statut} />
            <Label fontWeight="bold">{data.category}</Label>
            <Label fontWeight="">n°{data.number}</Label>
            <div className="grow"></div>
            <Label fontWeight="">{data.date}</Label>
            <div className="flex gap-firstDegree">
                <Label fontWeight="bold" message="consultants.expense.amountExclTaxs" />
                <Label fontWeight="">{data.amountExclTax} €</Label>
            </div>


            <div className="flex gap-firstDegree items-center">
                <Label fontWeight="bold" message="consultants.expense.amountTax" />
                <Popover open={open} onOpenChange={setOpen} >
                    <PopoverTrigger onMouseOver={(e) => setOpen(true)} onMouseOut={(e) => setOpen(false)} asChild className="flex gap-firstDegree items-center ">
                        <div className="relative">
                        <img src={Polygon1} className="absolute top-[-2px] left-[-6px] opacity-50" />

                            <Label fontWeight="" className="">{totalTax.toFixed(2)} €</Label>
                        </div>

                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col gap-firstDegree w-auto">
                        {data.amountTax && Object.entries(data.amountTax).map(([key, value], index) => (
                            <div className="flex gap-firstDegree" key={index}>
                                <Label size="body" fontWeight="bold">{key} % :</Label><Label size="body"> {value} €</Label>
                            </div>
                        ))}
                    </PopoverContent>
                </Popover>
            </div>

            <Label fontWeight="">{data.sizeFile}</Label>
           
            <ControlsDownload dataOwner={dataOwner} onClickNewTab={()=>console.log("onClickNewTab")} onClickDownload={()=>console.log("onClickDownload")} onClickDelete={()=>console.log("onClickDelete")}/>

        </div>
    )
}