// useExpenseForm.js

import { useState, useEffect } from 'react';

const useFormNewExpense = (onSubmit, submitRef, data) => {
    const currencies = [
        { code: "EUR", symbol: "€" },
        { code: "USD", symbol: "$" },
        { code: "JPY", symbol: "¥" },
        { code: "GBP", symbol: "£" },
        { code: "CHF", symbol: "Fr" }
    ];

    const [file, setFile] = useState(null);
    const [currency, setCurrency] = useState(currencies[0].symbol);
    const [date, setDate] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [amount, setAmount] = useState('');
    const [vatValues, setVatValues] = useState({});
    const [totalVat, setTotalVat] = useState(0);

    const [fileError, setFileError] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [selectValueError, setSelectValueError] = useState(null);
    const [amountError, setAmountError] = useState(null);
    const [totalVatError, setTotalVatError] = useState(null);

    const handleVatChange = (e, rate) => {
        const newVatValues = { ...vatValues, [rate]: parseFloat(e.target.value) || 0 };
        setVatValues(newVatValues);
    };

    useEffect(() => {
        const total = Object.values(vatValues).reduce((sum, current) => sum + current, 0);
        setTotalVat(total);
    }, [vatValues]);

    useEffect(() => {
        totalVat && validateForm("totalVatErrorTest");
    }, [totalVat]);

    useEffect(() => {
        date && validateForm("dateErrorTest");
    }, [date]);

    useEffect(() => {
        amount && validateForm("amountErrorTest");
    }, [amount]);

    useEffect(() => {
        selectValue && validateForm("selectValueErrorTest");
    }, [selectValue]);

    useEffect(() => {
        amount && validateForm("totalVatErrorTest");
    }, [amount]);

    useEffect(() => {
        if (data) {
            // setCurrency(data.currency);
            setFile()
            setDate(data.date);
            setSelectValue(data.category);
            setAmount(data.amountExclTax);
            setVatValues(data.amountTax);
            // setTotalVat(data.);›

        }
    }, [data]);

    const resetForm = () => {
        setFile(null);
        setDate('');
        setSelectValue('');
        setAmount('');
        setVatValues({});
        setTotalVat(0);
        setFileError(null);
        setDateError(null);
        setSelectValueError(null);
        setAmountError(null);
        setTotalVatError(null);
    };

    const declineExpense = ()=>{

    }

    function validateForm(varToTest) {

        const numericAmount = parseFloat(amount);
        const numericTotalVat = parseFloat(totalVat);
        // console.log("varToTest", varToTest);
        // Utilisez un switch pour gérer chaque cas spécifique ou tous les cas ensemble ("all")
        switch (varToTest) {
            case "all":
                setTotalVatError(!numericTotalVat);
                !data && setFileError(!file);
                setDateError(!date);
                setSelectValueError(!selectValue);
                setAmountError(!amount);
                // console.log(!file || !data, !data, file)
                break;
            case "fileErrorTest":
                setFileError(!file || !data);
                break;
            case "dateErrorTest":
                setDateError(!date);
                break;
            case "selectValueErrorTest":
                setSelectValueError(!selectValue);
                break;
            case "amountErrorTest":
                setAmountError(!amount);
                break;
            case "totalVatErrorTest":
                setTotalVatError((numericTotalVat >= numericAmount) || numericTotalVat == 0);
                break;
            default:
                console.log("Unknown error test:", varToTest);
                break;
        }
        if ((!data && !file) || !date || !selectValue || !amount || !numericTotalVat) {
            return false;
        }
        return true;
    };

    return {
        file,
        date,
        currency,
        currencies,
        selectValue,
        amount,
        vatValues,
        totalVat,
        handleVatChange,
        resetForm,
        declineExpense,
        fileError,
        dateError,
        selectValueError,
        amountError,
        totalVatError,
        setFile,
        setDate,
        setCurrency,
        setSelectValue,
        setAmount,
        validateForm
    };
};

export default useFormNewExpense;
