/* eslint-disable react/prop-types */
import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";

/**
 * @module Separator
 * @description A flexible and customizable separator component for layout division.
 * @see https://link-to-documentation-or-example (if applicable)
 * 
 * @example
 * import { Separator, separatorVariants } from './SeparatorComponent';
 * 
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       Content before separator
 *       <Separator color="primaryBlue" orientation="horizontal" />
 *       Content after separator
 *     </div>
 *   );
 * };
 * 
 * @component
 * @param {object} props - React props for Separator component.
 * @param {string} [props.className] - Additional CSS classes to be applied to the Separator.
 * @param {string} [props.color="default"] - Color variant for the separator (default, white, primaryBlue, primaryBlueBlur, secondaryBlue).
 * @param {string} [props.orientation="horizontal"] - Orientation of the separator (horizontal or vertical).
 * @param {boolean} [props.decorative=true] - Whether the separator is decorative or functional.
 * @param {boolean} [props.blur=false] - Whether to apply a blur effect to the separator.
 * @param {string} [props.blurColor] - Color of the blur effect if applied.
 * @param {React.Ref} ref - React ref to be forwarded to the underlying separator component.
 * @returns {React.Element} React component representing a separator.
 */

const baseClass = "flex justify-center items-center self-stretch shrink-0  ";

const separatorVariants = cva(baseClass,{
  variants: {
    orientation: {
      
      horizontal: ["h-[3px] w-[80%] border mx-marginLeftAndRight "], 
      vertical: ["h-[80%] w-[3px] absolute my-marginLeftAndRight"], // we can remove the absolute class if you want the separator to sepearte elements vertcally
    },
    color: {
      default: "bg-night",
      white: "bg-white",
      primaryBlue: "bg-primary",
      primaryBlueBlur: "bg-[#205CC1]",
      secondaryBlue: "bg-secondary",
    },
    blur: {
      default: false
    },
   
  },
  defaultVariants: {
    orientation: "horizontal",
    blur: "default",
    color: "default",
    
  },
});
const Separator = React.forwardRef(({ className, color, orientation, decorative = true, blur, blurColor, ...props }, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    orientation={orientation}
    decorative={decorative}
    className={cn(separatorVariants({ orientation, color }), className)}
    {...props}
  >
    {blur && (
      <>
        <div className={cn(baseClass,blurColor, "absolute mx-marginLeftAndRight  inset-0  h-[3px] w-[80%] blur-[5px]")}></div>
        <div className={cn(baseClass, blurColor,"absolute mx-marginLeftAndRight inset-0  h-[3px] w-[80%] blur-[5px]")}></div>
      </>
    )}
  </SeparatorPrimitive.Root>
));

Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator, separatorVariants };


