/* eslint-disable react/prop-types */
import React from "react";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import useTranslate from "@/services/global-services/useTanslate";


const baseClass ="rounded-sm border-disabled border-[1px] p-firstDegree text-h6 ";



const Input = React.forwardRef(({ className, placeHolder,...props }, ref) => {
  const [value, setValue] = useState(props.value || ""); 
  const  [t ] = useTranslation('global');
  const placeHolderTranslated = t(placeHolder);
  const handleChange = (e) => {
    setValue(e.target.value); // Mettre à jour la valeur d'état interne
    if (props.onChange) {
      props.onChange(e); // Appeler la fonction de gestionnaire de changement parent si elle est définie
    }
  };

  return (
    <input
      type={props.type}
      className={cn(className, baseClass, props.border)}
      ref={ref}
      value={value} // Utiliser la valeur de l'état interne
      onChange={handleChange}
      placeholder={placeHolderTranslated} // Gérer le changement de valeur localement
      {...props}
    />
  );
});
Input.displayName = "Input";
export default Input;
// export default function Input({
//   type,
//   defaultValue,
//   onChange,
//   step,
//   className,
//   htlmFor1,
//   placeHolder
// }) {
//    const [value, setValue] = useState(props.value || ""); // Maintenir une valeur d'état interne

//    // Fonction pour gérer le changement de valeur
//    const handleChange = (e) => {
//      setValue(e.target.value); // Mettre à jour la valeur d'état interne
//      if (props.onChange) {
//        props.onChange(e); // Appeler la fonction de gestionnaire de changement parent si elle est définie
//      }
//    };
//   return (
//     <input
//       type={type}
//       defaultValue={defaultValue}
//       onChange={onChange}
//       step={step}
//       name={htlmFor1}
//       placeholder={placeHolder}
//       className={cn(baseClass, className)}
//     />
//   );
// }