import { createTheme } from '@mui/material/styles'
// primary - for primary interface elements.
// secondary - for secondary interface elements.
// error - for elements that the user should be made aware of.
// warning - for potentially dangerous actions or important messages.
// info - for highlighting neutral information.
// success - for indicating the successful completion of an action that the user triggered.

const theme = createTheme({
  palette: {
    // ------------- generic tokens -------------
    primary: {
      main: '#1B3477',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#52D4E6',
    },
    tertiary: {
      main: '#F737F7'
    },
    //semanticError    
    error: {
      main: '#FF9790',
    },
    //semanticWarning
    warning: {
      main: '#FFDF8E',
    },
    //semanticInformation
    info: {
      main: '#6EC3FB',
    },
    //semanticSuccess
    success: {
      main: '#64DBAC',
    },

    // ------------- personalized tokens -------------
    semanticDisabled: {
      main: '#797979',
    },
    primaryLight:{
      main:'#CAD3EB'
    },
    primaryHover:{
      main:'#ADB6CE'
    },
    primaryClicked:{
      main:'#1B3477'
    },
  
    //Backgrounds
    backgroundLighter: {
      main: '#FEFEFE',
    },
    backgroundMedium: {
      main: '#F1F4F8',
    },
    backgroundGrey: {
      main: '#DCE1E8',
    },
    backgroundGreyStrong: {
      main: '#C6CDD8',
    },

    //Gradients
    verticalPrimaryLinear: {
      //TODO
      main: '',
    },
    horizontalPrimaryLinear: {
      //TODO
      main: '',
    },
    radialPrimaryLinear: {
      //TODO
      main: '',
    },
    linearBlue: {
      //TODO
      main: '',
    },

    //Peps color
    purple: {
      main: '#6D4AC6',
    },
    pink: {
      main: '#F737F7',
      contrastText: "#ffffff"
    },
    
    //Charts color palette
    chart1: {
      main: '#77D2E3',
    },
    chart2: {
      main: '#5E8BE0',
    },
    chart3: {
      main: '#7EE2B2',
    },
    chart4: {
      main: '#6058DE',
    },
    chart5: {
      main: '#D76C59',
    },
    chart6: {
      main: '#DEAF62',
    },
    chart7: {
      main: '#D65D89',
    },
    chart8: {
      main: '#D8E46B',
    },

    //basics
    white:{
      main:'#ffffff',
    }
  },
});

export default theme;