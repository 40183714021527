// hooks/useConnectionForm.js
import React, { useState, useRef } from 'react';

const useConnectionForm = () => {
    const [step, setStep] = useState(1000);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [formErrors, setFormErrors] = useState({});
    const [code, setCode] = useState();

    // Handles changes for email and password fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        if (formErrors[name]) {
            setFormErrors(prev => ({ ...prev, [name]: null }));
        }
    };

    const handleChangeCode = (code_) => {
        setCode(code_)
    }
    // Validates form data
    const validateForm = () => {
        const errors = {};
        if (!formData.email) errors.email = 'Email is required';
        if (!formData.password) errors.password = 'Password is required';
        return errors;
    };

    // Submits the form
    const handleSubmit = () => {
        if (step === 3) {
            const isCodeComplete = code.length == 6;
            if (!isCodeComplete) {
                console.log('Please fill all the code fields.');
                return;
            }
            nextStep();
            console.log('Submitting form with the following details:', formData, 'Code:', code);
            // Here, you would typically integrate backend submission logic
        } else {
            const errors = validateForm();
            if (Object.keys(errors).length === 0) {
                console.log('Moving to next step', formData);
                nextStep();
            } else {
                setFormErrors(errors);
            }
        }
    };

    // Advances to the next step
    const nextStep = () => setStep(prev => prev + 1);

    // Goes back to the previous step
    const prevStep = () => setStep(prev => prev - 1);

    return {
        step,
        formData,
        formErrors,
        code,
        handleChange,
        handleChangeCode,
        handleSubmit,
        nextStep,
        prevStep,
    };
};

export default useConnectionForm;
