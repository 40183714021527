import * as React from "react";
import { useState } from "react";
import LineArt from "@/assets/images/lineArt.png";
import BlueHeader from "@/components/organisms/blueHeader";
import DataCard from "@/components/organisms/charts/DataCard";
import Filter from "@/components/organisms/Filter";
// import MultipLineChart from "@/components/organisms/charts/MutltipleLineChart";
import {

    getMostRecentData,
    getPrecedentOfMostRecentData,

} from "@/services/admin-services/valuationData";

import { computeEvolution } from "@/utils/finance";
import LineChart from "@/components/organisms/charts/LineChart";
import { useEffect } from "react";
import useValuationData from "./useValuationData";
import PrintErrorMessage from "@/components/molecules/PrintErrorMessage";
import PrintFetchingData from "@/components/molecules/PrintFetchingData";
import { useTranslation } from "react-i18next";
import { translationsPaths } from "@/utils/translationPath";
import getNumbersFromString from "@/utils/getNumbersFromString";



const statCard = (data, evolution, label, helpText) => (
    <div className="  w-full ">
        <DataCard
            data={data}
            evolution={evolution}
            label={label}
            helpText={helpText}
            className="rounded-md"
        />
    </div>
);
export default function CompanyValuation() {
    const { valuationData, error } = useValuationData();

    const [items, setItems] = useState([]);
    const [dataForChart, setDataForChart] = useState([]);
    const [seletedItem, setSelectedItem] = useState(0);
    const [labelType, setLabelType] = useState("month")
    const [t] = useTranslation("global");
    const mostRecentValuationData = getMostRecentData();
    const precedent = getPrecedentOfMostRecentData();



    useEffect(() => {
        const formatDataForFilter = (vData) => {
            if (vData) {
                const items = vData.map(
                    (data) => data.item + " " + t(translationsPaths[data.type])
                );
                setItems(items);
                setSelectedItem(items[0]);
                const numberItem = getNumbersFromString(items[0]);
                const data = getValuationDataByItem(numberItem[0]);
                setDataForChart(data);
                console.log('data for chart', data)
            }


        };
        formatDataForFilter(valuationData);

    }, [valuationData, t]);


    useEffect(() => {
        // console.log(seletedItem)
        const getFirstNumberFromString = (str) => {
            const regex = /\d+/; // Correspond à la première séquence de chiffres
            const matches = str.match(regex); // Retourne le premier nombre trouvé

            if (matches && matches.length > 0) {
                return parseInt(matches[0], 10); // Convertir la chaîne en entier
            }

            return null; // Retourne null si aucun nombre n'est trouvé
        };
        const update = () => {
            const numberItem = getFirstNumberFromString(
                seletedItem ? seletedItem : "12"
            );
            const data = getValuationDataByItem(numberItem ? numberItem : 12);
            setDataForChart(data);
            setLabelType(getLabelType(numberItem ? numberItem : 12))
        }
        const getLabelType = (item) => {
            if (valuationData) {
                const matchingData = valuationData.find((d) => d.item === item); // Rechercher par 'item'
                if (matchingData) {
                    return matchingData.type; // Retourner le 'data' associé
                }
            }

            return null; // Retourner null si aucun élément correspondant n'est trouvé
        };


        update()


    }, [seletedItem])


    const getValuationDataByItem = (item) => {
        if (valuationData) {
            const matchingData = valuationData.find((d) => d.item === item); // Rechercher par 'item'
            if (matchingData) {
                return matchingData.data; // Retourner le 'data' associé
            }
        }

        return null; // Retourner null si aucun élément correspondant n'est trouvé
    };


    if (error) return <PrintErrorMessage />;
    if (!valuationData) return <PrintFetchingData />;

    return (
        <div className="bg-lighter flex grow overflow-hidden relative">

            <div className="w-full h-[93vh] overflow-y-scroll overflow-x-hidden z-10  ">
                <img src={LineArt} className="bgLineArt " />
                <BlueHeader
                    title="consultants.statement.valuationPanel.companyValuation"
                    caption="companyValuation.caption"
                />

                <div className="grid grid-rows gap-thirdDegree mt-thirdDegree justify-items-center w-full px-[10vw] mb-[20px]">
                    <div className="flex flex-row gap-secondDegree w-full ">
                        {
                            statCard(
                                mostRecentValuationData && mostRecentValuationData.ebit + "€",
                                computeEvolution(mostRecentValuationData.ebit, precedent.ebit),
                                "consultants.statement.valuationPanel.ebit",
                                "companyValuation.ebit"
                            )
                            //
                        }
                        {
                            statCard(
                                mostRecentValuationData &&
                                mostRecentValuationData.companyValuation + "€",
                                computeEvolution(
                                    mostRecentValuationData.companyValuation,
                                    precedent.companyValuation
                                ),
                                "consultants.statement.valuationPanel.companyValuation",
                                "companyValuation.companyValuation"
                            )
                            //
                        }
                        {
                            statCard(
                                mostRecentValuationData &&
                                mostRecentValuationData.sharePrice + "€",
                                computeEvolution(
                                    mostRecentValuationData.sharePrice,
                                    precedent.sharePrice
                                ),
                                "consultants.statement.valuationPanel.sharesPrice",
                                "companyValuation.sharesPrice"
                            )
                            // computeEvolution(mostRecentValuationData.sharePrice, precedent.sharePrice)
                        }
                        {
                            statCard(
                                mostRecentValuationData && mostRecentValuationData.actionNumber,
                                computeEvolution(
                                    mostRecentValuationData.actionNumber,
                                    precedent.actionNumber
                                ),
                                "companyValuation.shareNumber",
                                "companyValuation.shareNumber"
                            )
                            // // computeEvolution(mostRecentValuationData.actionNumber, precedent.actionNumber)
                        }
                    </div>


                    <Filter
                        items={items ? items : [0]}
                        informSelectedItem={setSelectedItem}
                    />

                    <LineChart
                        monthData={dataForChart ? dataForChart : [0]}
                        label={"companyValuation.sharesPrice"}
                        tension={0.3}
                                            // labelType={labelType}
                    />

                </div>
            </div>

        </div>
    );
}
