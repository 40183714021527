
import { Button } from '../buttons/button'
import { useState } from 'react';
//display one consultant
// eslint-disable-next-line react/prop-types
export default function ConsultantItem({ consultantName, onClick, isSelected }) {
  return (
    <div>
      <Button
        showLeftIcon={false}
        leftIcon=""
        showRightIcon={false}
        rightIcon=""
        showText={true}
        onClick={onClick}
        sizePadding="default"
        style={`${isSelected ? "secondaryDressed" :"ghost"}`}
        className={`cursor-pointer w-full  justify-start font-medium`}
      >
        {consultantName}
      </Button>
    </div>
  );
}
