// variantLogic.js
export const getVariantItems = (role) => {

  const itemsByRole = {
    admin: [
      { name: 'navbarAdmin.consultant', path: '/consultant' },
      { name: 'navbarAdmin.finance', path: '/finance' },
      { name: 'navbarAdmin.statistics', path: '/statistics' },
      { name: 'navbarAdmin.overflowOptimization', path: '/overflowOptimization' },
    ],
    consultant: [
      { name: 'navbarConsultant.administration', path: '/administration' },
      { name: 'navbarConsultant.profitSharing', path: '/profitSharing' },
      { name: 'navbarConsultant.compagnyValuation', path: '/companyValuation' },
      { name: 'navbarConsultant.overflowOptimization', path: '/overflow' },
    ],
  }

  return itemsByRole[role];
};
