import axios from "axios";

const defaultD = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas possimus nisi pariatur quo recusandae quidem exercitationem aperiam impedit aliquid eaque similique eius qui maiores iure quaerat voluptas, eveniet distinctio quibusdam?";
const getCertifications = () => {
    return [
        {
            certificationTitle: "GCP",
            organism: "Coursera",
            date: "10/11/2022",
            description: defaultD,
            id: "1",
        },
        {
            certificationTitle: "AWS",
            organism: "Udemy",
            date: "15/12/2022",
            description: defaultD,
            id: "2",
        },
        {
            certificationTitle: "Azure",
            organism: "edX",
            date: "20/01/2023",
            description: defaultD,
            id: "3",
        },
        {
            certificationTitle: "Python",
            organism: "Pluralsight",
            date: "25/02/2023",
            description: defaultD,
            id: "4",
        },
    ];
};

const getDescriptions = () => {
    return [
        { description: defaultD, date: "", id: "1" },
        { description: "Description 2", date: "", id: "2" },
        { description: "Description 3", date: "", id: "3" },
        { description: "Description 4", date: "", id: "4" },
    ];
};

const getEducations = () => {
    return [
        {
            educationTitle: "Computer Science",
            school: "University Paris",
            city: "Paris",
            country: "France",
            startDate: "01/01/2020",
            endDate: "01/01/2024",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            id: "1",
        },
        {
            educationTitle: "Data Science",
            school: "Data Science Institute",
            city: "New York",
            country: "USA",
            startDate: "01/01/2022",
            endDate: "01/01/2023",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            id: "2",
        },
        {
            educationTitle: "Machine Learning",
            school: "ML Academy",
            city: "Lille",
            country: "France",
            startDate: "01/01/2023",
            endDate: "01/01/2024",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            id: "3",
        },
        {
            educationTitle: "Web Development",
            school: "Web Dev School",
            city: "Bruxelles",
            country: "Belgique",
            startDate: "01/01/2021",
            endDate: "01/01/2022",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            id: "4",
        },
    ];
};

const getExperiences = () => {
    return [
        {
            jobTitle: "Data Engineer",
            enterprise: "Data Engineer.",
            city: "Los Angeles",
            country: "USA",
            startDate: "01/01/2020",
            endDate: "01/01/2022",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            technologies: "Python, SQL",
            id: "1",
        },
        {
            jobTitle: "Software Developer",
            enterprise: "Sonar Ltd.",
            city: "Geneve",
            country: "Suisse",
            startDate: "01/01/2022",
            endDate: "01/01/2023",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            technologies: "Java, JavaScript",
            id: "2",
        },
        {
            jobTitle: "Data Scientist",
            enterprise: "Tech Solutions",
            city: "Munich",
            country: "Allemagne",
            startDate: "01/01/2023",
            endDate: "01/01/2024",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            technologies: "Python, R",
            id: "3",
        },
        {
            jobTitle: "Web Developer",
            enterprise: "Web Dev Co.",
            city: "Lyon",
            country: "France",
            startDate: "01/01/2021",
            endDate: "01/01/2022",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            technologies: "HTML, CSS, JavaScript",
            id: "4",
        },
    ];
};

const getProjects = () => {
    return [
        {
            projectTitle: "E-commerce Website",
            enterprise: "Online Retail Ltd.",
            city: "Paris",
            country: "France",
            startDate: "01/01/2020",
            endDate: "01/01/2021",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            responsibilities: "Design, Development, Testing",
            technologies: "React, Node.js",
            id: "1",
        },
        {
            projectTitle: "Data Analysis Tool",
            enterprise: "Data Analysis Co.",
            city: "New york",
            country: "USA",
            startDate: "01/01/2022",
            endDate: "01/01/2023",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            responsibilities: "Design, Implementation",
            technologies: "Python, Pandas",
            id: "2",
        },
        {
            projectTitle: "Social Media App",
            enterprise: "Social Tech Ltd.",
            city: "Lyon",
            country: "France",
            startDate: "01/01/2023",
            endDate: "01/01/2024",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            responsibilities: "Design, Development, Deployment",
            technologies: "React, Redux",
            id: "3",
        },
        {
            projectTitle: "Portfolio Website",
            enterprise: "Freelance",
            city: "Marseille",
            country: "France",
            startDate: "01/01/2021",
            endDate: "01/01/2022",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            responsibilities: "Design, Development",
            technologies: "HTML, CSS, JavaScript",
            id: "4",
        },
    ];
};
const getSkills = () => {
    const skillsDefault = [
        { title: "Power BI", level: 2, id: "01" },
        { title: "GCP", level: 3, id: "02" },
        { title: "Java", level: 5, id: "03" },
    ];

    return skillsDefault;
};
const getInformations = () => {
    const informations = {
        languagesSpoken: [{
            languageName: "French",
            level: "A1",
        }],
        profilesTitles: [
            {
                profileTitle: "Ingénieur DATA",
                date: "01/02/2022",
                id: "01",
            },
        ],
    };

    return informations
}

const getRandomItems = (items, maxCount) => {
    const shuffledItems = items.sort(() => 0.5 - Math.random());
    return shuffledItems.slice(0, Math.min(maxCount, shuffledItems.length));
};

const getRandomName = () => {
    const firstNames = ["John", "Jane", "Michael  ", "Emily", "William"];
    const lastNames = ["Doe", "Smith", "Johnson", "Brown", "Taylor"];
    const randomFirstName =
        firstNames[Math.floor(Math.random() * firstNames.length)];
    const randomLastName =
        lastNames[Math.floor(Math.random() * lastNames.length)];
    return { firstName: randomFirstName, lastName: randomLastName };
};

const getAllDrafts = () => {
    const skills = getSkills();
    const projects = getRandomItems(getProjects(), 5);
    const experiences = getRandomItems(getExperiences(), 5);
    const educations = getRandomItems(getEducations(), 5);
    const descriptions = getRandomItems(getDescriptions(), 5);
    const certifications = getRandomItems(getCertifications(), 5);
    const informations = getInformations();

    const allDrafts = [];

    for (let i = 1; i <= 5; i++) {
        const { firstName, lastName } = getRandomName();
        allDrafts.push({
            resumeState: "draft", // ou "done"
            name: lastName,
            personalEmail: "test@gmail.com",
            sex: "M",
            dayOfBirth: "01/01/1997",
            firstName,
            id: `0${i}`,
            skills,
            projects,
            experiences,
            educations,
            descriptions,
            certifications,
            informations,
        });
    }

    return allDrafts;
};

//***A déplacer dans utils****//
const getCertificationTitles = () => {
    const certifications = getCertifications();
    return certifications.map(
        (certification) => certification.certificationTitle
    );
};
const getSkillsList = () => {
    const skills = getSkills();
    return Array.from(skills).map((skill) => skill.skill);
};

const getEducationTitles = () => {
    const educations = getEducations();
    return educations.map((education) => education.educationTitle);
};

const getExperienceTitles = () => {
    const experiences = getExperiences();
    return experiences.map((experience) => experience.jobTitle);
};

const getProjectTitles = () => {
    const projects = getProjects();
    return projects.map((project) => project.projectTitle);
};
const getDescriptionList = () => {
    const descriptions = getDescriptions();
    return descriptions.map((description) => description.description);
};
export {
    getCertifications,
    getDescriptions,
    getEducations,
    getExperiences,
    getProjects,
    getSkills,
    getCertificationTitles,
    getEducationTitles,
    getExperienceTitles,
    getProjectTitles,
    getDescriptionList,
    getSkillsList,
    getInformations,
    getAllDrafts,
};
