import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { translationsPaths } from "@/utils/translationPath";

export function getYears(data) {
  // Récupérer les clés (années) de l'objet contenant les données
  const years = Object.keys(data);
  return years;
}
export function getDataByYear(data, year) {
  // Vérifier si l'année spécifiée est présente dans les données
  // Retourner les données de l'année spécifiée
  return year ? data[year] : null;
}
export function formatDataForChart(rawData) {
  if (!rawData || typeof rawData !== "object") {
    return [];
  }

  // Transformer les données brutes en monthData
  const monthData = Object.keys(rawData)
    .filter((key) => key !== "total")
    .map((month) => {
      return { month: month, data: rawData[month] };
    });

  return monthData;
}

export function formatDataForDoughnut(yearlyData) {
  if (!yearlyData || typeof yearlyData !== "object") {
    return [];
  }

  // Transformer les données brutes en monthData
  const chartData = Object.keys(yearlyData)
    .filter(
      (key) => key == "S24" || key == "24S" || key == "S42" || key == "classic"
    )
    .map((keyV) => {
      return { label: keyV, data: yearlyData[keyV].total };
    });

  return chartData;
}

// Fonction appelée lors du clic sur le bouton de conversion
