/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Label } from "@/components/atoms/texts/label";
import Step from "@/components/molecules/buttons/Step";
import CheckIcon from "@mui/icons-material/Check";
import Input from "@/components/molecules/inputs/Input";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Button } from "@/components/molecules/buttons/button";
import UniqueSelect from "@/components/molecules/inputs/UniqueSelect";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import { Form, FormControl } from "@/components/organisms/forms/form";
import { validateFormData } from "./validateForm";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import useConsultantData from "@/components/organisms/navigation/MenuConsultant/useConsultantData";
import { getUsersName } from "@/utils/transformUserData";
import { cn } from "@/lib/utils";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import { motion } from "framer-motion";
import CreateAndUpdateCV from "../CreateAndUpdateCV";
import { formatDate } from "@/utils/formatDate";
// import Select from "@mui/material/Select";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import MenuItem from "@mui/material/MenuItem";
import UniqueSelect2 from "@/components/molecules/inputs/UniqueSelect2";
import UniqueSelectMui from "@/components/molecules/inputs/UniqueSelectMui";

import { useTranslation } from "react-i18next";
import { InputLabel } from "@mui/material";
import { DbCassandraConsistencyLevelValues } from "@opentelemetry/semantic-conventions";


const genre = ['Masculin', 'Féminin', 'Autres']
export default function EditConsultant({
    openCvSection,
    setEditing,
    setIsOpen,
    user,
    setUser,
    showFourthStep = false,
    setShowFourthStep,
    ...props
}) {
    const [hideFirstStep, setHideFirstStep] = useState(false);
    const [hideSecondStep, setHideSecondStep] = useState(true);
    const [hideThirdStep, setHideThirdStep] = useState(true);
    let { users } = useConsultantData();
    users ? (users = getUsersName(users)) : "";
    const [selectedCooptant, setCooptant] = useState(null);
    const form = useForm();
    const [isFirStepValid, setIsFirsStepValid] = useState(false);
    const [isSecondStepValid, setIsSecondStepValid] = useState(false);
    const [isThirdStepValid, setIsThirdStepValid] = useState(false);
    const [skipIsClicked, setSkipIsClicked] = useState(false);
    const [openCVSectionForDraft, setOpenCvSectionForDraft] = useState(false);
    const [civilities, setCivilites] = useState([])
    const [isCvCompleted, setIsCvCompleted] = useState({
        information: false,
        skills: false,
        description: false,
        certifications: false,
        experience: false,
        educations: false,
        projects: false,
    });

    const [formData, setFormData] = useState({
        name: user ? user.name : "",
        firstName: user ? user.firstName : "",
        personalEmail: user ? user.personalEmail : "",
        dayOfBirth: user ? user.dayOfBirth : "",
        gender: "male",
    });

    const [erros, setErros] = useState({
        name: "",
        firstName: "",
        personalEmail: "",
        dayOfBirth: "",
        gender: ""
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const [t] = useTranslation("global")

    useEffect(() => {
        let data = [];
        const male = t("consultants.statement.prospectConsultantPanel.male");
        const female = t("consultants.statement.prospectConsultantPanel.female");
        const other = t("consultants.statement.prospectConsultantPanel.other");
        data.push(male);
        data.push(female);
        data.push(other);
        setCivilites(data);
    }, [t]);
    const handleChange = (e, name) => {

        const value = name != "gender" ? e.target.value : e;

        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);
        console.log(value, e, name != "gender" ? e.target : e);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setUser((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleGenderChange = (gender) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["gender"]: gender,
        }));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);
        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            setHideFirstStep(true);
            setIsFirsStepValid(true);
            setHideSecondStep(false);
            setEditing(true);

        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("form data", formData);
        }
    };
    const handleSecondStep = (valueSelected) => {
        setCooptant(valueSelected);
        setIsSecondStepValid(true);
    };
    const setProperty = (propertyName, value, setIsCvCompleted) => {
        setIsCvCompleted((prevState) => ({
            ...prevState,
            [propertyName]: value,
        }));
    };
    //   useEffect(() => {
    //    setUser && setUser(formData);
    // },[formData])
    // Fonction pour vérifier si toutes les propriétés booléennes sont vraies
    const isAllTrue = (obj) => {
        for (const key in obj) {
            if (!obj[key]) {
                return false;
            }
        }
        return true;
    };
    const handleSkip = () => {
        // isThirdStepValid(false);
        setHideThirdStep(true);
        setSkipIsClicked(true);
        setShowFourthStep && setShowFourthStep(true);
    };
    const handleSecondStepSkip = () => {
        setHideThirdStep(false);
        setHideSecondStep(true);
    };
    const handleFirstStepClick = () => {
        setHideFirstStep(!hideFirstStep);
        setHideSecondStep(true);
        setHideThirdStep(true);
    };
    const handleSecondStepClick = () => {
        setHideFirstStep(true);
        setHideSecondStep(!hideSecondStep);
        setHideThirdStep(true);
    };
    const handleThirdStepClick = () => {
        setHideThirdStep(false);
        setHideSecondStep(true);
        setHideFirstStep(true);
        setShowFourthStep && setShowFourthStep(false)
    };
    const handleOpenCvSection = () => {
        openCvSection ? openCvSection(true) : setOpenCvSectionForDraft(true);
    };
    const [age, setAge] = useState();
    return (
        <motion.div
            initial={{
                scale: 0.7,
            }}
            animate={{
                scale: 1,
            }}
            {...props}
        >
            {!openCVSectionForDraft && (
                <div className="flex flex-col gap-thirdDegree p-secondDegree">
                    {/*first step*/}
                    <motion.div
                        initial={{
                            scale: 0.9,
                        }}
                        animate={{
                            scale: 1,
                        }}
                        className="flex gap-secondDegree"
                    >
                        <div className=" flex flex-col items-center ">
                            <Step
                                item={isFirStepValid ? <CheckIcon /> : "1"}
                                bg={isFirStepValid ? "success" : "white"}
                                borderColor={isFirStepValid && "success"}
                                borderSize={isFirStepValid ? "solid" : "default"}
                                onClick={handleFirstStepClick}
                            />
                            {!hideFirstStep && <Separator orientation="vertical" />}
                        </div>
                        <div className="flex flex-col w-full ">
                            <Label
                                message="consultants.statement.prospectConsultantPanel.generalInformation"
                                size="body"
                                fontWeight="bold"
                            />
                            <Label
                                message={
                                    hideFirstStep
                                        ? "global.information.done"
                                        : "global.information.mandatory"
                                }
                                size="caption"
                                color="primary"
                            />
                            {!hideFirstStep && (
                                <div>
                                    <Form {...form}>
                                        <form onSubmit={handleSubmit}>
                                            <div className="py-secondDegree flex flex-col gap-secondDegree w-full">
                                                {/* <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.gender}
                          label="Gendere"
                          onChange={handleGenderChange}
                        >
                          {genre.map((data, index) => {
                            return <MenuItem key={index } value={data}>{data}</MenuItem>;
                          })}

                        
                        </Select> */}
                                                {/* <UniqueSelect
                                                    showLabel={false}
                                                    dataToSelect={civilities}
                                                    placeholderSelect={formData.gender ? formData.gender : "global.information.genre"}
                                                    filterDataFunction={handleGenderChange}
                                                    className={"z-10 bg-medium"}
                                                //isSearchable={true}

                                                /> */}
                                                {console.log(formData)}
                                                <Select value={formData.gender} onValueChange={(e) => handleChange(e, "gender")} >
                                                    <SelectTrigger className="rounded-[5px] bg-transparent border-greyStrong focus:ring-0 focus:ring-offset-0">
                                                        <SelectValue placeholder={formData.gender ? formData.gender : t("global.information.genre")} />
                                                    </SelectTrigger>
                                                    <SelectContent >
                                                        <SelectGroup>
                                                            <SelectItem value="male"><Label message="consultants.statement.prospectConsultantPanel.male"></Label></SelectItem>
                                                            <SelectItem value="female"><Label message="consultants.statement.prospectConsultantPanel.female"></Label></SelectItem>
                                                            <SelectItem value="other"><Label message="consultants.statement.prospectConsultantPanel.other"></Label></SelectItem>
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                                {/* <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        label="Age"
                                                        onChange={(e) => setAge(e.target.value)}
                                                    >
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl> */}
                                                <TextFieldInput
                                                    placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.name"
                                                    value={formData.name}
                                                    onChange={(e) => handleChange(e, "name")}
                                                    //name="name"
                                                    type="text"
                                                    error={listOfInvalidInput.includes("name")}
                                                />
                                                <TextFieldInput
                                                    placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.firstName"
                                                    value={formData.firstName}
                                                    onChange={(e) => handleChange(e, "firstName")}
                                                    //name="firstName"
                                                    error={listOfInvalidInput.includes("firstName")}
                                                    type="text"
                                                />
                                                <TextFieldInput
                                                    placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.dayOfBirth"
                                                    value={formData.dayOfBirth}
                                                    onChange={(e) => handleChange(e, "dayOfBirth")}
                                                    //name="firstName"
                                                    error={listOfInvalidInput.includes("dayOfBirth")}
                                                    type="date"
                                                />

                                                <TextFieldInput
                                                    placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.personalEmail"
                                                    // name="personalEmail"
                                                    value={formData.personalEmail}
                                                    onChange={(e) => handleChange(e, "personalEmail")}
                                                    type="email"
                                                    error={listOfInvalidInput.includes("personalEmail")}
                                                // border={
                                                //   listOfInvalidInput.includes("personalEmail") &&
                                                //   "border-error"
                                                // }
                                                />
                                                {/* <UniqueSelectMui
                          //showLabel={false}
                          dataToSelect={genre}
                          placeholderSelect={"global.information.genre"}
                          filterDataFunction={handleGenderChange}
                          //isSearchable={true}
                         
                        /> */}
                                            </div>
                                            <div className="py-secondDegree">
                                                <Button
                                                    showText={true}
                                                    text="global.actions.next"
                                                    type="submit"
                                                    sizePadding="" // Utilisez "small" pour un padding plus petit
                                                    style="fill"
                                                    className="font-bold"
                                                //onClick={() => { erros && setHideFirstStep(true) }} // Options: "fill", "outline", "ghost", "dressedGhost"
                                                />
                                            </div>
                                        </form>
                                    </Form>
                                </div>
                            )}
                        </div>
                    </motion.div>
                    {/*second step*/}
                    <motion.div
                        initial={{
                            scale: 0.8,
                        }}
                        animate={{
                            scale: 1,
                        }}
                        className="flex gap-secondDegree"
                    >
                        <div className=" flex flex-col items-center ">
                            <Step
                                item={isSecondStepValid ? <CheckIcon /> : "2"}
                                bg={isSecondStepValid ? "success" : "white"}
                                borderColor={isSecondStepValid && "success"}
                                borderSize={isSecondStepValid ? "solid" : "default"}
                                onClick={handleSecondStepClick}
                            />
                            {!hideSecondStep && <Separator orientation="vertical" />}
                        </div>
                        <div className="flex flex-col w-full ">
                            <Label
                                message="consultants.statement.prospectConsultantPanel.cooptation"
                                size="body"
                                fontWeight="bold"
                            />
                            <Label
                                message={
                                    isSecondStepValid
                                        ? "global.information.done"
                                        : "consultants.statement.prospectConsultantPanel.canbeDoneLater"
                                }
                                size="caption"
                                color="primary"
                            />
                            {!hideSecondStep && (
                                <div className="w-full">
                                    <div className=" w-full flex py-secondDegree">
                                        {/* <UniqueSelect
                                            showLabel={false}
                                            dataToSelect={users}
                                            filterDataFunction={handleSecondStep}
                                            isSearchable={true}
                                            {...props}
                                        /> */}
                                        {/* onValueChange={(value) => changeData(value)} */}
                                        {console.log('users',users)}
                                        <Select  onValueChange={handleSecondStep}>
                                            <SelectTrigger>
                                                <SelectValue placeholder={<Label message="global.actions.select" />} />
                                            </SelectTrigger>

                                            <SelectContent>
                                                <>
                                                    {users.map((user) => (
                                                        <SelectItem value={user}><Label>{user}</Label></SelectItem>
                                                    ))}
                                                </>
                                            </SelectContent>
                                        </Select>



                                    </div>
                                    <div className="py-secondDegree">
                                        <Button
                                            showText={true}
                                            text="global.actions.next"
                                            sizePadding="" // Utilisez "small" pour un padding plus petit
                                            style="fill"
                                            className="font-bold"
                                            onClick={handleSecondStepSkip} // Options: "fill", "outline", "ghost", "dressedGhost"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </motion.div>
                    {/*third step*/}
                    <motion.div
                        initial={{
                            scale: 0.6,
                        }}
                        animate={{
                            scale: 1,
                        }}
                        className="flex gap-secondDegree"
                    >
                        <div className=" flex flex-col items-center ">
                            <Step
                                item={isThirdStepValid ? <CheckIcon /> : "3"}
                                bg={isThirdStepValid ? "success" : "white"}
                                borderColor={isThirdStepValid && "success"}
                                borderSize={isThirdStepValid ? "solid" : "default"}
                                onClick={handleThirdStepClick}
                            />
                            {!hideThirdStep && <Separator orientation="vertical" />}
                        </div>
                        <div className="flex flex-col w-full ">
                            <Label
                                message="consultants.statement.prospectConsultantPanel.createAndUpdateCV"
                                size="body"
                                fontWeight="bold"
                            />
                            <Label
                                message={
                                    isThirdStepValid
                                        ? "global.information.done"
                                        : "consultants.statement.prospectConsultantPanel.canbeDoneLater"
                                }
                                size="caption"
                                color="primary"
                            />
                            {!hideThirdStep && !showFourthStep && (
                                <div className="w-full">
                                    <div className="py-secondDegree">
                                        <div className=" w-full flex py-secondDegree">
                                            <Label message="consultants.statement.prospectConsultantPanel.youCanAddAndetc" />
                                        </div>
                                        <div className="py-secondDegree gap-firstDegree flex">
                                            <Button
                                                showText={true}
                                                text="global.actions.skip"
                                                sizePadding="" // Utilisez "small" pour un padding plus petit
                                                style="outline"
                                                className="font-bold border-primary"
                                                onClick={() => handleSkip()} // Options: "fill", "outline", "ghost", "dressedGhost"
                                            />
                                            <Button
                                                showText={true}
                                                showLeftIcon={true}
                                                leftIcon={<ContactPageIcon fontSize="small" />}
                                                showRightIcon={true}
                                                rightIcon={<ArrowForwardIcon fontSize="small" />}
                                                text="consultants.statement.prospectConsultantPanel.createCv"
                                                sizePadding="" // Utilisez "small" pour un padding plus petit
                                                style="fill"
                                                className="font-bold"
                                                onClick={handleOpenCvSection} // Options: "fill", "outline", "ghost", "dressedGhost"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </motion.div>
                    {showFourthStep && (
                        <motion.div
                            initial={{
                                scale: 0.3,
                            }}
                            animate={{
                                scale: 1,
                            }}
                            className="flex gap-secondDegree"
                        >
                            <div className=" flex flex-col items-center ">
                                <Step
                                    item={<CheckIcon />}
                                    bg={"success"}
                                    borderColor={"success"}
                                    borderSize={"solid"}
                                //onClick={() => setHideThirdStep(false)}
                                />

                                <Separator orientation="vertical" />
                            </div>
                            <div className="flex flex-col w-full ">
                                <Label
                                    message={"global.information.congrates"}
                                    size="body"
                                    fontWeight="bold"
                                />

                                <Label
                                    message={"global.information.profilSaved"}
                                    size="caption"
                                    color="primary"
                                />

                                <div className="w-full">
                                    <div className="py-secondDegree">
                                        {/* <div className=" w-full flex py-secondDegree">
                  <Label message="consultants.statement.prospectConsultantPanel.youCanAddAndetc" />
                </div> */}
                                        <div className="py-secondDegree gap-firstDegree flex">
                                            <Button
                                                showText={true}
                                                text="global.actions.goToProfile"
                                                sizePadding="" // Utilisez "small" pour un padding plus petit
                                                style="outline"
                                                className="font-bold border-primary"
                                            // onClick={() => isThirdStepValid(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                            />
                                            <Button
                                                showText={true}
                                                text="global.actions.close"
                                                sizePadding="" // Utilisez "small" pour un padding plus petit
                                                style="fill"
                                                className="font-bold"
                                                onClick={() => setIsOpen(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </div>
            )}
            {openCVSectionForDraft && (
                <CreateAndUpdateCV
                    openCvSection={setOpenCvSectionForDraft}
                    shoulGetSectionsData={true}
                    setShowFourthStep={setShowFourthStep}
                />
            )}
        </motion.div>
    );
}
