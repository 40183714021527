import { getPdfConfirmation } from '@/services/global-services/cvData';
import { useState, useEffect } from 'react';

function sendPdfRequest() {
    const [confirmation, setConfirmation] = useState(false);
    const [error, setError] = useState(null);
const addTemplate = ()=>{
    return "template was added successfully"
}
    useEffect(() => {
        const fetchTemplateData = async () => {
            try {
                const data = await getPdfConfirmation(); // Utilisation de données simulées pour l'exemple
                setConfirmation(data);
                console.log("sendPdfRequest", data)
                // console.log('fetchTemplateData, data:', data)
            } catch (error) {
                setError(error)
                console.error("Error fetching notifications:", error);
            }
        };

        fetchTemplateData();

    }, []);

    return ({confirmation, error, addTemplate})
}

export default sendPdfRequest;