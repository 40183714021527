import React from "react";
const formatName = (fullName) => {
  // Séparer les parties du nom
  const nameParts = fullName.split(' ');
  const firstCaracter = nameParts[0][0];
  const secondCaracter = nameParts[1][0];
  const lastCaracter = nameParts[1][nameParts[1].length - 1].toUpperCase();
  const initials = firstCaracter + secondCaracter + lastCaracter;

  const formattedName = `${initials} - ${fullName}`;

  return formattedName;
  };
const formatUsers = (users) => {
  if (!users) {
    return [];
  }
  
  
  return users.map((user) => ({
    ...user,
    name: formatName(user.name),
  }));
};

export default formatUsers;
