
import { Button } from '../molecules/buttons/button'
import { useTranslation } from 'react-i18next';
import { getEmojiFlag } from 'countries-list'
import { languages } from 'countries-list'
import { useEffect } from 'react';
import { useState } from 'react';

export default function Translate() {
    const [translate, i18n] = useTranslation("global");
    const [currentLanguage, setCurrentLanguage] = useState();

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    useEffect(() => {
        setCurrentLanguage(i18n.language.toUpperCase())
    }, [i18n.language]);

    return (

        <div className='flex rounded-sm overflow-hidden '>


            <Button
                showText={true}
                style={currentLanguage == "EN" ? `dressedGhost` : `ghost`}
                className="rounded-none"
                onClick={() => handleChangeLanguage("en")}>
                {getEmojiFlag("GB")} {languages.en.native}
            </Button>

            <Button
                showText={true}
                style={currentLanguage == "FR" ? `dressedGhost` : `ghost`}
                className="rounded-none"
                onClick={() => handleChangeLanguage("fr")}>
                {getEmojiFlag("FR")} {languages.fr.native}
            </Button>

        </div>
    )
}

