
// ConnectionForm.js
import React from 'react';
import TextField from '@mui/material/TextField';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MessageIcon from '@mui/icons-material/Message';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Label } from '@/components/atoms/texts/label';
import { Card } from '@/components/templates/Card';
import { Button } from '@/components/molecules/buttons/button';
import GoogleAuthenticator from '@/components/atoms/GoogleAuthenticator';
import useConnectionForm from './useConnectionForm'; // Adjust this import as needed
import useLogin from '@/pages/login/useLogin';
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
} from "@/components/molecules/inputs/input-otp"


const ConnectionForm = () => {
    const {
        step,
        formData,
        formErrors,
        code,
        handleChange,
        handleChangeCode,
        handleSubmit,
        nextStep,
        prevStep,
    } = useConnectionForm();

    // Utilizing another custom hook for login functionality.
    const handleLogin = useLogin();

    // Mapping titles and descriptions based on the current step of the form.
    const titlesAndDescriptions = {
        1: { title: "connection.cardTitle", description: "connection.cardDescription" },
        2: { title: "connection.cardTitleAuth", description: "connection.cardDescriptionAuth" },
        3: { title: "connection.cardTitleCheck", description: "connection.cardDescriptionCheck" },
        1000: { title: "connection.cardTitle", description: "connection.test" },
    };

    // Retrieving the current title and description based on the form's step.
    const { title, description } = titlesAndDescriptions[step] || {};

    // Function to render form content based on the current step.
    const renderContent = () => {
        switch (step) {
            case 1: // Email and password input fields for the login step.
                return (
                    <div className='flex flex-col gap-4 min-h-44 py-2'>

                        <TextField
                            required
                            id="outlined-required"
                            label={<Label message={"form.inputMail"} />}
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                        />
                        <TextField
                            label={<Label message={"form.inputPassword"} />}
                            type="password"
                            autoComplete="current-password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            error={!!formErrors.password}
                            helperText={formErrors.password}
                        />
                    </div>
                );
            case 2: // Authentication method selection step.
                return (
                    <div className='flex flex-col space-y-4 min-h-44'>
                        <div ><Label fontWeight="semibold" message="form.inputMail" /> : {formData.email}</div>
                        <Button text="connection.method1" style="dressedGhost" onClick={nextStep} showLeftIcon={true} leftIcon={<MessageIcon />} className="justify-start" />
                        <Button text="connection.method2" style="dressedGhost" onClick={nextStep} showLeftIcon={true} leftIcon={<GoogleAuthenticator />} className="justify-start" />
                    </div>
                );
            case 3:  // Code verification step.
                return (
                    <div className='flex flex-col space-y-4 min-h-44'>
                        <div ><Label fontWeight="semibold" message="form.inputMail" /> : {formData.email}</div>
                        <div className='flex gap-1 md:gap-4 w-full justify-center '>

                            <InputOTP maxLength={6} value={code} onChange={(value) => { handleChangeCode(value) }} className="w-full">
                                <InputOTPGroup>
                                    <InputOTPSlot index={0} />
                                    <InputOTPSlot index={1} />
                                    <InputOTPSlot index={2} />
                                </InputOTPGroup>
                                <InputOTPSeparator />
                                <InputOTPGroup>
                                    <InputOTPSlot index={3} />
                                    <InputOTPSlot index={4} />
                                    <InputOTPSlot index={5} />
                                </InputOTPGroup>
                            </InputOTP>

                        </div>
                    </div>
                );
            case 1000:  // Code verification step.
                return (
                    <div className="flex flex-col gap-secondDegree">
                        <Label fontWeight="bold" message="connection.choiceRole"></Label>

                        <div className="flex gap-secondDegree">
                            <Button className="my-2" onClick={() => handleLogin('admin')} text="testLogin.admin" />
                            <Button className="my-2" onClick={() => handleLogin('consultant')} text="testLogin.consultant" />
                        </div>

                    </div>
                );
            default: // Additional options provided for testing login functionalities.
                return <div>
                    <Button className="my-2" onClick={() => handleLogin('admin')} text="testLogin.admin" />
                    <Button className="my-2" onClick={() => handleLogin('consultant')} text="testLogin.consultant" />
                </div>;
        }
    };


    // Conditional rendering of the footer based on the current step.
    const renderFooter = () => {
        switch (step) {
            case 1: // Next button for the first step.
                return {
                    footerFilledButtonText: "form.btnNext",
                    iconRightBtnFilled: <ArrowForwardIcon />,
                };
            case 2: // Back button with no stretch for the second step.
                return {
                    btnFooterStretch: false,
                    classNameFooter: "justify-start",
                    footerOutlinedButtonText: "form.btnBack",
                    iconLeftBtnOutlined: <ArrowBackIcon />,
                    onClickBtnOutlined: () => prevStep(),
                    footerFilledButtonText: undefined,
                    iconRightBtnFilled: undefined
                }
            case 3: // Validate button with stretch for the third step.
                return {
                    btnFooterStretch: true,
                    footerOutlinedButtonText: "form.btnBack",
                    iconLeftBtnOutlined: <ArrowBackIcon />,
                    onClickBtnOutlined: () => prevStep(),
                    footerFilledButtonText: "form.btnValidate",
                    iconRightBtnFilled: <DoubleArrowIcon />
                };
            case 1000:
                return {

                }
            default:
                return {};
        }
    };

    // Rendering the Card component with dynamic title, description, content, and footer based on the current form step.
    return (
        <Card
            title={title} // Titre dynamique basé sur l'étape
            description={description} // Description dynamique basée sur l'étape
            content={renderContent()}
            {...renderFooter()}
            className="  md:w-[500px] flex flex-col justify-center  absolute w-[95vw]  bg-white  top-1/2 md:right-0 transform translate-x-[2.5vw]	 md:translate-x-1/2 -translate-y-1/2 rounded-md"
            onClickBtnFilled={() => handleSubmit()}

        />
    );
};

export default ConnectionForm;
