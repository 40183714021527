/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Label } from '@/components/atoms/texts/label';
import SvgEffectOnNavItem from '@/components/atoms/SvgEffectOnNavItem';
import { useNavigate } from 'react-router-dom';
import { Button } from '../buttons/button'
import { useNavbar } from '@/components/organisms/navigation/header/useNavBar';



export default function NavItem({ name, selected, path, handleNavItemClick }) {

  const navigate = useNavigate();

  return (

    <div className={` ${selected ? 'text-secondary  bg-hover font-[700]  xl:font-bodySmall xl:border-b-4 xl:border-secondary' : ''}
     h-[57px] w-full hover:bg-hover   xl:h-full xl:p-paddingTopAndBottom   xl:w-auto  cursor-pointer
      rounded-md xl:rounded-tl-[0px] xl:rounded-tr-[0px] xl:rounded-bl-[0px] xl:rounded-br-[0px] flex items-center justify-center
      `}
      onClick={() => handleNavItemClick(path)}>
      {

        <div className=" sm:p-[16px] xl:py-[0px] w-full xl:w-auto px-paddingLeftAndRight  flex-col ">
          <Label size='bodySmall' fontWeight='regular' fontFamily='' className="hidden xl:block py-[5px] " message={name} />
          <Label size='bodySmall' fontWeight='regular' fontFamily='' className="xl:hidden block   text-center  box-border    border-primary rounded-md  " message={name} />
          {selected && <SvgEffectOnNavItem />}
        </div>


      }

    </div>
  );
}
