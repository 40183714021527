import { Label } from '@/components/atoms/texts/label'
import React from 'react'

export default function DataDeletion() {
    return (
        <div className="flex flex-col gap-secondDegree p-secondDegree">
            <div className="flex flex-col gap-3">
                <Label message="consultants.informations.dataDeletionInfo.admin.q1" fontWeight="bold"></Label>
                <Label message="consultants.informations.dataDeletionInfo.admin.a1_1"></Label>
                <Label message="consultants.informations.dataDeletionInfo.admin.a1_2"></Label>
            </div>
            <div className="flex flex-col gap-3">
                <Label message="consultants.informations.dataDeletionInfo.admin.q2" fontWeight="bold"></Label>
                <Label message="consultants.informations.dataDeletionInfo.admin.a2"></Label>
            </div>
            <div className="flex flex-col gap-3">
                <Label message="consultants.informations.dataDeletionInfo.admin.q3" fontWeight="bold"></Label>
                <Label message="consultants.informations.dataDeletionInfo.admin.a3"></Label>
               

            </div>
        </div>
    )
}