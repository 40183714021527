 const getProfitSharingData = async (id) => {
    try {
        //   const response = await axios.get('url');
        const data = [
            {
                year: "2024", toOverflow: "false", toPayslip: "false", actual: "true", amount: "6 000", cardData: {
                    nbConsultant: "70",
                    evolNbConsultant: "34",
                    profitRate: "5",
                    evolProfitRate: "25",
                    nbCoopted: "4",
                    evolNbCoopted: "20",
                    benefit: "1000000",
                    evolBenefit: "65"
                }
            },
            { year: "2023", toOverflow: "false", toPayslip: "false", actual: "false", amount: "6 000" },
            { year: "2022", toOverflow: "true", toPayslip: "false", actual: "false", amount: "6 000" }

        ]
        return data;
    } catch (error) {
        console.error('Error fetching profit sharing data:', error.message);
        throw error;
    }
};
export {getProfitSharingData};