import React, { useEffect, useState } from "react";
import { PolarArea } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

// Register the necessary components
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const PolarAreaChart = ({ label = ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5"], datas = [10, 20, 30, 40, 50] }) => {
  
  const [t] = useTranslation("global")
  const [evaluate, setEval]= useState("")
  
  useEffect(() => {
    const evaluation = t("global.information.selfAssessment")
    setEval(evaluation)
  }, [t])
  // Example data
  const data = {
    labels: label,
    datasets: [
      {
        label: "My Polar Area Chart",
        data: datas, // Data for each label
        backgroundColor: [
          "rgba(94, 139, 224, 0.5)",
          "rgba(96, 88, 222, 0.5)",
          "rgba(119, 210, 227, 0.5)",
          "rgba(126, 226, 178, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(210,228, 107, 0.5)",
        ],
        borderColor: [
          "rgba(94, 139, 224, 1)",
          "rgba(96, 88, 222, 1)",
          "rgba(119, 210, 227, 1)",
          "rgba(126, 226, 178, 1)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(210,228, 107, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Options for the Polar Area chart
  const options = {
    responsive: true,
    scales: {
      r: {
        min: 0, // Définir la valeur minimale de l'échelle
        max: 5,
        pointLabels: {
          display: true,
          centerPointLabels: true,
          position: "inside",
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${evaluate}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

    return (
     
        <PolarArea data={data} options={options}  />
    
    );
};

export default PolarAreaChart;
