/* eslint-disable react/prop-types */
import React from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";

export default function TextFieldInput({
  placeHolder = "",
  color,
  className,

  ...props
}) {
  const [t] = useTranslation("global");
  const label = t(placeHolder);
 
  return (
    <Box
      className={cn(className)}
      sx={{
       
        maxWidth: "100%",
      }}
    >
      <TextField
        label={label}
        id="outlined-size-small"
        //defaultValue={label}
        size="small"
       
        fullWidth
        className={cn(className)}
        {...props}
      />
    </Box>
  );
}
