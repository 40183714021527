import { Separator } from "@/components/atoms/divider/separatorShadcn";
import Filter from "@/components/organisms/Filter";
import BlueHeader from "@/components/organisms/blueHeader";
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import LineChart from "@/components/organisms/charts/LineChart";
import getStaticsData, { getKpiData } from "@/services/admin-services/statistics";
import { formatDataForDoughnut, getDataByYear, getYears } from "./statistic";
import { computeRatio, computeEvolution } from "@/utils/finance";
import TableStatistics from "./TableStatistics";
// import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DoughnutChart from "@/components/organisms/charts/DoughnutChart";
import { Label } from "@/components/atoms/texts/label";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { translationsPaths } from "@/utils/translationPath";
import { monthPath } from "@/utils/translationPath";
import LineArt from "@/assets/images/lineArt.png";
import DataCard from "../../../components/organisms/charts/DataCard";
import { useTranslation } from "react-i18next";
import CircularProgressbar from "@/components/organisms/charts/CircularProgressBar";


const wrapChartInCard = (component, message) => (
    <div className=" flex flex-col gap-fifthDegree items-center bg-white  px-secondDegree  py-firstDegree shadow-lg rounded-sm border border-medium  ">
        {component}

        <div className="w-full truncate justify-center flex">
            {" "}
            <Label message={message} fontWeight="bold" className=" w-auto" />
        </div>
    </div>
);



export default function Statistics() {
    const [statisticsData, setStatisticsData] = useState(getStaticsData());

    const [years, setYears] = useState(getYears(statisticsData));

    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [dataForChart, setDataForChart] = useState([]);
    const [yearlyData, setYearlyData] = useState(
        getDataByYear(statisticsData, selectedYear)
    );
    const kpiData = getKpiData()
    const [yearlyKpi, setYearlyKpi] = useState(kpiData[2024]) //to be changed take last element


    const [label, setLabel] = useState("");
    const [evolutionConsultant, setEvolutionConsultant] = useState(0);
    const [evConsultantByGrossSalary, setEvConsGrossSal] = useState(0);
    const [t] = useTranslation("global");



    // const computeEvolution2 = (currentYearTotal, previousYearTotal) => {
    //   const evolutionPercentage =
    //     ((currentYearTotal - previousYearTotal) / previousYearTotal) * 100;
    //   return parseFloat(evolutionPercentage.toFixed(2));
    // };



    const updateDatas = (year) => {
        const selectedYearStr = year.toString(); // Convertir l'année sélectionnée en chaîne de caractères

        setSelectedYear(selectedYearStr);
        setYearlyData(getDataByYear(statisticsData, selectedYearStr));
        setYearlyKpi(kpiData[year])
        console.log("format data", statisticsData)

        if (years.includes((parseInt(selectedYearStr, 10) - 1).toString())) {
            const previousYearStr = (parseInt(selectedYearStr, 10) - 1).toString(); // Convertir l'année précédente en chaîne de caractères

            const currentYearData = getDataByYear(
                statisticsData,
                selectedYearStr
            ).consultants;
            const previousYearData = getDataByYear(
                statisticsData,
                previousYearStr
            ).consultants;

            const currentYearTotal = currentYearData.total;
            const previousYearTotal = previousYearData.total;

            const evolutionPercentage =
                ((currentYearTotal - previousYearTotal) / previousYearTotal) * 100;
            const floatEvolutionPercentage = parseFloat(
                evolutionPercentage.toFixed(2)
            );
            setEvolutionConsultant(floatEvolutionPercentage);
        } else {
            setEvolutionConsultant(0); // Si les données pour l'année précédente ne sont pas disponibles, l'évolution est de 0
        }
    };
    const convertToCSV = (statData) => {
        let csv = "Label"
        Object.values(monthPath).map((month) => {
            const monthTranslated = t(month)
            csv = csv + "," + monthTranslated

        });
        csv = csv + ',Total' + '\n'
        Object.entries(statData).forEach(([label, rowData]) => {
            const translatedLabel = t(translationsPaths[label]);
            csv += `${translatedLabel},${Object.values(rowData).join(",")}\n`;
        });
        return csv;
    };

    // Fonction pour télécharger le fichier CSV
    const downloadCSV = (statData) => {
        const csv = convertToCSV(statData);
        const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "table_data.csv");
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    };

    const getKPI = (kpiNameToFind) => {
        if (yearlyKpi)
            return yearlyKpi.find((item) => item.kpiName === kpiNameToFind);
        else return 0

    };
    return (
        <div className="bg-lighter flex grow overflow-hidden relative">

            <div className="w-full h-[93vh] overflow-y-scroll overflow-x-hidden z-10  ">
                <img src={LineArt} className="bgLineArt " />
                <BlueHeader title="statistics.globalAnalytics" />
                <div className="px-[8vw] flex flex-col gap-thirdDegree mt-thirdDegree items-center ">
                    <Filter items={years} informSelectedItem={updateDatas} />
                    <div className=" grid grid-cols-3 lg:grid-cols-6 gap-secondDegree w-full justify-center">
                        {wrapChartInCard(
                            <DataCard
                                // chipText="statistics.annual"
                                helpText="statistics.consultantsHelp"
                                roundedBorders={false}
                                data={getKPI("totalConsultants").data}
                                evolution={getKPI("totalConsultants").evolution}
                            />,
                            "statistics.consultants"
                        )}
                        {/* {wrapChartInCard(
            <CircularProgressbar value={56} text={`${56}%`} />,
            "statistics.consultants"
          )} */}
                        {wrapChartInCard(
                            <DataCard
                                helpText="statistics.hiringHelp"
                                chipText="statistics.monthly"
                                roundedBorders={false}
                                data={getKPI("monthlyHiring").data}
                                evolution={getKPI("monthlyHiring").evolution}
                            />,
                            "statistics.hiring"
                        )}
                        {wrapChartInCard(
                            <DataCard
                                // chipText="statistics.annual"
                                helpText="statistics.daysWorkedHelp"
                                chipText="statistics.monthly"
                                roundedBorders={false}
                                data={`${getKPI("daysWorkedRatio").data}`}
                                evolution={getKPI("daysWorkedRatio").evolution}
                            />,
                            "consultants.statement.overview.daysWorked"
                        )}
                        {wrapChartInCard(
                            <CircularProgressbar
                                roundedBorders={false}
                                // chipText={"statistics.annual"}
                                chipText="statistics.monthly"
                                helpText="statistics.sickDaysHelp"
                                evolution={10}
                                data={getKPI("sickDaysRatio").data}
                            />,
                            translationsPaths.sickDays
                        )}
                        {wrapChartInCard(
                            <DataCard
                                //chipText="statistics.annual"
                                chipText="statistics.monthly"
                                helpText="statistics.consultants/grossSalary"
                                data={getKPI("medianSalary").data}
                                evolution={getKPI("medianSalary").evolution}
                                roundedBorders={false}
                            />,
                            "statistics.medianSalary"
                        )}
                        {wrapChartInCard(
                            <DoughnutChart
                                datas={formatDataForDoughnut(yearlyData)}
                                helpText="statistics.profilesHelp"
                                roundedBorders={false}
                            // helpText="statisti"
                            />,
                            "statistics.profiles"
                        )}
                    </div>
                    <Separator />
                    <div className="w-full grid grid-rows justify-center gap-secondDegree mb-secondDegree">
                        <div className=" flex flex-row justify-between  w-full items-center ">
                            <div></div>
                            <span className="text-h3 font-bold">{selectedYear}</span>{" "}
                            <Button
                                showLeftIcon={true}
                                leftIcon={<DownloadRoundedIcon fontSize="small" />}
                                showText={false}
                                sizePadding="" // Utilisez "small" pour un padding plus petit
                                style="ghost"
                                className="font-bold"
                                onClick={() => downloadCSV(yearlyData)}
                            // onClick={handleContinue} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                        <TableStatistics
                            statData={yearlyData}
                            setDataForChart={setDataForChart}
                            setLabelForChart={setLabel}
                        />

                        <LineChart monthData={dataForChart} label={label} tension={0.3} />
                    </div>
                </div>
            </div>

        </div>
    );
}
