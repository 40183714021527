"use client";

import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { useInitials } from "../../hooks/global-hooks/useInitials";

import { cn } from "@/lib/utils";
//Exemple use case
// <Avatar src={user.avatarUrl} fallback={user.name} size="small"/>
const AvatarFallback = React.forwardRef(
  ({ className, fallback, size }, ref) => {
    const sizes = {
      small:
        "flex h-8 w-8 items-center justify-center rounded-full bg-lighter text-bodySmall",
      medium:
        "flex h-16 w-16 items-center justify-center rounded-full bg-lighter text-body",
      large:
        "flex h-44 w-44 items-center justify-center rounded-full bg-lighter text-body",
    };

    const sizeClass = sizes[size] || sizes.medium;

    // Utiliser la fonction useInitials pour extraire les initiales de fallback
    const initials = useInitials(fallback);

    return (
      <div ref={ref} className={cn(sizeClass, className)}>
        {initials}
      </div>
    );
  }
);

AvatarFallback.displayName = "AvatarFallback";

const Avatar = React.forwardRef(
  ({ className, src, fallback, size = "medium" }, ref) => {
    const sizes = {
      small: "h-8 w-8",
      medium: "h-16 w-16",
      large: "h-44 w-44",
    };

    const sizeClass = sizes[size] || sizes.medium;
// console.log("src = ", src ? true : false, fallback, size)
    return (
      <AvatarPrimitive.Root
        ref={ref}
        className={cn(
          "relative flex overflow-hidden rounded-full",
          sizeClass,
          className
        )}
      >
        {src ? (
          <AvatarPrimitive.Image
            src={src}
            className={cn("object-cover", sizeClass)}
          />
        ) : (
          <AvatarFallback fallback={fallback} size={size} />
        )}
      </AvatarPrimitive.Root>
    );
  }
);

Avatar.displayName = "Avatar";

export { Avatar };
