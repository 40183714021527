import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';
import { useEffect } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
export default function FinanceChart({ financeData, showBarChart=true, showLineChart=true }) {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Calculer les totaux pour income et expense
    const totalIncome = months.map(month => Object.values(financeData.income).reduce((acc, category) => acc + category[month.toLowerCase()], 0));
    const totalExpense = months.map(month => Object.values(financeData.expense).reduce((acc, category) => acc + category[month.toLowerCase()], 0) * -1);
    const difference = totalIncome.map((income, index) => income + totalExpense[index]);

    const [dataset, setDataSet] = useState([])
    useEffect(() => {
        // console.log("useeffect , showBarChart, showLineChart", showBarChart, showLineChart)
        if (showBarChart && showLineChart) {
    
            setDataSet([
                {
                    type: 'bar',
                    label: 'Total Income',
                    data: totalIncome,
                    borderColor: 'hsl(var(--chart3))',
                    backgroundColor: '#7EE2B270'
                },
                {
                    type: 'bar',
                    label: 'Total Expense',
                    data: totalExpense,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: '#D76C5970'
                },
                {
                    type: 'line',
                    label: 'Income - Expense',
                    data: difference,
                    fill: true,
                    borderColor: '#6058DE70',
                    backgroundColor: '#6058DE20',
                    tension: 0.4
                }
            ])
        } else if (showBarChart && !showLineChart) {
            setDataSet([
                {
                    type: 'bar',
                    label: 'Total Income',
                    data: totalIncome,
                    borderColor: 'hsl(var(--chart3))',
                    backgroundColor: '#7EE2B270'
                },
                {
                    type: 'bar',
                    label: 'Total Expense',
                    data: totalExpense,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: '#D76C5970'
                }
            ])
        } else if (!showBarChart && showLineChart) {
            setDataSet([
                {
                    type: 'line',
                    label: 'Income - Expense',
                    data: difference,
                    fill: true,
                    borderColor: '#6058DE70',
                    backgroundColor: '#6058DE20',
                    tension: 0.4
                }
            ])
        }else{
            setDataSet([
                {
                    type: 'line',
                    label: 'Income - Expense',
                    data: {},
                    fill: true,
                    borderColor: '#6058DE70',
                    backgroundColor: '#6058DE20',
                    tension: 0.4
                }
            ])
        }


    }, [showBarChart, showLineChart])
    const data = {
        labels: months,
        datasets: dataset
    };

    return ((showBarChart || showLineChart)&&<Bar data={data} className='bg-white' />);
}
