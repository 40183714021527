// hooks/useMobileDetect.js

import { useEffect, useState } from 'react';

export default function useMobileDetect() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Fonction pour vérifier la largeur de l'écran
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth < 768); // Considère mobile si la largeur est inférieure à 768px
        };

        // Vérifiez immédiatement et écoutez les changements de taille
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        // Nettoyage de l'écouteur d'événements
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    return isMobile;
};
