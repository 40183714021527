/* eslint-disable react/prop-types */
import { useState,useEffect } from "react";
import { translationsPaths } from "@/utils/translationPath";
import QueryStatsTwoToneIcon from "@mui/icons-material/QueryStatsTwoTone";
import { formatDataForChart } from "./statistic";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,

  TableCell,

  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/table";
import { Label } from "@/components/atoms/texts/label";
import { monthPath } from "@/utils/translationPath";

export default function TableStatistics({
  statData,
  setDataForChart,
  setLabelForChart,
}) {
  //console.log("row data", Object.keys(statData));
 

  const [selectedIcon, setSelectedIcon] = useState(0);
   const [t] = useTranslation("global");
  const changeDataForChart = (rowData, label, index) => {
    setDataForChart && setDataForChart(rowData);
    setLabelForChart && setLabelForChart(label);
    setSelectedIcon(index);
  };
  useEffect(()=>{
    const rowData=Object.values(statData)[0]
    const data=formatDataForChart(rowData)
      const label=translationsPaths[Object.keys(statData)[0]]
                   
     changeDataForChart(data, label, 0)
  }, [])
  
  
  return (
    <>
      <Table className="w-full ">
        <TableHeader>
          <TableRow className="text-bodySmall">
            <TableHead className="bg-transparent min-w-[70px] h-auto  "></TableHead>
            {Object.values(monthPath).map((month, index) => (
              <TableHead key={index} className="min-w-[70px] h-auto">
                <Label message={month} />
              </TableHead>
            ))}

            <TableHead className="bg-horizontalPrimaryLinear  text-white min-w-[70px] h-auto ">
              <Label message="global.total" />
            </TableHead>
            <TableHead className="bg-transparent  "></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {statData &&
            Object.values(statData).map((rowData, index) => (
              <TableRow key={index}>
                <TableCell className=" text-end bg-transparent ">
                  <Label
                    message={translationsPaths[Object.keys(statData)[index]]}
                    size="small"
                  />
                </TableCell>
                <TableCell className="tdHover">{rowData.january}</TableCell>
                <TableCell className="tdHover">{rowData.february}</TableCell>
                <TableCell className="tdHover">{rowData.march}</TableCell>
                <TableCell className="tdHover">{rowData.april}</TableCell>
                <TableCell className="tdHover">{rowData.may}</TableCell>
                <TableCell className="tdHover">{rowData.june}</TableCell>
                <TableCell className="tdHover">{rowData.july}</TableCell>
                <TableCell className="tdHover">{rowData.august}</TableCell>
                <TableCell className="tdHover">{rowData.september}</TableCell>
                <TableCell className="tdHover">{rowData.october}</TableCell>
                <TableCell className="tdHover">{rowData.november}</TableCell>
                <TableCell className="tdHover">{rowData.december}</TableCell>

                <TableCell className="bg-bgLight tdHoverDark">
                  {rowData.total}
                </TableCell>
                <TableCell className="bg-transparent ">
                  <QueryStatsTwoToneIcon
                    fontSize="small"
                    className={`${
                      index == selectedIcon ? "text-pink" : "text-black"
                    }`}
                    onClick={() =>
                      changeDataForChart(
                        formatDataForChart(rowData),
                        translationsPaths[Object.keys(statData)[index]],
                        index
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}
