import { useState } from "react";
import { getVatData } from "@/services/admin-services/vatData";
import HandleVats from "./HandleVats";
import AddIcon from "@mui/icons-material/Add";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
} from "../../../../templates/sheet";
import Action from "@/components/molecules/buttons/Action";
import { Euro as EuroIcon } from "@mui/icons-material";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
export default function VatSettingsPanel({ ...props }) {
    const [vatData, setVatData] = useState(getVatData());
    const [newVatValue, setNewVatValue] = useState("");
    const [showInput, setShowInput] = useState(false); // Nouveau pourcentage de TVA saisi
    const [isOpen, setIsOpen] = useState()
    const handleCheckboxChange = (index) => {
        const updatedVatData = [...vatData];
        updatedVatData[index].checked = !updatedVatData[index].checked;
        setVatData(updatedVatData);
        // console.log("new check detected", vatData);
    };

    const addNewVat = () => {
        if (newVatValue.length != 0) {
            const newVat = { vat: `${newVatValue}%`, checked: true };
            setVatData([...vatData, newVat]);
            setNewVatValue("");
            setShowInput(false); // Effacer la valeur de l'input après l'ajout
            console.log("new vat added", vatData);
        } else {
            console.log("emtpy value");
        }
    };

    return (
        <div>
            <Sheet open={isOpen} onOpenChange={setIsOpen}>
                <SheetTrigger asChild >
                    <button className="w-full">
                        {" "}
                        <Action
                            icon={<EuroIcon />}
                            text="consultants.dashboard.actions.vat"
                            initialVariant={isOpen ? "active" : "default"}
                            {...props}
                        />
                    </button>
                </SheetTrigger>
                <SheetContent>
                    <SheetHeader
                        title={"consultants.dashboard.actions.vat"}
                        subheader="consultants.statement.vatSettings.autoSaved"
                    />
                    <SheetSlot>
                        <div className=" flex flex-col p-secondDegree gap-thirdDegree">
                            {/*Insert here your component selectOrAdd*/}
                            <Label
                                message="consultants.statement.vatSettings.selectOrAdd"
                                size="body"
                            />

                            <HandleVats
                                vatData={vatData}
                                onCheckboxChange={handleCheckboxChange}
                            />
                            <div>
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<AddIcon fontSize="small" />}
                                    showText={true}
                                    text="consultants.statement.vatSettings.addNewVAT"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="ghost"
                                    onClick={() => setShowInput(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                />
                                {showInput && (
                                    <div className="relative items-center mt-2">
                                        <input
                                            type="number"
                                            value={newVatValue}
                                            onChange={(e) => setNewVatValue(e.target.value)}
                                            //   placeholder="Nouveau pourcentage de TVA"
                                            className="h-8 rounded-sm border-[1px] outline-none border-black w-full p-betweenText"
                                        />

                                        {/* Bouton pour ajouter la nouvelle TVA */}
                                        <div className="absolute top-1 right-1 z-50">
                                            {" "}
                                            <button
                                                onClick={addNewVat}
                                                className="rounded-sm px-betweenText hover:bg-medium"
                                            >
                                                <Label
                                                    message="global.actions.add"
                                                    className="text-primary text-[14px] font-bold"
                                                />
                                            </button>{" "}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </SheetSlot>
                </SheetContent>
            </Sheet>
        </div>
    );
}
