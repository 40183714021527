import React from 'react';
import theS from '../../assets/theS.svg'; // Assurez-vous que le chemin du fichier SVG est correct

export default function SvgEffectOnNavItem() {
    return (
        <>
            <div className='relative w-full items-center top-4 hidden xl:block' id="svgEffect">
            <img src={theS} className="absolute -top-3.5 drop-shadow mx-[45%]" alt="The S SVG" />
            </div>
    </>
  );
}
