/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";

import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@/components/molecules/inputs/Input";
import SaveIcon from "@mui/icons-material/Save";
import useId from "@mui/material/utils/useId";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextArea from "@/components/molecules/inputs/TextArea";
import { validateFormData } from "./validateForm";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
export default function AddEducation({
    education,
    showForm,
    setShowForm,
    setEducations,
    editingEducation = false,
    setEditingEducation,
}) {
    const form = useForm();
    const id = useId()
    const [formData, setFormData] = useState({
        title: education ? education.title : "",
        school: education ? education.school : "",
        country: education ? education.country : "",
        city: education ? education.city : "",
        startDate: education ? education.startDate : "",
        endDate: education ? education.endDate : "",
        description: education ? education.description : "",
        id: education ? education.id : id
    });
    const [erros, setErros] = useState({
        title: "",
        school: "",
        country: "",
        city: "",
        startDate: "",
        endDate: "",
        description: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        const { value } = e.target;
        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);
        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateFormData(formData, setErros);

        setlistOfInvalidInput(isValid);
        if (isValid.length == 0) {
            console.log("Form is valid", formData);
            if (editingEducation) {
                // Si nous sommes en mode édition, mettez à jour la description existante

                setEducations((prevData) =>
                    prevData.map((item) => (item.id === education.id ? formData : item))
                );
            } else {
                // Sinon, ajoutez simplement la nouvelle description à la liste
                // console.log("id ", id);
                setEducations((prevData) => [...prevData, formData]);
            }
            setShowForm(false);
        } else {
            console.log("invalid form");
            console.log("value invalid", isValid);
            console.log("form data", formData);
            console.log("value invalid", erros);
        }
    };
    const handleCancel = () => {
        setEditingEducation(false)
        setShowForm(false)
    }
    const handleDelete = () => {
        setShowForm(false);
        if (education) {
            // Supprimer la certification de la liste
            setEducations((prevData) =>
                prevData.filter((item) => item.id !== education.id)
            );
        }
    };
    return (
        <div>
            {showForm && (
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}

                        className={` flex flex-col gap-secondDegree shadow-md bg-lighter  border-sm border-medium  p-secondDegree`}
                    >
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.educationTitle"
                            }
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange(e, "title")}
                            error={listOfInvalidInput.includes("title")}
                        // border={
                        //   listOfInvalidInput.includes("educationTitle")
                        //     ? "border-error"
                        //     : ""
                        // }
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.school"
                            }
                            type="text"
                            value={formData.school}
                            onChange={(e) => handleChange(e, "school")}
                            error={listOfInvalidInput.includes("school")}
                        // border={
                        //   listOfInvalidInput.includes("school") ? "border-error" : ""
                        // }
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.city"
                            }
                            type="text"
                            value={formData.city}
                            onChange={(e) => handleChange(e, "city")}
                            error={listOfInvalidInput.includes("city")}
                        // border={listOfInvalidInput.includes("city") ? "border-error" : ""}
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.country"
                            }
                            type="text"
                            value={formData.country}
                            onChange={(e) => handleChange(e, "country")}
                            error={listOfInvalidInput.includes("country")}
                        // border={
                        //   listOfInvalidInput.includes("country") ? "border-error" : ""
                        // }
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.startDate"
                            }
                            type={"date"}
                            value={formData.startDate}
                            onChange={(e) => handleChange(e, "startDate")}
                            error={listOfInvalidInput.includes("startDate")}
                        // border={
                        //   listOfInvalidInput.includes("startDate") ? "border-error" : ""
                        // }
                        />
                        <TextFieldInput
                            placeHolder={
                                "consultants.statement.prospectConsultantPanel.formPlaceHolder.endDate"
                            }
                            type={"date"}
                            value={formData.endDate}
                            onChange={(e) => handleChange(e, "endDate")}
                            error={listOfInvalidInput.includes("endDate")}
                        // border={
                        //   listOfInvalidInput.includes("endDate") ? "border-error" : ""
                        // }
                        />
                        <TextArea
                            //value={value}
                            //onChange={onChange}
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.description"
                            rows={4} // Nombre de lignes par défaut
                            cols={40}
                            value={formData.description}
                            onChange={(e) => handleChange(e, "description")}
                            border={
                                listOfInvalidInput.includes("description") ? "border-error" : ""
                            } // Nombre de colonnes par défaut

                        // Ajoutez d'autres attributs ou styles au besoin
                        />

                        <div className="flex flex-row gap-[12px] items-center w-full">
                            {editingEducation && (
                                <Button
                                    onClick={handleDelete}
                                    showLeftIcon={true}
                                    leftIcon={
                                        <DeleteIcon className="text-primary cursor-pointer" />
                                    }
                                    style="ghost"
                                />
                            )}
                            <Button
                                showLeftIcon={true}
                                leftIcon={<ClearIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.cancel"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                className="font-bold border-primary w-full"
                                onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <Button
                                showLeftIcon={true}
                                leftIcon={<SaveIcon fontSize="small" />}
                                showText={true}
                                text="global.actions.save"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                className="font-bold w-full" // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    </form>
                </Form>
            )}
        </div>
    );
}
