/* eslint-disable react/prop-types */
import { lorem } from "faker/lib/locales/cz";
import  { useState,useEffect } from "react";
import AddEducation from "./AddEducation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import PrintEducation from "./PrintEducation";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import { getEducations } from "@/services/consultant-services/prospectConsultant";
import useCVTab from "@/hooks/global-hooks/useCVTab";

export default function Education({
  hideAllOverview,
  showComponent,
  educations,
  setEducations,
}) {
  const { CVData, error } = useCVTab();

  const [showForm, setShowForm] = useState(false);
  // const [educations, setEducations] = useState([]);
  const [editingEducation, setEditingEducation] = useState(false);

  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
  // useEffect(() => {
  //   if (CVData && CVData.education) {
  //     setEducations(CVData.education);
  //   }
  // }, [CVData]);
  // if (error) {
  //   return <Label message="error.errorFetchData" />;
  //   // "errorFetchData": "An error occured when fetching data",
  //   //   "fetchingData":
  // }
  // if (!CVData) {
  //   return <Label message="error.fetchingData" />;
  // }

  return (
    <motion.div variants={item}>
      <div className="flex items-center gap-fourthDegree w-full">
        <ArrowBackIcon className="cursor-pointer" onClick={hider} />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.education"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
      <div className=" flex flex-col gap-secondDegree">
        {showForm && (
          <AddEducation
            showForm={showForm}
            setShowForm={setShowForm}
            setEducations={setEducations}
            editingEducation={false}
            setEditingEducation={setEditingEducation}
          />
        )}
        {educations &&
          educations.map((education, index) => {
            return (
              <PrintEducation
                key={index}
                education={education}
                setEditingEducation={setEditingEducation}
                editingEducation={editingEducation}
                setEducations={setEducations}
              />
            );
          })}
      </div>
    </motion.div>
  );
}
