import { Label } from "../atoms/texts/label"
import { Button } from "../molecules/buttons/button"
import { Popover, PopoverContent, PopoverTrigger } from "../molecules/popover"
import Translate from "./Translate"
import { Navigate, useNavigate } from "react-router-dom";



export default function PopUpProfile({ user, component , isForMobile=false}) {

    const navigate = useNavigate();

    return (

        <Popover>
            <PopoverTrigger className={isForMobile && `w-full`}>{component}</PopoverTrigger>
            <PopoverContent className={`mr-3 items-end flex flex-col gap-firstDegree p-secondDegree shadow-md rounded-md overflow-hidden bg-white w-min ${isForMobile && `shadow-3xl absolute left-[50vw] -translate-x-1/2 top-[50vh] transform -translate-y-1/2`}`}>
                <span>
                    <Label message="global.popUpProfile.hi" />
                    <Label fontWeight="bold" >{user.surname} {user.name}</Label>
                </span>
                <span>
                    <Label message="global.popUpProfile.role" />
                    <Label fontWeight="bold" color="primary">{user.role}</Label>
                </span>
                <Translate />
                <Button style="outline" text="global.actions.logout" className="w-full" onClick={()=>navigate("/logout")} />
            </PopoverContent>
        </Popover>
    )
};