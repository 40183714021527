function formatDate(inputDate) {
  // Séparer la date en parties jour, mois et année
  const parts = inputDate.split("/");
  // Créer une nouvelle date au format "YYYY-MM-DD"
  const date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
//   // Obtenir l'année, le mois et le jour
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  // Concaténer les parties pour obtenir la date au format "YYYY-MM-DD"
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

export {formatDate}