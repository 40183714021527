import { Label } from "./components/atoms/texts/label";
import { useNavigate } from 'react-router-dom';
import { Button } from "./components/molecules/buttons/button";

export default function ErrorPage() {

    const navigate = useNavigate();

    return (
        <div id="error-page" className="flex flex-col items-center m-32 h-[90vh] " >
            <Label size="h1" fontWeight="bold" message="error.title" />

            <iframe className="my-0" src="https://lottie.host/embed/461672b0-e7f3-4786-be9c-c018120c7e0a/HASudKp5is.json" width={'50%'} height={'80%'}></iframe>
            <Label size="body" fontWeight="bold" message="error.notFound" />

            <Button text="navbarGlobal.home" onClick={() => navigate("/")} />

        </div>
    );
}
