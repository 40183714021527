import { Button } from '@/components/molecules/buttons/button';
import React from 'react';
import { getEmojiFlag } from 'countries-list'
import { useNavigate } from 'react-router-dom';


export default function SaveTemplate({ data, ...props }) {
    const navigate = useNavigate();
    return (

        <Button
            style="ghost"
            showLeftIcon
            leftIcon={getEmojiFlag(data.countryISE2)}
            onClick={() => {navigate(`generate/${data.id}`)}}
            {...props}>
            {data.title}
        </Button>

    )
}