import React, { useState, useEffect, useRef } from 'react';

import TestTranslate from "./TestTranslate";
import { useAuth } from "@/context/AuthContext";

import { Label } from "../atoms/texts/label";
import useNotifications from "@/hooks/global-hooks/useNotification";
import HandleProfileTitle from "../macro-organisms/panels/panels-admin/prospectConsultant/information/AddProfileTitle";
import PrintLanguageLine from "../macro-organisms/panels/panels-admin/prospectConsultant/information/PrintLanguageLine";
import HandleLanguage from "../macro-organisms/panels/panels-admin/prospectConsultant/information/HandleLanguage";
import FormInformationProfile from "../macro-organisms/panels/panels-admin/prospectConsultant/information/Information";
import EditConsultant from "../macro-organisms/panels/panels-admin/prospectConsultant/createConsultant/EditConsultant";
import Skills from "../macro-organisms/panels/panels-admin/prospectConsultant/skills/SkillsInPanel";
import Project from "../macro-organisms/panels/panels-admin/prospectConsultant/project/Project";

import Education from "../macro-organisms/panels/panels-admin/prospectConsultant/education/Education";
import Experience from "../macro-organisms/panels/panels-admin/prospectConsultant/experience/Experience";
import Description from "../macro-organisms/panels/panels-admin/prospectConsultant/description/Description";
import Certification from "../macro-organisms/panels/panels-admin/prospectConsultant/certification/Certification";
import Information from "../macro-organisms/panels/panels-admin/prospectConsultant/information/Information";
import UniqueSelect2 from "../molecules/inputs/UniqueSelect2";
import { languages } from "countries-list";
import LineChart from "../organisms/charts/LineChart";
import TableStatistics from "@/pages/admin/statistics/TableStatistics";
import StatCard from "@/components/organisms/charts/DataCard";
import { Button } from "@/components/molecules/buttons/button";

import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FormNewExpense from "../macro-organisms/tabs/consultant-vision-user/expense-account/formNewExpense";

const languageNames = Object.values(languages).map((lang) => lang.name);
export default function ComponentTest() {
     const { user } = useAuth();
   

    return (
        <>
            <h1 className="text-primary text-h1 font-regular">Component Test</h1>
            <h2>Hello, {user.name} </h2>
            {/* <TestTranslate /> */}



        </>
    );
}
