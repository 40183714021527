// useConsultantData.js
import { useState, useEffect } from 'react';
import { getProfitSharingData } from '@/services/consultant-services/profitSharingData';

const useProfitSharingData = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const tempData = await getProfitSharingData();
                setData(tempData);
            } catch (error) {
                setError(error);
                console.error("Erreur récupération des données", error);
            }
        };

        fetchData();
    }, []);

    return { data, error };
};

export default useProfitSharingData;

