// useConsultantData.js
import { useState, useEffect } from 'react';


import { effect } from '@preact/signals-react';
import { getInformationData } from '@/services/consultant-services/InformationData';


const useInformationData = (id) => {
    const [informationData, setInformationData] = useState(null);
    const [error, setError] = useState(null);
    effect(() => {
        // console.log('la valeur du signal change');
    })
    useEffect(() => {
        const fetchData = async () => {
            try {
                const tempData = await getInformationData(id);
                setInformationData(tempData);
            } catch (error) {
                setError(error);
                //errorSignal.value=error
                console.error("Erreur récupération des données", error);
            }
        };

        fetchData();
    }, []);

    return { informationData, error };
};

export default useInformationData;

