/* eslint-disable react/prop-types */
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import Add from "@mui/icons-material/Add";
import AddDescription from "./AddDescription";
import { useState } from "react";
export default function Printdescription({
  description,
  setEditingDescription,
editingDescription,
  setDescriptions,
}) {
  const [showForm, setShowForm] = useState(false);
    const handleEdit = () => {
      setShowForm(true);
        setEditingDescription(true);
    
        
    
  };
  return (
    <div className="">
      {!showForm && (
        <div className="flex  p-2 flex-row gap-[12px] w-full  ">
          <div className=" ">
            <span className=" items-center text-body">
              {description.description}
            </span>{" "}
          </div>
          <div>
            <EditIcon
              className="text-primary cursor-pointer"
              onClick={handleEdit}
            />
          </div>
        </div>
      )}
          <AddDescription
              description={description}
              showForm={showForm}
              setShowForm={setShowForm}
              editingDescription={editingDescription}
              setEditingDescription={setEditingDescription}
              setDescriptions={setDescriptions}
          />
    </div>
  );
}
