/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useState } from "react";
import { Label } from "@/components/atoms/texts/label";
import { cn } from "@/lib/utils";
import PersonIcon from "@mui/icons-material/Person";

const customers = "consultants.statement.billsDetails.customer";
export default function MultiSelect({
  dataToSelect,
  filterDataFunction,
  label = customers,
  icon = <PersonIcon />,
  className,
  labelClassName,
  marginBottom,
}) {
  const animatedComponents = makeAnimated();
  let options;
  dataToSelect &&
    (options = dataToSelect.map((value) => ({ value, label: value })));
  const [selectedValue, setSelectedValue] = useState(null);
  const handleSelectChange = (selectedOption) => {
    // `selectedOption` will contain the selected value and label
    if (selectedOption) {
      const selectedValues = selectedOption.map((option) => option.value);

      setSelectedValue(selectedValues);
      // console.log("Selected value:", selectedOption);

      //filterDataFunction
      filterDataFunction && filterDataFunction(selectedValues);
    }
  };

  return (
    <div className={cn(className, " w-full ")}>
      <div className={cn("flex flex-row  gap-firstDegree", marginBottom)}>
        <div>{icon}</div>
        <Label message={label} className={labelClassName} />
      </div>
      <div className="w-auto ">
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          className=""
          //defaultValue={[colourOptions[4], colourOptions[5]]}
          isMulti
          options={options}
          onChange={handleSelectChange}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "black" : "black",
              borderRadius: "8px",
            }),
          }}
        />
      </div>
    </div>
  );
}
