export function getRandomNumberBetween(min, max) {
  // Utilisez Math.random() pour obtenir un nombre décimal entre 0 et 1
  const randomDecimal = Math.random();

  // Utilisez la formule pour étendre la plage entre min et max
  const randomNumber = min + randomDecimal * (max - min);

  // Arrondir le résultat à un nombre entier si nécessaire
  return Math.round(randomNumber);
}
