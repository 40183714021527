/* eslint-disable react/prop-types */
import { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import AddProject from "./AddProject";
import { Label } from "@/components/atoms/texts/label";
const projectDefault = {
  projectTitle: "Ingénieur Data",
  enterprise: "Synapse",
  city: "country",
  debutDate: "01/02/2022",
  endDate: "01/02/2022",
  description: "lorem",
  responsabilities: "lorem",
  technologies: "lorem",
};

export default function PrintProject({
  project,
  setEditingProject,
  editingProject,
  setProjects,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setEditingProject(true);
    setShowForm(true);
  };
  return (
    <div className="flex flex-col gap-firstDegree">
      {!showForm && (
        <div className="flex gap-[12px] ">
          {!showDetails && (
            <ExpandMoreIcon onClick={() => setShowDetails(true)} />
          )}
          {showDetails && (
            <ExpandLessIcon onClick={() => setShowDetails(false)} />
          )}
          <span className="font-bold flex items-center">
            {project.title}
          </span>
          <EditIcon
            className="text-primary cursor-pointer"
            onClick={handleEdit}
          />
        </div>
      )}
      {!showForm && showDetails && (
        <div className="flex flex-col gap-firstDegree">
          <div className="flex flex-row gap-firstDegree py-firstDegree text-bodySmall items-center">
            <span className="font-bold">{project.enterprise}</span>
            <span>{project.city}</span>
            <span>{project.country}</span>
            <span>{project.startDate}</span>
            <Label
              message="global.information.fromAtoB"
              size="small"
              className=""
            />
            <span>{project.endDate}</span>
          </div>
          <div className="text-bodySmall">
            <span>{project.description}</span>
          </div>
        </div>
      )}
      {
        <AddProject
          project={project}
          showForm={showForm}
          setShowForm={setShowForm}
          editingProject={editingProject}
          setEditingProject={setEditingProject}
          setProjects={setProjects}
        />
      }
    </div>
  );
}
