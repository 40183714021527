/* eslint-disable react/prop-types */
import { lorem } from "faker/lib/locales/cz";
import  { useState,useEffect } from "react";
import AddProject from "./AddProject";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import PrintProject from "./PrintProject";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import FetchingData from "@/components/molecules/PrintFetchingData";
import ErrorMessage from "@/components/molecules/PrintErrorMessage";
import useCVTab from "@/hooks/global-hooks/useCVTab";

export default function Project({ hideAllOverview, showComponent, projects, setProjects }) {
  const { CVData, error } = useCVTab();
  const [showForm, setShowForm] = useState(false);

  const [editingProject, setEditingProject] = useState(false);
  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
  // useEffect(() => {
  //   if (CVData && CVData.project) {
  //     setProjects(CVData.project);
  //   }
  // }, [CVData]);
  // if (error) {
  //   return <ErrorMessage />;
  // }
  // if (!CVData) {
  //   return <FetchingData />;
  // }

  return (
    <motion.div variants={item}>
      <div className="flex items-center gap-fourthDegree w-full">
        <ArrowBackIcon className="cursor-pointer" onClick={hider} />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.projects"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
      <div className=" flex flex-col gap-secondDegree">
        {showForm && (
          <AddProject
            showForm={showForm}
            setShowForm={setShowForm}
            setProjects={setProjects}
            editingProject={false}
            setEditingProject={setEditingProject}
          />
        )}
        <div className=" flex flex-col gap-firstDegree">
          {projects.map((project, index) => {
            return (
              <PrintProject
                key={index}
                project={project}
                setEditingProject={setEditingProject}
                editingProject={editingProject}
                setProjects={setProjects}
              />
            );
          })}
        </div>
      </div>
    </motion.div>
  );
}