import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Label } from '@/components/atoms/texts/label';
import { Button } from '@/components/molecules/buttons/button';
import { CustomKanban } from './CustomKanban';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import useCVTab from '../../../../../hooks/global-hooks/useCVTab';
import useManageTemplate from './useManageTemplate';
import { useEffect } from 'react';
import { useState } from 'react';
import RenderCvContent from './RenderCvContent';
import WaitPdf from './WaitPdf';


const steps = ['consultants.cv.description', 'consultants.cv.jobTitle', 'consultants.cv.skills', 'consultants.cv.project', 'consultants.cv.experience', 'consultants.cv.certification', 'consultants.cv.education', 'consultants.cv.check'];

export default function GenerateCV() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const { template } = useManageTemplate();
    const [template_copy, setTemplate_copy] = useState();

    useEffect(() => { setTemplate_copy(template) }, [template])
    // useEffect(() => { console.log("template_copy was changed, is now : ", template_copy, "and template is : ", template) }, [template_copy])
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        const isDone = completedSteps() === totalSteps();
        isDone && console.log("data to send is : ", template_copy)

        return isDone;
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        // onNextClick();
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        setTemplate_copy(template);
    };


    const { CVData, error } = useCVTab();

    function filterCVDataByTemplate(CVData, template_copy) {
        // Créer une nouvelle structure de données pour le CV filtré
        const filteredCVData = {};
        // Parcourir chaque catégorie de CVData
        (CVData && template_copy) && Object.keys(CVData).forEach(category => {
            // Filtrer les éléments de la catégorie actuelle en fonction de leur présence dans blockSelected
            if (category != 'user') {
                 const filteredItems = CVData[category].filter((item) =>
                   template_copy.blockSelected.some(
                     (block) => block.id === item.id
                   )
                 );

                 // Assigner les éléments filtrés à la catégorie correspondante dans filteredCVData
                 filteredCVData[category] = filteredItems;
                
            }
           
        });
        return filteredCVData;
    }

    return (
        <div className='p-secondDegree'>
            <Stepper nonLinear activeStep={activeStep} className='w-full overflow-y-auto no-scrollbar'>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton onClick={handleStep(index)}>
                            <Label className={`font-sans ${activeStep == index && 'font-bold text-primary '}`} message={label}></Label>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>

            {allStepsCompleted() ? (
                <React.Fragment >
                    <WaitPdf handleReset={handleReset}/>

                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className='flex  flex-col p-secondDegree gap-thirdDegree mt-5'>
{                    console.log("filterCVDataByTemplate(CVData, template_copy) = ", filterCVDataByTemplate(CVData, template_copy))
}
                        {activeStep !== steps.length - 1 ?
                            CVData && <CustomKanban
                                step={activeStep}
                                CVData={CVData}
                                template={template_copy}
                                setTemplate={setTemplate_copy}
                                className="h-[55vh] gap-3 custom" />
                            :
                            <RenderCvContent CVData={filterCVDataByTemplate(CVData, template_copy)} />
                        }
                    </div>

                    <div className='flex justify-between'>
                        <Button style="ghost" disabled={activeStep === 0} onClick={handleBack} text="global.actions.back" />
                        <div>
                            {/* <Button style="ghost" onClick={handleNext} text="global.actions.next" /> */}

                            {activeStep !== steps.length &&
                                // (completed[activeStep] ? (
                                //     <Label> Step {activeStep + 1} already completed</Label>
                                // ) : (
                                <Button onClick={handleComplete} style="outline" className="font-semibold" text="global.actions.next">
                                    {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}

                                </Button>
                                //))
                            }
                        </div>
                    </div>

                </React.Fragment>
            )}

        </div>
    );
}
