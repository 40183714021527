import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose,
} from "@/components/ui/dialog";
import { Label } from "@/components/atoms/texts/label";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Button } from "@/components/molecules/buttons/button";
import Input from "@/components/molecules/inputs/Input";
import { useState } from "react";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { ToastAction } from "@/components/organisms/feedback/toast";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

export default function EditInformationData({ inputValue, setInputValue, title, actualLabel, selectLabel, data }) {
    const [open, setOpen] = useState();
    const { toast } = useToast();

    const handleClose = (open_, dataSaved = false, inputValue) => {
        setOpen(open_);
        // console.log("open = ", open, !dataSaved);
        if (open_ == false) {
            if (!dataSaved) {
                inputValue && toast({
                    variant: "warning",
                    title: "error.editWithoutSave",
                    description: inputValue,
                    duration: 2500,
                    action: (
                        <ToastAction
                            altText={inputValue}
                            onClick={async () => {
                                await navigator.clipboard.writeText(inputValue);
                            }}
                            className="border-0 py-thirdDegree"
                        >
                            <ContentCopyRoundedIcon />
                        </ToastAction>
                    ),
                });
            } else {
                inputValue && toast({
                    variant: "success",
                    title: "success.changesSaved",
                    description: inputValue,
                    duration: 2500,
                    action: (
                        <ToastAction
                            altText={inputValue}
                            onClick={async () => {
                                await navigator.clipboard.writeText(inputValue);
                            }}
                            className="border-0 py-thirdDegree"
                        >
                            <ContentCopyRoundedIcon />

                        </ToastAction>
                    ),
                });
            }
        }

    }

    return (
        <Dialog open={open} onOpenChange={(e) => { handleClose(e, false, inputValue); setInputValue(""); }}>
            <DialogTrigger className="invisible group-hover:visible ">
                <EditRoundedIcon color='secondary' />
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle><Label message={title} size="h4" /></DialogTitle>
                    <DialogDescription className="flex flex-col gap-4 py-4">
                        <span>
                            <Label message={actualLabel} />
                            <Label fontWeight="bold">{data}</Label>
                        </span>
                        <Label message={selectLabel} />
                        <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter className="sm:justify-start">
                    <Button type="button" style="ghost" text='global.actions.cancel' onClick={() => { setInputValue(""); handleClose(false, false, inputValue) }} />
                    <Button style="fill" text='global.actions.save' className="w-full" onClick={() => { console.log("send : ", inputValue); setInputValue(""); handleClose(false, true, inputValue) }} />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}