import React, { useState, useEffect } from 'react';

const notificationsExemple = [
    { action: "add", object: "timesheet", period: "07/23", person: "John Doe", type: "information", link: "", date: "2023-07-23" },
    { action: "edit", object: "timesheet", period: "10/2023", person: "Olivia Jones", type: "success", date: "2023-10-01" },
    { action: "edit", object: "payslip", period: "10/2023", person: "Bob Brown", type: "error", date: "2023-10-15" },
    { action: "add", object: "payslip", period: "08/2023", person: "Bob Brown", type: "success", date: "2023-08-05" },
    { action: "remove", object: "payslip", period: "12/2023", person: "William Martinez", type: "success", date: "2023-12-10" },
    { action: "add", object: "expense account", period: "10/2023", person: "John Doe", type: "information", date: "2023-10-03" },
    { action: "edit", object: "timesheet", period: "09/2023", person: "Emma Jones", type: "information", date: "2023-09-20" },
    { action: "generate", object: "leasing", period: "08/2023", person: "Jane Smith", type: "error", date: "2023-08-30" },
    { action: "edit", object: "payslip", period: "12/2023", person: "Bob Brown", type: "information", date: "2023-12-01" },
    { action: "generate", object: "timesheet", period: "12/2023", person: "William Martinez", type: "success", date: "2023-12-05" },
    { action: "remove", object: "expense account", period: "11/2023", person: "Alice Johnson", type: "error", date: "2023-11-18" },
    { action: "add", object: "timesheet", period: "10/2023", person: "Bob Brown", type: "error", date: "2023-10-20" },
    { action: "add", object: "expense account", period: "12/2023", person: "Alice Johnson", type: "error", date: "2023-12-02" },
    { action: "edit", object: "skills", period: "12/2023", person: "William Martinez", type: "success", date: "2023-12-20" },
    { action: "add", object: "payslip", period: "11/2023", person: "Bob Brown", type: "error", date: "2023-11-12" },
    { action: "remove", object: "skills", period: "10/2023", person: "Olivia Jones", type: "success", date: "2023-10-25" },
    { action: "edit", object: "expense account", period: "06/2023", person: "Bob Brown", type: "error", date: "2023-06-15" },
    { action: "edit", object: "payslip", period: "12/2023", person: "Alice Johnson", type: "information", date: "2023-12-05" },
    { action: "import", object: "leasing", period: "11/2023", person: "Michael Garcia", type: "success", date: "2023-11-10" },
    { action: "generate", object: "payslip", period: "09/2023", person: "Jane Smith", type: "error", date: "2023-09-08" },
    { action: "remove", object: "expense account", period: "08/2023", person: "Jane Smith", type: "information", date: "2023-08-20" }
];


const getNotifications = async () => {
    try {
        // Récupérer la liste d'utilisateurs depuis l'API
        //   const response = await axios.get('https://jsonplaceholder.typicode.com/users');
        // Enrichir la liste d'utilisateurs avec les nouvelles propriétés

        const data =  notificationsExemple ;
        console.log('Notifications Data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching information data:', error.message);
        throw error;
    }
};

export { getNotifications };