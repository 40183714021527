// useConsultantData.js
import { useState, useEffect } from 'react';


import { effect } from '@preact/signals-react';
import { getUserById } from '@/services/consultant-services/StatementData';

const useStatementData = (id) => {
    const [userConnected, setUserConnected] = useState(null);
    const [error, setError] = useState(null);
    effect(() => {
        // console.log('la valeur du signal change');
    })
    let idRequest = id ? id : 4
    useEffect(() => {
        const fetchData = async () => {
            try {
                const tempData = await getUserById(idRequest);
                setUserConnected(tempData);
            } catch (error) {
                setError(error);
                //errorSignal.value=error
                console.error("Erreur récupération des données", error);
            }
        };

        fetchData();
    }, []);

    return { userConnected, error };
};

export default useStatementData;

