/* eslint-disable react/prop-types */
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { languages } from "countries-list";
import UniqueSelect from "@/components/molecules/inputs/UniqueSelect";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { getLanguageCode } from "@/utils/languagesUtils";
import { getEmojiFlag } from "countries-list";

import PrintLanguageLine from "./PrintLanguageLine";
import AddLanguage from "./AddLanguage";





export default function HandleLanguage({ languages, setLanguages }) {
  const [editLanguage, setEditLanguage] = useState(false);
   const [showForm, setShowForm] = useState(false);
  const changeEditLanguage = () => {
    languages ? setEditLanguage(true) : setEditLanguage(false);
    (languages==undefined && showForm) && setShowForm(true)
  };
  // const [languages, setLanguages] = useState(getLanguagesSpoken());
 
  return (
    <div>
      {!editLanguage ? (
        <div className="flex gap-firstDegree items-center">
          {languages &&
            languages.map((lang, index) => (
              <div
                key={index}
                className="flex p-betweenText gap-betweenText bg-white h-7 rounded-[8px] items-center"
              >
                <span className="text-[23px]">
                  {getEmojiFlag(
                    lang.language == "English"
                      ? "GB"
                      : getLanguageCode(lang.language)
                  )}
                </span>
                <span>{lang.level}</span>
              </div>
            ))}
        <Button
            showLeftIcon={true}
            leftIcon={<EditIcon className="text-primary" fontSize="small" />}
            showText={false}
            style="ghost"
            onClick={() =>setEditLanguage(true)}
          />
        </div>
      ) : (
        <div className=" flex flex-col gap-secondDegree">
          <div className="flex flex-col gap-secondDegree">
            {languages &&
              languages.map((lang, index) => (
                <PrintLanguageLine
                  key={index}
                  language={lang}
                  setLanguages={setLanguages}
                />
              ))}
          </div>

          <AddLanguage
            showForm={showForm}
            setShowForm={setShowForm}
            setEditingLanguage={setEditLanguage}
              setLanguages={setLanguages}
              languages={languages}
          />
          <div className="flex items-center gap-firstDegree">
            <Button
              showLeftIcon={true}
              leftIcon={<AddIcon fontSize="small" />}
              sizePadding="default" // Utilisez "small" pour un padding plus petit
              style="dressedGhost"
              showText={true}
              text="global.actions.add"
              className="w-full"
              onClick={() => setShowForm(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
            />
            <Button
              showLeftIcon={true}
              leftIcon={<ClearIcon fontSize="small" />}
              showText={false}
              style="ghost"
              onClick={() => setEditLanguage(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
