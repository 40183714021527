import { Label } from "./components/atoms/texts/label";
import { useNavigate } from 'react-router-dom';
import { Button } from "./components/molecules/buttons/button";

export default function ErrorUnderConstruction() {

    const navigate = useNavigate();

    return (
        <div id="error-page" className="flex flex-col items-center p-thirdDegree justify-center gap-thirdDegree h-[90vh]  " >
            <Label size="h1" fontWeight="bold" message="error.title" />
            <iframe className="my-0" src="https://lottie.host/embed/338c5b12-ccc3-44b1-84a1-c9e2e6a42fad/uQaBNq6HrQ.json" width={'50%'} ></iframe>
            <Label size="body" fontWeight="bold" message="error.underConstruction" />

            <Button text="navbarGlobal.home" onClick={() => navigate("/")} />

        </div>
    );
}
