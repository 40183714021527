/* eslint-disable react/prop-types */
import React from 'react';
import { Label } from '@/components/atoms/texts/label';
// import { translaterRowNameTab } from '@/utils/monthStringTranslater';
 import { useConsultantContext } from '@/context/ConsultantContext';
const hoverLabelEmployeeCost = [
  { employerCost: "consultants.statement.overflow.tableHead.employerCost" },
  { customerPaiment: "consultants.statement.overflow.tableHead.customerPaiment" },
  { cooptation: "consultants.statement.overflow.tableHead.cooptation" },
  { overflow: "consultants.statement.overflow.tableHead.overflow" },
];

let hoverCooptationLabel = [
    { employerCoopted: 'JDE-John DOE'+'_'+ '5%' },
    { employerCoopted: 'Alexa ALEXIS'+'_'+ '15%' },
    { employerCoopted: 'Kurtis Wernets'+'_'+ '5%' },
    { employerCoopted: 'JDE-John DOE'+'_'+ '5%' },
    { employerCoopted: 'JDE-John DOE'+'_'+ '5%' },
    { employerCoopted: 'JDE-John DOE'+'_'+ '5%'  },
    { employerCoopted: 'JDE-John DOE'+'_'+ '5%' },
    
  
]
let hoverCooptationData = [
    {  montant: '600 €' },
    {  montant: '1500 €' },
    {  montant: '600 €' },
    {  montant: '600 €' },
    {  montant: '600 €' },
    {  montant: '600 €' },
    {  montant: '600 €' },
    
  
]

let hoverDataEmployeeCost = [
  { employerCost: 123 },
  { customerPaiment: 345 },
  { cooptation: 1245 },
  { overflow: 980 },
];
const employerCostOverview = 'consultants.statement.overflow.employerCostOverview';
const cooptationIncomeDetails = 'consultants.statement.overflow.cooptationIncomeDetails';

const computeHoverTitle = (actualCellId) => {
    const titleHover = actualCellId.split('_');
    let result = "";
    if (titleHover[1] == 1)
        result = employerCostOverview
    else if (titleHover[1] == 3) 
        result = cooptationIncomeDetails
    
    return result
};

const computeHoverData = (actualCellId ='') => {
    
    const currentCol = computeHoverTitle(actualCellId);
    let label = [];
    let data = [];
    if (currentCol.includes('employerCostOverview')) {
        label = hoverLabelEmployeeCost;
        data = hoverDataEmployeeCost;
    }
   else if (currentCol.includes('cooptationIncomeDetails')) {
        label = hoverCooptationLabel;
        data = hoverCooptationData;
        // data: item.montant
    }
        return {label, data}
}
//shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.30)]
export default function DisplayHoverContent({Id}){
     //const { currentTableCellHovered } = useConsultantContext();
    const titleHover = computeHoverTitle(Id);
    const { label, data } = computeHoverData(Id)
    // const actualMonth=actualCell.split('_')[0]
    return (
    
 <div className={`${ titleHover.includes('cooptationIncomeDetails') ? 'w-[160px]' : 'w-[150px]'} bg-[#FFF] px-[16px] py-[8px] rounded-sm hoverCardContent`} >

        <div className="w-full py-2 border-b-2 border-black font-bold ">
            
            <Label size='h7' fontWeight="" fontFamily="" message={titleHover} color="black" className="text-black text-h7" />
            </div>
            
        <div className='w-full mt-[10px] flex flex-row justify-center'>

            <div>
                
      
                {label.map((item, index) => (
                    <div key={index} className="flex justify-end w-full">
                        <Label size="h8" fontWeight="" fontFamily="" message={!titleHover.includes('cooptationIncomeDetails') && (Object.values(item)[0])} color="" >{ titleHover.includes('cooptationIncomeDetails') &&( Object.values(item)[0])}</Label>
                    </div>
                ))}
            </div>
            <div>
                {data.map((item, index) => (
                    <div key={index} className="flex">
                
                        <Label className='ml-[10px] text-h8' size='h8'>{Object.values(item)[0]}</Label>
                    </div>
                ))}
            </div>
            </div>
             {/* <Label size='h9' className='ml-5 w-full text-[8px] h-2' message={ translaterRowNameTab[actualMonth]}/>  */}
        </div>
    )
};
