import FormFieldContent from "@/components/organisms/forms/FormFieldContent";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import { cn } from "@/lib/utils";
import { useState, useRef, useEffect } from "react";
import { validateFormData } from "./validateForm";
export default function EditValuation({
    isButtonSaveClicked = false,
    setSaveClicked,
    setHistoricalData,
    setIsErrorInform,
    setIsEditing
}) {
    const form = useForm();
    const buttonRef = useRef(null);
    //const [isErrorInForm, setIsErrorInform]=useState(false)
    const [formData, setFormData] = useState({
        date: "",
        ebit: 0,
        companyValuation: 0,
        actionNumber: 0,
        sharePrice: 0,
    });
    const [erros, setErros] = useState({
        date: "",
        ebit: "",
        companyValuation: "",
        actionNumber: "",
        sharePrice: "",
    });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);

    useEffect(() => {
        const handleButtonClick = () => {
            // simulate the click on button submit so that the form can be sumitted
            buttonRef.current.click();

            // console.log("button Editbills is cliked");
        };
        isButtonSaveClicked == true && handleButtonClick(); // Appelé lorsque le composant est monté
    }, [isButtonSaveClicked]);

    const handleSubmit = (e) => {
        e.preventDefault();

        //Send the data to the Backend
        const isValid = validateFormData(formData, setErros);
        setlistOfInvalidInput(isValid);

        if (isValid.length == 0) {
            //
            console.log("Form is valid", formData);
            //setHistoricalData(prevData => [...prevData, formData])
            setHistoricalData(formData);
            setIsEditing(false)
            //   setIsSaveClicked(true);
            //   setisErrorInForm(false);
        } else {
            console.log("invalid form");
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setIsEditing(true);

        // Vérifier si le champ est 'sharePrice' et s'il contient une virgule
        if (name === "sharePrice" && value.includes(",")) {
            e.preventDefault(); // Empêcher la saisie de la virgule
            console.log("La virgule n'est pas autorisée dans le champ sharePrice");
        } else {
            // Mettre à jour le formulaire avec la nouvelle valeur
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
            setSaveClicked(false);
        }
    };

    const baseClassForInput =
        "rounded-md border-[1px] p-firstDegree text-body w-full";
    const classNameForElements =
        "flex flex-col  w-full grid justify-items-start space-y-firstDegree  mt-firstDegree ";
    const baseClassForLabels =
        "w-full justify-items-start font-bold text-body";
    const baseClassForInputContent = "w-full justify-items-start h-full";
    return (
        <div className="">
            <Form {...form}>
                <form className="w-full flex flex-col gap-secondDegree" onSubmit={handleSubmit}>
                    <FormFieldContent
                        baseClassForInput={baseClassForInputContent}
                        classNameForFormLabel={baseClassForLabels}
                        className={classNameForElements}
                        hasSeparator={false}
                        form={form}
                        fieldName="date"
                        inputBalise={
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                className={cn(
                                    baseClassForInput,
                                    listOfInvalidInput.includes("date") && "border-error"
                                )}
                            />
                        }
                        labelText={"consultants.statement.valuationPanel.date"}
                    />

                    <FormFieldContent
                        baseClassForInput={baseClassForInputContent}
                        classNameForFormLabel={baseClassForLabels}
                        className={classNameForElements}
                        hasSeparator={false}
                        form={form}
                        fieldName="ebit"
                        euro={true}
                        inputBalise={
                            <input
                                type="number"
                                name="ebit"
                                value={formData.ebit}
                                onChange={handleChange}
                                min={0}
                                className={cn(
                                    baseClassForInput,
                                    listOfInvalidInput.includes("ebit") && "border-error"
                                )}
                            />
                        }
                        labelText={"consultants.statement.valuationPanel.ebit"}
                    />

                    <FormFieldContent
                        baseClassForInput={baseClassForInputContent}
                        classNameForFormLabel={baseClassForLabels}
                        className={classNameForElements}
                        hasSeparator={false}
                        form={form}
                        euro={true}
                        fieldName="companyValuation"
                        inputBalise={
                            <input
                                type="number"
                                name="companyValuation"
                                value={formData.companyValuation}
                                min={0}
                                onChange={handleChange}
                                className={cn(
                                    baseClassForInput,
                                    listOfInvalidInput.includes("companyValuation") &&
                                    "border-error"
                                )}
                            />
                        }
                        labelText={"consultants.statement.valuationPanel.companyValuation"}
                    />

                    <FormFieldContent
                        baseClassForInput={baseClassForInputContent}
                        classNameForFormLabel={baseClassForLabels}
                        className={classNameForElements}
                        hasSeparator={false}
                        form={form}
                        euro={true}
                        fieldName="actionNumber"
                        inputBalise={
                            <input
                                type="number"
                                name="actionNumber"
                                value={formData.actionNumber}
                                min={0}
                                onChange={handleChange}
                                className={cn(
                                    baseClassForInput,
                                    listOfInvalidInput.includes("actionNumber") && "border-error"
                                )}
                            />
                        }
                        labelText={"consultants.statement.valuationPanel.actionNumber"}
                    />

                    <FormFieldContent
                        baseClassForInput={baseClassForInputContent}
                        classNameForFormLabel={baseClassForLabels}
                        className={classNameForElements}
                        hasSeparator={false}
                        form={form}
                        euro={true}
                        fieldName="sharePrice"
                        inputBalise={
                            <input
                                type="number"
                                name="sharePrice"
                                value={formData.sharePrice}
                                min={0}
                                onChange={handleChange}
                                className={cn(
                                    baseClassForInput,
                                    listOfInvalidInput.includes("sharePrice") && "border-error"
                                )}
                                step={1}
                            />
                        }
                        labelText={"consultants.statement.valuationPanel.sharesPrice"}
                    />
                    <button
                        type="submit"
                        //onClick={handleButtonClick}
                        ref={buttonRef}
                    // onClick={(e)=>e.preventDefault()}
                    ></button>
                </form>
            </Form>


        </div>
    );
}
