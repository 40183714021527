function generateRandomId() {
  // Générer un nombre aléatoire entre 0 et 99999
  const randomNumber = Math.floor(Math.random() * 100000);
  // Obtenir l'horodatage actuel pour créer un identifiant unique
  const timestamp = Date.now();
  // Concaténer le nombre aléatoire et l'horodatage pour former l'ID
  const randomId = `${randomNumber}-${timestamp}`;
  return randomId;
}

// Exemple d'utilisation de la fonction pour générer un identifiant aléatoire
const randomId = generateRandomId();
console.log(randomId); // Affichera quelque chose comme "12345-1649289200000"

export default generateRandomId