import * as React from "react";
import { Label } from "@/components/atoms/texts/label";
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import { Button } from "@/components/molecules/buttons/button";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import DataCard from "@/components/organisms/charts/DataCard";

export default function ProfitSharingByYear({ actual, year, toOverflow, toPayslip, amount, dataCard }) {
    return (
        <div className=" px-[10vw] py-thirdDegree  gap-thirdDegree flex flex-col items-center ">
            <div className="flex gap-betweenText items-center w-full">
                <Label size="h2" fontWeight="bold" message="profitSharing.profitSharing"></Label>

                <Label size="h2" fontWeight="bold" > : {(year && year)}</Label>

            </div>
            <div className="flex gap-firstDegree items-center ">
                {actual ? <Label message="profitSharing.estimatedAt" /> : <Label message="profitSharing.profitWas" />}
                <Label size="h3" fontWeight="bold">{amount} €</Label>

            </div>

            {actual ?
                <>
                    <Label message="profitSharing.explainCalcul"></Label>
                    <div className="flex w-full gap-secondDegree">
                        {dataCard && <DataCard
                            data={dataCard.nbConsultant}
                            evolution={dataCard.evolNbConsultant}
                            label="profitSharing.dataCard.consultants"
                            helpText="profitSharing.dataCard.consultantsHelper" />}

                        {dataCard && <DataCard
                            data={dataCard.profitRate}
                            evolution={dataCard.evolProfitRate}
                            label="profitSharing.dataCard.profilRate"
                            helpText="profitSharing.dataCard.profilRateHelper" />}

                        {dataCard && <DataCard
                            data={dataCard.nbCoopted}
                            evolution={dataCard.evolNbCoopted}
                            label="profitSharing.dataCard.cooptations"
                            helpText="profitSharing.dataCard.cooptationsHelper" />}

                        {dataCard && <DataCard
                            data={dataCard.benefit}
                            evolution={dataCard.evolBenefit}
                            label="profitSharing.dataCard.benefit"
                            helpText="profitSharing.dataCard.benefitHelper" />}

                    </div>

                </>
                :
                <div className="flex w-full gap-secondDegree justify-center">
                    {toOverflow || toPayslip ?
                        <>
                            {toPayslip && <Button disabled showLeftIcon leftIcon={<FileCopyRoundedIcon />} style="outline" text="profitSharing.toPayslipDone" />}
                            {toOverflow && <Button disabled showLeftIcon leftIcon={<TrendingUpRoundedIcon />} text="profitSharing.toOverflowDone" />}
                        </>
                        :
                        <>
                            <Button showLeftIcon leftIcon={<FileCopyRoundedIcon />} style="outline" text="profitSharing.toPayslip" />
                            <Button showLeftIcon leftIcon={<TrendingUpRoundedIcon />} text="profitSharing.toOverflow" />
                        </>
                    }

                </div>
            }
            <Separator className="bg-greyStrong" />

        </div>
    )
}