import Chip from "@/components/molecules/buttons/Chip";

export default function ReadingStatus({ type }) {
    switch (type) {
        case "-1":
            return(<Chip item="consultants.expense.denied" bg="error" fontWeight="bold" />);
        case "0":
            return(<Chip item="consultants.expense.inProgress" bg="information" fontWeight="bold" />);
        case "1":
            return(<Chip item="consultants.expense.approved" bg="success" fontWeight="bold" />);
        default:
            break;
    }
    
}