
import { ResizablePanel } from '@/components/ui/resizable'
import { Label } from '@/components/atoms/texts/label'
import ListingConsultant from './ListingConsultant'
import { Button } from '@/components/molecules/buttons/button';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {  useNavigate, useParams } from "react-router-dom";

export default function MenuListingConsultants() {

  const navigate = useNavigate();

  return (

    <ResizablePanel defaultSize={15} className=' h-full px-firstDegree bg-medium max-w-[260px] w-[260px] min-w-[260px] z-20' id="panelListing" order={1} >
      <div className='w-full h-full flex flex-col items-center justify-between  '>
        <div className='w-full flex items-center justify-center border-b-2 border-grey py-paddingTopAndBottom'>
          <Label
            size="body"
            fontWeight="regular"
            fontFamily="mediaGothic"
            message="menuListingConsultant.consultants"
            className="font-400 font-sans text-black truncate "
          />
        </div>

        <ListingConsultant className="grow " />

        <Button
          id='btU'
          showLeftIcon={true}
          leftIcon={<RecentActorsIcon />}
          showRightIcon={false}
          rightIcon=""
          showText={true}
          text="menuListingConsultant.actions"
          sizePadding="default" 
          style="outline"
          className="w-full bottom-0  mb-firstDegree font-[700]"
          onClick={() => navigate('.')}
        />
      </div>



    </ResizablePanel>

  )
}
