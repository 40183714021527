import React, { useEffect, useState } from "react";
import { getYearDataForUser, getMonthData } from "@/utils/transformUserData";
import { Label } from "../../atoms/texts/label";
import { translationsPaths } from "@/utils/translationPath";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const options = {
    responsive: true,
    animation: {
        animateRotate: true,
        animateScale: true,
        duration: 1000,
        easing: "linear",
        reverse: true,
    },
    plugins: {},
    maintainAspectRatio: false,
};

const formatDataForChart = (months, overflows) => {
    return {
        labels: months,
        datasets: [
            {
                fill: true,
                label: "Overflow",
                data: overflows,
                borderColor: "#1B3477",
                borderWidth: 1,
                backgroundColor: (context) => {
                    const bgColor = [
                        "#77D2E3",
                        "rgba(81, 121, 228, 0.15)",
                        "rgba(137, 170, 254, 0.15)",
                        "rgba(108, 246, 250, 0.15)",
                        "rgba(152, 240, 242, 0.15)",
                        "#FFFF",
                    ];
                    if (!context.chart.chartArea) {
                        return;
                    }
                    const {
                        ctx,
                        data,
                        chartArea: { top, bottom },
                    } = context.chart;
                    const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
                    gradientBg.addColorStop(1, bgColor[4]);
                    gradientBg.addColorStop(1, bgColor[3]);
                    gradientBg.addColorStop(1, bgColor[2]);
                    gradientBg.addColorStop(0, bgColor[0]);
                    gradientBg.addColorStop(1, bgColor[1]);
                    gradientBg.addColorStop(0.99, bgColor[5]);

                    return gradientBg;
                },
                tension: 0.4,
            },
        ],
    };
};

const LineChartForStatementTab = ({ year, user }) => {
    const [chartHeight, setChartHeight] = useState(true);
    const [t] = useTranslation("global");
    const monthData = getMonthData(getYearDataForUser(user, year));

    const months = [];
    const overflows = [];
    const monthsTranslated = [];

    monthData.forEach((month) => {
        months.push(month.rowNameOrMonth);
        overflows.push(month.overflow);

        const translatedMonth = t(translationsPaths[month.rowNameOrMonth]);
        translatedMonth != "" && monthsTranslated.push(translatedMonth);
    });

    const data = formatDataForChart(monthsTranslated, overflows);
    //   console.log("monthTranslated", monthsTranslated);

    // useEffect(() => {
    //   const updateChartHeight = () => {
    //     const screenWidth = window.innerWidth;
    //     console.log('Screen width:', screenWidth);
    //     // setChartHeight(screenWidth < 1500 ? true : false);
    //     setChartHeight(true)
    //   };

    //   window.addEventListener('resize', updateChartHeight);
    //   updateChartHeight();

    //   return () => {
    //     window.removeEventListener('resize', updateChartHeight);
    //   };
    // }, []);
    // if (chartHeight) {
    //   setChartHeight(false)

    //   return (<Line
    //   options={options}
    //   data={data}
    //   // height={60}
    //   // width={250}

    //   className='bg-white rounded-sm'
    // />)
    // }

    return (
        <Line
            options={options}
            data={data}
            tension={0.3}
        // height={40}
        // width={250}
        // className='bg-white rounded-sm'
        />
    );
};

export default LineChartForStatementTab;
