import { Label } from "../atoms/texts/label";
import LineArt from "@/assets/images/lineArt.png"

export default function BlueHeader({ title, caption }) {
    return (
        <div className="bg-primary py-[150px] px-[10%] flex flex-col gap-secondDegree items-center relative z-10 text-center">
            <img src={LineArt} className="bgLineArtIntegrated  overflow-hidden" />
            <Label fontFamily="mediaGothic" size="h1" color="white" message={title}></Label>
            <Label size="body" color="white" message={caption} className=""></Label>
        </div>
    )
}