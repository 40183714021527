import React, { useState } from 'react';

import { Label } from '@/components/atoms/texts/label';

import { useTranslation } from "react-i18next";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export default function DragAndDropZone({file, setFile,fileError}) {
    const [t] = useTranslation("global");
    const choosefile = t("global.actions.chooseFile");
    const [dragOver, setDragOver] = useState(false);

    // Gère le changement de fichier
    const handleFileChange = (event) => {
        // console.log("File input changed"); // Debugging line
        const files = event.target.files;
        if (files.length) {
            // console.log("Selected file:", files[0]); // More detailed debugging
            setFile(files[0]);
        }
    };

    // Fonction pour gérer le dépôt de fichier
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        if (files.length) {
            setFile(files[0]);
            document.getElementById("fileInput").files = files;
            // console.log("files[0] = ", files[0])
        }

        setDragOver(false); // Réinitialiser l'état de survol

    };

    // Prévenir le comportement par défaut pour permettre le dépôt de fichier
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(true); // Mettre à jour l'état lors du survol avec un fichier
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false); // Réinitialiser l'état lorsque le fichier quitte la zone
    };

    // Appliquer une classe conditionnelle pour le survol
    const bgClassName = dragOver ? 'bg-hover' : 'bg-medium';
    return (
        <div
            className={`flex flex-col ${bgClassName} rounded-md justify-center items-center w-full py-thirdDegree gap-secondDegree border ${file ? `border-success` : (fileError ? `border-error`:`border-medium`)} `}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <div className='flex flex-col justify-center items-center gap-firstDegree '>
                <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    className='hidden'
                />

                <Label size="" fontWeight="bold" color="gray400"  message="global.actions.dragAndDrop" />
                <Label size=""  color="gray400" message="global.actions.OrBrowse"  />
                <label onChange={handleFileChange} htmlFor="fileInput" className="cursor-pointer inline-block bg-white text-body text-night font-medium py-2 px-4 rounded-md hover:bg-hover">
                    {choosefile}
                </label>

            </div>
            <div className='h-8 flex  text-body text-success justify-center items-center gap-firstDegree'>
                {file && <CheckCircleRoundedIcon color='success' />}

                {file && <span className="ml-2 text-body font-bold">{file.name}</span>}
            </div>
        </div>
    )
}