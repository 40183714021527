/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetFooter,
    SheetSlot,
} from "../../../../templates/sheet";
import Action from "@/components/molecules/buttons/Action";
import { Storefront } from "@mui/icons-material";
import { getHistoricalData } from "@/services/admin-services/valuationData";
import HistoricalDataList from "./HistoricalValuationList";
import SortButton from "@/components/molecules/buttons/Sort";
import EditValuation from "./EditValuation";
import { Label } from "@/components/atoms/texts/label";
import LineChartValuation from "./LineChartValuation";
import { Separator } from "@/components/atoms/divider/separatorShadcn";

import { useToast } from "@/components/organisms/feedback/use-toast";

const sortHistoricalData = (data, sortOrder = "asc") => {
    const sortedData = [...data]; // Copie du tableau pour ne pas modifier l'original

    sortedData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if (sortOrder === "asc") {
            //   console.log("tri croissant");
            return dateA - dateB; // Tri croissant
        } else if (sortOrder === "desc") {
            //   console.log("tri décroissant");
            return dateB - dateA; // Tri décroissant
        } else {
            console.error('Invalid sortOrder. Please use "asc" or "desc".');
            return 0; // Si sortOrder n'est ni 'asc' ni 'desc', le tableau reste inchangé
        }
    });
    // console.log("tri data", sortedData);
    return sortedData;
};
export default function ValuationPanel({ ...props }) {
    const [isButtonSaveClicked, setIsButtonSaveClicked] = useState(false);
    const [historicalData, setHistoricalData] = useState(getHistoricalData());
    const [dataForList, setDataForList] = useState(getHistoricalData());
    const [filterOrder, setFilterOrder] = useState("asc");
    const [isErrorInform, setIsErrorInform] = useState(true);
    const [open, setOpen] = useState(false);
    // const [actionVariant, setActionVariant] = useState("default");
    const [isEditing, setEditing] = useState(false)
    const { toast } = useToast();


    const displayToast = () => {
        toast({
            variant: "warning",
            title: "error.editWithoutSave",
            duration: 1500,

        });

    }
    const handleOpen = () => {
        setOpen(!open)
        setEditing(false)
    }

    function onCancel() {
        isEditing ? displayToast() : ''

        setOpen(false);
        // setActionVariant("default");
    }
    const sortDataForHistorical = () => {
        filterOrder == "asc" ? setFilterOrder("desc") : setFilterOrder("asc");
        const data = sortHistoricalData(historicalData, filterOrder);
        setDataForList(data);
    };
    const updateData = (formData) => {
        setHistoricalData((prevData) => [...prevData, formData]);
        setDataForList((prevData) => [...prevData, formData]);
    };

    return (
        <div>
            <Sheet open={open} onOpenChange={handleOpen}>
                <SheetTrigger asChild>
                    <button className="w-full">
                        {" "}
                        <Action
                            icon={<Storefront />}
                            text="consultants.dashboard.actions.valuation"
                            initialVariant={open ? "active" : "default"}
                            {...props}
                        />
                    </button>
                </SheetTrigger>
                <SheetContent>
                    <SheetHeader
                        title={"consultants.statement.valuationPanel.valuationData"}
                    />
                    <SheetSlot >
                        <div className=" flex flex-col p-secondDegree gap-thirdDegree" >
                            <div className=" flex flex-col gap-secondDegree" >
                                <Label
                                    message="consultants.statement.valuationPanel.updateDatas"
                                    size="h4"
                                    className="font-bold "
                                />
                                <EditValuation
                                    isButtonSaveClicked={isButtonSaveClicked}
                                    setSaveClicked={setIsButtonSaveClicked}
                                    setHistoricalData={updateData}
                                    setIsErrorInform={setIsErrorInform}
                                    setIsEditing={setEditing}
                                />
                            </div>
                            <Separator orienation="horizontal" className="w-[96%]" />

                            {/* <Button onClick={ sortDataForHistorical}>filter</Button>  */}
                            <div className=" flex flex-col gap-secondDegree" >

                                <div className=" flex justify-between ">
                                    <Label
                                        message="consultants.statement.valuationPanel.history"
                                        size="h4"
                                        fontWeight="bold"
                                    />
                                    <SortButton
                                        type="date"
                                        sort={filterOrder == "asc" ? "up" : "down"}
                                        onClick={() => sortDataForHistorical()}
                                    />
                                </div>
                                <LineChartValuation historicalData={historicalData} />
                                <HistoricalDataList historicalData={dataForList} />
                            </div>
                        </div>

                        {/* {printValuation()} */}
                    </SheetSlot>
                    <SheetFooter
                        //closeButton={true}
                        buttonOutlineText={"global.actions.cancel"}
                        buttonDressedText={"global.actions.save"}
                        onClickOutline={() => onCancel()}
                        onClickDressed={() => setIsButtonSaveClicked(true)}
                    />
                </SheetContent>
            </Sheet>
        </div>
    );
}
