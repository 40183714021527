import React from 'react'
import { Skeleton } from '@mui/material';
import { cn } from "@/lib/utils";
export default function SkeletonOverview() {
  const skeletonNumber = 12;

  // Créez une liste d'éléments div basée sur le nombre de cartes
  const skeletonElements = Array.from({ length: skeletonNumber }, (_, index) => (
    
    <Skeleton key={index} className="rounded-sm" animation="wave" height={200} sx={{ my: -5.5, mx: -1 }} />
     
     
  ));
  

  return (
    <div className='h-full p-secondDegree'>
      <div className="space-y-2">
        <Skeleton className="h-6 w-[50%] rounded-sm" height={50}  />
        <Skeleton className="h-4 w-[45%] rounded-sm" />
      </div>
    <div className='grid grid-cols-2 gap-[30px] h-[98%] mt-4 '> 
      {skeletonElements}
      </div>

       </div>
  );
}
