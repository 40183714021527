import { languages } from "countries-list";
const getLanguageCode = (languageName) => {
  const languageIndex = Object.entries(languages).reduce(
    (index, [code, { name, native }]) => {
      index[name] = code;
      index[native] = code;
      return index;
    },
    {}
  );
  return languageIndex[languageName].toUpperCase() || null;
};
const languageNames = Object.values(languages).map((lang) => lang.name);



const languageLevels = ["A1", "A2", "B1", "B2", "C1", "C2", "Mother tongue"];
export { getLanguageCode, languageNames , languageLevels};
