import * as React from "react";
import BlueHeader from "@/components/organisms/blueHeader";

import { Label } from "@/components/atoms/texts/label";
import LineArt from "@/assets/images/lineArt.png";
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import { Button } from "@/components/molecules/buttons/button";
import ProfitSharingByYear from "./profitSharingByYear";
import useProfitSharingData from "@/hooks/consultant-hooks/useProfitSharingData";

export default function ProfitSharing() {
    const { data, error } = useProfitSharingData();
    return (
        <div className="bg-lighter flex grow overflow-hidden relative">

            <div className="w-full h-[93vh] overflow-y-scroll overflow-x-hidden z-10  ">

                <img src={LineArt} className="bgLineArt " />

                <BlueHeader title="profitSharing.title" caption="profitSharing.method" />

                {!error && data && data.map((item, index) => (
                    <ProfitSharingByYear
                        key={index}
                        year={item.year}
                        toOverflow={item.toOverflow === "true"}
                        toPayslip={item.toPayslip === "true"}
                        actual={item.actual === "true"}
                        amount={item.amount}
                        dataCard={item.cardData}
                    />
                ))}

            </div></div>

    )
}