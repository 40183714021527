export function getAddedSkills() {
    return [
      {
        title: "C++",
        type: "Hard skill",
        id: "3edad995-3bf0-4ddd-9ebd-d9c8eea271dc",
        numberOfUsage: 1,
      },
      {
        title: "Java",
        type: "Hard skill",
        id: "23b98262-788e-4b42-9de5-c0798f589a69",
        numberOfUsage: 2,
      },
      {
        title: "JS",
        type: "Hard skill",
        id: "6f57a435-7e2c-46f8-a949-35cae82d5e87",
        numberOfUsage: 12,
      },

      {
        title: "Commmunication",
        type: "Soft skill",
        id: "be4f7e07-ea36-44b8-905c-7a817a7933f2",
        numberOfUsage: 15,
      },
      {
        title: "Teamwork",
        type: "Soft skill",
        id: "16c476ba-184b-420b-b22c-80a3f67bef3e",
        numberOfUsage: 7,
      },
      {
        title: "Problem solving",
        type: "Soft skill",
        id: "7fad1540-7658-4802-835d-4c22ec8c7f48",
        numberOfUsage: 20,
      },

      {
        title: "Data analysis",
        type: "Power skill",
        id: "af57ea0e-4b0f-4759-b02d-481b61037bd5",
        numberOfUsage: 0,
      },
      {
        title: "Digital Literacy",
        type: "Power skill",
        id: "ebf6fb30-9603-445a-b4a9-c1c6d111579d",
        numberOfUsage: 5,
      },
      {
        title: "Project Management",
        type: "Power skill",
        id: "4b48d42e-2644-414b-bc0a-73ba6953c785",
        numberOfUsage: 100,
      },
    ];
}

export function getSkillsType() {
    return [ "Soft skill", "Hard skill", "Power skill"];
}