import { isNumeric } from "@/utils/formatDateString";
// EditBills.jsx
const validateFormData = (formData, setErrors) => {
  const newErrors = {
    consultant: "",
    date: "",
    customer: "",
    billNumber: "",
    days: "",
    amount: "",
    marge: "",
    tJM: "",
  };

  // Règles de validation
  const validationRules = {
    consultant: { required: true, message: "Consultant is required" },
    date: { required: true, message: "Date is required" },
    customer: { required: true, message: "Customer is required" },
    billNumber: { required: true, message: "Bill Number is required" },
    days: { required: true, message: "Days is required" },
    amount: { required: true, message: "Amount is required" },
    marge: { required: true, message: "Marge is required" },
    tJM: { required: true, message: "TJM is required" },
  };

  // Appliquer les règles de validation à chaque champ
  Object.keys(validationRules).forEach((fieldName) => {
    const rule = validationRules[fieldName];
    const value = formData[fieldName];
    // console.log("the cause v ", value);
    if (!isNumeric(value)) {
      if (rule.required && !value.trim()) {
        newErrors[fieldName] = rule.message;
      }
    }
  });

  // Mettez à jour le state des erreurs
  setErrors(newErrors);

  // Retournez la liste des champs non renseignés
  return Object.keys(newErrors).filter((fieldName) => newErrors[fieldName]);
};

export { validateFormData };
