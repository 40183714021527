/* eslint-disable react/prop-types */
import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import Chip from "@/components/molecules/buttons/Chip";
import CreateAndUpdateCV from "../CreateAndUpdateCV";
import EditConsultant from "../createConsultant/EditConsultant";
import { motion } from "framer-motion";
export default function DraftOverview({
  user,
  hideOthers,

  
  setEditing,
  setIsOpen,
  showAllDraft,
  setShowAllDraft,

}) {
  const [showCv, setShowCV] = useState(false);
  const [userDraft, setUser] = useState(user);
  const [showFourthStep, setShowFourthStep] = useState(false);
  const handleContinue = () => {
    hideOthers(true);
    setShowCV(true);
    setShowAllDraft(false);
  };
  return (
    <motion.div
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
      }}
    >
      {showAllDraft && (
        <motion.div
          initial={{
            scale: 0,
            y: 220,
          }}
          animate={{
            scale: 1,
            y: 0,
          }}
          className="w-full  py-paddingTopAndBottom px-paddingLeftAndRight flex flex-row items-center gap-firstDegree bg-medium rounded-sm  shadow-md"
        >
          <Button
            showLeftIcon={true}
            leftIcon={
              <DeleteOutlineIcon className="text-primary" fontSize="small" />
            }
            showText={false}
            style="ghost"
          />
          <Chip
            item="consultants.statement.prospectConsultantPanel.draft"
            rounded=""
            bg="default"
            fontWeight="bold"
          />

          <div className="basis-1/3 pl-2 flex gap-betweenText">
            <span>{user.firstName}</span>
            <span>{user.name}</span>
          </div>

          <Button
            showLeftIcon={true}
            leftIcon={<ArrowForwardIcon fontSize="small" />}
            showText={true}
            text="global.actions.continue"
            sizePadding="" // Utilisez "small" pour un padding plus petit
            style="ghost"
            className="font-bold"
            onClick={handleContinue} // Options: "fill", "outline", "ghost", "dressedGhost"
          />
        </motion.div>
      )}
      {showCv && (
        <EditConsultant
          //openCvSection={openCvSection}
          setEditing={setEditing}
          setIsOpen={setIsOpen}
          user={user}
          setUser={setUser}
          showFourthStep={showFourthStep}
          setShowFourthStep={setShowFourthStep}
        />
      )}
    </motion.div>
  );
}
