import React, { useEffect, useState } from "react";
import { Avatar } from "@/components/atoms/avatar";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/molecules/table";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose,
} from "@/components/ui/dialog";

import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import { Toaster } from "@/components/organisms/feedback/toaster"

import { Separator } from '@/components/atoms/divider/separatorShadcn';
import DataDeletion from '@/components/macro-organisms/panels/panels-admin/DataDeletion';
import Copy from '@/components/atoms/Copy';

import NewCooptationPanel from '@/components/macro-organisms/panels/panels-admin/newCooptation/NewCooptationPanel';
import useInformationData from "@/hooks/admin-hooks/useInformationData";
import { useParams } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/organisms/forms/select";

// import useInformationTab from './UseNewCooptation'

export default function InformationTab() {
    const { id } = useParams();
    const { informationData, error } = useInformationData(id);
    console.log("informationData = ", informationData);
    const [openRGPD, setOpenRGPD] = React.useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState("");

    const [selectedRetribution, setSelectedRetribution] = useState();
    const [selectedProfile, setSelectedProfile] = useState();

    useEffect(() => {
        informationData && setSelectedRetribution(informationData.retribution)
    }, [informationData])
    const [isInputValid, setIsInputValid] = useState(true); // État pour suivre la validité de l'input

    const [inputValue, setInputValue] = useState("");

    const validateInput = (value) => {
        const regex = /^\d{2}\/\d{2}\/\d{2}$/; // Expression régulière pour le format 00/00/00
        return regex.test(value);
    };

    const handleInputBlur = (e) => {
        if (!e.target.value) {
            return;
        }
        setSelectedRetribution("input");
        const isValid = validateInput(e.target.value);
        setIsInputValid(isValid); // Met à jour l'état de validité de l'input
        if (isValid) {
            setInputValue(e.target.value); // Met à jour la valeur sélectionnée si l'input est valide
        }
    };

    // const { toast } = useToast();

    const renderCooptingRows = () => {
        return informationData.coopting.map((cooptingItem, index) => (
            <TableRow key={index}>
                <TableCell className="p-1 text-center">{cooptingItem.name}</TableCell>
                <TableCell className="p-1 text-center">
                    {cooptingItem.retribution} <Label size="small">%</Label>{" "}
                </TableCell>
                <TableCell className="p-1 text-center align-center">
                    {cooptingItem.ownership} <Label size="small">%</Label>{" "}
                </TableCell>
                <TableCell className="p-1 text-center">
                    {cooptingItem.totalDuration === "∞" ? (
                        cooptingItem.totalDuration
                    ) : (
                        <>
                            {cooptingItem.duration} / {cooptingItem.totalDuration}{" "}
                            <Label size="small">months</Label>
                        </>
                    )}
                </TableCell>
            </TableRow>
        ));
    };

    if (!informationData) {
        //todo redirect en fonction de l'id. EA.
        return <div>User not found! Please try again later</div>;
    }
    return (
        <div className="flex gap-thirdDegree">
            <Toaster />
            <Avatar
                fallback={informationData.name}
                size="large"
                src={informationData.avatarUrl}
                className="shrink-0 grow-0"
            />
            <div className="flex flex-col gap-secondDegree grow ">
                <div className="flex justify-between  grow">
                    <Label
                        size="h3"
                        fontWeight="bold"
                        message="consultants.dashboard.tabs.information"
                    />

                    <NewCooptationPanel />

                </div>
                <div className="flex gap-secondDegree">
                    <div className="flex flex-col grow justify-start gap-secondDegree">
                        {/* <Label className="group">{informationData.name}<Copy value={informationData.name} /></Label> */}

                        {/* Email */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.email" />
                            <Label className="group">{informationData.email}<Copy value={informationData.email} /></Label>
                        </div>

                        {/* Address */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.address" />
                            <Label className="group ">
                                {informationData.address.suite},  {informationData.address.street}
                                <br />
                                {informationData.address.zipcode},  {informationData.address.city}
                                <Copy value={informationData.address.suite + ", " + informationData.address.street + ", " + informationData.address.zipcode + ", " + informationData.address.city} />
                            </Label>
                        </div>

                        {/* Seniority */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.seniority" />
                            <Label className="group ">{informationData.seniority}  <Copy value={informationData.seniority} /></Label>
                        </div>

                        {/* RIB */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.rib" />
                            <Label className="group ">{informationData.rib}  <Copy value={informationData.rib} /></Label>
                        </div>

                        {/* BIC */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.bic" />
                            <Label className="group ">{informationData.bic}  <Copy value={informationData.bic} /></Label>
                        </div>

                        {/* Data privacy */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.dataPrivacy" />
                            <Sheet open={openRGPD} onOpenChange={setOpenRGPD}>
                                <SheetTrigger className="w-min">
                                    <Button text="global.panelOpening.showMore" className="w-min" style="dressedGhost" />
                                </SheetTrigger>
                                <SheetContent >
                                    <SheetHeader
                                        title={"consultants.informations.dataDeletionInfo.panel.title"}
                                    />
                                    <SheetSlot className=" flex ">
                                        <DataDeletion />
                                    </SheetSlot>
                                    <SheetFooter
                                        buttonOutlineText={"global.actions.cancel"}
                                        buttonDressedText={"global.actions.request"}
                                        warning={"consultants.informations.dataDeletionInfo.warning.dataDeletion"}
                                        onClickOutline={() => setOpenRGPD(false)}
                                        onClickDressed={(checkboxIsChecked) => { console.log(informationData.id, 'user ask data deletion, consent done  : ', checkboxIsChecked) }}
                                    />
                                </SheetContent>
                            </Sheet>
                        </div>

                    </div>
                    <Separator orientation="vertical" />
                    <div className="flex flex-col grow-5 justify-start gap-secondDegree ">
                        {/* CooptedBy */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.cooptedBy" />
                            <Label className="group">{informationData.cooptedBy}  <Copy value={informationData.cooptedBy} /></Label>
                        </div>

                        {/* Profile */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.profile"
                            />
                            <aside className="flex gap-secondDegree items-center">
                                <Label className="group h-full">
                                    {informationData.profil} <Copy value={informationData.profil} />
                                </Label>
                                <Dialog >
                                    <DialogTrigger>
                                        <Label className="hover:cursor-pointer font-bold underline " color="primary" size="body" message="global.actions.set" />
                                    </DialogTrigger>
                                    <DialogContent>
                                        <DialogHeader>
                                            <DialogTitle><Label message="consultants.informations.profile" size="h4" /></DialogTitle>
                                            <DialogDescription className="flex flex-col gap-4 py-4">
                                                <span>
                                                    <Label message="consultants.informations.actualProfil" />
                                                    <Label fontWeight="bold">{informationData.profil}</Label>
                                                </span>
                                                <Label message="consultants.informations.selectProfile" />
                                                <Select value={selectedProfile}  onValueChange={setSelectedProfile}>
                                                    <SelectTrigger className="w-[180px]">
                                                        <SelectValue placeholder={informationData.profil} />
                                                    </SelectTrigger>
                                                    <SelectContent>

                                                    {informationData.allProfiles.map((item, index) => (
                                                        <SelectItem value={item} id={index} >{item}</SelectItem>
                                                    ))}
                                                        

                                                    </SelectContent>
                                                </Select>
                                            </DialogDescription>
                                        </DialogHeader>
                                        <DialogFooter className="sm:justify-start">
                                            <DialogClose asChild>
                                                <Button type="button" style="ghost" text='global.actions.cancel' />


                                            </DialogClose>
                                            <Button style="fill" text='global.actions.save' className="w-full"  onClick={() => { console.log("send : ", selectedRetribution, inputValue); setDialogIsOpen(false) }}/>

                                        </DialogFooter>
                                    </DialogContent>
                                </Dialog>
                            </aside>

                        </div>

                        {/* Regime */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.dashboard.newCooptation.table.regime" />
                            <aside>
                                <Label className="group">{informationData.retribution}   <Copy value={informationData.retribution} /></Label>
                                <Dialog open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
                                    <DialogTrigger>
                                        <Label className="hover:cursor-pointer font-bold underline" color='primary' message="global.actions.set" />
                                    </DialogTrigger>
                                    <DialogContent>
                                        <DialogHeader>
                                            <DialogTitle><Label message="consultants.informations.retribution.title" size="h4" />{informationData.name}</DialogTitle>
                                            <DialogDescription className="flex flex-col gap-4 py-4">
                                                <span className='font-bold text-body'><Label className='font-medium' message="consultants.informations.retribution.actual" />{informationData.retribution}</span>
                                                <Label fontWeight='bold' message="consultants.informations.retribution.instruction" />
                                                <RadioGroup value={selectedRetribution} onValueChange={setSelectedRetribution} >
                                                    {informationData.allRetribution.map((item, index) => (
                                                        <div className="flex items-center space-x-2" key={index}>
                                                            <RadioGroupItem value={item} id={index} />
                                                            <Label htmlFor={index}>{item}</Label>
                                                        </div>
                                                    ))}
                                                    {/* {console.log("selectedRetribution : ", selectedRetribution)} */}
                                                    <div className="flex items-center space-x-2">
                                                        <RadioGroupItem value="input" id="r3" />
                                                        <input
                                                            htmlFor="r3"
                                                            className={`px-2 py-1 rounded-sm border ${isInputValid ? 'border-grey' : 'border-error'}`}
                                                            onBlur={handleInputBlur}
                                                            onClick={() => setSelectedRetribution("input")}
                                                            onChange={() => setSelectedRetribution("input")}
                                                            placeholder='--/--/--' />
                                                    </div>
                                                </RadioGroup>
                                                {!isInputValid && <Label className="text-error" message="error.formatRetribution" />}
                                            </DialogDescription>
                                        </DialogHeader>
                                        <DialogFooter className="sm:justify-start">
                                            <DialogClose asChild>
                                                <Button type="button" style="ghost" text='global.actions.cancel' />
                                            </DialogClose>
                                            <Button style="fill" text='global.actions.save' className="w-full" onClick={() => { console.log("send : ", selectedRetribution, inputValue); setDialogIsOpen(false) }} />
                                        </DialogFooter>
                                    </DialogContent>

                                </Dialog>

                            </aside>

                        </div>
                        
                        {/* Cooptations */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.activeCooptation" />
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="bg-transparent w-[25%] h-auto py-firstDegree"><Label message="consultants.dashboard.newCooptation.table.name" /></TableHead>
                                        <TableHead className=""><Label message="consultants.dashboard.newCooptation.table.regime" /></TableHead>
                                        <TableHead className=""><Label message="consultants.dashboard.newCooptation.table.ownership" /></TableHead>
                                        <TableHead className=""><Label message="consultants.dashboard.newCooptation.table.duration" /></TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>

                                    {renderCooptingRows()}

                                </TableBody>
                            </Table>

                        </div>

                    </div>

                </div>
            </div>
        </div>


    )

}
