import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Button } from './buttons/button';

export default function ControlsDownload({dataOwner, onClickNewTab, onClickDownload, onClickDelete}) {
    return (
        <div className='flex justify-center items-center h-full'>
            <Button showLeftIcon leftIcon={<OpenInNewRoundedIcon />} showText={false} style="ghost" sizePadding="small" className="text-night" onClick={()=>onClickNewTab()}/>
            <Button showLeftIcon leftIcon={<DownloadRoundedIcon />} showText={false} style="ghost" sizePadding="small" className="text-night" onClick={()=>onClickDownload()}/>
            {dataOwner && <Button showLeftIcon leftIcon={<DeleteRoundedIcon />} showText={false} style="ghost" sizePadding="small" className="text-error" onClick={()=>onClickDelete()}/>}
        </div>
    )
}