import DetailsTabs from "@/components/macro-organisms/tabs/consultant-vision-user/DetailsTabs";
import * as React from "react";
import LineArt from "@/assets/images/lineArt.png"

export default function Administration() {
    return (
        <div className="bg-lighter h-full  overflow-hidden relative z-10">
                        <img src={LineArt} className="bgLineArt overflow-hidden " />

            <DetailsTabs />
        </div>
    )
}