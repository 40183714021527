/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";

import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";

import useId from "@mui/material/utils/useId";
import { validateFormData } from "./validateFormLanguage";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import UniqueSelect from "@/components/molecules/inputs/UniqueSelect";
// import languageNames from "countries-list/minimal/languages.native.min";
import { languages } from "countries-list";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import generateRandomId from "@/utils/randomId";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

// Obtenir une liste des noms de langue à partir de l'objet languages
const languageNames = Object.values(languages).map((lang) => lang.name);


const languageLevels = [
  "A1",
  "A2",
  "B1",
  "B2",
 "C1",
  "C2",
 
]
export default function AddLanguage({
    language,
    showForm,
    setShowForm,
    editingLanguage = false,
    setLanguages,
    setEditingLanguage,
    languages
}) {
  const form = useForm();
 
  const id = generateRandomId(); //à enlever
  const [languageName, setLanguageName] = useState("")
  const [level, setLevel]=useState("")
  const [t] = useTranslation("global");
  useEffect(() => {
    const mother = t("global.information.motherTongue");
    if (languageLevels[6]) {
      languageLevels[6] != mother && languageLevels.push(mother);
    } else {
      languageLevels.push(mother);
    }
  }, [t]);
  const handleSubmit = (e) => {
    e.preventDefault();
   
    console.log("id ", id);
    setShowForm(false);
   languages
     ? setLanguages((prevData) => [
         ...prevData,
         {
           language: languageName,
           level: level,
           id: id,
         },
       ])
     : setLanguages([
         {
           language: languageName,
           level: level,
           id: id,
         },
       ]);
     
    
  };
  const handleCancel = () => {
    setEditingLanguage(false);
    setShowForm(false);
  };
 
  

  return (
    <div>
      {showForm && (
        <div className="gap-secondDegree flex flex-col p-secondDegree shadow-md ">
          <Separator orientation="horizontal" className="bg-black"/>
          <Form {...form}>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-firstDegree w-full"
            >
              {/**/}
              <div className="flex flex-row gap-betweenText">
                <UniqueSelect
                  className=""
                  id="1"
                  showLabel={false}
                  dataToSelect={languageNames}
                  isSearchable={true}
                  filterDataFunction={setLanguageName}
                />
                <UniqueSelect
                  className=""
                  showLabel={false}
                  dataToSelect={languageLevels}
                  filterDataFunction={setLevel}
                  id="3"
                />
              </div>
              <div className="flex flex-row gap-[12px] items-center w-full">
                {/* <DeleteIcon
                  className="text-primary cursor-pointer"
                  onClick={handleDelete}
                /> */}
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<ClearIcon fontSize="small" />}
                                    showText={true}
                                    text="global.actions.cancel"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="outline"
                                    className="font-bold border-primary w-full"
                                    onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                                />
                                <Button
                                    showLeftIcon={true}
                                    leftIcon={<SaveIcon fontSize="small" />}
                                    showText={true}
                                    text="global.actions.save"
                                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                                    style="fill"
                                    className="font-bold w-full"
                                    type="submit"
                                // Options: "fill", "outline", "ghost", "dressedGhost"
                                />
                            </div>
                        </form>
                    </Form>
                </div>
            )}
        </div>
    );
}
