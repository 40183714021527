/* eslint-disable react/prop-types */
import { Label } from "@/components/atoms/texts/label";

import { Button } from "@/components/molecules/buttons/button";
import Input from "@/components/molecules/inputs/Input";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import React from 'react'
import AddProfileTitle from "./AddProfileTitle";

export default function PrintProfileTitle({
  profile,
  setEditingProfile,
  editingProfile,
  setProfiles,
}) {
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setShowForm(true);
    setEditingProfile(true);
  };
  return (
    <div className="">
      {!showForm && (
        <div className="flex  flex-row gap-[12px] w-[30%]  ">
          <div className=" truncate ...">
            <span className="font-bold items-center  ">
              {profile.profileTitle}
            </span>{" "}
          </div>
          <div>
            <EditIcon
              className="text-primary cursor-pointer"
              onClick={handleEdit}
            />
          </div>
        </div>
      )}
      <AddProfileTitle
        profile={profile}
        showForm={showForm}
        setShowForm={setShowForm}
        editingProfile={editingProfile}
        setEditingProfile={setEditingProfile}
        setProfiles={setProfiles}
      />
    </div>
  );
}
