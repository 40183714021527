/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import { getAllDrafts } from "@/services/consultant-services/prospectConsultant";
import React from "react";
import DraftOverview from "./DraftOverview";

export default function AllDrafts({
  hideOthers,
 
  
  setEditing,
  setIsOpen,
 
}) {
  const [allDrafts, setAllDrafts] = useState(getAllDrafts());
 const [showAllDraft, setShowAllDraft] = useState(true);
  return (
    <div className="flex flex-col  gap-secondDegree">
      {allDrafts.map((user, index) => {
        return (
          <DraftOverview
            key={index}
            user={user}
            hideOthers={hideOthers}
           
            
            setEditing={setEditing}
            setIsOpen={setIsOpen}
            showAllDraft={showAllDraft}
            setShowAllDraft={setShowAllDraft}
           
          
          />
        );
      })}
    </div>
  );
}
