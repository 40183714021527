import React, { useRef, useEffect, useState } from "react";
import { Avatar } from "@/components/atoms/avatar";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import DataDeletion from "@/components/macro-organisms/panels/panels-consultant/DataDeletion";
import NewCooptation from "@/components/macro-organisms/panels/panels-admin/newCooptation/NewCooptation";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/molecules/table";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Toaster } from "@/components/organisms/feedback/toaster";
import { useToast } from "@/components/organisms/feedback/use-toast";
import AddIcon from "@mui/icons-material/Add";
import VerticalSplitRoundedIcon from "@mui/icons-material/VerticalSplitRounded";
import useInformationData from "@/hooks/consultant-hooks/useInformationDataUser";

import { useAuth } from "@/context/AuthContext";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import Copy from "@/components/atoms/Copy";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose,
} from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/organisms/forms/select";
import Input from "@/components/molecules/inputs/Input";
import EditInformationData from "../../../../organisms/forms/editInformationData";
export default function InformationTabUser() {
    // const { user } = useConsultantContext();

    // const {user} = useAuth();
    const { user, error } = useInformationData();

    const [selectedRIB, setSelectedRIB] = React.useState();
    const [selectedBIC, setSelectedBIC] = React.useState();
    const [selectedAddress, setSelectedAddress] = React.useState();
    const [selectedEmail, setSelectedEmail] = React.useState();

    const [openRGPD, setOpenRGPD] = React.useState(false);

    const { toast } = useToast();
    const renderCooptingRows = () => {
        return user.coopting.map((cooptingItem, index) => (
            <TableRow key={index} className=" border-primary border-4 border-solid">
                <TableCell
                    className={`${(index == 4 || index == 9) &&
                        `border-secondary border-b-2 border-solid tdHover`
                        }`}
                >
                    {cooptingItem.name}
                </TableCell>
                <TableCell
                    className={`${(index == 4 || index == 9) &&
                        `border-secondary border-b-2 border-solid tdHover`
                        }`}
                >
                    {cooptingItem.retribution} <Label size="small">%</Label>{" "}
                </TableCell>
                <TableCell
                    className={`align-center ${(index == 4 || index == 9) &&
                        `border-secondary border-b-2 border-solid tdHover`
                        }`}
                >
                    {cooptingItem.ownership} <Label size="small">%</Label>{" "}
                </TableCell>
                <TableCell
                    className={`${(index == 4 || index == 9) &&
                        `border-secondary border-b-2 border-solid `
                        }`}
                >
                    {cooptingItem.totalDuration === "∞" ? (
                        cooptingItem.totalDuration
                    ) : (
                        <>
                            {cooptingItem.duration} / {cooptingItem.totalDuration}{" "}
                            <Label size="small">months</Label>
                        </>
                    )}
                </TableCell>
            </TableRow>
        ));
    };

    if (error) {
        // return <div>Erreur : {error}</div>;
        return (
            <Label
                size="bodySmall"
                fontWeight="regular"
                fontFamily=""
                className=" "
                message="listingConsultant.error"
            />
        );
    }

    if (!user) {
        //return <div>Chargement en cours...</div>;
        return (
            <Label
                size="bodySmall"
                fontWeight="regular"
                fontFamily=""
                className=" "
                message="listingConsultant.loading"
            />
        );
    }
    return (
        <div className="flex gap-thirdDegree">
            {/* <span>{console.log("user dans information tab : ", user)}</span> */}

            <Toaster />
            <Avatar
                fallback={user.name}
                size="large"
                src={user.avatarUrl}
                className="shrink-0 grow-0"
            />
            <div className="flex flex-col gap-secondDegree grow">
                <div className="flex justify-between">
                    <Label
                        size="h3"
                        fontWeight="bold"
                        message="consultants.dashboard.tabs.information"
                    />
                </div>
                <div className="flex gap-secondDegree">
                    <div className="flex flex-col grow justify-start gap-secondDegree">
                        {/* Email */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.email"
                            />
                            <aside className="flex gap-firstDegree items-center group">
                                <Label className=" ">
                                    {user.email}
                                </Label>
                                <Copy value={user.email} />
                                <EditInformationData
                                    inputValue={selectedEmail}
                                    setInputValue={setSelectedEmail}
                                    title="consultants.informations.email"
                                    actualLabel="consultants.informations.actualEmail"
                                    selectLabel="consultants.informations.selectEmail"
                                    data={user.email} />

                            </aside>

                        </div>

                        {/* Address */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.address"
                            />
                            <aside className="flex gap-firstDegree items-center group">
                                <Label className=" ">
                                    {user.address.suite}, {user.address.street}
                                    <br />
                                    {user.address.zipcode}, {user.address.city}
                                </Label>
                                <Copy
                                    value={
                                        user.address.suite +
                                        ", " +
                                        user.address.street +
                                        ", " +
                                        user.address.zipcode +
                                        ", " +
                                        user.address.city
                                    }
                                />
                                <EditInformationData
                                    inputValue={selectedAddress}
                                    setInputValue={setSelectedAddress}
                                    title="consultants.informations.address"
                                    actualLabel="consultants.informations.actualAddress"
                                    selectLabel="consultants.informations.selectAddress"
                                    data={user.address.suite + ", " + user.address.street + ", " + user.address.zipcode + ", " + user.address.city} />
                            </aside>
                        </div>

                        {/* Seniority */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.seniority"
                            />
                            <Label className="group ">
                                {user.seniority} <Copy value={user.seniority} />
                            </Label>
                        </div>

                        {/* RIB */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.rib" />
                            <aside className="flex gap-firstDegree items-center group">
                                <Label className=" ">
                                    {user.rib}
                                </Label>
                                <Copy value={user.rib} />
                                <EditInformationData
                                    inputValue={selectedRIB}
                                    setInputValue={setSelectedRIB}
                                    title="consultants.informations.rib"
                                    actualLabel="consultants.informations.actualRIB"
                                    selectLabel="consultants.informations.selectRIB"
                                    data={user.rib} />

                            </aside>
                        </div>

                        {/* BIC */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.bic" />

                            <aside className="flex gap-firstDegree items-center group">
                                <Label className=" ">
                                    {user.bic}
                                </Label>
                                <Copy value={user.bic} />
                                <EditInformationData
                                    inputValue={selectedBIC}
                                    setInputValue={setSelectedBIC}
                                    title="consultants.informations.bic"
                                    actualLabel="consultants.informations.actualBIC"
                                    selectLabel="consultants.informations.selectBIC"
                                    data={user.bic} />
                            </aside>
                        </div>

                        {/* Data privacy */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.dataPrivacy"
                            />
                            <Sheet open={openRGPD} onOpenChange={setOpenRGPD}>
                                <SheetTrigger className="w-min">
                                    <Button
                                        text="global.panelOpening.showMore"
                                        className="w-min"
                                        style="dressedGhost"
                                    />
                                </SheetTrigger>
                                <SheetContent>
                                    <SheetHeader
                                        title={
                                            "consultants.informations.dataDeletionInfo.panel.title"
                                        }
                                    />
                                    <SheetSlot className=" flex ">
                                        <DataDeletion />
                                    </SheetSlot>
                                    <SheetFooter
                                        buttonOutlineText={"global.actions.cancel"}
                                        buttonDressedText={"global.actions.request"}
                                        warning={
                                            "consultants.informations.dataDeletionInfo.warning.dataDeletionRequest"
                                        }
                                        consent={
                                            "consultants.informations.dataDeletionInfo.consent.beforeDataDeletion"
                                        }
                                        onClickOutline={() => setOpenRGPD(false)}
                                        onClickDressed={(checkboxIsChecked) => {
                                            console.log(
                                                "user ask data deletion, consent done : ",
                                                checkboxIsChecked
                                            );
                                        }}
                                    />
                                </SheetContent>
                            </Sheet>
                        </div>
                    </div>
                    <Separator orientation="vertical" />
                    <div className="flex flex-col grow-5 justify-start gap-secondDegree ">
                        {/* CooptedBy */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.cooptedBy"
                            />
                            <Label className="group">
                                {user.cooptedBy} <Copy value={user.cooptedBy} />
                            </Label>

                        </div>

                        {/* Profile */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.profile"
                            />
                            <aside className="flex gap-secondDegree items-center">
                                <Label className="group h-full">
                                    {user.profil} <Copy value={user.profil} />
                                </Label>

                            </aside>



                        </div>

                        {/* Active cooptations */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.activeCooptation"
                            />
                            <span>
                                <Label message="consultants.informations.commissionRate" />
                            </span>
                            <ul className="text-body">
                                <li
                                    className={
                                        (user.coopting.length > 1
                                            ? "text-primary "
                                            : "text-disabled ") +
                                        (user.coopting.length <= 5 ? "font-bold" : "font-regular")
                                    }
                                >
                                    1 - 5{" "}
                                    <Label
                                        size="body"
                                        message="consultants.informations.consulantLowerCase"
                                    />{" "}
                                    : 5%
                                </li>
                                <li
                                    className={
                                        (user.coopting.length > 5
                                            ? "text-primary "
                                            : "text-disabled ") +
                                        (5 < user.coopting.length && user.coopting.length <= 10
                                            ? "font-bold"
                                            : "font-regular")
                                    }
                                >
                                    6 - 10{" "}
                                    <Label message="consultants.informations.consulantLowerCase" />{" "}
                                    : 10%
                                </li>
                                <li
                                    className={
                                        (user.coopting.length > 10
                                            ? "text-primary "
                                            : "text-disabled ") +
                                        (user.coopting.length > 10 ? "font-bold" : "font-regular")
                                    }
                                >
                                    11+{" "}
                                    <Label message="consultants.informations.consulantLowerCase" />{" "}
                                    : 15%
                                </li>
                            </ul>

                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="bg-transparent w-[25%] h-auto py-firstDegree" />
                                        <TableHead className="py-2">
                                            <Label message="global.newCooptation.table.regime" />
                                        </TableHead>
                                        <TableHead className="py-2">
                                            <Label message="global.newCooptation.table.ownership" />
                                        </TableHead>
                                        <TableHead className="py-2">
                                            <Label message="global.newCooptation.table.duration" />
                                        </TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>{renderCooptingRows()}</TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
