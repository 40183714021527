/* eslint-disable react/prop-types */

import Stars from "./Stars";
import Edit from "@mui/icons-material/Edit";
import {useState} from "react"
import AddSkill from "./AddSkill"
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Button } from "@/components/molecules/buttons/button";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
const defaultSkill = "None";
export default function PrintSkill({ skill = defaultSkill, setSkills, className, canEdit=true}) {
  const [showForm, setShowForm] = useState(false);
  return (
    <motion.div
      initial={{
        scale: 0.9,
      }}
      animate={{
        scale: 1,
      }}
      className="w-full"
    >
      {!showForm && (
        <div
          className={cn(
            " flex flex-row  place-content-end items-center gap-firstDegree w-full text-body ",
            className
          )}
        >
          <div className=" basis-1/2 flex place-content-end truncate w-auto">
            <span className="truncate w-auto">{skill.title}</span>{" "}
          </div>
          <Separator orientation="vertical" className="bg-black" />
          <Stars
            totalStars={5}
            indexSelected={skill.level}
            allowChanges={false}
          />
          {canEdit && (
            <Button
              showLeftIcon={true}
              leftIcon={<Edit className="text-primary" />}
              padding="small"
              style="ghost"
              showText={false}
              onClick={() => setShowForm(true)}
            />
          )}
        </div>
      )}
      <AddSkill
        skill={skill}
        showForm={showForm}
        setShowForm={setShowForm}
        setSkills={setSkills}
        editingSkill={true}
      />
    </motion.div>
  );
}
