/* eslint-disable react/prop-types */
import { Button } from "@/components/molecules/buttons/button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import { useState } from "react";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
const defaultItems = [
    "Master informatique",
    "Licence informatique",
    "Bac STMG ",
    "Licence informatique",
];
const experience = "consultants.statement.prospectConsultantPanel.experience";

export default function SectionsCvOverview({
    items = defaultItems,
    title = experience,
    hideAllOverview,
    showComponent,
    showingInProspect = true
}) {
    const hider = () => {
        hideAllOverview(true);
        showComponent(true);
    };
    //   console.log(items);
    return (
        <motion.div variants={item}>
            <div
                className={`${showingInProspect ? '' : ''} py-paddingTopAndBottom px-paddingSmallLeftAndRight 
      flex flex-row gap-firstDegree bg-medium 
      shadow-md rounded-sm border-l-[1px] border-l-solid  w-full `}
            >
                {/* flex flex-row place-content-end */}
                <div className="flex flex-row place-content-end items-center  py-firstDegree truncate basis-1/3 ">
                    <Label message={title} size="body" fontWeight="bold" />
                </div>
                <div className="flex ">
                    <Separator orientation="vertical" className="bg-black" />
                </div>
                <div className="flex flex-col gap-firstDegree place-content-center text-bodySmall truncate basis-1/2">
                    {items &&
                        items.map((item, index) => {
                            return (
                                <span className="truncate" key={index}>
                                    {item}
                                </span>
                            );
                        })}
                </div>
                <div className="grid items-center">
                    <Button
                        showLeftIcon={true}
                        leftIcon={<ArrowForwardIcon fontSize="small" />}
                        showText={false}
                        text="global.actions.continue"
                        sizePadding="" // Utilisez "small" pour un padding plus petit
                        style="ghost"
                        className="font-bold"
                        onClick={() => hider()} // Options: "fill", "outline", "ghost", "dressedGhost"
                    />
                </div>
            </div>
        </motion.div>
    );
}
