/* eslint-disable react/prop-types */
// TableHead.jsx
/**
 * TableHead Component
 * @component
 *
 * @param {Array} tableHeadData - An array of objects representing the table head data.
 * @param {number} indexContainingIcon - Index of the cell where the icon should be displayed.
 * @param {JSX.Element} icon - Icon element to be displayed in the specified cell.
 * @param {number} indexWithSpecificColor - Index of the cell with a specific background color.
 * @param {string} colorForTheSpecificIndex - Background color for the cell at the specified index.
 * @param {string} colorsOfAllcells - Background color for all other cells.
 *
 * @example
 * // Usage of this component:
 * <TableHead
 *   tableHeadData={[
 *     { employerCost: "consultants.statement.overflow.tableHead.employerCost" },
 *     { customerPaiment: "consultants.statement.overflow.tableHead.customerPaiment" },
 *     { cooptation: "consultants.statement.overflow.tableHead.cooptation" },
 *     { overflow: "consultants.statement.overflow.tableHead.overflow" },
 *   ]}
 *   indexContainingIcon={1}
 *   icon={<MovingIcon className="mr-2" />}
 *   indexWithSpecificColor={2}
 *   colorForTheSpecificIndex="primaryLinear"
 *   colorsOfAllcells="grey"
 * />
 */

import DataCell from "../../molecules/data-cell/DataCell";
import { cn } from "@/lib/utils";
const tablehead = [
  { employerCost: "consultants.statement.overflow.tableHead.employerCost" },
  {
    customerPaiment: "consultants.statement.overflow.tableHead.customerPaiment",
  },
  { cooptation: "consultants.statement.overflow.tableHead.cooptation" },
  { overflow: "consultants.statement.overflow.tableHead.overflow" },
];
//primaryLinear
//<MovingIcon className="mr-2" />
//grey
const TableHead = ({
  tableHeadData = tablehead,
  indexContainingIcon,
  icon,
  indexWithSpecificColor,
  colorForTheSpecificIndex,
  colorsOfAllcells,
  className,
  classNameForCell,
}) => {
  return (
    <>
      <div
        className={cn(
          "flex flex-row gap-betweenText my-betweenText w-full",
          className
        )}
      >
        <DataCell
          data=""
          border="none"
          background="transparent"
          padding="default"
          contentType="text"
          className="w-full"
        />

        {tableHeadData.map((header, index) => (
          <DataCell
            key={index}
            data={Object.values(header)[0]}
            border="top"
            background={
              index === indexWithSpecificColor
                ? colorForTheSpecificIndex
                : colorsOfAllcells
            }
            className={cn(
              "bold py-[12px] 2xl:h-[36px] h-[28px] w-full",
              classNameForCell
            )}
            padding="default"
            contentType="text"
            sizeText="h7"
            textColor={index === indexWithSpecificColor && "white"}
            icon={index === indexContainingIcon && icon}
          />
        ))}
      </div>
    </>
  );
};

export default TableHead;
