/* eslint-disable react/prop-types */

import { Button } from "@/components/molecules/buttons/button";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";

import { useState } from "react";
import { validateFormData } from "./validateForm";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextArea from "@/components/molecules/inputs/TextArea";
import { useId } from "react";

export default function AddDescription({
  description,
  showForm,
  setShowForm,
  editingDescription = false,
  setDescriptions,
  setEditingDescription,
}) {
 
  const form = useForm();
  const today = new Date()
  const id=useId()
  const [formData, setFormData] = useState({
    description: description ? description.description : "",
    date: description ? description.date : today,
    id: description ? description.id : id
  });
  const [erros, setErros] = useState({
    description: "",
    
  });
  const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
  const handleChange = (e, name) => {
    const { value } = e.target;

    // console.log(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // console.log(formData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateFormData(formData, setErros);

    setlistOfInvalidInput(isValid);
    if (isValid.length == 0) {
      console.log("Form is valid", formData);
     if (editingDescription) {
       // Si nous sommes en mode édition, mettez à jour la description existante
       
       setDescriptions((prevData) =>
         prevData.map((item) => (item.id === description.id ? formData : item))
       );
     } else {
       // Sinon, ajoutez simplement la nouvelle description à la liste
    //    console.log("id ",id)
       setDescriptions((prevData) => [...prevData, formData]);
     }

      setShowForm(false);
    } else {
      console.log("invalid form");
      console.log("value invalid", isValid);
      console.log("form data", formData);
    }
  };
  const handleCancel = () => {
    setEditingDescription(false);
    setShowForm(false);
  };
   const handleDelete = () => {
     setShowForm(false);
     if (description) {
       // Supprimer la certification de la liste
       setDescriptions((prevData) =>
         prevData.filter((item) => item.id !== description.id)
       );
     }
   };
  return (
    <div className="flex flex-col ">
      {showForm && (
        <div className="w-full">
          <Form {...form}>
            <form
              onSubmit={handleSubmit}
              className={` shadow-md bg-lighter  border-sm border-medium  p-secondDegree`}
            >
              <TextArea
                value={formData.description}
                onChange={(e) => handleChange(e, "description")}
                border={
                  listOfInvalidInput.includes("description")
                    ? "border-error"
                    : ""
                }
                placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.description"
                rows={4} // Nombre de lignes par défaut
                cols={40} // Nombre de colonnes par défaut
                // Ajoutez d'autres attributs ou styles au besoin
              />
              <div className="flex flex-row gap-[12px] items-center w-full">
                {editingDescription && (
                  <Button
                    onClick={handleDelete}
                    showLeftIcon={true}
                    leftIcon={
                      <DeleteIcon className="text-primary cursor-pointer" />
                    }
                    style="ghost"
                  />
                )}
                <Button
                  showLeftIcon={true}
                  leftIcon={<ClearIcon fontSize="small" />}
                  showText={true}
                  text="global.actions.cancel"
                  sizePadding="default" // Utilisez "small" pour un padding plus petit
                  style="outline"
                  className="font-bold border-primary w-full"
                  onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                />
                <Button
                  showLeftIcon={true}
                  leftIcon={<SaveIcon fontSize="small" />}
                  showText={true}
                  text="global.actions.save"
                  sizePadding="default" // Utilisez "small" pour un padding plus petit
                  style="fill"
                  className="font-bold w-full"
                  type="submit" // Options: "fill", "outline", "ghost", "dressedGhost"
                />
              </div>
            </form>
          </Form>
        </div>
      )}
    </div>
  );
}
