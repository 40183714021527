import { useEffect } from 'react';
import useLogout from './uselogout';

const Logout = () => {
  const logout = useLogout();

  useEffect(() => {
    logout(); // Déclenche la déconnexion dès le montage du composant
  }, [logout]);

  return null; // Aucune interface utilisateur n'est rendue
};

export default Logout;
