/* eslint-disable react/prop-types */
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRef, useEffect } from "react";
import { Form } from "@/components/organisms/forms/form";
import { translationsPaths } from "@/utils/translationPath";
import { validateFormData } from "./validateForm";
import { cn } from "@/lib/utils";
import { getStatisticValue } from "@/utils/transformUserData";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { Separator } from "@/components/atoms/divider/separator";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { useAuth } from "@/context/AuthContext";
import FormFieldContent from "../../../organisms/forms/FormFieldContent";

export default function EditBills({
  dataToEdit = ["empty"],
  activeUser,
  isButtonSaveClicked = false,
  setIsSaveClicked,
  setisErrorInForm,
  setChange,
  itemTodelete,
  newBillIsClicked = false,
  setBillIsClicked,
  selectedYear,
  setIsEditing
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [actualtextInDelete, setActuatlTextIndelete] = useState(
    "global.actions.delete"
  );
  const { user } = useAuth();
  // const [amountFor, setAmount] = useState(null)
  // const [tJM, setTJM] = useState(null);
  // const [days, setDays]=useState(null)

  //const { activeUser } = useConsultantContext();
  const margin = selectedYear
    ? getStatisticValue(activeUser, selectedYear, "s42Margin")
    : "";
  const tjm = selectedYear
    ? getStatisticValue(activeUser, selectedYear, "dailyRateConsultant")
    : "";
  const form = useForm();
  //const customers = getClientsForUser(activeUser);

  const buttonRef = useRef(null);
  // const { toast } = useToast();

  const [formData, setFormData] = useState({
    consultant: activeUser ? activeUser.name : "",
    date: "",
    customer: dataToEdit ? dataToEdit[1] : "",
    billNumber: dataToEdit ? dataToEdit[2] : "",
    days: dataToEdit ? dataToEdit[3] : 0,
    amount: dataToEdit ? dataToEdit[4] : 0,
    tJM: dataToEdit ? tjm : 0,
    marge: dataToEdit ? margin : 0,
  });

  const [erros, setErros] = useState({
    consultant: "",
    date: "",
    customer: "",
    billNumber: "",
    days: "",
    amount: "",
    tJM: "",
    marge: "",
  });
  const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);

  useEffect(() => {
    const handleButtonClick = () => {
      // simulate the click on button submit so that the form can be sumitted
      buttonRef.current.click();

    //   console.log("button Editbills is cliked");
    };
    isButtonSaveClicked && handleButtonClick(); // Appelé lorsque le composant est monté
  }, [isButtonSaveClicked]);

  const handleChange = (e) => {
    setIsEditing(true)
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // console.log("form data", formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Faire quelque chose avec les données du formulaire
    //Send the data to the Backend
    const isValid = validateFormData(formData, setErros);
    setlistOfInvalidInput(isValid);

    if (isValid.length == 0) {
      // Envoyez les données au backend ou effectuez d'autres actions
      if (user.role == "admin") {
        setFormData({
          ...formData,
          amount: formData.tJM * formData.days + parseFloat(formData.marge),
        });
      } else {
        setFormData({ ...formData, amount: formData.tJM * formData.days });
      }

       console.log("formdata", formData);
      setIsSaveClicked(true);
      setisErrorInForm(false);
      setIsEditing(false)
    } else {
      setisErrorInForm(true);

       console.log("invalid form");
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setActuatlTextIndelete("global.actions.confirmDelete");
    if (actualtextInDelete === "global.actions.confirmDelete") {
      console.log("Send this id of facture to Back", formData.billNumber);
      setIsDeleting(false);
      setActuatlTextIndelete("global.actions.delete");
      setChange(false);
      itemTodelete(formData.billNumber);
      setBillIsClicked && setBillIsClicked(false);
    }
  };
  const makeAddition = (a, b) => {
    
    if (typeof b === "string") {
      const trimB = b.trim();
      if (trimB !== "") {
        b = parseFloat(trimB);
      }
    }
   
    //const aFloat=a.trim() !== "" ? parseFloat(a) : 0;
    //const bFloat = b.trim() !== "" ? parseFloat(b) : 0; 

    return a + b;

    
  }

  const baseClassForInput =
    "rounded-sm border-disabled border-[1px] p-firstDegree text-bodySmall";

  return (
    <div className="p-secondDegree">
      <Form {...form}>
        <form className="w-full h-[400px]" onSubmit={handleSubmit}>
          <FormFieldContent
            form={form}
            fieldName="consultant"
            inputBalise={
              <input
                type="text"
                name="consultant"
                readOnly={true}
                value={formData.consultant}
                onChange={handleChange}
                className={cn(
                  baseClassForInput,
                  listOfInvalidInput.includes("consultant") && "border-error"
                )}
              />
            }
            labelText={translationsPaths.consultant}
          />

          <FormFieldContent
            form={form}
            fieldName="date"
            inputBalise={
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className={cn(
                  baseClassForInput,
                  listOfInvalidInput.includes("date") && "border-error"
                )}
              />
            }
            labelText={translationsPaths.date}
          />

          <FormFieldContent
            form={form}
            fieldName="customer"
            inputBalise={
              <input
                type="text"
                name="customer"
                value={formData.customer}
                onChange={handleChange}
                className={cn(
                  baseClassForInput,
                  listOfInvalidInput.includes("customer") && "border-error"
                )}
              />
            }
            labelText={translationsPaths.customer}
          />
          {/* <FormFieldContent form={form} fieldName='customer'
             inputBalise={<UniqueSelect type="text" dataToSelect={customers} isSearchable={true} name='customer' onChange={handleChange} className={cn(baseClassForInput, listOfInvalidInput.includes('customer') && 'border-error')} />}
                      labelText={translationsPaths.customer} />
                           */}

          <FormFieldContent
            form={form}
            fieldName="billNumber"
            inputBalise={
              <input
                type="text"
                name="billNumber"
                value={formData.billNumber}
                onChange={handleChange}
                className={cn(
                  baseClassForInput,
                  listOfInvalidInput.includes("billNumber") && "border-error"
                )}
              />
            }
            labelText={translationsPaths.billNumber}
          />

          <FormFieldContent
            form={form}
            fieldName="days"
            inputBalise={
              <input
                type="number"
                name="days"
                value={formData.days}
                min={0}
                onChange={handleChange}
                className={cn(
                  baseClassForInput,
                  listOfInvalidInput.includes("days") && "border-error"
                )}
                step="1"
              />
            }
            labelText={translationsPaths.days}
          />

          <FormFieldContent
            form={form}
            fieldName="tJM"
            inputBalise={
              <input
                type="number"
                name="tJM"
                value={formData.tJM}
                min={0}
                onChange={handleChange}
                className={cn(
                  baseClassForInput,
                  listOfInvalidInput.includes("amount") && "border-error"
                )}
              />
            }
            labelText={"consultants.statement.overview.dailyRateConsultant"}
          />

          <FormFieldContent
            form={form}
            fieldName="marge"
            inputBalise={
              <input
                type="number"
                name="marge"
                min={0}
                value={formData.marge}
                onChange={handleChange}
                className={cn(
                  baseClassForInput,
                  listOfInvalidInput.includes("amount") && "border-error"
                )}
              />
            }
            labelText={"consultants.statement.overview.s42Margin"}
          />
          {/* faire un petit automatique on multplie le nb*TJM vue par consultant . coté admin rajouté la marge*/}

          <FormFieldContent
            form={form}
            fieldName="amount"
            inputBalise={
              <input
                type="text"
                name="amount"
                readOnly={true}
                value={
                  user.role == "admin"
                    ? makeAddition((formData.days * formData.tJM),formData.marge)
                    : formData.days * formData.tJM
                }
                onChange={handleChange}
                className={cn(
                  baseClassForInput,
                  "bg-hover",
                  listOfInvalidInput.includes("amount") && "border-error"
                )}
              />
            }
            labelText={translationsPaths.amount}
          />

          {/* //consultants.statement.billsDetails.delete */}

          <button
            type="submit"
            //onClick={handleButtonClick}
            ref={buttonRef}
            // onClick={(e)=>e.preventDefault()}
          ></button>
        </form>
      </Form>
      {!newBillIsClicked && (
        <div className="w-full  flex  flex-row gap-3 mt-0 ">
          <div className="w-[90px] grid justify-items-end items-end  h-full py-firstDegree text-h7 font-bold">
            <Label message="global.actions.action" size="small" />
            {/* <span>actions</span> */}
          </div>

          <div className="relative w-[3%] h-full  grid justify-items-center ">
            <Separator
              orientation="vertical"
              className="-mt-0 h-full w-[1px]"
            />

            <div
              className={cn(
                "border-disabled   ml-5 h-full flex items-center  gap-firstDegree w-[370px]"
              )}
            >
              {/* <DeleteSweepIcon className='text-error' />
              <Label   message="consultants.statement.billsDetails.delete" size="h6" className="text-error"/>  */}
              <Label
                message="global.actions.errorText"
                className={`${isDeleting ? "block" : "hidden"} text-bodySmall`}
              />

              <Button
                showLeftIcon={true}
                leftIcon={
                  <DeleteSweepIcon className="text-error" fontSize="small" />
                }
                showRightIcon={true}
                sizePadding="firstDegree" // Utilisez "small" pour un padding plus petit
                style="ghost"
                className={`${
                  isDeleting ? "" : ""
                }  text-bodySmall text-error h-full`}
                onClick={handleDelete} // Options: "fill", "outline", "ghost", "dressedGhost"
              >
                {" "}
                <Label message={actualtextInDelete} size="small" />
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* <Toaster /> */}
    </div>
  );
}
