/* eslint-disable react/prop-types */
import { Label } from "@/components/atoms/texts/label";
import { Separator } from "@/components/atoms/divider/separator";
import { useConsultantContext } from "@/context/ConsultantContext";
import ListOfEditableOverview from "@/components/organisms/ListOfEditableOverview";
import { useState, useEffect } from "react";
import TableOverflow from "@/components/organisms/TableOverflow";
import Filter from "@/components/organisms/Filter";
import { useAuth } from "@/context/AuthContext";

import LineChartComp from "@/components/organisms/charts/LineChartForStatementTab";
import BillsMain from "@/components/macro-organisms/panels/Bills/BillsMain";
import { getUniqueYearsForUser } from "@/utils/transformUserData";
import SkeletonHandler from "@/components/templates/skeletons/SkeletonHandler";
// import userConnectedData from "./useConnectedUserData";
import { Toaster } from "@/components/organisms/feedback/toaster";
import useStatementData from "@/hooks/consultant-hooks/useStatementData";
import { use } from "i18next";
import { useParams } from "react-router-dom";

export default function StatementTab() {
    const { id } = useParams();
    const { userConnected, error } = useStatementData(id);

    const { user } = useAuth();
    //const { activeUser } = useConsultantContext()
    const consultantContext = useConsultantContext();
    // const { userConnected, error } = userConnectedData();
    const [selectedYear, setSelectedYear] = useState("");

    useEffect(() => {
        // This block will run when activeUser changes
        console.log("Selected year is", selectedYear);

        // Add any logic to handle the update here
    }, [selectedYear]);

    if (!userConnected || error) {
        return (
            <div className="grid grid-cols-2">
                <SkeletonHandler name={"overview"} />
                <SkeletonHandler name={"overflow"} />
            </div>
        );
    }
    const { activeUser } = consultantContext || {};

    const userProcessed = user.role == "admin" ? activeUser : userConnected;

    const years = getUniqueYearsForUser(userProcessed);
    // console.log('user role', user.role)
    //   console.log("user Active", userProcessed);
    //   console.log("user years", years);
    // Add useEffect or similar logic to handle updates

    //h-[90svh] w-full flex flex-col gap-1"
    return (
        <div className="h-[100%] w-[100%] flex flex-col gap-1 statementTab ">
            <div className=" flex flex-row w-full justify-between h-[47px]">
                <div className="w-14 lg:w-44"></div>
                <Filter items={years} informSelectedItem={setSelectedYear} />

                <BillsMain years={years} activeUser={userProcessed} />
            </div>
            <div className=" flex  gap-1 ">
                {/*  */}
                <div className="flex flex-col  ">
                    <div className=" flex flex-col mb-firstDegree  ">
                        <Label
                            size="h2"
                            fontWeight="bold"
                            fontFamily=""
                            message="consultants.statement.overview.overview"
                            color="black"
                            className="font-700"
                        />
                        {user.role == "admin" && (
                            <Label
                                size="small"
                                fontWeight=""
                                fontFamily=""
                                message="consultants.statement.overview.doubleTapToEdit"
                                color="black"
                                className=""
                            />
                        )}
                    </div>
                    <ListOfEditableOverview
                        year={selectedYear}
                        userActive={userProcessed}
                        className="w-[300px]"
                    />
                </div>
                {/* <div> */}
                <Separator
                    className={`${user.role == "admin"
                            ? "2xl:h-[118%] 2xl:mx-8 mx-4"
                            : "2xl:h-[100%] mx-8 "
                        } relative  h-[730px] w-[1px] `}
                    orientation="vertical"
                    color="secondaryBlue"
                    blur=""
                />
                {/* </div> */}
                <div className=" w-[100%] flex flex-col ">
                    <Label
                        size="h2"
                        fontWeight="bold"
                        fontFamily=""
                        message="consultants.statement.overflow.overflowFluctuation"
                        color="black"
                        className=""
                    />
                    <TableOverflow user={userProcessed} year={selectedYear} />

                    <div className="w-[100%]  max-h-[250px] h-full  bg-lighter">
                        <LineChartComp
                            year={selectedYear}
                            user={userProcessed}
                            className=""
                        />
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
    );
}
