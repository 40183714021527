import React, { useState } from "react";
import { FaStar } from "react-icons/fa";

const Star = ({ totalStars , informSelectedIndex, indexSelected=0,allowChanges=true, ...props}) => {
  const [selectedStars, setSelectedStars] = useState(indexSelected);

  const handleStarClick = (index) => {
    if (allowChanges) {
      setSelectedStars(index + 1);
      informSelectedIndex(index + 1)
    }
  };

  return (
    <div className="flex " {...props}>
      {[...Array(totalStars)].map((_, index) => (
        <FaStar
          key={index}
          value={selectedStars}
          onClick={() => handleStarClick(index)}
          style={{
            cursor: allowChanges && "pointer",
            fontSize: 25,
          }}
          className={`${
            (index < selectedStars ) ? "text-primary" : "text-grey"
          } border-primary `}
        />
      ))}

      {/* <p>{selectedStars}</p> */}
    </div>
  );
};

export default Star;
