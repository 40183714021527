import { getUserById } from '@/services/consultant-services/StatementData';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ConsultantContext = createContext();

export function useConsultantContext() {
  return useContext(ConsultantContext);
}

export const ConsultantProvider = ({ children }) => {
  const [showDetailsAboutConsultant] = useState(false); // a ne plus utiliser
  const [currentClicked, setCurentClicked] = useState(''); // a ne plus utiliser
  const [activeUser, setActiveUser] = useState(['']);
  const [currentTableCellHovered, setCurrentTableCellHovered] = useState(null); // a ne plus utiliser

// if url contain id and activerUser is not setted then set activeUserBy(UrlID) and set currentClickedBy(UrlId)
  const toggleShowDetails = (showDetails, user) => {
    setActiveUser(user)
    console.log(user, " is received")
  };
  // // Effet pour définir l'utilisateur actif et le nom cliqué basé sur l'ID de l'URL
  // useEffect(() => {
  //   if (id && (!activeUser[0] || activeUser[0] === "")) {
  //     const user = getUserById(id); // Fonction hypothétique pour obtenir un utilisateur par ID
  //     if (user) {
  //       console.log("user set by url id : ", id)
  //       setActiveUser(user);
  //       setCurentClicked(user.id);
  //     }
  //   }
  // }, [id, activeUser, getUserById]);
  return (
    <ConsultantContext.Provider value={{ 
      showDetailsAboutConsultant, 
      toggleShowDetails, 
      currentClicked, setCurentClicked, 
      activeUser, setActiveUser,
      currentTableCellHovered, setCurrentTableCellHovered }}>
      {children}
    </ConsultantContext.Provider>
  );
};
