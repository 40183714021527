import React, { useState } from "react";

import Badge from '@mui/material/Badge';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { ThemeProvider } from '@mui/material/styles';
import theme from './colors/theme';
import { createTheme } from "@mui/material";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/molecules/popover"
import { Label } from './texts/label';
import useNotifications from '@/hooks/global-hooks/useNotification';
import Notification from '../organisms/notification/Notification';
import { Button } from '../molecules/buttons/button';
import { useConsultantContext } from '@/context/ConsultantContext';
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";



function NotificationIcon() {
  const { notifications, error } = useNotifications();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isOpen, setIsOpen]= useState(false);

  const handleClick = () => {
    setIsOpen(false)
    navigate("/consultant")
  }


  if (error || (!notifications)) {
    return <Label size='bodySmall' fontWeight='regular' fontFamily='' className=" " message='error.fetchUnsuccessfull'>error </Label>;
  }

  return (
    <Popover onOpenChange={setIsOpen} open={isOpen}>
      <PopoverTrigger>
        <Badge color="pink" size="small" badgeContent={notifications.length} >
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path d="M12.9993 20H8.99932M1.29316 4.81989C1.27882 3.36854 2.0613 2.01325 3.32538 1.3M20.7015 4.8199C20.7159 3.36855 19.9334 2.01325 18.6693 1.3M16.9993 7C16.9993 5.4087 16.3672 3.88258 15.242 2.75736C14.1167 1.63214 12.5906 1 10.9993 1C9.40802 1 7.8819 1.63214 6.75668 2.75736C5.63146 3.88258 4.99932 5.4087 4.99932 7C4.99932 10.0902 4.21979 12.206 3.34899 13.6054C2.61445 14.7859 2.24718 15.3761 2.26065 15.5408C2.27556 15.7231 2.31418 15.7926 2.4611 15.9016C2.59378 16 3.19191 16 4.38818 16H17.6105C18.8067 16 19.4049 16 19.5375 15.9016C19.6845 15.7926 19.7231 15.7231 19.738 15.5408C19.7515 15.3761 19.3842 14.7859 18.6497 13.6054C17.7789 12.206 16.9993 10.0902 16.9993 7Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Badge>
      </PopoverTrigger>
      <PopoverContent className=" flex flex-col max-h-[300px] gap-secondDegree w-96 bg-medium py-paddingTopAndBottom px-paddingLeftAndRight">
        <div className='max-w-full overflow-auto space-y-firstDegree '>
          {notifications.map((notification, index) => (
            <Notification key={index} notification={notification} showDate={false} />
          ))}
        </div>
        {user.role == "admin" &&
          <Button style="ghost" className="font-bold" onClick={handleClick} text="global.actions.showMore"></Button>
        }
      </PopoverContent>
    </Popover>



  )
}

export default NotificationIcon