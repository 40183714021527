/* eslint-disable react/prop-types */

import { Separator } from "@/components/atoms/divider/separator";
import { Label } from "@/components/atoms/texts/label";
import { formatDateString } from "@/utils/formatDateString";

export default function PrintValuation({ historicalLine }) {
  const { date, ebit, companyValuation, actionNumber, sharePrice } =
    historicalLine;

  return (
    <div className="w-full h-17 bg-medium flex flex-row gap-3  text-body p-secondDegree rounded-sm shadow-md">
      <div className="grid justify-center items-center">
        {formatDateString(date)}
      </div>
      <div className="relative h-17 items-center grid justify-center ">
        <Separator orientation="vertical" className=" -mt-0 h-full w-[1px]" />
      </div>
      <div className=" flex flex-col gap-firstDegree">
        <div>
          <Label
            message="consultants.statement.valuationPanel.ebit"
            className="font-semibold"
            size="body"
          />
          <span> :  {ebit} €</span>
        </div>
        <div>
          <Label
            message="consultants.statement.valuationPanel.companyValuation"
            className="font-semibold"
            size="body"
          />
           <span> :  {companyValuation} €</span>
        </div>
        <div>
          <Label
            message="consultants.statement.valuationPanel.actionNumber"
            className="font-semibold"
            size="body"
          />
          <span> :  {actionNumber}</span>
        </div>
        <div>
          <Label
            message="consultants.statement.valuationPanel.sharesPrice"
            className="font-semibold"
            size="body"
          />
          <span> :  {sharePrice}</span>
        </div>
      </div>
    </div>
  );
}
