import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetSlot,
} from "@/components/templates/sheet";
import { useState, useEffect } from "react";
import { Button } from "@/components/molecules/buttons/button";
import Action from "@/components/molecules/buttons/Action";
import AddTask from "@mui/icons-material/AddTask";
import useSetSkillPanelData from "./useSetSkillPanelData";
import PrintErrorMessage from "@/components/molecules/PrintErrorMessage";
import PrintFetchingData from "@/components/molecules/PrintFetchingData";
import PrintSkillAdded from "./PrintSkillAdded";
import AddNewSkill from "./AddNewSkill/AddNewSkill";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function SetSkillsPanel({ ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const { skillsAdded, error } = useSetSkillPanelData();
  const [skills, setSkills] = useState([]);
  const [changeContent, setChangeContent] = useState(false);
  const [skillName, setSkillName] = useState("");
  const [numberOfConsultant, setNumberOfConsultant] = useState(0);
  useEffect(() => {
    setSkills(skillsAdded);
  }, [skillsAdded]);
const repeatedNames = Array.from({ length: numberOfConsultant }, (_, index) => index);
  //   console.log("skills added", skillsAdded)
  //   console.log("skills ff", skills)

  if (error) return <PrintErrorMessage />;
  if (!skillsAdded) return <PrintFetchingData />;

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Action
          icon={<AddTask />}
          text="consultants.dashboard.actions.skills"
          initialVariant={isOpen ? "active" : "default"}
          {...props}
        />
      </SheetTrigger>
      <SheetContent>
        <SheetHeader title={"consultants.statement.setSkillsPanel.addSkill"} />
        <SheetSlot>
          {!changeContent ? (
            <div className=" flex flex-col gap-secondDegree py-paddingTopAndBottom px-paddingLeftAndRight w-full ">
              <AddNewSkill setSkills={setSkills} />
              <Label
                message="consultants.statement.setSkillsPanel.activeSkills"
                fontWeight="bold"
                className="justify-center flex"
              />
              <Separator />
              {skills &&
                skills.map((skill, index) => {
                  return (
                    <PrintSkillAdded
                      skill={skill}
                      key={skill.id}
                      setSkills={setSkills}
                      setChangeContent={setChangeContent}
                      skills={skills}
                      setNumberOfConsultant={setNumberOfConsultant}
                      setSkillName={setSkillName}
                    />
                  );
                })}
            </div>
          ) : (
            <div className=" flex flex-col gap-secondDegree p-secondDegree">
              <div className="flex justify-start">
                <Button
                  showLeftIcon={true}
                  leftIcon={<ArrowBackIcon fontSize="small" />}
                  showText={true}
                  text="global.actions.back"
                  sizePadding="default" // Utilisez "small" pour un padding plus petit
                  style="ghost"
                  className="font-bold  "
                  onClick={() => setChangeContent(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
                />
              </div>

              <div className=" flex flex-col gap-firstDegree">
                <div className="flex flex-row gap-betweenText">
                                      <Label message="consultants.statement.prospectConsultantPanel.formPlaceHolder.skill" fontWeight='bold' />
                                      

                  <Label>{': '+ skillName}</Label>
                </div>

                {/* {to change after} */}
                {repeatedNames &&
                  repeatedNames.map((i) => <Label key={i}>Jon Doe</Label>)}
              </div>
            </div>
          )}
        </SheetSlot>
      </SheetContent>
    </Sheet>
  );
}
