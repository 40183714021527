import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import sendPdfRequest from "./sendPdfRequest";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { getCountryCode, getCountryData, getCountryDataList, getEmojiFlag } from 'countries-list'
import { continents, countries, languages } from 'countries-list'
import { useState } from "react";

import { Input } from "@/components/ui/input"
import { toast } from "@/components/organisms/feedback/use-toast";
import { Toaster } from "@/components/organisms/feedback/toaster";
import { useNavigate } from "react-router-dom";

export default function WaitPdf({ handleReset }) {
    const { confirmation, error, addTemplate } = sendPdfRequest();
    const [languageForTemplate, setLanguageForTemplate] = useState("");
    const [titleForTemplate, setTitleForTemplate] = useState("");
    const navigate = useNavigate()

    const topEuropeanLanguages = ['GB', 'DE', 'FR', 'IT', 'ES','NL'];

    // console.log("getEmojiFlag('FR') : ", getEmojiFlag('FR'));
    const addNewTemplate = () => {
        if ((languageForTemplate && titleForTemplate)) {
            navigate("../")
            addTemplate()
        
        } else {
            toast({
                variant: "warning",
                title: "error.cantBeNull",
                duration: 1500,
            })
        }


    }

    return (
        <div className='flex flex-col min-h-[55vh] justify-center items-center py-thirdDegree text-center gap-thirdDegree'>
            <Toaster />
            {confirmation
                ? <>
                    <Label message="success.done"/>
                </>
                :
                <>
                    <Label message="consultants.cv.waitingPdf" />
                    <iframe src="https://lottie.host/embed/4a4bb743-f502-4351-9ed1-71191525c1f5/CpD3cpg3EG.json" className='h-50'></iframe>
                </>
            }
            <Label message="consultants.cv.wantSaveTemplate"></Label>
            <div className="flex gap-2">
                <Select onValueChange={setLanguageForTemplate} value={languageForTemplate} >
                    <div className="flex flex-col items-start gap-1">
                        <Label className="text-primary" message="consultants.cv.mainLanguage"/>
                        <SelectTrigger className="w-[300px] bg-hoverLight">
                            <SelectValue placeholder={<Label message="global.actions.select" />}  />
                        </SelectTrigger>
                    </div>

                    <SelectContent>

                        {Object.keys(countries)
                            .filter(code => topEuropeanLanguages.includes(code))
                            .map(code => {
                                const country = countries[code];
                                const emoji = getEmojiFlag(code.toUpperCase());
                                const l = languages[country.languages[0]] && languages[country.languages[0]].name
                                // languages[country.languages[0]] && console.log("languages[country.languages[0]].name", languages[country.languages[0]].name);
                                return <SelectItem key={code} value={code}>{emoji + " " + l}</SelectItem>;
                            })}
                    </SelectContent>
                </Select>
                <div className="flex flex-col items-start gap-1">
                    <Label className="text-primary" message="consultants.cv.titleTemplate"/>
                    <Input className="w-[300px] bg-hoverLight" onChange={(e) => setTitleForTemplate(e.target.value)} value={titleForTemplate} />
                </div>
            </div>



            <div className="m-8 space-x-4">
                <Button style="ghost" onClick={handleReset} text="global.actions.reStart" className="w-fit" />
                <Button style="outline" onClick={()=>{navigate("../")}} text="global.actions.backToMain"></Button>
                <Button style="" text="global.actions.save" onClick={() => addNewTemplate()} />
            </div>

        </div>
        // sendPdfRequest
    )
}




