import * as React from "react";

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import { Label } from "../../atoms/texts/label";
import { Button } from "../../molecules/buttons/button";
import cn from 'classnames'; 
function getActionPastParticiple(action) {
    const irregulars = {
        "add": "added",
        "remove": "removed",
        "edit": "edited",
        "import": "imported",
        "generate": "generated"
        // Ajoutez d'autres verbes irréguliers au besoin
    };

    // Retourne la forme correcte du participe passé pour l'action
    return irregulars[action] || action + "ed";
}
export default function Notification({ notification, showDate = true }) {
    let message;
    switch (notification.type) {
        case "error":
            message = `Error: unable to ${notification.action} ${notification.object} for ${notification.person} from ${notification.period}`;
            break;
        default:
            message = `${notification.person} has ${getActionPastParticiple(notification.action)} the ${notification.object} for ${notification.period}.`;
            break;
    }


    const variant = {
        error: {
            borderColorClass: "border-error",
            Icon: WarningRoundedIcon,
            iconColor: "error"
        },
        information: {
            borderColorClass: "border-information",
            Icon: DoneAllRoundedIcon,
            iconColor: "info" // Assurez-vous que cette couleur existe dans votre système de design
        },
        success: {
            borderColorClass: "border-success",
            Icon: CheckCircleRoundedIcon,
            iconColor: "success"
        },
    }[notification.type] || {};

    const BorderColorClass = variant.borderColorClass;
    const Icon = variant.Icon;
    const iconColor = variant.iconColor;

    return (
        <div className={cn("flex justify-center items-center gap-firstDegree bg-lighter border-inside border rounded-sm py-firstDegree px-secondDegree ", BorderColorClass)}>
            {Icon && <Icon color={iconColor} />}
            <div className="flex flex-col w-full gap-0 leading-none">
                <Label wFull size="small" >{message}</Label>
                {showDate && <Label size="caption" fontWeight="semibold" color="grey">{notification.date}</Label>}
            </div>
            {notification.link && <Button style="pill" sizePadding="pill">More</Button>}
            <Button showText={false} showLeftIcon leftIcon={<DeleteSweepRoundedIcon />} style="ghost" sizePadding="small"></Button>
        </div>
    );
}
