/* eslint-disable react/prop-types */
import React from 'react'
import { Separator } from '@/components/atoms/divider/separator';
import { cn } from '@/lib/utils';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
    FormLabel
} from "@/components/organisms/forms/form";
import { Label } from '@/components/atoms/texts/label';

const defaultFieldName = "emptyName"
const baseClass = 'w-full my-secondDegree flex space-y-0 flex-row gap-3 h-[33px]'
const baseClassForLabel = "w-[90px] grid justify-items-end items-end  h-full py-firstDegree text-bodySmall font-bold"
const baseClassForInputs='w-[74%] h-full' 
export default function FormFieldContent({inputBalise, labelText , hasSeparator=true,form, fieldName=defaultFieldName, className=baseClass, classNameForFormLabel=baseClassForLabel, baseClassForInput=baseClassForInputs, euro=false}) {
    
    
    return (
    
                        <FormField
                            control={form.control}
                            name={fieldName}
                            
                            render={({ field }) => (
                                    <FormItem className={cn(className)} >                    
                                         <FormLabel message={labelText}  className={cn(classNameForFormLabel)} /> 
                                        {hasSeparator && <div className='relative w-[3%] h-full  grid justify-items-center '><Separator orientation="vertical" className="-mt-0 h-full w-[1px]" /></div>}
                                        <div className='flex w-full items-center gap-firstDegree h-full'>
                                            <FormControl className={cn(baseClassForInput)}>
                                        {inputBalise}
                                        </FormControl>
                                        {euro ? <Label size="body">€</Label>:<></>}
                                        </div>
                                        
                                    {/* <FormMessage /> */}
                                    </FormItem>
                              
                                     )}
                        />

          
    
  )
}
