/* eslint-disable react/prop-types */
import { formatDateString } from "@/utils/formatDateString";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const options = {
    responsive: true,
    animation: {
        animateRotate: true,
        animateScale: true,
        duration: 1000,
        easing: "linear",
        reverse: true,
    },
    plugins: {},
};

const formatDataForChart = (months, overflows) => {
    return {
        labels: months,
        datasets: [
            {
                fill: true,
                label: "Compagny valuation",
                data: overflows,
                borderColor: "rgba(247, 55, 247, 0.70)",
                borderWidth: 2,
                backgroundColor: "rgba(255, 255, 255, 0)",
                tension: 0.1,
            },
        ],
    };
};

const LineChartValuation = ({ historicalData }) => {
    const datesOnly = historicalData.map((entry) => formatDateString(entry.date));
    const companyValuation = historicalData.map(
        (entry) => entry.companyValuation
    );
    // const [chartHeight, setChartHeight] = useState(true);
    // const [t] = useTranslation('global');

    const data = formatDataForChart(datesOnly, companyValuation);
    // console.log(data);
    return (
        <div className="">
            <Line
                options={options}
                data={data}
                height={100}
                width={250}
                className=" rounded-sm"
                tension={0.3}
            />
        </div>
    );
};

export default LineChartValuation;
