"use client";
import * as React from "react"
import * as SheetPrimitive from "@radix-ui/react-dialog"
import { cva } from "class-variance-authority";
import { X } from "lucide-react"

import { cn } from "@/lib/utils"
import { Label } from "../atoms/texts/label";
import { Button } from '../molecules/buttons/button';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';

import Checkbox from '@mui/material/Checkbox';


const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal


const SheetOverlay = React.forwardRef(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
    ref={ref} />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva(
  "fixed z-50 gap-4 bg-lighter shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
  {
    variants: {
      side: {
        right:
          "inset-y-0 right-0 h-full w-3/4  data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-lg",
      },
    },
    defaultVariants: {
      side: "right",
    },
  }
)

const SheetContent = React.forwardRef(({ className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content ref={ref} className={cn(sheetVariants("right"), className,)} {...props}>
      <div className="flex flex-col h-full" >
        {children}
      </div>
    </SheetPrimitive.Content>
  </SheetPortal>
))
SheetContent.displayName = SheetPrimitive.Content.displayName


const SheetHeader = ({ title, subheader, onClose, className, ...props }) => (
  <div
    className={cn("px-secondDegree py-thirdDegree bg-primary flex justify-between items-center text-white space-x-secondDegree", className)}
    {...props}>
    <SheetClose
      onClick={onClose}
      className="cursor-pointer p-2 block">
      <X className="h-6 w-6 text-white" aria-label="Close" />
    </SheetClose>
    <div className="flex flex-col space-y-betweenText grow">
      <Label size="h2" fontWeight="semibold" message={title} color="white" />
      <Label size="small" message={subheader} color="white" />
    </div>
  </div>
);
SheetHeader.displayName = "SheetHeader";


const SheetSlot = React.forwardRef(({ className, children, ...props }, ref) => (
  <div className="flex flex-col grow overflow-scroll">{children}</div>
))
SheetSlot.displayName = "SheetSlot"

const SheetFooter = ({ warning, consent, buttonOutlineText, buttonDressedText, closeButton, className, onClickOutline, onClickDressed, ...props }) => {
  const [checked, setChecked] = React.useState(false);
  const white = "#ffffff";

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div className={cn("flex flex-col bg-primary p-secondDegree space-y-secondDegree ", className)}
      {...props}>
      {warning &&
        <div className="flex space-x-3 items-center">
          <GppMaybeOutlinedIcon className="text-warning" />
          <Label size="body" fontWeight="semibold" message={warning} color="warning"></Label>
        </div>
      }


      {consent &&
        <div className="flex space-x-3 items-center">
          <Checkbox
            id="terms"
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              color: white,
              '&.Mui-checked': {
                color: white,
              },
              padding: '0px'
            }}
          />
          <Label htmlFor="terms" size="body" message={consent} color="white" />
        </div>
      }


      <div className="flex justify-end space-x-4 overflow-x-auto">
        {closeButton &&
          <SheetClose asChild>
            <Button style="ghost" className="text-white" text="global.actions.close"/>
          </SheetClose>
        }
        {buttonOutlineText && <Button style="outline" className="text-white border-white " text={buttonOutlineText} onClick={() => onClickOutline(checked)}></Button>}
        {buttonDressedText && <Button style="dressedGhost" text={buttonDressedText} onClick={() => onClickDressed(checked)} />}


      </div>
    </div>
  )
}
SheetFooter.displayName = "SheetFooter"

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetSlot,

}

