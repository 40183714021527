const isNumeric = (str) => /^\d+$/.test(str);

const formatDateString = (inputDate) => {
  const dateObject = new Date(inputDate);
  
  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés de 0 à 11
  const year = dateObject.getFullYear();

  return `${day}/${month}/${year}`;
};



export { isNumeric, formatDateString };