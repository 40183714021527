/* eslint-disable react/prop-types */

import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/atoms/texts/label";
import Person from "@mui/icons-material/Person";
import Action from "@/components/molecules/buttons/Action";
import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import DraftOverview from "./draft/DraftOverview";
import EditConsultant from "./createConsultant/EditConsultant";
import SaveIcon from "@mui/icons-material/Save";
import AllDrafts from "./draft/AllDrafts";

import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
} from "@/components/templates/sheet";

import CreateAndUpdateCV from "./CreateAndUpdateCV";
import { ArrowBack } from "@mui/icons-material";
import Skills from "./skills/SkillsInPanel";
import { useToast } from "@/components/organisms/feedback/use-toast";

{
    /*
  consultants.statement.prospectConsultantPanel.informations
  header
  .create 
  orTakeOver
  draft
  generalInformation
  createAndUpdateCV
  canbeDoneLater
  profileTitle
  informations
  Description
  skills
  experience
  education
  certifications
  projects
  */
}

export default function ProspectConsultantPanel({ ...props }) {
    const [isNewClicked, setNewClicked] = useState(false);
    const [isOpen, setIsOpen] = useState();
    const [openCvSection, setOpenCvSection] = useState(false);
    const [draftIsClicked, setDraftIsClicked] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [showFourthStep, setShowFourthStep] = useState(false);

    const [userCreated, setUserCreated] = useState({
        name: "",
        firstName: "",
        personalEmail: "",
        gender:""
    });
    const { toast } = useToast();

    const displayToast = () => {
        toast({
            variant: "warning",
            title: "consultants.statement.prospectConsultantPanel.autosaved",
            duration: 1500,
        });
    };
    const handleOpen = () => {
        setIsOpen(!isOpen);
        setOpenCvSection(false);
        setNewClicked(false);
        setDraftIsClicked(false);
        isEditing ? displayToast() : "";

        setEditing(false);
    };

    function onCancel() {
        isEditing ? displayToast() : "";

        setIsOpen(false);
        // setActionVariant("default");
    }

    return (
        <div>
            <Sheet open={isOpen} onOpenChange={handleOpen}>
                <SheetTrigger asChild>
                    <button className="w-full">
                        <Action
                            icon={<Person />}
                            text="consultants.dashboard.actions.newProfile"
                            initialVariant={isOpen ? "active" : "default"}
                            {...props}
                        />
                    </button>
                </SheetTrigger>
                <SheetContent>
                    <SheetHeader
                        title={"consultants.statement.prospectConsultantPanel.header"}
                    // subheader="consultants.statement.vatSettings.autoSaved"
                    />
                    <SheetSlot>
                        {!isNewClicked && (
                            <div
                                className={`${!draftIsClicked ? "p-secondDegree" : "p-0"
                                    } w-full flex flex-col  gap-secondDegree `}
                            >
                                {!draftIsClicked && (
                                    <Button
                                        showLeftIcon={true}
                                        leftIcon={<AddIcon />}
                                        showText={true}
                                        text="consultants.statement.prospectConsultantPanel.create"
                                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                                        style="fill"
                                        className="w-full"
                                        onClick={() => setNewClicked(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                    />
                                )}

                                {!draftIsClicked && (
                                    <Label message="consultants.statement.prospectConsultantPanel.orTakeOver" />
                                )}

                                {/*List of drafts here AFTER*/}

                                <AllDrafts
                                    hideOthers={setDraftIsClicked}
                                    setNewIsClicked={setNewClicked}
                                    setEditing={setEditing}
                                    setIsOpen={setIsOpen}
                                // user={userCreated}
                                // setUser={setUserCreated}
                                />
                            </div>
                        )}

                        {!draftIsClicked && isNewClicked && !openCvSection && (
                            <EditConsultant
                                openCvSection={setOpenCvSection}
                                setEditing={setEditing}
                                setIsOpen={setIsOpen}
                                user={userCreated}
                                setUser={setUserCreated}
                                showFourthStep={showFourthStep}
                                setShowFourthStep={setShowFourthStep}
                            />
                        )}
                        {!draftIsClicked && openCvSection && (
                            <CreateAndUpdateCV
                                openCvSection={setOpenCvSection}
                                setDraftClicked={setDraftIsClicked}
                                setNewIsClicked={setNewClicked}
                                shoulGetSectionsData={false}
                                setShowFourthStep={setShowFourthStep}
                            // user={userCreated}
                            // setUser={setUserCreated}
                            />
                        )}
                    </SheetSlot>
                </SheetContent>
            </Sheet>
        </div>
    );
}
