import React from 'react';
import ReactDOM from 'react-dom/client';
// import AuthProvider from './context/AuthContext';
// import { createBrowserRouter, RouterProvider, Routes, Route , Navigate} from "react-router-dom";

import App from './App.jsx';
import './index.css';
import global_en from './components/atoms/texts/en/global.json';
import global_fr from './components/atoms/texts/fr/global.json';
import i18next from 'i18next';
import {I18nextProvider} from 'react-i18next';
import {ThemeProvider} from '@mui/material/styles';

import theme from './components/atoms/colors/theme';
import TraceProvider from '@/lib/tracing.jsx';

i18next.init({
    interpolation: {escapeValue: false},
    lng: 'fr',
    resources: {
        en: {
            global: global_en,
            label:"English"
        },
        fr: {
            global: global_fr,
            label:"Français"
        }
    }
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <ThemeProvider theme={theme}>
                <TraceProvider>
                    <App/>
                </TraceProvider>
            </ThemeProvider>
        </I18nextProvider>
    </React.StrictMode>
);
