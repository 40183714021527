import { newTemplateData } from '@/services/global-services/templateData';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useTemplateData from './useTemplateData';

function useManageTemplate() {
  const { templateData, error } = useTemplateData();
  const templates = !error ? templateData : [];
  const { templateID } = useParams();
  const [templateIDState, setTemplateIDState] = useState();
  const [template, setTemplate] = useState(); // Nouvel état pour stocker l'objet template

  useEffect(() => {
    const initializeTemplate = async () => {
      let foundTemplate;
      if (templateID === undefined) {
        try {
          // Créez un nouveau template et mettez à jour l'état avec son ID et l'objet
          const newTemplateId = await newTemplateData(); // Supposons que cette fonction retourne un nouvel ID de template
          foundTemplate = { templateId: newTemplateId, blockSelected: [] }; // Structure d'un nouveau template
          setTemplateIDState(newTemplateId);
          setTemplate(foundTemplate);
        } catch (error) {
          console.error("Error creating new template:", error);
        }
      } else {
        // Trouvez le template existant par son ID
        foundTemplate = templates.find(t => t.templateId === templateID);
        setTemplateIDState(templateID);
        // Si le template existe, utilisez-le; sinon, créez une structure par défaut
        setTemplate(foundTemplate || { templateId: templateID, blockSelected: [] });
      }
    };

    initializeTemplate();
  }, [ templates]);

  return { template }; // Retournez à la fois l'ID du template et l'objet template
}

export default useManageTemplate;
