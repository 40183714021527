import React from 'react';
import { Button } from '@/components/molecules/buttons/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/molecules/popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router-dom'; // Importez useLocation


import TranslateIcon from '@mui/icons-material/Translate';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import useCVTab from '../../../../../hooks/global-hooks/useCVTab';

export default function ShowMoreButton({idBlock}) {
    const location = useLocation(); // Utilisez useLocation pour accéder à l'objet de localisation
    const {deleteBlockById, duplicateBlockById, translateBlockById} = useCVTab()
    // Vérifiez si l'URL contient "generate"
    if (location.pathname.includes("generate")) {
        return null; // Ne rien rendre si l'URL contient "generate"
    }
    return (
        <div className=" absolute top-0 right-0 w-fit">
            <Popover  >
                <PopoverTrigger>
                    <Button style="ghost" showLeftIcon showText={false} leftIcon={<MoreVertIcon />} />
                </PopoverTrigger>
                <PopoverContent >
                    <div className='flex flex-col items-start'>
                        <Button style="ghost" showLeftIcon leftIcon={<TranslateIcon />} className="w-full justify-start" text="global.actions.translate" onClick={()=>{translateBlockById(idBlock)}}/>
                        <Button style="ghost" showLeftIcon leftIcon={<EditIcon />} className="w-full justify-start" text="global.actions.edit"/>
                        <Button style="ghost" showLeftIcon leftIcon={<FileCopyIcon />} className="w-full justify-start" text="global.actions.duplicate" onClick={()=>{duplicateBlockById(idBlock)}}/>
                        <Button style="ghost" showLeftIcon leftIcon={<DeleteSweepIcon />} className="w-full justify-start" text="global.actions.delete" onClick={()=>{deleteBlockById(idBlock)}}/>
                    </div>
                </PopoverContent>
            </Popover>

        </div>

    )
}