import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";

import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorMessage from "@/components/molecules/PrintErrorMessage";
import FetchingData from "@/components/molecules/PrintFetchingData";
import HandleLanguage from "./HandleLanguage";
import AddProfileTitle from "./AddProfileTitle";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import { useState,useEffect } from "react";
import PrintProfileTitle from "./PrintProfileTitle";
import ProfileAvatar from "./ProfileAvartar";
import useCVTab from "@/hooks/global-hooks/useCVTab";
const getProfileTitles = () => {
  return [{
    profileTitle: "Ingénieur DATA",
    date: "01/02/2022",
    id:"01"

  }]
}
export default function Information({
  hideAllOverview,
  showComponent,
  avatarSrc,
  setAvatarSrc,
  languages,
  setLanguages,
  user
}) {
  const { CVData, error } = useCVTab();
  const [showForm, setShowForm] = useState(false);
  const [profiles, setProfiles] = useState(getProfileTitles());
  const [editingProfile, setEditingProfile] = useState(false);


  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };

  return (
    <motion.div
      variants={item}
      className="w-full flex  flex-col gap-secondDegree"
    >
      <div className="flex items-center gap-fourthDegree">
        <ArrowBackIcon className="cursor-pointer" onClick={hider} />
        <Label
          message="consultants.statement.prospectConsultantPanel.informations"
          size="h3"
          fontWeight="bold"
        />
      </div>

      <ProfileAvatar
        avatarSrc={avatarSrc}
        setAvatarSrc={setAvatarSrc}
        name={user.name}
        firstName={user.firstName}
      />
      {/* */}
      <Separator orientation="horizontal" />
      <Label
        message="global.information.languages"
        size="h3"
        fontWeight="bold"
      />
      <HandleLanguage languages={languages} setLanguages={setLanguages} />
      <Separator orientation="horizontal" />

      <div className="flex flex-row justify-between gap-secondDegree">
        <Label
          message="consultants.statement.prospectConsultantPanel.profileTitle"
          size="h3"
          fontWeight="bold"
        />
        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)}
        />
      </div>
      {showForm && (
        <AddProfileTitle
          showForm={showForm}
          setShowForm={setShowForm}
          setProfiles={setProfiles}
          editingProfile={false}
          setEditingProfile={setEditingProfile}
        />
      )}

      {profiles &&
        profiles.map((profile, index) => {
          return (
            <PrintProfileTitle
              key={index}
              profile={profile}
              editingProfile={editingProfile}
              setEditingProfile={setEditingProfile}
              setProfiles={setProfiles}
            />
          );
        })}
    </motion.div>
  );
}
