import { Button } from "@/components/molecules/buttons/button";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import { useState } from "react";
import generateRandomId from "@/utils/randomId";
import UniqueSelect2 from "@/components/molecules/inputs/UniqueSelect2";
import { getSkillsType } from "@/services/admin-services/setSkillPanelData";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import { validateFormData } from "./validateForm";
import AddIcon from "@mui/icons-material/Add";
import { Label } from "@/components/atoms/texts/label";
export default function AddNewSkill({ setSkills }) {
    const form = useForm();

    const [formData, setFormData] = useState({
        title: "",
        type: "",
        numberOfUsage: 0,
        id: generateRandomId(),
    });

    const [erros, setErros] = useState({ title: "", type: "" });
    const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
    const handleChange = (e, name) => {
        const { value } = e.target;
        deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);

        // console.log(value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // console.log(formData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const invalids = validateFormData(formData, setErros);
        setlistOfInvalidInput(invalids);

        if (invalids.length === 0) {
            setSkills((prevData) => [formData, ...prevData]); // Ajout en tête de liste
        } else {
            console.log("value invalid nr", invalids);
        }
    };

    const handleSelectedType = (type) => {
        const name = "type"
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type,
        }));

    }
    return (
        <div className="w-full flex">
            <Form {...form} className="w-full">
                <form
                    onSubmit={handleSubmit}
                    className="w-full flex flex-col gap-secondDegree "
                >
                    <div className="flex flex-row gap-firstDegree w-full ">
                        <TextFieldInput
                            value={formData.title}
                            placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.skill"
                            className={"w-full"}
                            onChange={(e) => handleChange(e, "title")}
                            error={listOfInvalidInput.includes("title") && "error"}
                        />
                        <UniqueSelect2
                            dataToSelect={getSkillsType()}
                            className={"w-1/3"}
                            filterDataFunction={handleSelectedType}
                        />
                    </div>
                    <div className="flex flex-row gap-firstDegree items-center">

                        <Label
                            message="consultants.statement.setSkillsPanel.addingSkill"
                            size="small"
                            className="text-end flex w-full"


                        />
                        <Button
                            showLeftIcon={true}
                            leftIcon={<AddIcon />}
                            showText={true}
                            text="global.actions.add"
                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                            style="fill"
                            className="grow "
                            type="submit"
                        //onClick={() => setNewClicked(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                        />
                    </div>
                </form>
            </Form>
        </div>
    );
}
