/* eslint-disable react/prop-types */
//SEE documentation https://www.notion.so/Documentation-de-code-4d05e187093344a3803c90977e1b24f7?pvs=4 

import * as React from "react";
import { Slot } from "@radix-ui/react-slot"; // For slotting in other components or HTML elements
import { cva } from "class-variance-authority"; // For handling class name variations
import { cn } from "@/lib/utils"; // Utility for concatenating class names
import { Label } from "@/components/atoms/texts/label";

// Define button variants with default styles and responsive behaviors
const buttonVariants = cva(
  // Base classes applied to every button
  " inline-flex items-center justify-center  whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    // Style variants for the button
    variants: {
      style: {
        fill: "bg-primary  text-primary-foreground hover:bg-primary_hover", // Filled style
        outline: "border-2 border-primary text-primary hover:bg-hover", // Outlined style
        ghost: "text-primary hover:bg-hover font-semibold", // Ghost style, transparent background
        dressedGhost: "bg-medium hover:bg-grey", // Dressed ghost style with a medium background
        secondaryDressed: "cursor-pointer w-full text-left justify-start bg-secondary text-white font-bold hover:bg-gradientCustom",
        pill:"bg-medium hover:bg-grey active:bg-greyStrong rounded-sm rounded-full ",
      },
      // Padding size variants
      sizePadding: {
        default: "box-border h-12 px-paddingLeftAndRight py-paddingTopAndBottom", // Default padding
        firstDegree:"p-firstDegree",
        small: "p-betweenText", // Smaller padding
        pill:"py-betweenText px-firstDegree"
      },
    },
    // Default variants if none are specified
    defaultVariants: {
      style: "fill", // Default style is fill
      sizePadding: "default", // Default padding size is default
    },
  }
);

// Button component accepting various props for customization
const Button = React.forwardRef(({
  className, // Custom class name passed by the user
  showLeftIcon = false, // Whether to show an icon on the left
  showRightIcon = false, // Whether to show an icon on the right
  leftIcon = null, // The left icon element
  rightIcon = null, // The right icon element
  showText = true, // Whether to show text
  text, // Button text
  sizePadding = "default", // Padding size variant
  style = "fill", // Button style variant
  children,
  asChild = false, // If the button should act as a slot for another component
  onClick,
  ...props // Spread other props to the button
}, ref) => {
  const Comp = asChild ? Slot : "button"; // Use Slot if asChild is true, else use a regular button
  
  // Render the button with conditional rendering for icons and text
  return (
    <Comp
      className={cn(buttonVariants({ style, sizePadding }), className)} // Apply variant and custom classes
      ref={ref} // Forward the ref
      {...props} // Spread any other passed props
      onClick={onClick}
title={children}
    >
      {showLeftIcon && leftIcon && <span className="h-thirdDegree w-thirdDegree inline-flex items-center justify-center">{leftIcon}</span>} 
      {showText && <Label className="px-firstDegree grow " message={text}>{children}</Label>} 
      {showRightIcon && rightIcon && <span className="h-thirdDegree w-thirdDegree inline-flex items-center justify-center">{rightIcon}</span>} 
    </Comp>
  );
});
Button.displayName = "Button"; // Set display name for debugging purposes

export { Button, buttonVariants }; // Export the Button component and its variants