/* eslint-disable react/prop-types */
import { lorem } from "faker/lib/locales/cz";
import React from "react";
import AddCertification from "./AddCertification";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import { useState,useEffect } from "react";
import PrintCertification from "./PrintCertification";
import { getCertifications } from "@/services/consultant-services/prospectConsultant";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import useCVTab from "@/hooks/global-hooks/useCVTab";

export default function Certification({
  hideAllOverview,
  showComponent,
  certifications,
  setCertifications,
}) {
  const { CVData, error } = useCVTab();

  const [showForm, setShowForm] = useState(false);
  // const [certifications, setCertifications] = useState([]);
  const [editingCertification, setEditingCertification] = useState(false);
  const hider = () => {
    hideAllOverview(false);
    showComponent(false);
  };
  // useEffect(() => {
  //   if (CVData && CVData.certification && Array.isArray(CVData.certification)) {
  //     setCertifications(CVData.certification);
  //   }
  // }, [CVData]);
  // if (error) {
  //   return <Label message="error.errorFetchData" />;
  //   // "errorFetchData": "An error occured when fetching data",
  //   //   "fetchingData":
  // }
  // if (!CVData) {
  //   return <Label message="error.fetchingData" />;
  // }

  // if (CVData) {
  //   setCertifications(CVData.certification)
  // }

  return (
    <motion.div variants={item}>
      <div className="flex items-center gap-fourthDegree w-full">
        <ArrowBackIcon className="cursor-pointer" onClick={hider} />
        <div className="basis-3/4">
          <Label
            message="consultants.statement.prospectConsultantPanel.certifications"
            size="h3"
            fontWeight="bold"
          />
        </div>

        <Button
          showLeftIcon={true}
          leftIcon={<AddIcon fontSize="small" />}
          showText={true}
          text="global.actions.add"
          sizePadding="default" // Utilisez "small" pour un padding plus petit
          style="ghost"
          className="font-bold basis-1/4 "
          onClick={() => setShowForm(true)}
          //onClick={() => setShowHandler(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
        />
      </div>
      <div className=" flex flex-col gap-secondDegree">
        {showForm && (
          <AddCertification
            showForm={showForm}
            setShowForm={setShowForm}
            setCertifications={setCertifications}
            editingCertification={false}
            setEditingCertification={setEditingCertification}
          />
        )}
        {certifications &&
          certifications.map((certification, index) => {
            return (
              <PrintCertification
                key={index}
                certification={certification}
                setEditingCertification={setEditingCertification}
                editingCertification={editingCertification}
                setCertifications={setCertifications}
              />
            );
          })}
      </div>
    </motion.div>
  );
}