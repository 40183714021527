import React from "react";

import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { Avatar } from "@/components/atoms/avatar";

import EducationItem from "./EducationItem";
import CertificationItem from "./CertificationItem";
import ExperienceItem from "./ExperienceItem";
import ProjectItem from "./ProjectItem";
import SkillItem from "./SkillItem";
import DescriptionItem from "./DescriptionItem";
import JobTitleItem from "./JobTitleItem";
import SaveTemplate from "./SaveTemplate";
import EditCVPanel from "@/components/macro-organisms/panels/panels-admin/EditCV/EditCVPanel";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import useCVTab from "../../../../../hooks/global-hooks/useCVTab";
import { useEffect } from "react";

export default function RenderCvContent({ CVData, isMain = false }) {
    // const { CVData, error } = useCVTab();

    // console.log("CVData.profile", CVData.profile, "CVData.profile.urlPicture", CVData.profile[0].urlPicture, "CVData.profile.name", CVData.profile.name);
    const navigate = useNavigate();
    // console.log("RenderCvContent, CVData : ", CVData);
    useEffect(() => {
        console.log("RenderCvContent  CVData =", CVData);
    }, [CVData]);

    return !CVData ? (
        <Label>Squeleton</Label>
    ) : (
        <div
            className={`flex flex-col gap-secondDegree rounded-sm  ${!isMain &&
                " h-[55vh] overflow-y-auto border border-white p-3 bg-hoverLight "
                } `}
        >
            {isMain ? (
                <div className=" flex gap-secondDegree">
                    <div className="bg-white shadow-md rounded-sm grow gap-secondDegree p-firstDegree flex">
                        {CVData.user && (CVData.user.urlPicture || CVData.user.name) && (
                            <Avatar
                                fallback={!CVData.user.urlPicture && CVData.user.name}
                                size="large"
                                className="shrink-0"
                                src={
                                    CVData.user.urlPicture != ""
                                        ? CVData.user.urlPicture
                                        : undefined
                                }
                            />
                        )}
                        <div className="flex flex-col gap-firstDegree">
                            <div className="flex items-center space-between overflow-auto">
                                {CVData.user && CVData.user.name && (
                                    <div className="grow">
                                        <Label fontWeight="bold" size="h4">
                                            {CVData.user.name}
                                        </Label>
                                    </div>
                                )}
                                {CVData.jobTitle && (
                                    <>
                                        {CVData.jobTitle.map((jobTitle, index) => (
                                            <JobTitleItem key={jobTitle.id} data={jobTitle} />
                                        ))}
                                    </>
                                )}
                            </div>
                            {CVData.description && (
                                <>
                                    {CVData.description.map((description, index) => (
                                        <DescriptionItem key={description.id} data={description} />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col w-[230px] gap-firstDegree">
                        <Button
                            style="outline"
                            className="font-semibold"
                            showLeftIcon
                            leftIcon={<PictureAsPdfIcon />}
                            text="global.actions.selectForPdf"
                            onClick={() => navigate("./generate")}
                        />
                        <span className="text-center leading-none">
                            <Label size="caption" message="consultants.cv.editCaption" />
                        </span>
                        <EditCVPanel />
                        {CVData.template && (
                            <div className="bg-white flex flex-col shadow-md rounded-sm p-firstDegree gap-firstDegree overflow-auto">
                                <Label fontWeight="bold" message="consultants.cv.saved" />
                                {CVData.template.map((templateItem, index) => (
                                    <SaveTemplate key={templateItem.id} data={templateItem} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-secondDegree">
                    {CVData.jobTitle && CVData.jobTitle.length > 0 && (
                        <>
                            <Label
                                size="h3"
                                fontWeight="bold"
                                message="consultants.cv.jobTitle"
                            />
                            {CVData.jobTitle.map((jobTitle, index) => (
                                <JobTitleItem
                                    key={jobTitle.id}
                                    data={jobTitle}
                                    className={
                                        isMain
                                            ? ""
                                            : "bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree w-full relative grow-0"
                                    }
                                />
                            ))}
                        </>
                    )}

                    {CVData.description && CVData.description.length > 0 && (
                        <>
                            <Label
                                size="h3"
                                fontWeight="bold"
                                message="consultants.cv.description"
                            />

                            {CVData.description.map((description, index) => (
                                <DescriptionItem
                                    key={description.id}
                                    data={description}
                                    className={
                                        isMain
                                            ? ""
                                            : "bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree min-w-[320px] relative grow-0"
                                    }
                                />
                            ))}
                        </>
                    )}
                </div>
            )}
            {CVData.skills &&
                CVData.skills.some((skill) => skill.type === "hard") && (
                    <div className=" gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.hardSkills"
                        />
                        <div className="py-1 flex gap-firstDegree overflow-auto  flex-wrap ">
                            {CVData.skills.map(
                                (skillItem, index) =>
                                    skillItem.type == "hard" && (
                                        <SkillItem key={skillItem.id} data={skillItem} />
                                    )
                            )}
                        </div>
                    </div>
                )}
            {CVData.skills &&
                CVData.skills.some((skill) => skill.type === "soft") && (
                    <div className=" gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.softSkills"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto  flex-wrap">
                            {CVData.skills.map(
                                (skillItem, index) =>
                                    skillItem.type == "soft" && (
                                        <SkillItem key={skillItem.id} data={skillItem} />
                                    )
                            )}
                        </div>
                    </div>
                )}
            {CVData.skills &&
                CVData.skills.some((skill) => skill.type === "power") && (
                    <div className=" gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.powerSkills"
                        />
                        <div className="py-1 rounded-sm flex gap-firstDegree overflow-auto flex-wrap">
                            {CVData.skills.map(
                                (skillItem, index) =>
                                    skillItem.type == "power" && (
                                        <SkillItem key={skillItem.id} data={skillItem} />
                                    )
                            )}
                        </div>
                    </div>
                )}

            {isMain && <Separator />}

            {CVData.project && CVData.project.length > 0 && (
                <>
                    <div className="gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.project"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto">
                            {CVData.project.map((projectItem, index) => (
                                <ProjectItem
                                    key={projectItem.id}
                                    data={projectItem}
                                    className="max-w-[320px]"
                                />
                            ))}
                        </div>
                    </div>
                    {isMain && <Separator />}
                </>
            )}

            {CVData.experience && CVData.experience.length > 0 && (
                <>
                    <div className="gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.experience"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto ">
                            {CVData.experience.map((experienceItem, index) => (
                                <ExperienceItem
                                    key={experienceItem.id}
                                    data={experienceItem}
                                    className="max-w-[320px]"
                                />
                            ))}
                        </div>
                    </div>
                    {isMain && <Separator />}
                </>
            )}

            {CVData.certification && CVData.certification.length > 0 && (
                <>
                    <div className="gap-secondDegree">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.certification"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto">
                            {CVData.certification.map((certificationItem, index) => (
                                <CertificationItem
                                    key={certificationItem.id}
                                    data={certificationItem}
                                    className="max-w-[320px]"
                                />
                            ))}
                        </div>
                    </div>
                    {isMain && <Separator />}
                </>
            )}

            {CVData.education && CVData.education.length > 0 && (
                <>
                    <div className="gap-secondDegree ">
                        <Label
                            size="h3"
                            fontWeight="bold"
                            message="consultants.cv.education"
                        />

                        <div className="py-1 flex gap-firstDegree overflow-auto">
                            {CVData.education.map((educationItem, index) => (
                                <EducationItem
                                    key={educationItem.id}
                                    data={educationItem}
                                    className="max-w-[320px]"
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
