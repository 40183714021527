import React from 'react'
import StatementTab from '../../consultants-vision-admin/statement/StatementTab'
function StatementTabUser() {
  return ( <div className='px-6 2xl:px-10'>
  <StatementTab/>
  </div>
    
  )
}

export default StatementTabUser