import React, { useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import UniqueSelect from '@/components/molecules/inputs/UniqueSelect';
import { getSkillsType } from '@/services/admin-services/setSkillPanelData';
import UniqueSelect2 from '@/components/molecules/inputs/UniqueSelect2';
import { Button } from '@/components/molecules/buttons/button';
import { Label } from '@/components/atoms/texts/label';

export default function PrintSkillAdded({
  skill,
  setSkills,
  skills,
  setChangeContent,
  setNumberOfConsultant,
  setSkillName,
}) {
  const [skillPrinted, setSkillPrinted] = useState(skill);

  const handleDelete = (itemToDelete) => {
    //setShowForm(false);
    if (skill) {
      setSkills((prevData) =>
        prevData.filter((item) => item.id !== itemToDelete.id)
      );
    }
  };
  const handleChange = () => {
    setChangeContent(true);
    setNumberOfConsultant(skillPrinted.numberOfUsage);
    setSkillName(skillPrinted.title)
  };

  return (
    <div className="w-full px-firstDegree flex flex-row gap-secondDegree items-center  justify-between ">
      <div className=" flex flex-row gap-firstDegree  basis-3/2 ">
        <div className="flex flex-row items-center gap-1 w-full">
          <Button
            showLeftIcon={true}
            leftIcon={<DeleteIcon className="text-primary" />}
            padding="small"
            style="ghost"
            showText={false}
            onClick={() => handleDelete(skill)}
          />

          <Label fontWeight="bold">{skillPrinted && skillPrinted.title}</Label>
        </div>

        <div className=" flex flex-row gap-betweenText items-center  text-center basis-3/2 ">
          <Label
            message="consultants.statement.setSkillsPanel.usedBy"
            size="body"
            className="truncate"
          />

          <span
            className={`${ skillPrinted.numberOfUsage >0 ? 'cursor-pointer': 'cursor-not-allowed'} font-bold  text-body underline`}
            onClick={() => handleChange()}
          >
            {skillPrinted && skillPrinted.numberOfUsage}
          </span>

          <Label
            message={
              skillPrinted.numberOfUsage > 1
                ? "consultants.informations.consulantLowerCase"
                : "global.information.consultant"
            }
            size="body"
          />
        </div>
      </div>

      <div className=" basis-1/4">
        <UniqueSelect2
          dataToSelect={getSkillsType()}
          defaultValue={skillPrinted.type}
          //showLabel={false}
        />
      </div>
    </div>
  );
}
