// useConsultantData.js
import { useState, useEffect } from "react";
import { getValuationData } from "@/services/admin-services/valuationData";



const useValuationData= () => {
  const [valuationData, setValuationData] = useState(null);
  const [items, setItems]=useState(null)
  const [error, setError] = useState(null);

  useEffect(() => {

    const getValuationItems = (data) => {
      // Extraction des valeurs 'item'
      const itemValues = data.map((d) => d.item); // Retourne uniquement les valeurs 'item'

      return itemValues;
    };
    const fetchData = async () => {
      try {
        const data = await getValuationData();
        setValuationData(data);
        setItems(getValuationItems(data))
      } catch (error) {
        setError(error);
        //errorSignal.value=error
        console.error("Erreur récupération des données", error);
      }
    };

    fetchData();
  }, []);

  

  return { valuationData, error, items };
};


export default useValuationData;
