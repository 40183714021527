import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Label } from "@/components/atoms/texts/label";

import { cn } from "@/lib/utils";
import Chip from "@/components/molecules/buttons/Chip";
import { ArrowDownward, ArrowUpward, HelpOutline } from "@mui/icons-material";

import ToolTip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
        {
            //label: "# of Votes",
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                "rgba(94, 139, 224, 1)",
                "rgba(96, 88, 222, 1)",
                "rgba(119, 210, 227, 1)",
                "rgba(126, 226, 178, 1)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
            ],
            //   borderColor: [
            //     "rgba(255, 99, 132, 1)",
            //     "rgba(54, 162, 235, 1)",
            //     "rgba(255, 206, 86, 1)",
            //     "rgba(75, 192, 192, 1)",
            //     "rgba(153, 102, 255, 1)",
            //     "rgba(255, 159, 64, 1)",
            //   ],
            borderWidth: 1,
        },
    ],
};
const defaultLabel = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
const dataD = [12, 19, 3, 5, 2, 3]
const formatDataOptions = (datas) => {
    return {
        labels: Object.values(datas).map((data) => data.label),
        datasets: [
            {
                //label: "# of Votes",
                data: Object.values(datas).map((data) => data.data),
                backgroundColor: [
                    "rgba(94, 139, 224, 1)",
                    "rgba(96, 88, 222, 1)",
                    "rgba(119, 210, 227, 1)",
                    "rgba(126, 226, 178, 1)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                ],
                //   borderColor: [
                //     "rgba(255, 99, 132, 1)",
                //     "rgba(54, 162, 235, 1)",
                //     "rgba(255, 206, 86, 1)",
                //     "rgba(75, 192, 192, 1)",
                //     "rgba(153, 102, 255, 1)",
                //     "rgba(255, 159, 64, 1)",
                //   ],
                borderWidth: 1,
            },
        ],
    };
}

export default function DoughnutChart({
    datas,
    evolution,
    helpText,
    label,
    roundedBorders = true,
    chipText,
    className,
}) {
    const dataForChart = formatDataOptions(datas);
    const [t] = useTranslation("global");
    const text = t(helpText);
    // console.log('me help', text)
    const borders = roundedBorders
        ? "shadow-lg rounded-sm border border-medium"
        : "shadow-0 rounded-0 border border-0  ";

    return (
        <div
            className={cn(
                "py-firstDegree px-secondDegree flex flex-col gap-fifthDegree bg-white items-center w-full h-full min-h-[155px] object-contain relative ",
                borders,
                className
            )}
        >
            <div className="w-full flex justify-between  items-stretch absolute">

                <div> </div>
                {helpText && (
                    <ToolTip title={text} placement="top" arrow>

                        <HelpOutline className="top-[-10px] text-grey  " />
                    </ToolTip>
                )}
            </div>

            <Doughnut
                data={dataForChart}
                options={{
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                }}
                className="mt-[30px]"
            />

            <div className=" flex flex-row gap-betweenText items-center font-bold ">
                {/* <span className={`${evolution > 0 ? "text-success" : "text-error"}`}>
          {evolution && evolution > 0 && "+"}
          {evolution + "%"}
        </span> */}
                {/* {evolution && evolution > 0 ? (
          <ArrowUpward className=" text-success" fontSize="small" />
        ) : (
          <ArrowDownward className=" text-error" fontSize="small" />
        )} */}
            </div>

            {label && <Label message={label} size="body" fontWeight="bold" />}
        </div>
    );
}
