import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
/* eslint-disable react/prop-types */
import React from 'react'
import AddCertification from "./AddCertification";

export default function PrintCertification({
  certification,
  setEditingCertification,
  editingCertification,
  setCertifications,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const handleEdit = () => {
    setEditingCertification(true);
    setShowForm(true);
  };

  return (
    <div className="flex flex-col gap-firstDegree">
      {!showForm && (
        <div className="flex gap-[12px] ">
          {!showDetails && (
            <ExpandMoreIcon onClick={() => setShowDetails(true)} />
          )}
          {showDetails && (
            <ExpandLessIcon onClick={() => setShowDetails(false)} />
          )}
          <span className="font-bold flex items-center">
            {certification.title}
          </span>
          <EditIcon
            className="text-primary cursor-pointer"
            onClick={handleEdit}
          />
        </div>
      )}
      {!showForm && showDetails && (
        <div className="flex flex-col gap-firstDegree">
          <div className="flex flex-row gap-firstDegree py-firstDegree text-bodySmall items-center">
            <span className="font-bold">{certification.organism}</span>
            <span>{certification.date}</span>
          </div>
          <div className="text-bodySmall">
            <span>{certification.description}</span>
          </div>
        </div>
      )}
      <AddCertification
        certification={certification}
        showForm={showForm}
        setShowForm={setShowForm}
        editingCertification={editingCertification}
        setEditingCertification={setEditingCertification}
        setCertifications={setCertifications}
      />
    </div>
  );
}
