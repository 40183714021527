import React from "react";
import LineArt from "../../assets/images/lineArt.png";
import logoBigS from "../../assets/images/logoBigS.svg";
import ConnectionForm from "@/components/organisms/forms/ConnectionForm";

export default function Login() {

    return (
        <div className="h-[93%] w-full bg-verticalPrimaryLinear overflow-hidden">
            <div className="h-full w-2/3 bg-primary relative grid place-content-evenly">

                <img src={LineArt} className="z-0 hidden sm:block w-[110vw] absolute bottom-0 left-[-5vw] max-w-none opacity-50	" alt="linear waves" />
                <img src={logoBigS} className="z-10 hidden box-anim md:block h-80 mr-48 lg:mr-16" alt="logo" />
                <ConnectionForm ></ConnectionForm>

            </div>
        </div>
    );
};
