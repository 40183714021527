// useConsultantData.js
import { useState, useEffect } from 'react';
import { getUsers } from '../../../../services/global-services/consultantService';


import { effect } from '@preact/signals-react';

const useConsultantData = () => {
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userList = await getUsers();
          setUsers(userList);
          
      } catch (error) {
          setError(error);
          //errorSignal.value=error
         console.error("Erreur récupération des données", error);
      }
    };

    fetchData();
  }, []);

  return { users, error };
};

export default  useConsultantData ;