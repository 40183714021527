import { Label } from '@/components/atoms/texts/label';
import { Button } from '@/components/molecules/buttons/button';
import React from 'react';

export default function SkillItem({ data, className, ...props }) {
    let type = ""
    switch (data.type) {
        case "hard":
            type = "H"
            break;
        case "soft":
            type = "S"
            break;
        case "power":
            type = "P"
            break;
        default:
            type = ""
            break;
    }
    return (
        <div className={`flex justify-start items-center rounded-sm border border-medium p-2  bg-white shadow-sm ${className}`}>
            <div className='h-6 w-6 flex justify-center bg-medium  rounded-[50px] mr-2 border border-grey'>{type}</div>
            <Label>{data.title}</Label>
        {/* <Button style="outline" sizePadding="small" className='rounded-chip' {...props}></Button> */}
        </div>
    )
}