import React, { useRef, useState, useEffect } from 'react';

import { Button } from "@/components/molecules/buttons/button";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import Expense from "@/components/macro-organisms/tabs/consultant-vision-user/expense-account/expense";
import useExpenseData from "./useExpenseData";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import FormNewExpense from "../../consultant-vision-user/expense-account/formNewExpense";
import ImageWithProgress from './imageWithProgress';




export default function ExpenseTab() {
    const { expenses, error } = useExpenseData();
    const [expensesCopy, setExpensesCopy] = useState([]);
    let isDeclined = false;
    const submitRef = useRef(null);
    const [expensesToApprove, setExpensesToApprove] = useState([]);

    useEffect(() => {
        if (expenses) {
            // Tri des expenses par statut
            const filteredExpenses = expenses.filter(expense => expense.statut === "0");
            const sorted = filteredExpenses.sort((a, b) => new Date(b.date) - new Date(a.date));

            setExpensesToApprove(sorted);
            setExpensesCopy(expenses)
        }
    }, [expenses]);

    const handleSubmit = async (formData) => {
        console.log("Form data:", formData);
        let statut_ = expensesToApprove[0].statut;
        try {
            // const response = await fetch('API_URL', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(formData)
            // });
            // const data = await response.json();
            // console.log("Response:", data);
            setExpensesToApprove(prevExpenses => prevExpenses.slice(1));
            setExpensesCopy(prevExpenses => prevExpenses.map(expense => {
                if (expense.id === formData.id) {
                    // Retourner un nouvel objet avec le statut modifié
                    return { ...expense, statut: statut_ };
                }
                return expense;
            }));

        } catch (error) {
            console.error("Erreur lors de la soumission:", error);
        }
    };
    const handleDownload = async () => {
        console.log("handleDownload:");
        try {
            // const response = await fetch('API_URL', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(formData)
            // });
            // const data = await response.json();
            // console.log("Response:", data);
        } catch (error) {
            console.error("Erreur lors de la soumission:", error);
        }
    };
    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-scroll pb-secondDegree px-1">
            <div className="flex gap-secondDegree">

                {expensesToApprove.length > 0
                    ?
                    <Sheet className="">
                        <SheetTrigger >
                            <Button leftIcon={<PlaylistAddCheckRoundedIcon />} showLeftIcon style="outline" text="consultants.expense.approveExpense" />

                        </SheetTrigger>
                        <SheetContent>
                            <SheetHeader
                                title={"consultants.expense.newExpense"}
                            />
                            <SheetSlot className=" flex  relative">
                                {/* {console.log("test avant image", expensesToApprove)} */}
                                <ImageWithProgress
                                    src={expensesToApprove[0].url} />
                                {/* // src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/ReceiptSwiss.jpg/1920px-ReceiptSwiss.jpg"/> */}
                                <FormNewExpense onSubmit={handleSubmit} submitRef={submitRef} data={expensesToApprove[0]} />
                            </SheetSlot>
                            <SheetFooter
                                buttonOutlineText={"global.actions.decline"}
                                buttonDressedText={"global.actions.ConfirmNext"}
                                warning={"global.userBeNotified"}
                                onClickOutline={() => {
                                    expensesToApprove[0].statut = "-1";//declined
                                    submitRef.current && submitRef.current.click();
                                }}
                                onClickDressed={() => {
                                    expensesToApprove[0].statut = "1";//approve
                                    submitRef.current && submitRef.current.click()
                                }}
                            />
                        </SheetContent>
                    </Sheet>
                    :
                    <Button leftIcon={<PlaylistAddCheckRoundedIcon />} disabled showLeftIcon style="outline" text="consultants.expense.approveExpense" />

                }


                <Button leftIcon={<FileDownloadRoundedIcon />} showLeftIcon text="consultants.expense.downloadCSV" onClick={handleDownload} />
            </div>
            {(!error && expensesCopy) && expensesCopy.map((item, index) => (
                <Expense key={index} data={item} />
            ))}
        </div>
    )
}