/* eslint-disable react/display-name */
// Importing React and necessary custom components and utilities
import * as React from "react";
import { cn } from "@/lib/utils";// Utility function for class name concatenation
import { Label } from "../atoms/texts/label";// Custom Label component for text rendering
import { Button } from "../molecules/buttons/button"; // Custom Button component for actions

// The Card component using React.forwardRef for potential ref forwarding
const Card = React.forwardRef(({
  className, // Custom className for additional styling
  title, // Title of the card
  description, // Description of the card
  content, // Main content of the card
  footerOutlinedButtonText, // Text for the outlined button in the footer
  footerFilledButtonText, // Text for the filled button in the footer
  iconRightBtnOutlined, // Optional right icon for the outlined button
  iconLeftBtnOutlined, // Optional left icon for the outlined button
  iconRightBtnFilled, // Optional right icon for the filled button
  iconLeftBtnFilled, // Optional left icon for the filled button
  onClickBtnFilled, 
  onClickBtnOutlined,
  classNameFooter,
  btnFooterStretch,
  ...props // Other props
}, ref) => (
  < div
    ref={ref}
    className={cn("rounded-md border bg-lighter text-night shadow-sm px-secondDegree py-thirdDegree gap-4 flex flex-col", className)}
    {...props} >
    <CardHeader title={title} description={description} />
    <CardContent className="max-h-[30vh] overflow-scroll">{content}</CardContent>
    <CardFooter
      outlinedButtonText={footerOutlinedButtonText}
      iconRightBtnOutlined={iconRightBtnOutlined}
      iconLeftBtnOutlined={iconLeftBtnOutlined}

      filledButtonText={footerFilledButtonText}
      iconRightBtnFilled={iconRightBtnFilled}
      iconLeftBtnFilled={iconLeftBtnFilled}
      onClickBtnOutlined={onClickBtnOutlined}
      onClickBtnFilled={onClickBtnFilled}
      className={classNameFooter}
      btnFooterStretch={btnFooterStretch}
    />
  </div >
));

// The CardHeader component displaying the card's title and description using the Label component
const CardHeader = React.forwardRef(({ title, description }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-secondDegree flex-wrap")}>
    <Label size="h2" fontWeight="semibold" message={title} />
    <Label size="body" fontWeight="regular" message={description} />
  </div>
));

// The CardContent component for the main content area of the card
const CardContent = React.forwardRef(({ children, className }, ref) => (
  <div ref={ref} className={cn("", className)}>
    {children}
  </div>
));

// The CardFooter component containing action buttons with optional icons
const CardFooter = React.forwardRef(({ outlinedButtonText, filledButtonText, className, iconRightBtnOutlined, iconLeftBtnOutlined, iconRightBtnFilled, iconLeftBtnFilled, onClickBtnOutlined, onClickBtnFilled, btnFooterStretch }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-right justify-end space-x-secondDegree flex-wrap", className)}>
    {(outlinedButtonText != undefined) &&
      <Button
        showLeftIcon={iconLeftBtnOutlined ? true : false}
        leftIcon={iconLeftBtnOutlined}

        showRightIcon={iconRightBtnOutlined ? true : false}
        rightIcon={iconRightBtnOutlined}

        text={outlinedButtonText}
        sizePadding="default"
        style="outline"
        onClick={() => onClickBtnOutlined()}
        className={btnFooterStretch && "grow"}
      />
    }
    {(filledButtonText != undefined) &&
      <Button
        showLeftIcon={iconLeftBtnFilled ? true : false}
        leftIcon={iconLeftBtnFilled}

        showRightIcon={iconRightBtnFilled ? true : false}
        rightIcon={iconRightBtnFilled}

        showText={true}
        text={filledButtonText}

        sizePadding="default"
        style="fill"
        onClick={() => onClickBtnFilled()}
        className={btnFooterStretch && "grow"}

      />
    }
  </div>
));

// Exporting Card and its subcomponents for external use
export { Card, CardHeader, CardFooter, CardContent };
