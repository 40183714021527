/* eslint-disable react/prop-types */
import { Button } from "@/components/molecules/buttons/button";
import { Label } from "@/components/atoms/texts/label";
import { languages } from "countries-list";
import Input from "@/components/molecules/inputs/Input";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import UniqueSelect from "@/components/molecules/inputs/UniqueSelect";

import { useEffect, useState } from "react";
import { getLanguageCode, languageNames } from "@/utils/languagesUtils";
import { getEmojiFlag } from "countries-list";
import UniqueSelect2 from "@/components/molecules/inputs/UniqueSelect2";
import { useTranslation } from "react-i18next";

//const languageNames = Object.values(languages).map((lang) => lang.name);
const languageLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];

export default function PrintLanguageLine({
  language,

  setEditingLanguage,
  editingLanguage,
  setLanguages,
}) {
 const [t] = useTranslation("global");
 useEffect(() => {
   const mother = t("global.information.motherTongue");
   if (languageLevels[6]) {
     languageLevels[6] != mother && languageLevels.push(mother);
   } else {
     languageLevels.push(mother);
   }
 }, [t]);
 
  // const [showForm, setShowForm] = useState(false);
  // const handleEdit = () => {
  //   setShowForm(true);
  //   setEditingLanguage(true);
  // };
   const handleDelete = () => {
     //setShowForm(false);
     if (language) {
       // Supprimer la certification de la liste
       setLanguages((prevData) =>
         prevData.filter((item) => item.id !== language.id && item.language!=language.language)
       );
     }
   };
  //const [hideInput, setHideInput] = useState(false);
  return (
    <div className=" flex gap-betweenText items-center text-body">
      <Button
        onClick={handleDelete}
        showLeftIcon={true}
        showText={false}
        leftIcon={<DeleteIcon className="text-primary cursor-pointer" />}
        style="ghost"
      />
      {/* <Input
        className=" text-body"
        value={language.language}
        // value={
        //   getEmojiFlag(
        //     language.language === "English"
        //       ? "GB"
        //       : getLanguageCode(language.language)
        //   ) +
        //   " " +
        //   language.language
        // }
        readOnly={true}
        //  onClick={() => setHideInput(true)}
      /> */}
      <UniqueSelect
        className=""
        showLabel={false}
        dataToSelect={languageNames}
        placeholderSelect={language.language}
        //filterDataFunction={setLevel}
        id="3"
      />
      <UniqueSelect
        className=""
        showLabel={false}
        dataToSelect={languageLevels}
        placeholderSelect={language.level}
        //filterDataFunction={setLevel}
        id="3"
      />
      {/* <UniqueSelect2
        className="basis-1/2"
        dataToSelect={languageLevels}
        defaultValue={language.level}
      /> */}
    </div>
  );
}
