/* eslint-disable react/prop-types */


import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";


import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetSlot,
} from "@/components/templates/sheet";

import CreateAndUpdateCV from "../prospectConsultant/CreateAndUpdateCV";

import { useToast } from "@/components/organisms/feedback/use-toast";
import EditIcon from "@mui/icons-material/Edit";

{
  /*
consultants.statement.prospectConsultantPanel.informations
header
.create 
orTakeOver
draft
generalInformation
createAndUpdateCV
canbeDoneLater
profileTitle
informations
Description
skills
experience
education
certifications
projects
*/
}

export default function EditCVPanel({ ...props }) {
  const [isNewClicked, setNewClicked] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [openCvSection, setOpenCvSection] = useState(false);
  const [draftIsClicked, setDraftIsClicked] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [userCreated, setUserCreated] = useState({
    name: "",
    firstName: "",
    personalEmail: "",
  });
  const { toast } = useToast();

  const displayToast = () => {
    toast({
      variant: "warning",
      title: "consultants.statement.prospectConsultantPanel.autosaved",
      duration: 1500,
    });
  };
  const handleOpen = () => {
    setIsOpen(!isOpen);
    setOpenCvSection(false);
    setNewClicked(false);
    setDraftIsClicked(false);
    isEditing ? displayToast() : "";

    setEditing(false);
  };

  function onCancel() {
    isEditing ? displayToast() : "";

    setIsOpen(false);
    // setActionVariant("default");
  }

  return (
    <div>
      <Sheet open={isOpen} onOpenChange={handleOpen}>
        <SheetTrigger className="w-full">
          <Button
            style="ghost"
            showLeftIcon={true}
            leftIcon={<EditIcon/>}
            text="global.actions.editInfos"
            className="w-full"
          />
        </SheetTrigger>
        <SheetContent>
          <SheetHeader
            title={"consultants.statement.prospectConsultantPanel.header"}
            // subheader="consultants.statement.vatSettings.autoSaved"
          />
          <SheetSlot>
           
              <CreateAndUpdateCV
                shoulGetSectionsData={true}
                showButtonsBackAndSave={false}
                showStepper={false}
                showingSectionInProspect={false}
             
              />
          
          </SheetSlot>
        </SheetContent>
      </Sheet>
    </div>
  );
}
