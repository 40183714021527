import React from "react";

const ProgressBar = ({ percentage }) => {
  // Contrainte du pourcentage entre 0 et 100
  const validatedPercentage = Math.max(0, Math.min(100, percentage));

  return (
    <div className="w-full h-8 bg-gray-300 rounded-sm relative ">
      {/* Partie bleue pour indiquer le pourcentage */}
      <div
        className={`${percentage ==100 ? "bg-success": "bg-gradientPrimarySecondary" } h-full  rounded-sm `}
        style={{ width: `${validatedPercentage}%` }}
      >
        {" "}
        
      </div>

      {/* SVG sur le rectangle comme tête de flèche */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="100%"
        viewBox="0 0 11 52"
        fill="none"
        className="absolute top-1/2 transform -translate-y-1/2"
        style={{
          left: "30%",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <path
          d="M1.5 1L8.24885 20.8496C9.38437 24.1893 9.38436 27.8107 8.24885 31.1504L1.5 51"
          stroke="#FEFEFE"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="100%"
        viewBox="0 0 11 52"
        fill="none"
        className="absolute"
        style={{
          left: "66%",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <path
          d="M1.5 1L8.24885 20.8496C9.38437 24.1893 9.38436 27.8107 8.24885 31.1504L1.5 51"
          stroke="#FEFEFE"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default ProgressBar;
