import { Label } from '@/components/atoms/texts/label'
import React from 'react'

export default function DataDeletion() {
    return (
        <div className="flex flex-col gap-secondDegree p-secondDegree">
            <div className="flex flex-col gap-3">
                <Label message="consultants.informations.dataDeletionInfo.consultant.q1" fontWeight="bold"></Label>
                <Label message="consultants.informations.dataDeletionInfo.consultant.a1_1"></Label>
                <Label message="consultants.informations.dataDeletionInfo.consultant.a1_2"></Label>
            </div>
            <div className="flex flex-col gap-3">
                <Label message="consultants.informations.dataDeletionInfo.consultant.q2" fontWeight="bold"></Label>
                <Label message="consultants.informations.dataDeletionInfo.consultant.a2"></Label>
            </div>
            <div className="flex flex-col gap-3">
                <Label message="consultants.informations.dataDeletionInfo.consultant.q3" fontWeight="bold"></Label>
                <Label message="consultants.informations.dataDeletionInfo.consultant.a3_1"></Label>
                <ul className="list-disc list-inside">
                    <li><Label message="consultants.informations.dataDeletionInfo.consultant.a3_2" wFull="true"></Label></li>
                    <li><Label message="consultants.informations.dataDeletionInfo.consultant.a3_3"></Label></li>
                    <li><Label message="consultants.informations.dataDeletionInfo.consultant.a3_4"></Label></li>
                    <li><Label message="consultants.informations.dataDeletionInfo.consultant.a3_5"></Label></li>
                    <li><Label message="consultants.informations.dataDeletionInfo.consultant.a3_6"></Label></li>
                    <li><Label message="consultants.informations.dataDeletionInfo.consultant.a3_7"></Label></li>
                </ul>
                <Label message="consultants.informations.dataDeletionInfo.consultant.a3_8"></Label>

            </div>
        </div>
    )
}